import { Component, Input } from "@angular/core";

@Component({
  selector: "page",
  templateUrl: "page.html",
})
export class PageComponent {
  @Input() detailData: { title: string; status: string };
  @Input() firewalled: boolean;
  @Input() productType: string;
  @Input() terminated: boolean;

  constructor() {}
}
