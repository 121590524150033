import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiHelper } from "../../../helpers/apihelper";
import { AuthService } from "../../../services/AuthService";
import { NotificationSetting } from "../../../helpers/types";

@Component({
  selector: "dialog-notification-settings",
  templateUrl: "dialog-notification-settings.html",
  styleUrls: ["dialog-notification-settings.scss"],
})
export class DialogNotificationSettingsComponent {
  public name: string;
  public notificationSetting: NotificationSetting;
  public subscriptionId: string;

  constructor(
    private dialog: MatDialog,
    private api: ApiHelper,
    public auth: AuthService,
    public dialogRef: MatDialogRef<DialogNotificationSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.subscriptionId = data.subscriptionId;
    this.name = data.name;
    if (data.impactSetting !== "") {
      this.notificationSetting = data.impactSetting;
    } else {
      this.notificationSetting = "Loss of Resiliency";
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  saveSettings() {
    const customerGuid = localStorage.getItem("viewingCustomerGUID");
    this.api
      .set_minimal_impact_notification(customerGuid, this.subscriptionId, this.notificationSetting)
      .then((result) => {
        this.dialogRef.close({ refresh: true, minimalImpactNotification: this.notificationSetting });
      })
      .catch((err) => {
        console.log(err);
      });
  }
}
