<div
  *ngIf="!loadingData && availablePorts.length === 0"
  class="dialog__body dialog__body--grow dialog__body--grey dialog__body--center selfservice-add-port__empty-state"
>
  <div class="selfservice-loader" style="max-width: 340px">
    <img src="assets/images/ports-illustration.svg" alt="" />
    <h3 class="selfservice-loader__title">{{ 'Selfservice.AddPort.EmptyTitle' | translate }}</h3>
    <p>{{ 'Selfservice.AddPort.EmptyText' | translate }}</p>
  </div>
</div>

<div
  *ngIf="currentStep === 1"
  class="dialog__body dialog__body--scroll dialog__body--flex-justify dialog__body--no-padding dialog__body--grow dialog__body--grey"
>
  <div class="dialog__inner">
    <h3 class="dialog__sub-heading">{{ 'Selfservice.AddPort.Subheading' | translate }}</h3>
    <p class="no-margin">{{ 'Selfservice.AddPort.Text' | translate }}</p>
  </div>
  <div>
    <div *ngIf="loadingData">
      <loader></loader>
    </div>
    <div *ngFor="let port of availablePorts; index as i" class="selfservice-add-port__bar">
      <div class="selfservice-add-port__left">
        <div class="selfservice-add-port__details" [ngClass]="{'active': port.selected}">
          <div class="selfservice-add-port__icon-box">
            <svg-icon src="assets/images/icons/port.svg"></svg-icon>
          </div>
          <div>
            <h3>{{ port.customerDescription }}</h3>
            <div class="selfservice-add-port__meta">
              {{ port.customer.fullname }}, {{ port.address.city }} ({{ port.address.street }}), {{ port.subscriptionId
              | shorten_guid }}
            </div>
          </div>
        </div>
      </div>
      <div class="selfservice-add-port__right">
        <div class="selfservice-add-port__toggle">
          <input [id]="'toggle-' + i" type="checkbox" [(ngModel)]="port.selected" />
          <label [for]="'toggle-' + i">
            <selfservice-toggle [active]="port.selected"></selfservice-toggle>
          </label>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="currentStep === 2"
  class="dialog__body dialog__body--scroll dialog__body--flex-justify dialog__body--no-padding dialog__body--grow dialog__body--grey"
>
  <div class="dialog__inner">
    <h3 class="dialog__sub-heading">{{ 'Selfservice.AddPort.Subheading2' | translate }}</h3>
    <p class="no-margin">{{ 'Selfservice.AddPort.Text2' | translate }}</p>
  </div>
  <div *ngFor="let port of selectedPorts; index as i" class="selfservice-add-port__bar">
    <div class="selfservice-add-port__left">
      <div class="selfservice-add-port__details" [ngClass]="{'active': port.selected}">
        <div class="selfservice-add-port__icon-box">
          <svg-icon src="assets/images/icons/port.svg"></svg-icon>
        </div>
        <div>
          <h3>{{ port.customerDescription }}</h3>
          <div class="selfservice-add-port__meta">
            {{ port.customer.fullname }}, {{ port.address.city }} ({{ port.address.street }}), {{ port.subscriptionId |
            shorten_guid }}
          </div>
        </div>
      </div>
      <div class="selfservice-add-port__left-bottom">
        <div>{{ 'Selfservice.AddPort.NotAvailableVlans' | translate }}</div>
        <ul class="selfservice-add-port__tags">
          <li *ngFor="let v of port.usedVlans">{{ v }}</li>
        </ul>
      </div>
    </div>
    <div class="selfservice-add-port__right">
      <div class="selfservice-add-port__form">
        <label>{{ 'Selfservice.AddPort.NewVlan' | translate }}</label>
        <input type="string" [(ngModel)]="port.vlan" [ngClass]="{'error': port.vlan && !validVlan(port, port.vlan)}" />
      </div>
      <div class="red" *ngIf="port.vlan && !validVlan(port, port.vlan)" style="font-size: 12px">
        {{ 'Selfservice.AddPort.VlanInUse' | translate }}
        <br />
        <br />
      </div>
      <p class="selfservice-add-port__footnote">{{ 'Selfservice.AddPort.VlanHint' | translate }}</p>
    </div>
  </div>
</div>

<div
  *ngIf="currentStep === 3"
  class="dialog__body dialog__body--scroll dialog__body--flex-justify dialog__body--no-padding dialog__body--grow dialog__body--grey"
>
  <div class="dialog__inner">
    <h3 class="dialog__sub-heading">{{ 'Global.Summary' | translate }}</h3>
    <p class="no-margin t-newline">{{ 'Selfservice.AddPort.SummaryText' | translate }}</p>
  </div>

  <div class="selfservice-add-port__summary">
    <div class="selfservice-add-port__summary-item">
      <div class="selfservice-add-port__summary-top" (click)="toggleSummaryItemOne()">
        <div class="selfservice-add-port__summary-top-left">{{ 'Selfservice.AddPort.AddedPorts' | translate }}</div>
        <div class="selfservice-add-port__summary-top-right">
          <div>{{ 'Selfservice.IPP.Total' | translate }}</div>
          <div>{{ selectedPorts.length }}</div>
          <svg-icon src="assets/images/icons/chevron-down.svg" [ngClass]="{'active': summaryItemOneExpanded}">
          </svg-icon>
        </div>
      </div>
      <div *ngIf="summaryItemOneExpanded" class="selfservice-add-port__summary-expand">
        <ul class="selfservice-add-port__list">
          <li
            *ngFor="let port of selectedPorts; index as i"
            class="selfservice-add-port__list-item selfservice-add-port__list-item--green"
          >
            <svg-icon src="assets/images/icons/+.svg"></svg-icon>
            <div>
              <div class="selfservice-add-port__list-title">{{ port.customerDescription }}</div>
              <div>
                {{ port.subscriptionId | shorten_guid }} - {{ port.customer.fullname }}, {{ port.address.city }} ({{
                port.address.street }}), VLAN {{ port.vlan }}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="selfservice-add-port__summary">
    <div class="selfservice-add-port__summary-item">
      <div class="selfservice-add-port__summary-top" (click)="toggleSummaryItemTwo()">
        <div class="selfservice-add-port__summary-top-left">
          {{ 'Selfservice.AddPort.AlreadyLinkedPorts' | translate }}
        </div>
        <div class="selfservice-add-port__summary-top-right">
          <div>{{ 'Selfservice.IPP.Total' | translate }}</div>
          <div>{{ subscription.esis.length }}</div>
          <svg-icon src="assets/images/icons/chevron-down.svg" [ngClass]="{'active': summaryItemTwoExpanded}">
          </svg-icon>
        </div>
      </div>

      <div *ngIf="summaryItemTwoExpanded" class="selfservice-add-port__summary-expand">
        <ul class="selfservice-add-port__list">
          <li
            *ngFor="let item of subscription.esis"
            class="selfservice-add-port__list-item selfservice-add-port__list-item--purple"
          >
            <div *ngIf="item.endpoints.length === 1" class="selfservice-add-port__list-item-inner">
              <svg-icon src="assets/images/icons/port.svg"></svg-icon>
              <div>
                <div class="selfservice-add-port__list-title">{{ item.endpoints[0].port.customerDescription }}</div>
                <div>
                  {{ item.endpoints[0].port.subscriptionId | shorten_guid }} - {{
                  item.endpoints[0].port.customer.fullname }}, {{ item.endpoints[0].port.address.city }} ({{
                  item.endpoints[0].port.address.street }}), VLAN {{ item.endpoints[0].vlanrange }}
                </div>
              </div>
            </div>

            <div *ngIf="item.endpoints.length > 1" class="selfservice-add-port__list-item-multihome">
              <div class="selfservice-add-port__list-item-multihome-title">Multihomed interface</div>
              <div *ngFor="let subitem of item.endpoints" class="selfservice-add-port__list-item-inner">
                <svg-icon src="assets/images/icons/port.svg"></svg-icon>
                <div>
                  <div class="selfservice-add-port__list-title">{{ subitem.port.customerDescription }}</div>
                  <div>
                    {{ subitem.port.subscriptionId | shorten_guid }} - {{ subitem.port.customer.fullname }}, {{
                    subitem.port.address.city }} ({{ subitem.port.address.street }}), VLAN {{ subitem.vlanrange }}
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div *ngIf="hasErrors" class="dialog__error dialog__error--margin-x">
    <ul>
      <li *ngFor="let e of errors">{{ e.msg }}</li>
    </ul>
  </div>
</div>
<div class="dialog__footer dialog__footer--center dialog__footer--border">
  <div class="dialog__footer-text">
    <selfservice-authentication-box></selfservice-authentication-box>
  </div>
  <div *ngIf="!loadingData && availablePorts.length === 0" class="button__flex">
    <button class="button button--secondary" mat-dialog-close>{{ 'Services.Action.Close' | translate }}</button>
  </div>
  <div *ngIf="currentStep === 1 && !loadingData && availablePorts.length > 0" class="button__flex">
    <button class="button button--secondary" mat-dialog-close>{{ 'Global.Action.Cancel' | translate }}</button>
    <button (click)="next()" class="button" [ngClass]="{'button--disabled': !selectedPorts.length}">
      {{ 'Global.Action.Continue' | translate }}
    </button>
  </div>
  <div *ngIf="currentStep === 2 && !loadingData && availablePorts.length > 0" class="button__flex">
    <button class="button button--secondary" (click)="prev()">{{ 'Global.Action.Back' | translate }}</button>
    <button (click)="next()" class="button">{{ 'Global.Action.Continue' | translate }}</button>
  </div>
  <div *ngIf="currentStep === 3 && !loadingData && availablePorts.length > 0" class="button__flex">
    <button class="button button--secondary" (click)="prev()">{{ 'Global.Action.Back' | translate }}</button>
    <button class="button" (click)="submit()" [ngClass]="{'loading': buttonLoading }">
      <svg-icon src="assets/images/icons/loader.svg" class="button__loader"></svg-icon>
      <span>{{ 'Global.Action.Confirm' | translate }}</span>
    </button>
  </div>
</div>
