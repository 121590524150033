import { Instance } from "./instance";
import { transform } from "../../helpers/subscription/wireless";

export class WirelessInstance extends Instance {
  customerDescriptions: string[];
  customerId: string;
  description: string;
  domain: string;
  name: string;
  subscriptionId: string;
  location: any;

  constructor(data?: any) {
    super();
    const details = transform(data);
    Object.entries(details).forEach((entry) => {
      this[entry[0]] = entry[1];
    });
  }
}
