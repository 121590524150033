import { gql } from "apollo-angular";
import { FirewallSubscriptionDetailsFragment } from "../fragments/firewall-subscription";
import { IpBgpSubscriptionFragment } from "../fragments/ip-bgp-subscription";
import { IpPrefixSubscriptionFragment } from "../fragments/ip-prefix-subscription";
import { IpStaticSubscriptionFragment } from "../fragments/ip-static-subscription";
import {
  LightPathReduntantSubscriptionFragment,
  LightPathSubscriptionFragment,
} from "../fragments/lightpath-subscription";
import { SubscriptionDetailsFragment } from "../fragments/fragments";
import {
  AggregatedServicePortSubscriptionDetailsFragment,
  IrbServicePortSubscriptionDetailsFragment,
  MscSubscriptionDetailsFragment,
  PortSubscriptionDetailsFragment,
} from "../fragments/port-subscription";
import { L3VpnSubscriptionFragment } from "../fragments/l3vpn-subscription";
import { L2VpnSubscriptionFragment } from "../fragments/l2vpn-subscription";
import { SurfWirelessSubscriptionDetailsFragment } from "../fragments/wireless";
import { BaseSubscriptionDetailsFragment } from "../fragments/base-subscription";

export const SUBSCRIPTION_STATUS_QUERY = gql`
  query SubscriptionStatusQuery($id: ID!) {
    subscription(id: $id) {
      subscriptionId
      status
    }
  }
`;

export const SUBSCRIPTION_QUERY = gql`
  ${IpBgpSubscriptionFragment}
  ${IpStaticSubscriptionFragment}
  ${LightPathSubscriptionFragment}
  ${LightPathReduntantSubscriptionFragment}
  ${IpPrefixSubscriptionFragment}
  ${FirewallSubscriptionDetailsFragment}
  ${PortSubscriptionDetailsFragment}
  ${L3VpnSubscriptionFragment}
  ${L2VpnSubscriptionFragment}
  ${SurfWirelessSubscriptionDetailsFragment}
  ${AggregatedServicePortSubscriptionDetailsFragment}
  ${MscSubscriptionDetailsFragment}
  ${IrbServicePortSubscriptionDetailsFragment}
  query SubscriptionDetailQuery($id: ID!, $portServices: Boolean = true) {
    subscription(id: $id) {
      subscriptionId
      customerId
      minimalImpactNotifications {
        impact
        customerId
      }
      metadata
      subscriptionType: __typename
      ...IpBgpSubscriptionDetails
      ...IpStaticSubscriptionDetails
      ...LightPathSubscriptionDetails
      ...LightPathRedundantSubscriptionDetails
      ...IpPrefixSubscriptionDetails
      ...FirewallSubscriptionDetails
      ...PortSubscriptionDetails
      ...L3VpnSubscriptionDetails
      ...L2VpnSubscriptionDetails
      ...SurfWirelessSubscriptionDetails
      ...AggregatedServicePortSubscriptionDetails
      ...MscSubscriptionDetails
      ...IrbServicePortSubscriptionDetails
    }
  }
`;

export const BASE_SUBSCRIPTION = gql`
  ${SubscriptionDetailsFragment}
  ${BaseSubscriptionDetailsFragment}
  query ListpageBaseSubscription($id: ID!) {
    subscription(id: $id) {
      subscriptionType: __typename
      ...SubscriptionDetails
      status
      customerDescriptions {
        customerId
        description
      }
      product {
        type
        tag
        name
      }
      locations {
        abbreviation
        name
        addresses(typeFilter: VISITING) {
          city
          type
          street
          number
          zipcode
          addition
          countryCode
          extraAddressLine
        }
      }
      ...BaseSubscriptionDetails
    }
  }
`;

export const SUBSCRIPTIONS_OF_TYPE_QUERY = gql`
  query SubscriptionsOfTypeQuery($customer: String!, $products: [String!], $status: [String!]) {
    customer(organisationId: $customer) {
      id: uuid
      subscriptions(productsIncludedFilter: $products, statusFilter: $status) {
        subscriptionId
        customerId
        description
        customerDescriptions {
          customerId
          description
        }
        ... on IpPrefixSubscription {
          subscriptionId
          ipPrefix {
            name
            ipam {
              prefix
            }
          }
        }
      }
    }
  }
`;

export const BASE_SUBSCRIPTIONS_OF_TYPE_QUERY = gql`
  query BaseSubscriptionsOfTypeQuery($customer: String!, $products: [String!], $status: [String!]) {
    customer(organisationId: $customer) {
      id: uuid
      subscriptions(productsIncludedFilter: $products, statusFilter: $status) {
        subscriptionId
        customerId
        description
        customerDescriptions {
          customerId
          description
        }
        product {
          type
        }
      }
    }
  }
`;

export const FULL_SUBSCRIPTIONS_OF_TYPE_QUERY = gql`
  ${IpBgpSubscriptionFragment}
  ${IpStaticSubscriptionFragment}
  ${LightPathSubscriptionFragment}
  ${LightPathReduntantSubscriptionFragment}
  ${IpPrefixSubscriptionFragment}
  ${FirewallSubscriptionDetailsFragment}
  ${PortSubscriptionDetailsFragment}
  ${L3VpnSubscriptionFragment}
  ${L2VpnSubscriptionFragment}
  ${SurfWirelessSubscriptionDetailsFragment}
  ${AggregatedServicePortSubscriptionDetailsFragment}
  ${MscSubscriptionDetailsFragment}
  ${IrbServicePortSubscriptionDetailsFragment}
  query FullSubscriptionsOfTypeQuery(
    $customer: String!
    $products: [String!]
    $status: [String!]
    $portServices: Boolean = true
  ) {
    customer(organisationId: $customer) {
      id: uuid
      subscriptions(productsIncludedFilter: $products, statusFilter: $status) {
        subscriptionType: __typename
        ...IpBgpSubscriptionDetails
        ...IpStaticSubscriptionDetails
        ...LightPathSubscriptionDetails
        ...LightPathRedundantSubscriptionDetails
        ...IpPrefixSubscriptionDetails
        ...FirewallSubscriptionDetails
        ...PortSubscriptionDetails
        ...L3VpnSubscriptionDetails
        ...L2VpnSubscriptionDetails
        ...SurfWirelessSubscriptionDetails
        ...AggregatedServicePortSubscriptionDetails
        ...MscSubscriptionDetails
        ...IrbServicePortSubscriptionDetails
      }
    }
  }
`;

export const SUBSCRIPTION_DETAIL_QUERY = gql`
  ${SubscriptionDetailsFragment}
  query SubscriptionBasicDetailQuery($id: ID!) {
    subscription(id: $id) {
      subscriptionType: __typename
      ...SubscriptionDetails
    }
  }
`;

export const SUBSCRIPTION_TYPE_QUERY = gql`
  query SubscriptionTypeQuery($id: ID!) {
    subscription(id: $id) {
      subscriptionId
      product {
        type
      }
    }
  }
`;
