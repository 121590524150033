import { getFilteredStatus } from "./get-filtered-status";
import { getFilteredTags } from "./get-filtered-tags";
import { getFilteredQuery } from "./get-filtered-query";
import { getFilteredLocation } from "./get-filtered-location";

export interface IFilter {
  type: string;
  value: string;
}

export const getFilteredItems = (items, activeFilters, activeTags, activeLocations, query) => {
  let filteredSubscriptions = items;

  if (activeFilters.length > 0) {
    filteredSubscriptions = getFilteredStatus(activeFilters, filteredSubscriptions);
  }
  if (activeTags.length > 0) {
    filteredSubscriptions = getFilteredTags(activeTags, filteredSubscriptions);
  }

  if (activeLocations.length > 0) {
    filteredSubscriptions = getFilteredLocation(activeLocations, filteredSubscriptions);
  }

  if (query) {
    filteredSubscriptions = getFilteredQuery(query, filteredSubscriptions);
  }

  return filteredSubscriptions;
};
