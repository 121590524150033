/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { StrictHttpResponse } from "../../strict-http-response";
import { RequestBuilder } from "../../request-builder";

import { ReverseZoneReadMinimal } from "../../models/reverse-zone-read-minimal";
import { ReverseZoneType } from "../../models/reverse-zone-type";

export interface ReadReverseZones$Params {
  organisation_id: string;

  /**
   * The sort will accept parameters like `col` or `col:desc` and will split on the `:`. If it does not find a `:` it will sort ascending on that column. Sortable columns: id, prefix, type, auto
   */
  sort?: Array<string> | null;

  /**
   * Filtering is possible on a partial prefix (e.g. 192.0.2.%). If a complete prefix is provided, all reverse zones are returned that are within this prefix.
   */
  prefix?: string | null;
  zone_type?: ReverseZoneType | null;
  auto?: boolean | null;
  conflicts?: boolean | null;
  token?: string | null;
  offset?: number;
  limit?: number;
}

export function readReverseZones(
  http: HttpClient,
  rootUrl: string,
  params: ReadReverseZones$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<Array<ReverseZoneReadMinimal>>> {
  const rb = new RequestBuilder(rootUrl, readReverseZones.PATH, "get");
  if (params) {
    rb.path("organisation_id", params.organisation_id, {});
    rb.query("sort", params.sort, {});
    rb.query("prefix", params.prefix, {});
    rb.query("zone_type", params.zone_type, {});
    rb.query("auto", params.auto, {});
    rb.query("conflicts", params.conflicts, {});
    rb.query("token", params.token, {});
    rb.query("offset", params.offset, {});
    rb.query("limit", params.limit, {});
  }

  // add this header so we are able to examine the failure response
  // otherwise it's intercepted by the http interceptor
  rb.header("x-ignore-interceptor", "1");

  return http.request(rb.build({ responseType: "json", accept: "application/json", context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<ReverseZoneReadMinimal>>;
    }),
  );
}

readReverseZones.PATH = "/organisations/{organisation_id}/reverse/";
