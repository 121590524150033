<div class="dialog dialog--order">
  <svg-icon src="assets/images/icons/x.svg" class="dialog__close" (click)="closeDialog()"></svg-icon>

  <div class="dialog__step">
    <div class="dialog__top">
      <h2 class="dialog__heading">{{ 'Order.RequestSubscription' | translate }}</h2>
      <p>{{ 'Order.RequestSubscriptionDescription' | translate }}</p>
    </div>
    <div class="dialog__body dialog__body--grow">
      <div class="row">
        <div class="col-8 offset-2">
          <div class="tile">
            <h3 class="service-tile__title">
              <svg-icon src="assets/images/icons/lightpath.svg" class="service-tile__icon"></svg-icon>
              <span>{{ 'Order.RequestSurfLightpath' | translate }}</span>
            </h3>
            <div>{{ 'Order.RequestSurfLightpathDescription' | translate }}</div>
          </div>
          <div class="tile">
            <h3 class="service-tile__title">
              <svg-icon src="assets/images/icons/l2vpn.svg" class="service-tile__icon"></svg-icon>
              <span>{{ 'Order.RequestL2VPN' | translate }}</span>
            </h3>
            <div>{{ 'Order.RequestL2VPNDescription' | translate }}</div>
          </div>
          <div class="tile">
            <h3 class="service-tile__title">
              <svg-icon src="assets/images/icons/port.svg" class="service-tile__icon"></svg-icon>
              <span>{{ 'Order.RequestPort' | translate }}</span>
            </h3>
            <div>{{ 'Order.RequestPortDescription' | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Step 1 -->
  <!-- <div class="dialog__step dialog__step--center">
    <div class="row">
      <div class="col-6 offset-3 text-center">
        <svg-icon src="assets/images/icons/lightpath.svg" class="icon blue"></svg-icon>
        <h2>Nieuw SURFlichtpad aanvragen</h2>
        <p>Met SURFlichtpaden zet je supersnelle verbindingen op naar bijvoorbeeld een andere instelling, een datacentrum of een dienstaanbieder.</p>
      </div>
    </div>
  </div> -->

  <!-- Step 2 -->
  <!-- <div class="dialog__step">
    <div class="dialog__top">
      <h2 class="dialog__heading">Kies een poort</h2>
      <p>De gekozen poort zal dienen als de bron van het lichtpad</p>
    </div>

    <div class="dialog__body dialog__body--grow">
      <carousel></carousel>
    </div>
  </div> -->

  <!-- Step 3 -->
  <!-- 	<div class="dialog__step">
    <div class="dialog__top">
      <h2 class="dialog__heading">Locatie</h2>
      <p>Selecteer een bestaande locatie of voer een nieuwe locatie in</p>
    </div>

    <div class="dialog__body dialog__body--grow">
      <div class="row">
        <div class="col-6">
          <div class="radio">
            <input type="radio" name="location" value="" id="radio1" checked>
            <label for="radio1" class="radio__label">Zoek en/of selecteer een bestaande locatie</label>
          </div>
          <completer-search></completer-search>
          <div class="live-location">
            <div class="live-location__pulse">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div class="live-location__caption">
              <div class="live-location__street">Burgemeester Huppeldepuplaan 121 B</div>
              <div class="live-location__place">123 AB Utrecht</div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="radio">
            <input type="radio" name="location" value="" id="radio2">
            <label for="radio2" class="radio__label">Naam van nieuwe locatie</label>
          </div>
          <div class="input__wrapper">
            <input type="text" class="input" placeholder="">
          </div>
          <div class="row">
            <div class="col-6">
              <label for="" class="form-label">Postcode</label>
              <div class="input__wrapper">
                <input type="text" class="input" placeholder="">
              </div>
            </div>
            <div class="col-3">
              <label for="" class="form-label">Nr.</label>
              <div class="input__wrapper">
                <input type="text" class="input" placeholder="">
              </div>
            </div>
            <div class="col-3">
              <label for="" class="form-label">Toevoeging</label>
              <div class="input__wrapper">
                <input type="text" class="input" placeholder="">
              </div>
            </div>
          </div>
          <div class="live-location">
            <div class="live-location__pulse">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div class="live-location__caption">
              <div class="live-location__street">Burgemeester Huppeldepuplaan 121 B</div>
              <div class="live-location__place">123 AB Utrecht</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <!-- Step 4 -->
  <!-- 	<div class="dialog__step">
    <div class="dialog__top">
      <h2 class="dialog__heading">VLAN ID</h2>
      <p>Kies een VLAN ID op uw nieuwe poort</p>
    </div>

    <div class="dialog__body dialog__body--grow">
      <div class="row">
        <div class="col-7">
          <label for="" class="form-label">Voer VLAN ID's in <span>(2 - 4094)</span></label>
          <div class="input__wrapper">
            <tags-input class="tags-input__wrapper" placeholder="VLAN ID" [removeLastOnBackspace]="true">
            </tags-input>
          </div>
          <br>
          <br>
          <label for="" class="form-label">Reed gebruikte VLAN ID's op poort</label>
          <div class="tags">
            <div class="tag tag--blue">16</div>
            <div class="tag tag--blue">48</div>
            <div class="tag tag--blue">56-64</div>
          </div>
          <br>
          <br>
          <div class="tip">
            <svg-icon class="tip__icon" src="assets/images/icons/info.svg"></svg-icon>
            <div class="tip__text">
              Scheid nummers door op enter te drukken (bijv. 1 ↩ 8 ↩)<br>
              Scheid nummber met een koppelteken om een reeks op te geven (bijv. 128-148)
            </div>
          </div>

        </div>
        <div class="col-4 offset-1">

          <div class="tile">
            <div class="tile__row">
              <div class="service__top__col">
                <svg-icon src="assets/images/icons/status-ok.svg" class="service__status"></svg-icon>
                <div class="service__caption">
                  <h3 class="service__title">Naam van MSP Poort</h3>
                </div>
              </div>
            </div>
            <div class="tile__row">
              <div class="pie__container">
                <pie></pie>
                <div class="pie__inner">
                  <div class="pie__inner__item">
                    <div class="pie__inner__small">Vrije ruimte</div>
                    <div class="pie__inner__big">2.5 Gb/s</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tile__row">
              <ul>
                <li><strong>Locatienaam</strong></li>
                <li>Straatnaam 123</li>
                <li>Plaatsnaam</li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div> -->

  <!-- Step 5 -->
  <!-- <div class="dialog__step">
    <div class="dialog__top">
      <h2 class="dialog__heading">Gewenste bandbreedte</h2>
      <p>Kies de gewenste bandbreedte voor uw lichtpad</p>
    </div>

    <div class="dialog__body dialog__body--grow">
      <div class="pie__container pie__container--large">
        <pie-dynamic></pie-dynamic>
        <div class="pie__inner">
          <input type="text" class="pie__input" placeholder="0000" maxlength="4" min="0" autofocus>

          <div class="pie__radio-wrapper">
            <div class="pie__radio">
              <input type="radio" name="quantity" value="" id="radioMB" checked>
              <label for="radioMB" class="pie__label">Mb</label>
            </div>
            <div class="pie__radio">
              <input type="radio" name="quantity" value="" id="radioGB">
              <label for="radioGB" class="pie__label">Gb</label>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div> -->

  <!-- Step 6 -->
  <!-- <div class="dialog__step">
    <div class="dialog__top">
      <h2 class="dialog__heading">Personaliseer</h2>
      <p>Voer een naam en omschrijving in voor uw lichtpad</p>
    </div>

    <div class="dialog__body dialog__body--grow">
      <br>
      <br>
      <div class="row">
        <div class="col-6 offset-3">
          <label for="" class="form-label">Naam</label>
          <div class="input__wrapper">
            <input type="text" class="input" placeholder="Naam invoeren" autofocus>
          </div>
          <label for="" class="form-label">Omschrijving</label>
          <div class="textarea__wrapper">
            <textarea class="textarea" placeholder="Geef een omschrijving"></textarea>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <!-- Step 7.1 -->
  <!-- <div class="dialog__step">
    <div class="dialog__top">
      <h2 class="dialog__heading">Bestemming</h2>
      <p>Selecteer waar het eindpunt zou moeten liggen</p>
    </div>
    <div class="dialog__body dialog__body--grow">
      <div class="row">
        <div class="col-8 offset-2">
          <div class="tile tile--active">
            <h3>Een bestaande of nieuwe poort van mijn organisatie</h3>
            <div>Zet een verbinding op tussen een MSP van uw organisatie of vraag een nieuwe poort aan.</div>
          </div>
          <div class="tile">
            <h3>Service provider of andere organisatie</h3>
            <div>Zet een verbinding op naar bijvoorbeeld een datacentrum, clouddienst of onderwijsinstelling.</div>
          </div>
          <div class="tile">
            <h3>Internationaal eindpunt</h3>
            <div>Een verbinding naar het buitenland kan opgezet worden als u in het bezit bent van een NSI-STP.</div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <!-- Step 7.1.2 -->
  <!-- <div class="dialog__step">
    <div class="dialog__top">
      <h2 class="dialog__heading">Kies een poort</h2>
      <p>De gekozen poort zal dienen als de bron van het lichtpad</p>
    </div>

    <div class="dialog__body dialog__body--grow">
      <carousel></carousel>
    </div>
  </div> -->

  <!-- Step 7.1.3 -->
  <!-- <div class="dialog__step">
    <div class="dialog__top">
      <h2 class="dialog__heading">VLAN ID</h2>
      <p>Kies een VLAN ID op uw nieuwe poort</p>
    </div>

    <div class="dialog__body dialog__body--grow">
      <div class="row">
        <div class="col-7">
          <label for="" class="form-label">Voer VLAN ID's in <span>(2 - 4094)</span></label>
          <div class="input__wrapper">
            <tags-input class="tags-input__wrapper" placeholder="VLAN ID" [removeLastOnBackspace]="true">
            </tags-input>
          </div>
          <br>
          <label for="" class="form-label">Reed gebruikte VLAN ID's op poort</label>
          <div class="tags">
            <div class="tag tag--blue">16</div>
            <div class="tag tag--blue">48</div>
            <div class="tag tag--blue">56-64</div>
          </div>
          <br>
          <label for="" class="form-label">Gekozen VLAN ID's op de bron</label>
          <div class="tags">
            <div class="tag tag--blue">12</div>
            <div class="tag tag--blue">56</div>
          </div>
          <br>
          <div class="tip">
            <svg-icon class="tip__icon" src="assets/images/icons/info.svg"></svg-icon>
            <div class="tip__text">
              Scheid nummers door op enter te drukken (bijv. 1 ↩ 8 ↩)<br>
              Scheid nummber met een koppelteken om een reeks op te geven (bijv. 128-148)
            </div>
          </div>

        </div>
        <div class="col-4 offset-1">

          <div class="tile">
            <div class="tile__row">
              <div class="service__top__col">
                <svg-icon src="assets/images/icons/status-ok.svg" class="service__status"></svg-icon>
                <div class="service__caption">
                  <h3 class="service__title">Naam van MSP Poort</h3>
                </div>
              </div>
            </div>
            <div class="tile__row">
              <div class="pie__container">
                <pie></pie>
                <div class="pie__inner">
                  <div class="pie__inner__item">
                    <div class="pie__inner__small">Vrije ruimte</div>
                    <div class="pie__inner__big">2.5 Gb/s</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tile__row">
              <ul>
                <li><strong>Locatienaam</strong></li>
                <li>Straatnaam 123</li>
                <li>Plaatsnaam</li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div> -->

  <!-- Step 7.2 -->
  <!-- <div class="dialog__step">
    <div class="dialog__top">
      <h2 class="dialog__heading">Bestemming</h2>
      <p>Selecteer waar het eindpunt zou moeten liggen</p>
    </div>
    <div class="dialog__body dialog__body--grow">
      <div class="row">
        <div class="col-8 offset-2">
          <div class="tile">
            <h3>Een bestaande of nieuwe poort van mijn organisatie</h3>
            <div>Zet een verbinding op tussen een MSP van uw organisatie of vraag een nieuwe poort aan.</div>
          </div>
          <div class="tile tile--active">
            <h3>Service provider of andere organisatie</h3>
            <div>Zet een verbinding op naar bijvoorbeeld een datacentrum, clouddienst of onderwijsinstelling.</div>
          </div>
          <div class="tile">
            <h3>Internationaal eindpunt</h3>
            <div>Een verbinding naar het buitenland kan opgezet worden als u in het bezit bent van een NSI-STP.</div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <!-- Step 7.2.2 -->
  <!-- <div class="dialog__step">
    <div class="dialog__top">
      <h2 class="dialog__heading">Kies organisatie of service provider</h2>
      <p>Zoek en/of selecteer de gewenste organisatie of service provider</p>
    </div>
    <div class="dialog__body dialog__body--grow">
      <div class="row">
        <div class="col-6 offset-3">
          <label for="" class="form-label">Zoek locatie</label>
          <completer-search></completer-search>
          <div class="live-location">
            <div class="live-location__pulse">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div class="live-location__caption">
              <div class="live-location__street">Burgemeester Huppeldepuplaan 121 B</div>
              <div class="live-location__place">123 AB Utrecht</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <!-- Step 7.2.3 -->
  <!-- <div class="dialog__step">
    <div class="dialog__top">
      <h2 class="dialog__heading">Aanvullende informatie</h2>
      <p>Voer relevante informatie in voor de ontvanger, indien van toepassing</p>
    </div>
    <div class="dialog__body dialog__body--grow">
      <div class="row">
        <div class="col-6 offset-3">
          <br>
          <br>
          <label for="" class="form-label">Aanvullende informatie voor ontvanger</label>
          <div class="textarea__wrapper">
            <textarea class="textarea" placeholder="Geef een omschrijving" autofocus></textarea>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <!-- Step 7.3 -->
  <!-- <div class="dialog__step">
    <div class="dialog__top">
      <h2 class="dialog__heading">Bestemming</h2>
      <p>Selecteer waar het eindpunt zou moeten liggen</p>
    </div>
    <div class="dialog__body dialog__body--grow">
      <div class="row">
        <div class="col-8 offset-2">
          <div class="tile">
            <h3>Een bestaande of nieuwe poort van mijn organisatie</h3>
            <div>Zet een verbinding op tussen een MSP van uw organisatie of vraag een nieuwe poort aan.</div>
          </div>
          <div class="tile">
            <h3>Service provider of andere organisatie</h3>
            <div>Zet een verbinding op naar bijvoorbeeld een datacentrum, clouddienst of onderwijsinstelling.</div>
          </div>
          <div class="tile tile--active">
            <h3>Internationaal eindpunt</h3>
            <div>Een verbinding naar het buitenland kan opgezet worden als u in het bezit bent van een NSI-STP.</div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <!-- Step 7.3.2 -->
  <!-- <div class="dialog__step">
    <div class="dialog__top">
      <h2 class="dialog__heading">NSI-STP</h2>
      <p>Vul de NSI-STP in die u heeft ontvangen</p>
    </div>
    <div class="dialog__body dialog__body--grow">
      <div class="row">
        <div class="col-6 offset-3">
          <br>
          <br>
          <label for="" class="form-label">NSI-STP</label>
          <div class="input__wrapper">
            <input type="text" class="input" placeholder="Hier plakken">
          </div>
          <br>
          <label for="" class="form-label">VLAN ID('s)</label>
          <div class="input__wrapper">
            <tags-input class="tags-input__wrapper" placeholder="VLAN ID" [removeLastOnBackspace]="true">
            </tags-input>
          </div>
          <br>
          <br>
          <div class="tip">
            <svg-icon class="tip__icon" src="assets/images/icons/info.svg"></svg-icon>
            <div class="tip__text">
              Het aantal VLAN ID’s die u opgeeft moet overeenkomen met het aantal die u reeds heeft opgegeven bij de bron in een eerdere stap.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <!-- Step 7.3.3 -->
  <!-- <div class="dialog__step">
    <div class="dialog__top">
      <h2 class="dialog__heading">Aanvullende informatie</h2>
      <p>Voer relevante informatie in voor de ontvanger, indien van toepassing</p>
    </div>
    <div class="dialog__body dialog__body--grow">
      <div class="row">
        <div class="col-6 offset-3">
          <br>
          <br>
          <label for="" class="form-label">Aanvullende informatie voor ontvanger</label>
          <div class="textarea__wrapper">
            <textarea class="textarea" placeholder="Geef een omschrijving" autofocus></textarea>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <!-- Step 8 -->
  <!-- <div class="dialog__step">
    <div class="dialog__top">
      <h2 class="dialog__heading">Samenvatting</h2>
      <p>Controleer of onderstaande informatie klopt en bevestig uw aanvraag</p>
    </div>
    <div class="dialog__body">
        <div class="row">

          <div class="col-6">
            <h3>Bron</h3>
            <table class="table-properties">
              <tr>
                <td>Poort</td>
                <td>Naam van (nieuwe) poort</td>
              </tr>
              <tr>
                <td>Locatie</td>
                <td>Burg, Huppeldepuplaan 121B <br>1234 AB Utrecht</td>
              </tr>
              <tr>
                <td>VLAN ID</td>
                <td>56</td>
              </tr>
              <tr>
                <td>Bandbreedte</td>
                <td>950 Mb</td>
              </tr>
              <tr>
                <td>Nama lichtpad</td>
                <td>Lorem ipsum dolor si</td>
              </tr>
              <tr>
                <td>Omschrijving</td>
                <td>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ex excepturi dolorem dicta quisquam magnam saepe ullam.</td>
              </tr>
            </table>
          </div>

          <div class="col-6">
            <h3>Bestemming</h3>
            <table class="table-properties">
              <tr>
                <td>Poort</td>
                <td>Naam van (nieuwe) poort</td>
              </tr>
              <tr>
                <td>Locatie</td>
                <td>Burg, Huppeldepuplaan 121B <br>1234 AB Utrecht</td>
              </tr>
              <tr>
                <td>VLAN ID</td>
                <td>56</td>
              </tr>
              <tr>
                <td>Bandbreedte</td>
                <td>950 Mb</td>
              </tr>
              <tr>
                <td>Nama lichtpad</td>
                <td>Lorem ipsum dolor si</td>
              </tr>
              <tr>
                <td>Omschrijving</td>
                <td>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ex excepturi dolorem dicta quisquam magnam saepe ullam.</td>
              </tr>
            </table>
          </div>

      </div>
    </div>
  </div> -->

  <!-- Step 9 -->
  <!-- <div class="dialog__step dialog__step--center">
    <div class="row">
      <div class="col-6 offset-3 text-center">
        <svg-icon src="assets/images/icons/ok.svg" class="icon blue"></svg-icon>
        <h2>Aanvraag succesvol</h2>
        <p>Morbi pharetra rhoncus dui id iaculis. Duis vestibulum, ligula a gravida convallis, lorem diam accumsan est, sed pharetra neque sem eu ex. Integer quis semper ligula. Sed sit amet elementum arcu, at porta ante.</p>
      </div>
    </div>
  </div> -->

  <div class="dialog__footer">
    <div>
      <div class="progress__title">{{ 'Order.RequestLightpath' | translate }}</div>
      <div class="progress__bar">
        <div class="progress__value" style="width: 40%"></div>
      </div>
    </div>
    <div class="button__flex">
      <a href="" class="button button--secondary">{{ 'Global.Action.Cancel' | translate }}</a>
      <!-- <a href="" class="button button--secondary">Terug</a> -->
      <a href="" class="button button--disabled">{{ 'Global.Action.Continue' | translate }}</a>
      <!-- <a href="" class="button">Bevestig</a> -->
      <!-- <a href="" class="button">Sluit window</a> -->
    </div>
  </div>
</div>
