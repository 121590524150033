import { Component, Input, OnInit, OnChanges, SimpleChanges, AfterViewInit, ViewChild } from "@angular/core";
import { Subscription as RxSubscription } from "rxjs";
import * as Highcharts from "highcharts";
import { ReadablePacketSizePipe, ReadableSizePipe } from "../../../helpers/bitspipe/bitspipe";
import { ExtendedChartComponent } from "../../models/base/extendedchartcomponent";
import { ApiHelper } from "../../../helpers/apihelper";
import { Instance } from "../../models/instance";
import { MessageService } from "../../../services/messageservice";
import { DdosFilteredTraffic } from "../../../helpers/types";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { CreateCertDdosChartOptions } from "./cert-ddos-chart-options";
import { Apollo } from "apollo-angular";
import { InstanceFactory } from "../../models/base/instancefactory";
import { DDOS_CHART_QUERY, transform } from "../../../graphql/component/cert-ddos";

export interface CertDdosChartSubscription {
  subscriptionId: string;
  description: string;
}

@Component({
  selector: "cert-ddos-chart",
  templateUrl: "cert-ddos-chart.html",
})
export class CertDdosLinechartComponent extends ExtendedChartComponent implements OnInit, AfterViewInit {
  @Input() errorState = false;
  @Input() metaIsLoaded = false;
  @Input() splineData: any;
  @Input() usePresetSplineData = false;
  @Input() public hideSeries: any;
  @Input() happyState = false;
  @ViewChild("chart") componentRef;

  highcharts: typeof Highcharts = Highcharts;
  chartConstructor = "chart"; // optional string, defaults to 'chart'
  chartOptions: Highcharts.Options = this.chartInfo; // required
  updateFlag = false; // optional boolean
  oneToOneFlag = true; // optional boolean, defaults to false
  packetsSubFilters = ["unicast", "broadcast", "multicast"];
  packetsSubFilterActive = "unicast";
  splineDataRaw: any;
  firewallDataRaw = [];
  portsFilterData = [];
  serviceInstanceId = "";
  instanceIdFilter = "";
  vlans = null;
  selectedPreset = "1d";
  presets = ["1d", "1w", "1m", "1y"];

  public selectedSubscription: Instance = null;
  public subscriptionFilter: string = null; // subscription ID
  public ipVersionFilter = 4; // IP version number (4 or 6)
  public subscription: Instance = null;
  public subscriptions: Instance[] = [];

  public chartFilter = 2;
  public chartDataModus = "bits";
  public exportTitle = "";
  public defaultTimeWindow = 1 * 24 * 3600 * 1000;
  public firstDate = new Date(Date.now() + -this.defaultTimeWindow);
  public secondDate = new Date();

  public defaultDate = true;
  public tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
  public exportOverflowVisible = false;
  public chartNotification = "Retrieving data...";
  public enableCurrentTraffic = false;
  public legend: any[] = [];
  private subChartFilter: RxSubscription;
  private inError = false;
  private deferredChartAction = false;
  private deferredChartData: DdosFilteredTraffic[] = [];
  private deferredChartReload = false;

  constructor(
    public translate: TranslateService,
    private apollo: Apollo,
    private api: ApiHelper,
    private msgsrv: MessageService,
    public dialog: MatDialog,
  ) {
    super();
  }

  get chartInfo(): any {
    const bitsPipe = new ReadableSizePipe();
    const packetPipe = new ReadablePacketSizePipe();
    const thisContext = this;
    const titleGenerator = () => this.chartDataModus;
    const tipLabel = this.translate.instant(`Chart.TrafficGraph.Tooltip.Ddos.FilteredTraffic`);

    const chartEvents = {
      selection: (event) => {
        if (event.xAxis !== undefined) {
          /**
           * trigger new data loading after selecting in the chart
           */
          thisContext.firstDate = new Date(event.xAxis[0].min);
          thisContext.secondDate = new Date(event.xAxis[0].max);
          thisContext.defaultDate = false;

          thisContext.getNewData();
        }
      },
    };

    return CreateCertDdosChartOptions(chartEvents, bitsPipe, packetPipe, titleGenerator, tipLabel);
  }

  ngOnInit() {
    this.apollo
      .watchQuery<any>({
        query: DDOS_CHART_QUERY,
        errorPolicy: "all",
        variables: {
          customer: localStorage.getItem("viewingCustomerGUID"),
          status: ["active", "provisioning"],
        },
      })
      .valueChanges.subscribe(({ data }) => {
        this.subscriptions = [];
        data.customer?.subscriptions?.map((subscription) => {
          const instanceBase = InstanceFactory.create(subscription);
          const transformedResult = transform(subscription);
          const instance = Object.assign(instanceBase, {
            ...transformedResult,
          });

          //const instance = InstanceFactory.create(subscription);
          if (instance === null) {
            console.error(`Could not create an instance from ${subscription.subscriptionId}`);
            return;
          }
          // FW subscriptions only qualify if they have an IP gateway with surfcertFilter enabled.
          if (instance.product.type === "FW" && instance.ipGateway?.surfcertFilter !== "enabled") {
            return;
          }

          this.subscriptions.push(instance);
          if (!this.selectedSubscription) {
            this.selectedSubscription = instance;
            this.updateSubscriptionFilter();
          }
          if (this.subscriptionFilter === null) {
            this.subscriptionFilter = instance.subscriptionId;
          }
        });
      });
  }

  ngAfterViewInit() {
    this.updateChartSeries();
  }

  updateSubscriptionFilter(): void {
    this.subscriptionFilter = this.selectedSubscription.subscriptionId;
    this.getNewData();
  }

  /**
   * Switch between bits and packets.
   */
  toggleBitsPackets() {
    this.chartDataModus = this.chartDataModus === "bits" ? "packets" : "bits";
    this.getNewData();
  }

  setChartInstance(chart: Highcharts.Chart) {
    this.chartRef = chart;

    // If the chartRef is set after we fetched data (because we switched
    // from the happy state to a state with filtered traffic), check for
    // deferredChartAction.
    if (this.deferredChartAction) {
      this.deferredChartAction = false;
      if (this.deferredChartReload) {
        this.getNewData();
      } else {
        this.setChartData(this.deferredChartData);
      }
    }
  }

  chartCallback(chart: Highcharts.Chart) {
    // here, 'this' points to the highcharts-chart component.
  }

  toggleLegendItem(series?: string) {
    super.toggleLegendItem(series);
  }

  updateExportTitle() {
    if (this.chartDataModus === "bits") {
      this.translate.get("Chart.Label.BitsS").subscribe((exportTitleTranslation: string) => {
        this.exportTitle = exportTitleTranslation;
      });
    }

    if (this.chartDataModus === "packets") {
      this.translate.get("Chart.Label.PacketsS").subscribe((exportTitleTranslation: string) => {
        this.exportTitle = exportTitleTranslation;
      });
    }

    this.chartRef.update({
      exporting: {
        chartOptions: {
          yAxis: [
            {
              title: {
                text: this.exportTitle,
              },
            },
          ],
        },
      },
    });
  }

  exportAsPNG() {
    this.updateExportTitle();
    this.chartRef.exportChart({ type: "image/png" });
  }
  exportAsJPG() {
    this.updateExportTitle();
    this.chartRef.exportChart({ type: "image/jpeg" });
  }
  exportAsCSV() {
    this.updateExportTitle();
    this.chartRef.downloadCSV();
  }

  setDatePreset(preset: string) {
    this.selectedPreset = preset;
    this.firstDate = new Date();

    switch (preset) {
      case "1d":
        this.firstDate.setDate(this.firstDate.getDate() - 1);
        break;
      case "1w":
        this.firstDate.setDate(this.firstDate.getDate() - 7);
        break;
      case "1m":
        this.firstDate.setMonth(this.firstDate.getMonth() - 1);
        break;
      case "1y":
        this.firstDate.setDate(this.firstDate.getDate() - 365);
        break;
    }

    this.secondDate = new Date();

    this.getNewData();
  }

  /**
   * Handle newly picked dates (chart range)
   */
  onDateChange() {
    this.selectedPreset = "";
    if (this.secondDate > new Date()) {
      this.secondDate = new Date();
    }

    if (this.firstDate > this.secondDate) {
      this.firstDate = new Date(this.secondDate.getTime() + -this.defaultTimeWindow);
    }

    this.defaultDate = false;

    this.getNewData();
  }

  resetDatePickers() {
    this.selectedPreset = "";
    this.secondDate = new Date();
    this.firstDate = new Date(this.secondDate.getTime() + -this.defaultTimeWindow);
    this.defaultDate = true;

    this.getNewData();
  }

  toggleExportOptions() {
    this.exportOverflowVisible = !this.exportOverflowVisible;
  }

  chartError(reason?: string) {
    this.chartNotification = `Geen data gevonden (${reason})`;

    this.chartRef.hideLoading();
    // hideNoData, because the string is not updated unless hidden.
    this.chartRef.hideNoData();
    this.chartRef.showNoData(this.chartNotification);
  }

  resetChartError() {
    this.chartNotification = "Retrieving data...";
    this.chartRef.hideNoData();
    this.chartRef.showNoData(this.chartNotification);
  }

  clearAllData() {
    if (this.chartRef) {
      while (this.chartRef.series.length > 0) {
        this.chartRef.series[0].remove(false);
      }
      this.chartRef.redraw(); // update the visual aspect
    }
  }

  getNewData() {
    if (this.happyState) {
      // still in happystate, probably. No (useful) chartRef here.
      // wait for the chart callback
      this.deferredChartAction = true;
      this.deferredChartData = [];
      this.deferredChartReload = true;
      this.happyState = false;
      this.updateFlag = true;
      return;
    }

    if (this.chartRef !== undefined) {
      this.chartRef.showLoading(" ");
      this.resetChartError();
    }
    this.clearAllData();

    const deltaMinutes = Math.round((this.secondDate.getTime() - this.firstDate.getTime()) / 1000 / 60);
    const step = this.api.convertStep(deltaMinutes);
    const startDate = this.api.snapDate(this.firstDate, step);
    const endDate = this.api.snapDate(this.secondDate, step);

    this.api
      .ddosFilteredTraffic(this.subscriptionFilter, {
        ip_version: this.ipVersionFilter,
        start: startDate,
        end: endDate,
        step,
      })
      .then((filteredTraffic) => {
        const incoming = Array.isArray(filteredTraffic) ? filteredTraffic : [filteredTraffic];
        if (incoming.length === 0) {
          //this.setChartData(mockdata);
          this.happyState = true; // true
        } else {
          this.setChartData(incoming);
        }
      });
  }

  formatSerieName(name: string) {
    let result = name.split("-").pop();
    result = result.replace(/_/g, " ");
    result = result.replace("police ", "");
    return result;
  }

  setChartData(data: DdosFilteredTraffic[]) {
    if (!this.chartRef) {
      this.deferredChartAction = true;
      this.deferredChartData = data;
      return;
    }
    this.chartRef.hideLoading(" ");
    data.forEach((series) => {
      const serieName = this.formatSerieName(series.name);
      const s = this.chartRef.addSeries(
        {
          name: serieName,
          data: series.ddos_stat.map((row) => [row[0] * 1000, row[1]]),
        },
        false,
      );
    });
    this.legend = [...this.chartRef.series];
    this.chartRef.redraw();
  }

  onClickedOutside() {
    if (this.exportOverflowVisible) {
      this.exportOverflowVisible = false;
    }
  }

  updateChartSeries() {
    if (this.splineDataRaw === undefined || this.splineDataRaw === null) {
      return;
    }

    if (this.chartDataModus === "bits") {
      this.splineData = {
        in: this.splineDataRaw.in,
        out: this.splineDataRaw.out,
        spike_in: this.splineDataRaw.spike_in,
        spike_out: this.splineDataRaw.spike_out,
      };
    } else {
      this.splineData = {
        in: this.splineDataRaw[this.packetsSubFilterActive].in,
        out: this.splineDataRaw[this.packetsSubFilterActive].out,
        spike_in: this.splineDataRaw[this.packetsSubFilterActive].spike_in,
        spike_out: this.splineDataRaw[this.packetsSubFilterActive].spike_out,
      };
    }

    if (this.splineData.in.length === 0 && this.splineData.out.length === 0) {
      this.chartError("empty");
    }

    const lineColors = {
      0: "#177abf",
      1: "#e7303a",
      2: "#ee7628",
      3: "#2ca055",
    };

    if (this.chartRef !== undefined) {
      this.chartRef.showLoading(" ");

      let indexNow = 0;
      let noDataFound = true;

      for (const serieName in this.splineData) {
        if (this.chartRef.series[indexNow] === undefined) {
          this.chartRef.addSeries(
            {
              name: serieName,
              data: this.splineData[serieName],
              type: this.chartInfo.type,
              color: lineColors[indexNow],
            },
            false,
            false,
          );
        } else {
          this.chartRef.series[indexNow].update({ name: serieName, type: this.chartInfo.type }, false);
          this.chartRef.series[indexNow].setData(this.splineData[serieName], false, false, false);
        }

        if (this.splineData[serieName].length > 0) {
          noDataFound = false;
        }
        indexNow++;
      }

      if (/*!this.metaIsLoaded || */ noDataFound) {
        this.chartError("empty response");
      }

      this.chartRef.redraw(true);
      this.chartRef.hideLoading();
      this.chartRef.zoom(); // force zoom out
    }
  }

  updatePacketsSubFilters(param: any) {
    throw new Error("Unimplemented method");
  }
}
