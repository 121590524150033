<div class="dialog dialog-cert">
  <div>
    <div class="dialog__header">
      <h3 class="dialog__title">{{ data?.source?.ip }}</h3>
      <svg-icon src="assets/images/icons/x.svg" class="dialog__close" mat-dialog-close></svg-icon>
    </div>
    <nav class="dialog-cert__tabs">
      <div (click)="changeTab('details')" [ngClass]="{'active': activeTab === 'details' }">Details</div>
      <div (click)="changeTab('extra')" [ngClass]="{'active': activeTab === 'extra' }">
        {{ 'Cert.ExtraInformation' | translate }}
      </div>
    </nav>
    <div class="dialog__body">
      <table *ngIf="activeTab === 'details'" class="dialog-cert__table">
        <tr>
          <td>Classification identifier</td>
          <td>{{ data?.classification?.identifier }}</td>
        </tr>
        <tr>
          <td>Classification taxonomy</td>
          <td>{{ data?.classification?.taxonomy }}</td>
        </tr>
        <tr>
          <td>Classification type</td>
          <td>{{ data?.classificatoin?.type }}</td>
        </tr>
        <tr>
          <td>Severity</td>
          <td>{{ data?.severity }}</td>
        </tr>
        <tr>
          <td>Feed accuracy</td>
          <td>{{ data?.feed?.accuracy }}</td>
        </tr>
        <tr>
          <td>Feed name</td>
          <td>{{ data?.feed?.name }}</td>
        </tr>
        <tr>
          <td>Protocol transport</td>
          <td>{{ data?.protocol?.transport }}</td>
        </tr>
        <tr>
          <td>Source ASN</td>
          <td>{{ data?.source?.asn }}</td>
        </tr>
        <tr>
          <td>Source GEO location Country</td>
          <td>{{ data?.source?.geolocation?.cc }}</td>
        </tr>
        <tr>
          <td>Source GEO location City</td>
          <td>{{ data?.source?.geolocation?.city }}</td>
        </tr>
        <tr>
          <td>Source GEO location Region</td>
          <td>{{ data?.source?.geolocation?.region }}</td>
        </tr>
        <tr>
          <td>Source IP</td>
          <td>{{ data?.source?.ip }}</td>
        </tr>
        <tr>
          <td>Source Port</td>
          <td>{{ data?.source?.port }}</td>
        </tr>
        <tr>
          <td>Source Reverse DNS</td>
          <td>{{ data?.source?.reverse_dns }}</td>
        </tr>
        <tr>
          <td>Time observation</td>
          <!-- dirty FE fix, as time observation data is written to time.source -->
          <td>{{ data?.time?.source }}</td>
        </tr>
      </table>
      <table *ngIf="activeTab === 'extra'" class="dialog-cert__table">
        <tr *ngFor="let item of extraData">
          <td>{{ item.header }}</td>
          <td>{{ item.value }}</td>
        </tr>
      </table>
    </div>
  </div>
</div>
