import { AfterViewInit, OnInit, OnDestroy, Component, Input, ViewContainerRef, isDevMode } from "@angular/core";
import { MatDialog, MatDialogRef, MatDialogState } from "@angular/material/dialog";
import { Subscription as RxSubscription } from "rxjs";
import { AuthService } from "../../../services/AuthService";
import { ChooseOrganisationDialogComponent } from "../../../components/dialogs/choose-organisation/dialog";
import { DialogRolesComponent } from "../../../components/dialogs/dialog-roles/dialog-roles";
import { Router, RouterEvent, ActivatedRoute } from "@angular/router";
import { filter } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { DialogOrderComponent } from "../../../components/dialogs/dialog-order/dialog-order";
import { constructWelcomeString, constructPageTitle } from "./helpers";
import { Location } from "@angular/common";
import { Subscription } from "rxjs";
import { DialogGenericComponent } from "../../../components/dialogs/dialog-generic/dialog-generic";
import { NWD_VERSION } from "../../../version";

@Component({
  selector: "navigation",
  templateUrl: "navigation.html",
  styleUrls: ["navigation.scss"],
})
export class NavigationComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() firewallIconVisible = false;
  @Input() isSticky = true;
  @Input() IPPrefixIconVisible = false;
  @Input() productType = "";
  @Input() detailData: { title: string; status: string };
  @Input() activeItem = 0;
  @Input() requestsBubble = 0;
  @Input() isChildRoute = false;
  @Input() isDetailPage = false;
  @Input() CertIconVisible = false;
  @Input() DomainsIconVisible = false;
  @Input() terminated = false;
  @Input() subNavItems = [];
  @Input() forceTitle = null;

  public debouncer = null;
  public sticky = false;
  public toggleProfile = false;
  public navIsFixed = false;
  public hasHistory = false;
  public devMode = isDevMode();
  public backURL: string;
  public mainNavText;
  public routeTitle: string;
  public nwdReleaseVersion = NWD_VERSION;
  private routerSubscription;
  private roleEventSubscription: Subscription = Subscription.EMPTY;
  private dialogRef: MatDialogRef<MatDialog>;
  private pageTitle: string;
  private pageURL: string;
  private rxSubscriptions: RxSubscription[] = [];

  constructor(
    public auth: AuthService,
    public dialog: MatDialog,
    public viewContainerRef: ViewContainerRef,
    public router: Router,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private location: Location,
  ) {
    this.routerSubscription = this.subscribeToRouter(router);
    this.hasHistory = this.router.navigated;

    if (this.roleEventSubscription.closed) {
      this.roleEventSubscription = this.auth.roleEvent.subscribe((roleEvent) => {
        const organizationName = this.auth.state.currentUser.organizationName;

        switch (roleEvent.event) {
          case "no-roles":
            this.openRolesDialog(this.translate.instant("Role.NoRoles.Title", { organisation: organizationName }));
            break;
          case "no-organization":
            const params = {
              data: {
                title: this.translate.instant("Dialog.OrganizationUnknown.Title"),
                message: this.translate.instant("Dialog.OrganizationUnknown.Message"),
                state: {},
                code: null,
              },
            };
            this.dialog.open(DialogGenericComponent, params);
            break;
          case "requirements-not-met":
            this.openRolesDialog(
              this.translate.instant("Role.NoAccess.Title", {
                productType: this.translate.instant(`Subscription.${roleEvent.productType}.Name`),
              }),
              roleEvent.need,
            );
            break;
        }
      });
    }
  }

  ngOnInit() {
    this.setMainNavText();
    this.rxSubscriptions.push(
      this.auth.userLoaded.subscribe((loaded) => {
        this.setMainNavText();
      }),
    );
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
    this.rxSubscriptions.forEach((sub) => sub.unsubscribe());
    if (!this.roleEventSubscription.closed) {
      this.roleEventSubscription.unsubscribe();
    }
  }

  openDialogOrder(): void {
    this.dialog.open(DialogOrderComponent);
  }

  public setRouteTitle(translationKey: string) {
    this.translate.get(translationKey).subscribe((translation: string) => {
      this.routeTitle = translation;
    });
    this.isChildRoute = false;
    this.productType = "";
    this.isDetailPage = false;
  }
  public setRouteTitleOnly(translationKey: string) {
    this.translate.get(translationKey).subscribe((translation: string) => {
      this.routeTitle = translation;
    });
  }

  public switchLanguage(lang: string): void {
    this.translate.use(lang);
    localStorage.setItem("lang", lang);
  }

  public logOut() {
    localStorage.removeItem("access_token");
    this.auth.load();
  }

  togglePro() {
    this.toggleProfile = !this.toggleProfile;
  }

  clickedOutside() {
    this.toggleProfile = false;
  }

  openChooserDialog() {
    const dialogRef = this.dialog.open(ChooseOrganisationDialogComponent, {});

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
    });
  }

  openRolesDialog(dialogHeader?: string, need: string[] = []) {
    const organizationName = this.auth.state.currentUser.organizationName;
    const roles = this.auth.state.currentUser.roles ?? []; // fix sentry notifications about 'this.data.roles is undefined'.
    const defaultHeader = this.translate.instant("Role.ViewRoles.Title", { organisation: organizationName });

    const openDialog = this.dialog.getDialogById("roles-dialog");
    if (openDialog?.getState() !== MatDialogState.OPEN) {
      this.dialog.open(DialogRolesComponent, {
        id: "roles-dialog",
        data: {
          roles,
          title: this.translate.instant("Navigation.Roles"),
          description: dialogHeader || defaultHeader,
          need,
        },
      });
    }
  }

  goBack() {
    window.scrollTo(0, 0);

    if (this.pageURL.startsWith("/domains")) {
      this.router.navigate(["/"]);
      return;
    }

    if (this.hasHistory) {
      this.location.back();
    } else {
      this.router.navigate(["/"]);
    }
  }

  private subscribeToRouter(router) {
    return router.events
      .pipe(filter((e: RouterEvent): e is RouterEvent => e instanceof RouterEvent))
      .subscribe((e: RouterEvent) => {
        this.pageURL = e.url;
        this.pageTitle = "";
        switch (this.pageURL) {
          case "/":
          case "/dashboard":
            this.setRouteTitle("Navigation.Overview");
            break;
          case "/berichten":
            this.setRouteTitle("Navigation.Messages");
            break;
          case "/support":
            this.setRouteTitle("Navigation.Support");
            break;
          case "/storing-en-onderhoud":
            this.setRouteTitle("Global.Overview");
            this.pageTitle = "Navigation.MalfunctionAndMaintenance";
            break;
          case "/diensten":
            this.setRouteTitle("Navigation.Subscriptions");
            break;
          case "/support":
            this.setRouteTitle("Navigation.Support");
            break;
          case "/ip-prefixes":
            this.isChildRoute = true;
            this.pageTitle = "Subscription.IP_PREFIX.Name";
            this.IPPrefixIconVisible = true;
            break;
          case "/notification-settings":
            this.isChildRoute = true;
            this.setRouteTitle("Global.Notifications");
            this.pageTitle = "Navigation.NotificationSettings";

            break;
          case "/cert":
            this.isChildRoute = true;
            this.pageTitle = "Subscription.Cert.Name";
            this.CertIconVisible = true;
            break;

          default:
            this.productType = this.route.snapshot.params.productType || "";
            this.isChildRoute = true;
            this.routeTitle = null;

            if (this.pageURL.startsWith("/storing-en-onderhoud/")) {
              this.setRouteTitle("Global.Overview");
              this.pageTitle = "Navigation.MalfunctionAndMaintenance";
            }

            if (this.pageURL.startsWith("/diensten/")) {
              this.isDetailPage = false;
            }
            if (this.pageURL.startsWith("/subscription/")) {
              this.backURL = `/diensten/${this.productType}`;
              this.isDetailPage = true;
            }

            if (this.pageURL.startsWith("/domains")) {
              this.setRouteTitleOnly("Domains.Title");
              this.pageTitle = "Domains.Title";
              this.DomainsIconVisible = true;
            }
        }
      });
  }

  private getMainNavText() {
    if (this.isChildRoute) {
      return constructPageTitle(this.translate, this.pageURL);
    } else if (this.pageTitle !== "") {
      return new Promise((resolve) => {
        this.translate.get(this.pageTitle).subscribe((translation: string) => {
          resolve(translation);
        });
      });
    }
    return constructWelcomeString(this.translate, this.auth.state.currentUser.displayName);
  }

  private setMainNavText() {
    if (this.pageURL === "/ip-prefixes") {
      this.mainNavText = "IP prefixes";
      return;
    }
    this.getMainNavText().then((value) => {
      this.mainNavText = value;
    });
  }
}
