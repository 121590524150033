export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AsPrepend: any;
  Asn: any;
  BgpSessionPriority: any;
  Date: any;
  DateTime: any;
  Domain: any;
  JSON: any;
  MTU: any;
  MaxPrefix: any;
  MetricOut: any;
  UUID: any;
};

/** Filter access points by status */
export type AccessPointFilter = {
  /** Excluded access point statuses */
  statusExcluded?: InputMaybe<Array<Scalars["String"]>>;
  /** Included access point statuses */
  statusIncluded?: InputMaybe<Array<Scalars["String"]>>;
};

export type AccessPointType = {
  __typename?: "AccessPointType";
  brand?: Maybe<Scalars["String"]>;
  ciStatus?: Maybe<Scalars["String"]>;
  firmwareVersion?: Maybe<Scalars["String"]>;
  key?: Maybe<Scalars["String"]>;
  model?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  serialNumber?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["DateTime"]>;
  supplier?: Maybe<Scalars["String"]>;
};

export type AccessPointsStatsType = {
  __typename?: "AccessPointsStatsType";
  active: Scalars["Int"];
  inactive: Scalars["Int"];
};

export type AddressType = {
  __typename?: "AddressType";
  address?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  fqdn?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  mac?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  prefixId?: Maybe<Scalars["Int"]>;
  prefixLabel?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["Int"]>;
  stateLabel?: Maybe<Scalars["String"]>;
  tags?: Maybe<Array<Scalars["String"]>>;
  vrf?: Maybe<Scalars["Int"]>;
  vrfLabel?: Maybe<Scalars["String"]>;
};

export enum AddressTypeFilter {
  Other = "OTHER",
  Visiting = "VISITING",
}

export type AffectedSubscription = {
  __typename?: "AffectedSubscription";
  impactType: Scalars["String"];
  subscription?: Maybe<SubscriptionInterface>;
  subscriptionId: Scalars["UUID"];
};

/** Information about AIRT Scan records */
export type AirtScan = {
  __typename?: "AirtScan";
  /** Contents of Intel MQ Classification field */
  classification?: Maybe<Scalars["JSON"]>;
  /** CRM Organisation UUID */
  customerId?: Maybe<Scalars["String"]>;
  /** Contents of Intel MQ Destination field */
  destination?: Maybe<Scalars["JSON"]>;
  /** Contents of Intel MQ Extra field */
  extra?: Maybe<Scalars["JSON"]>;
  /** Contents of Intel MQ Feed field */
  feed?: Maybe<Scalars["JSON"]>;
  /** First time seen this message */
  firstSeen?: Maybe<Scalars["String"]>;
  /** Unique AIRT Scan record identifier */
  id: Scalars["String"];
  /** Datetime of import */
  importedAt: Scalars["String"];
  /** Last time seen this message */
  lastSeen?: Maybe<Scalars["String"]>;
  /** Contents of Intel MQ Malware field */
  malware?: Maybe<Scalars["JSON"]>;
  /** Mongo collection version */
  modelVersion: Scalars["String"];
  /** The amount of hits on this IP in the queried time period */
  numberOfHits?: Maybe<Scalars["Int"]>;
  /** Contents of Intel MQ Protocol field */
  protocol?: Maybe<Scalars["JSON"]>;
  /** Contents of the Severity field */
  severity?: Maybe<Scalars["String"]>;
  /** Contents of Intel MQ Source field */
  source?: Maybe<Scalars["JSON"]>;
  /** Contents of Intel MQ Time field */
  time?: Maybe<Scalars["JSON"]>;
};

export type AirtScanConnection = {
  __typename?: "AirtScanConnection";
  edges: Array<AirtScanEdge>;
  pageInfo: PageInfo;
};

export type AirtScanEdge = {
  __typename?: "AirtScanEdge";
  cursor: Scalars["Int"];
  node: AirtScan;
};

export type AirtSort = {
  order: Array<AirtSortField>;
};

/** Sort ordering of results */
export type AirtSortField = {
  firstObservation?: InputMaybe<SortOrder>;
  ip?: InputMaybe<SortOrder>;
  lastObservation?: InputMaybe<SortOrder>;
  scan?: InputMaybe<SortOrder>;
  severity?: InputMaybe<SortOrder>;
};

export type BaseEnrichedTagsSubscription = {
  enrichedTags: Array<Scalars["String"]>;
  subscriptionId: Scalars["UUID"];
};

export type BaseHealthSubscription = {
  enrichedTags: Array<Scalars["String"]>;
  healthStatus?: Maybe<HealthStatus>;
  subscriptionId: Scalars["UUID"];
};

export enum Category {
  General = "GENERAL",
  Malfunction = "MALFUNCTION",
  Plannedwork = "PLANNEDWORK",
}

export type CategoryType = {
  __typename?: "CategoryType";
  code?: Maybe<Scalars["Int"]>;
  codeid?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
};

export type CimTicketType = {
  __typename?: "CimTicketType";
  endDate?: Maybe<Scalars["DateTime"]>;
  incidentImpact?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["DateTime"]>;
  status?: Maybe<Scalars["String"]>;
  summary?: Maybe<Scalars["String"]>;
  ticketId: Scalars["String"];
};

/** Filter colocations by attribute */
export type ColocationFilter = {
  /** Colocation status to filter by */
  status?: InputMaybe<Scalars["Int"]>;
};

/** Location in CRM */
export type ColocationType = {
  __typename?: "ColocationType";
  addresses: Array<CrmAddressType>;
  /** Location code (i.e. "Asd001B") */
  code?: Maybe<Scalars["String"]>;
  /** Contact persons for this colocation */
  contacts: Array<ContactType>;
  country?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  /** Get the organisation for this colocation */
  organisation?: Maybe<Customer>;
  /** Full organisation code (i.e. "IXion/COLO/Asd001B") */
  organisationCode?: Maybe<Scalars["String"]>;
  /** UUID of mother organisation */
  organisationUuid?: Maybe<Scalars["String"]>;
  /** PoP Classificatie (A, B, C, etc) */
  popClassification?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["Int"]>;
  tel?: Maybe<Scalars["String"]>;
  uuid?: Maybe<Scalars["String"]>;
};

export type CommentType = {
  __typename?: "CommentType";
  createdAt?: Maybe<Scalars["DateTime"]>;
  text: Scalars["String"];
};

export type ContactType = {
  __typename?: "ContactType";
  contactMediums?: Maybe<Array<ContactmediumType>>;
  department?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  function?: Maybe<Scalars["String"]>;
  gender?: Maybe<Scalars["String"]>;
  initials?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  middleName?: Maybe<Scalars["String"]>;
  owners?: Maybe<Array<OwnerType>>;
  preferredLanguage?: Maybe<Scalars["String"]>;
  roles?: Maybe<Array<ContactroleType>>;
  status?: Maybe<Scalars["String"]>;
  titlePostfix?: Maybe<Scalars["String"]>;
  titlePrefix?: Maybe<Scalars["String"]>;
  uuid: Scalars["String"];
};

export type ContactmediumType = {
  __typename?: "ContactmediumType";
  type?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type ContactroleType = {
  __typename?: "ContactroleType";
  type?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

/** Filter contacts by attribute */
export type ContactsFilter = {
  /** Contact status to filter by */
  status?: InputMaybe<Scalars["String"]>;
};

/** Content with language, short and long description */
export type Content = {
  __typename?: "Content";
  description: ContentDescription;
  /** Language of the long and short description */
  language: Scalars["String"];
};

/** Content description in short and long format */
export type ContentDescription = {
  __typename?: "ContentDescription";
  /** Long description */
  long?: Maybe<Scalars["String"]>;
  /** Short description */
  short?: Maybe<Scalars["String"]>;
};

export type CrmAddressType = {
  __typename?: "CrmAddressType";
  addition?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  countryCode?: Maybe<Scalars["String"]>;
  extraAddressLine?: Maybe<Scalars["String"]>;
  number?: Maybe<Scalars["String"]>;
  street?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  uuid?: Maybe<Scalars["String"]>;
  zipcode?: Maybe<Scalars["String"]>;
};

export type CrmLocationType = {
  __typename?: "CrmLocationType";
  addresses: Array<CrmAddressType>;
  code?: Maybe<Scalars["String"]>;
  /** Get the organisation for this location */
  organisation?: Maybe<Customer>;
  type?: Maybe<Scalars["String"]>;
  uuid?: Maybe<Scalars["String"]>;
};

/** Customer */
export type Customer = {
  __typename?: "Customer";
  abbreviation?: Maybe<Scalars["String"]>;
  /** Get all addresses for this organisation */
  addresses: Array<CrmAddressType>;
  categories: Array<CategoryType>;
  /** Get all colocations for this organisation, very expensive/slow call! */
  colocations: Array<ColocationType>;
  /** Get all contacts for this organisation */
  contacts: Array<ContactType>;
  email?: Maybe<Scalars["String"]>;
  fax?: Maybe<Scalars["String"]>;
  locations: Array<CrmLocationType>;
  memberSurf?: Maybe<Scalars["Boolean"]>;
  /** List of minimal impacted notifications for this customer */
  minimalImpactNotifications?: Maybe<Array<MinimalImpactNotification>>;
  name?: Maybe<Scalars["String"]>;
  owner?: Maybe<OwnerType>;
  sector?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["Int"]>;
  /** List of subscriptions for this customer */
  subscriptions?: Maybe<Array<SubscriptionInterface>>;
  tel?: Maybe<Scalars["String"]>;
  uuid?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
};

/** Customer */
export type CustomerAddressesArgs = {
  typeFilter?: InputMaybe<AddressTypeFilter>;
};

/** Customer */
export type CustomerContactsArgs = {
  contactIncludedActiveRoleFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** Customer */
export type CustomerSubscriptionsArgs = {
  productsExcludedFilter?: InputMaybe<Array<Scalars["String"]>>;
  productsIncludedFilter?: InputMaybe<Array<Scalars["String"]>>;
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type CustomerDescription = {
  __typename?: "CustomerDescription";
  createdAt: Scalars["Float"];
  customerId: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  subscriptionId: Scalars["String"];
};

export type CustomerInfoType = {
  __typename?: "CustomerInfoType";
  contents?: Maybe<Scalars["String"]>;
  label?: Maybe<LabelEnum>;
};

export type CustomerSubscriptionDescription = {
  __typename?: "CustomerSubscriptionDescription";
  createdAt?: Maybe<Scalars["String"]>;
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  id?: Maybe<Scalars["UUID"]>;
  subscriptionId: Scalars["UUID"];
};

export type CustomerSubscriptionDescriptionPythiaNotFoundPythiaError =
  | CustomerSubscriptionDescription
  | PythiaError
  | PythiaNotFound;

export enum DomainEnum {
  Surfnet8 = "SURFNET8",
}

/** Return status of Workflow engine. */
export type EngineStatus = {
  __typename?: "EngineStatus";
  /** Workflow engine locked for maintenance? */
  globalLock: Scalars["Boolean"];
  /** Textual representation of status */
  globalStatus: Scalars["String"];
  /** Number of currently running workflow processes */
  runningProcesses: Scalars["Int"];
};

export type FederatedLocation = {
  __typename?: "FederatedLocation";
  abbreviation?: Maybe<Scalars["String"]>;
  /** Get all addresses for this organisation */
  addresses: Array<CrmAddressType>;
  categories: Array<CategoryType>;
  /** Get all colocations for this organisation, very expensive/slow call! */
  colocations: Array<ColocationType>;
  /** Get all contacts for this organisation */
  contacts: Array<ContactType>;
  email?: Maybe<Scalars["String"]>;
  fax?: Maybe<Scalars["String"]>;
  locationCode: Scalars["String"];
  locations: Array<CrmLocationType>;
  memberSurf?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  owner?: Maybe<OwnerType>;
  sector?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["Int"]>;
  tel?: Maybe<Scalars["String"]>;
  uuid?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
};

export type FederatedLocationAddressesArgs = {
  typeFilter?: InputMaybe<AddressTypeFilter>;
};

export type FederatedLocationContactsArgs = {
  contactIncludedActiveRoleFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type FwBlock = ProductBlockModel & {
  __typename?: "FwBlock";
  asn: Scalars["Asn"];
  availabilityZoneName: Scalars["String"];
  customerAsn: Scalars["Asn"];
  deployType: Scalars["String"];
  ipGwEndpoint?: Maybe<FwIpGwEndpointBlock>;
  l2Endpoints: Array<FwL2EndpointBlock>;
  l3Endpoints: Array<FwL3EndpointBlock>;
  label?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  nfvServiceId: Scalars["String"];
  /** Returns list of other_subscriptions ids */
  otherSubscriptionIds: Array<Scalars["UUID"]>;
  /** Returns list of other subscriptions */
  otherSubscriptions: Array<SubscriptionInterface>;
  ownerSubscriptionId?: Maybe<Scalars["UUID"]>;
  subscriptionInstanceId: Scalars["UUID"];
  title?: Maybe<Scalars["String"]>;
};

export type FwBlockOtherSubscriptionIdsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type FwBlockOtherSubscriptionsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type FwIpGwEndpointBlock = ProductBlockModel & {
  __typename?: "FwIpGwEndpointBlock";
  endpointDescription: Scalars["String"];
  ip: Sn8IpBgpVirtualCircuitBlock;
  l2vpnInternal: Sn8L2VpnVirtualCircuitBlock;
  label?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  /** Returns list of other_subscriptions ids */
  otherSubscriptionIds: Array<Scalars["UUID"]>;
  /** Returns list of other subscriptions */
  otherSubscriptions: Array<SubscriptionInterface>;
  ownerSubscriptionId?: Maybe<Scalars["UUID"]>;
  subscriptionInstanceId: Scalars["UUID"];
  title?: Maybe<Scalars["String"]>;
};

export type FwIpGwEndpointBlockOtherSubscriptionIdsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type FwIpGwEndpointBlockOtherSubscriptionsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type FwL2EndpointBlock = ProductBlockModel & {
  __typename?: "FwL2EndpointBlock";
  /** tbd */
  customerPtpIpv4PrimaryIpam?: Maybe<PrefixType>;
  customerPtpIpv4PrimaryIpamId?: Maybe<Scalars["Int"]>;
  /** tbd */
  customerPtpIpv4SecondaryIpam: Array<PrefixType>;
  customerPtpIpv4SecondaryIpamIds: Array<Scalars["Int"]>;
  /** tbd */
  customerPtpIpv6PrimaryIpam?: Maybe<PrefixType>;
  customerPtpIpv6PrimaryIpamId?: Maybe<Scalars["Int"]>;
  /** tbd */
  customerPtpIpv6SecondaryIpam: Array<PrefixType>;
  customerPtpIpv6SecondaryIpamIds: Array<Scalars["Int"]>;
  endpointDescription: Scalars["String"];
  esis: Array<Sn8L2VpnEsiBlock>;
  label?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  /** Returns list of other_subscriptions ids */
  otherSubscriptionIds: Array<Scalars["UUID"]>;
  /** Returns list of other subscriptions */
  otherSubscriptions: Array<SubscriptionInterface>;
  ownerSubscriptionId?: Maybe<Scalars["UUID"]>;
  subscriptionInstanceId: Scalars["UUID"];
  title?: Maybe<Scalars["String"]>;
};

export type FwL2EndpointBlockOtherSubscriptionIdsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type FwL2EndpointBlockOtherSubscriptionsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type FwL3EndpointBlock = ProductBlockModel & {
  __typename?: "FwL3EndpointBlock";
  endpointDescription: Scalars["String"];
  l2vpnInternal: Sn8L2VpnVirtualCircuitBlock;
  label?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  /** Returns list of other_subscriptions ids */
  otherSubscriptionIds: Array<Scalars["UUID"]>;
  /** Returns list of other subscriptions */
  otherSubscriptions: Array<SubscriptionInterface>;
  ownerSubscriptionId?: Maybe<Scalars["UUID"]>;
  saps: Array<Sn8ServiceAttachPointBlock>;
  subscriptionInstanceId: Scalars["UUID"];
  title?: Maybe<Scalars["String"]>;
};

export type FwL3EndpointBlockOtherSubscriptionIdsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type FwL3EndpointBlockOtherSubscriptionsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** Firewall subscription */
export type FwSubscription = BaseEnrichedTagsSubscription &
  BaseHealthSubscription &
  SubscriptionInterface & {
    __typename?: "FwSubscription";
    /** Return organisation on this subscription */
    customer: Customer;
    customerDescriptions: Array<CustomerDescription>;
    customerId: Scalars["UUID"];
    /** Get depends_on subscriptions */
    dependsOn: Array<SubscriptionInterface>;
    description: Scalars["String"];
    domain: Scalars["Domain"];
    endDate?: Maybe<Scalars["String"]>;
    enrichedTags: Array<Scalars["String"]>;
    firewall: FwBlock;
    firewallEnabled: Scalars["Boolean"];
    /** Return fixed inputs */
    fixedInputs: Scalars["JSON"];
    healthStatus?: Maybe<HealthStatus>;
    /** Return all ims circuits */
    imsCircuits: Array<ImsCircuit>;
    /** Get in_use_by subscriptions */
    inUseBy: Array<SubscriptionInterface>;
    insync: Scalars["Boolean"];
    /** Return locations on this subscription */
    locations: Array<FederatedLocation>;
    /** Get metadata for this subscription */
    metadata: Scalars["JSON"];
    /** List of minimal impacted notifications for this subscription */
    minimalImpactNotifications: Array<MinimalImpactNotification>;
    name?: Maybe<Scalars["String"]>;
    note?: Maybe<Scalars["String"]>;
    notifications?: Maybe<Array<Notification>>;
    portSubscriptionInstanceId?: Maybe<Scalars["UUID"]>;
    product: Product;
    /** Return all products blocks that are part of a subscription */
    productBlocks: Array<ProductBlock>;
    protectionType: Scalars["String"];
    size: Scalars["Int"];
    startDate?: Maybe<Scalars["String"]>;
    status: Scalars["String"];
    subscriptionId: Scalars["UUID"];
    tag?: Maybe<Scalars["String"]>;
    vlanRange?: Maybe<Scalars["String"]>;
  };

/** Firewall subscription */
export type FwSubscriptionDependsOnArgs = {
  dependsOnStatusFilter?: InputMaybe<Array<Scalars["String"]>>;
  productTypeFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** Firewall subscription */
export type FwSubscriptionImsCircuitsArgs = {
  boundary?: SubscriptionBoundary;
};

/** Firewall subscription */
export type FwSubscriptionInUseByArgs = {
  inUseByFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** Firewall subscription */
export type FwSubscriptionProductBlocksArgs = {
  resourceTypes?: InputMaybe<Array<Scalars["String"]>>;
  tags?: InputMaybe<Array<Scalars["String"]>>;
};

export type GraphqlFilter = {
  field: Scalars["String"];
  value: Scalars["String"];
};

export type GraphqlSort = {
  field: Scalars["String"];
  order: SortOrder;
};

/** Health */
export type Health = {
  __typename?: "Health";
  /** Health of workflow engine */
  orchestrator: ServiceHealth;
};

export type HealthStatus = {
  __typename?: "HealthStatus";
  status?: Maybe<Scalars["String"]>;
  traffic?: Maybe<Traffic>;
};

export enum Impact {
  Down = "DOWN",
  NoImpact = "NO_IMPACT",
  ReducedRedundancy = "REDUCED_REDUNDANCY",
  ResilienceLoss = "RESILIENCE_LOSS",
  Unknown = "UNKNOWN",
}

/** Detailed information about an IMS circuit */
export type ImsCircuit = {
  __typename?: "ImsCircuit";
  /** Circuit identifier in IMS */
  imsCircuitId: Scalars["Int"];
  /** List of planned work for this circuit */
  plannedWork: Array<PlannedWorkWithCustomerInfoType>;
};

/** Detailed information about an IMS circuit */
export type ImsCircuitPlannedWorkArgs = {
  active?: Scalars["Boolean"];
  beginDatetime?: InputMaybe<Scalars["DateTime"]>;
  beginTimestamp?: Scalars["Float"];
  customersInformed?: Scalars["Boolean"];
  endDatetime?: InputMaybe<Scalars["DateTime"]>;
  endTimestamp?: InputMaybe<Scalars["Float"]>;
};

export type ImsEndpoint = {
  __typename?: "ImsEndpoint";
  id: Scalars["Int"];
  type: TypeEnum;
  vlanranges?: Maybe<Array<VlanRangeType>>;
};

export type ImsLocationType = {
  __typename?: "ImsLocationType";
  /** [readonly] Location access procedure */
  accessProcedure?: Maybe<Scalars["String"]>;
  /** Street name */
  addr?: Maybe<Scalars["String"]>;
  addrCity?: Maybe<Scalars["String"]>;
  addrCountry?: Maybe<Scalars["String"]>;
  /** House number */
  addrNo?: Maybe<Scalars["String"]>;
  addrZip?: Maybe<Scalars["String"]>;
  /** Name of the SiteType */
  classification: Scalars["String"];
  /** Site name (CRM Locationcode, unique and immutable) */
  code: Scalars["String"];
  /** [readonly] Location description */
  description?: Maybe<Scalars["String"]>;
  imsId?: Maybe<Scalars["Int"]>;
  /** Latitude */
  lat?: Maybe<Scalars["Float"]>;
  /** Longitude */
  long?: Maybe<Scalars["Float"]>;
  /** Get all nodes for this location */
  nodes: Array<NodeType>;
  /** Name of related Customer (CRM abbreviation) */
  organisationCode?: Maybe<Scalars["String"]>;
  /** UUID of related Customer (CRM UUID) */
  organisationUuid?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  status: StatusEnum;
};

export type ImsLocationTypeNodesArgs = {
  status: StatusEnum;
};

export type ImsPort = {
  __typename?: "ImsPort";
  connectorType?: Maybe<Scalars["String"]>;
  fiberType?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  ifaceType?: Maybe<Scalars["String"]>;
  lineName?: Maybe<Scalars["String"]>;
  /** Get location for this internal port */
  location?: Maybe<ColocationType>;
  /**
   * Get location code
   * @deprecated Use location resolver instead
   */
  locationCode: Scalars["String"];
  /** Get the location details for this service */
  locationOwner?: Maybe<Customer>;
  /** Get node for this port */
  node?: Maybe<NodeType>;
  patchposition?: Maybe<Scalars["String"]>;
  port?: Maybe<Scalars["String"]>;
  /** Get service for this port */
  service?: Maybe<ImsService>;
  status?: Maybe<StatusEnum>;
};

export type ImsPortInternalPortTypeInternetTypeImsService = ImsPort | ImsService | InternalPortType | InternetType;

/** Provides IMS information based on ims_circuit_id */
export type ImsProvider = {
  /** Get detailed IMS info */
  ims?: Maybe<ImsService>;
  /** Unique circuit identifier in IMS */
  imsCircuitId?: Maybe<Scalars["Int"]>;
};

/** IMS Service type */
export type ImsService = {
  __typename?: "ImsService";
  aliases?: Maybe<Array<Scalars["String"]>>;
  customerId?: Maybe<Scalars["String"]>;
  domain?: Maybe<DomainEnum>;
  /** Get the endpoints for this service */
  endpoints: Array<ImsPortInternalPortTypeInternetTypeImsService>;
  extraInfo?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  /** ID of Site A */
  location?: Maybe<Scalars["String"]>;
  /** [readonly] Site A */
  locationA?: Maybe<ImsLocationType>;
  /** [readonly] Site B */
  locationB?: Maybe<ImsLocationType>;
  /** Get the location details for this service */
  locationOwner?: Maybe<Customer>;
  name: Scalars["String"];
  /** Get the object relates for this service */
  objectRelates: Array<ObjectRelateType>;
  orderId: Scalars["String"];
  /**
   * Retrieve the first endpoint of type 'port' from the service
   * @deprecated Use endpoints with a 'type' filter and 'limit' instead
   */
  port?: Maybe<ImsPort>;
  product: ProductEnum;
  /** @deprecated Kept for backward compatibility */
  service: ImsService;
  speed: SpeedEnum;
  status?: Maybe<StatusEnum>;
};

/** IMS Service type */
export type ImsServiceEndpointsArgs = {
  limit?: InputMaybe<Scalars["Int"]>;
  type?: InputMaybe<TypeEnum>;
};

export enum InetFamily {
  IPv4 = "IPv4",
  IPv6 = "IPv6",
}

export type InternalPortType = {
  __typename?: "InternalPortType";
  id?: Maybe<Scalars["Int"]>;
  lineName?: Maybe<Scalars["String"]>;
  /** Get location for this internal port */
  location?: Maybe<ImsLocationType>;
  /** Get node for this internal port */
  node?: Maybe<NodeType>;
  port: Scalars["String"];
};

/** An InternetType is just a service in IMS */
export type InternetType = {
  __typename?: "InternetType";
  aliases?: Maybe<Array<Scalars["String"]>>;
  customerId?: Maybe<Scalars["String"]>;
  domain?: Maybe<DomainEnum>;
  endpoints?: Maybe<Array<ImsEndpoint>>;
  extraInfo?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  /** ID of Site A */
  location?: Maybe<Scalars["String"]>;
  /** [readonly] Site A */
  locationA?: Maybe<ImsLocationType>;
  /** [readonly] Site B */
  locationB?: Maybe<ImsLocationType>;
  /** Get the location details for this service */
  locationOwner?: Maybe<Customer>;
  name: Scalars["String"];
  /** Get the object relates for this service */
  objectRelates: Array<ObjectRelateType>;
  orderId: Scalars["String"];
  /**
   * Retrieve the first endpoint of type 'port' from the service
   * @deprecated Use endpoints with a 'type' filter and 'limit' instead
   */
  port?: Maybe<ImsPort>;
  product: ProductEnum;
  /** @deprecated Kept for backward compatibility */
  service: ImsService;
  speed: SpeedEnum;
  status?: Maybe<StatusEnum>;
};

export type IpPeerBlock = ProductBlockModel & {
  __typename?: "IpPeerBlock";
  asPrepend: Scalars["AsPrepend"];
  asn: Scalars["Asn"];
  blackholeCommunity: Scalars["String"];
  communityListOut: Array<Scalars["String"]>;
  ipv4MaxPrefix?: Maybe<Scalars["MaxPrefix"]>;
  ipv6MaxPrefix?: Maybe<Scalars["MaxPrefix"]>;
  label?: Maybe<Scalars["String"]>;
  multipath: Scalars["Boolean"];
  name: Scalars["String"];
  nsoServiceId: Scalars["UUID"];
  /** Returns list of other_subscriptions ids */
  otherSubscriptionIds: Array<Scalars["UUID"]>;
  /** Returns list of other subscriptions */
  otherSubscriptions: Array<SubscriptionInterface>;
  ownerSubscriptionId?: Maybe<Scalars["UUID"]>;
  peerCommunity: Scalars["Asn"];
  peerName: Scalars["String"];
  peers: Array<PeerBlock>;
  subscriptionInstanceId: Scalars["UUID"];
  title?: Maybe<Scalars["String"]>;
};

export type IpPeerBlockOtherSubscriptionIdsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type IpPeerBlockOtherSubscriptionsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type IpPeerGroupBlock = ProductBlockModel & {
  __typename?: "IpPeerGroupBlock";
  interconnectionType: Scalars["String"];
  label?: Maybe<Scalars["String"]>;
  metricOut?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  nsoServiceId: Scalars["UUID"];
  /** Returns list of other_subscriptions ids */
  otherSubscriptionIds: Array<Scalars["UUID"]>;
  /** Returns list of other subscriptions */
  otherSubscriptions: Array<SubscriptionInterface>;
  ownerSubscriptionId?: Maybe<Scalars["UUID"]>;
  peerCommunity?: Maybe<Scalars["Asn"]>;
  peerGroupName: Scalars["String"];
  peerType: Scalars["String"];
  routeServers: Array<Scalars["String"]>;
  subscriptionInstanceId: Scalars["UUID"];
  title?: Maybe<Scalars["String"]>;
};

export type IpPeerGroupBlockOtherSubscriptionIdsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type IpPeerGroupBlockOtherSubscriptionsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The IP Peer group subscription type. */
export type IpPeerGroupSubscription = SubscriptionInterface & {
  __typename?: "IpPeerGroupSubscription";
  /** Return organisation on this subscription */
  customer: Customer;
  customerDescriptions: Array<CustomerDescription>;
  customerId: Scalars["UUID"];
  /** Get depends_on subscriptions */
  dependsOn: Array<SubscriptionInterface>;
  description: Scalars["String"];
  domain: Scalars["Domain"];
  endDate?: Maybe<Scalars["String"]>;
  firewallEnabled: Scalars["Boolean"];
  /** Return fixed inputs */
  fixedInputs: Scalars["JSON"];
  /** Return all ims circuits */
  imsCircuits: Array<ImsCircuit>;
  /** Get in_use_by subscriptions */
  inUseBy: Array<SubscriptionInterface>;
  insync: Scalars["Boolean"];
  ipPeerGroupBlock: IpPeerGroupBlock;
  /** Return locations on this subscription */
  locations: Array<FederatedLocation>;
  /** Get metadata for this subscription */
  metadata: Scalars["JSON"];
  /** List of minimal impacted notifications for this subscription */
  minimalImpactNotifications: Array<MinimalImpactNotification>;
  name?: Maybe<Scalars["String"]>;
  note?: Maybe<Scalars["String"]>;
  notifications?: Maybe<Array<Notification>>;
  portSubscriptionInstanceId?: Maybe<Scalars["UUID"]>;
  product: Product;
  /** Return all products blocks that are part of a subscription */
  productBlocks: Array<ProductBlock>;
  startDate?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  tag?: Maybe<Scalars["String"]>;
  vlanRange?: Maybe<Scalars["String"]>;
};

/** The IP Peer group subscription type. */
export type IpPeerGroupSubscriptionDependsOnArgs = {
  dependsOnStatusFilter?: InputMaybe<Array<Scalars["String"]>>;
  productTypeFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The IP Peer group subscription type. */
export type IpPeerGroupSubscriptionImsCircuitsArgs = {
  boundary?: SubscriptionBoundary;
};

/** The IP Peer group subscription type. */
export type IpPeerGroupSubscriptionInUseByArgs = {
  inUseByFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The IP Peer group subscription type. */
export type IpPeerGroupSubscriptionProductBlocksArgs = {
  resourceTypes?: InputMaybe<Array<Scalars["String"]>>;
  tags?: InputMaybe<Array<Scalars["String"]>>;
};

export type IpPeerPortBlock = ImsProvider &
  ProductBlockModel & {
    __typename?: "IpPeerPortBlock";
    /** Get detailed IMS info */
    ims?: Maybe<ImsService>;
    imsCircuitId: Scalars["Int"];
    ipv4IpamAddressId?: Maybe<Scalars["Int"]>;
    ipv4Mtu: Scalars["MTU"];
    ipv6IpamAddressId?: Maybe<Scalars["Int"]>;
    ipv6Mtu: Scalars["MTU"];
    label?: Maybe<Scalars["String"]>;
    name: Scalars["String"];
    nsoServiceId: Scalars["UUID"];
    /** Returns list of other_subscriptions ids */
    otherSubscriptionIds: Array<Scalars["UUID"]>;
    /** Returns list of other subscriptions */
    otherSubscriptions: Array<SubscriptionInterface>;
    ownerSubscriptionId?: Maybe<Scalars["UUID"]>;
    peerPortName: Scalars["String"];
    peerPortType: Scalars["String"];
    ptpIpv4IpamId?: Maybe<Scalars["Int"]>;
    ptpIpv6IpamId?: Maybe<Scalars["Int"]>;
    sap: Sn8ServiceAttachPointBlock;
    subscriptionInstanceId: Scalars["UUID"];
    title?: Maybe<Scalars["String"]>;
  };

export type IpPeerPortBlockOtherSubscriptionIdsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type IpPeerPortBlockOtherSubscriptionsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The IP Peer port subscription type. */
export type IpPeerPortSubscription = SubscriptionInterface & {
  __typename?: "IpPeerPortSubscription";
  /** Return organisation on this subscription */
  customer: Customer;
  customerDescriptions: Array<CustomerDescription>;
  customerId: Scalars["UUID"];
  /** Get depends_on subscriptions */
  dependsOn: Array<SubscriptionInterface>;
  description: Scalars["String"];
  domain: Scalars["Domain"];
  endDate?: Maybe<Scalars["String"]>;
  firewallEnabled: Scalars["Boolean"];
  /** Return fixed inputs */
  fixedInputs: Scalars["JSON"];
  /** Return all ims circuits */
  imsCircuits: Array<ImsCircuit>;
  /** Get in_use_by subscriptions */
  inUseBy: Array<SubscriptionInterface>;
  insync: Scalars["Boolean"];
  ipPeerPortBlock: IpPeerPortBlock;
  /** Return locations on this subscription */
  locations: Array<FederatedLocation>;
  /** Get metadata for this subscription */
  metadata: Scalars["JSON"];
  /** List of minimal impacted notifications for this subscription */
  minimalImpactNotifications: Array<MinimalImpactNotification>;
  name?: Maybe<Scalars["String"]>;
  note?: Maybe<Scalars["String"]>;
  notifications?: Maybe<Array<Notification>>;
  portSubscriptionInstanceId?: Maybe<Scalars["UUID"]>;
  product: Product;
  /** Return all products blocks that are part of a subscription */
  productBlocks: Array<ProductBlock>;
  startDate?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  tag?: Maybe<Scalars["String"]>;
  vlanRange?: Maybe<Scalars["String"]>;
};

/** The IP Peer port subscription type. */
export type IpPeerPortSubscriptionDependsOnArgs = {
  dependsOnStatusFilter?: InputMaybe<Array<Scalars["String"]>>;
  productTypeFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The IP Peer port subscription type. */
export type IpPeerPortSubscriptionImsCircuitsArgs = {
  boundary?: SubscriptionBoundary;
};

/** The IP Peer port subscription type. */
export type IpPeerPortSubscriptionInUseByArgs = {
  inUseByFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The IP Peer port subscription type. */
export type IpPeerPortSubscriptionProductBlocksArgs = {
  resourceTypes?: InputMaybe<Array<Scalars["String"]>>;
  tags?: InputMaybe<Array<Scalars["String"]>>;
};

/** The IP Peer subscription type. */
export type IpPeerSubscription = SubscriptionInterface & {
  __typename?: "IpPeerSubscription";
  /** Return organisation on this subscription */
  customer: Customer;
  customerDescriptions: Array<CustomerDescription>;
  customerId: Scalars["UUID"];
  /** Get depends_on subscriptions */
  dependsOn: Array<SubscriptionInterface>;
  description: Scalars["String"];
  domain: Scalars["Domain"];
  endDate?: Maybe<Scalars["String"]>;
  firewallEnabled: Scalars["Boolean"];
  /** Return fixed inputs */
  fixedInputs: Scalars["JSON"];
  /** Return all ims circuits */
  imsCircuits: Array<ImsCircuit>;
  /** Get in_use_by subscriptions */
  inUseBy: Array<SubscriptionInterface>;
  insync: Scalars["Boolean"];
  ipPeerBlock: IpPeerBlock;
  /** Return locations on this subscription */
  locations: Array<FederatedLocation>;
  /** Get metadata for this subscription */
  metadata: Scalars["JSON"];
  /** List of minimal impacted notifications for this subscription */
  minimalImpactNotifications: Array<MinimalImpactNotification>;
  name?: Maybe<Scalars["String"]>;
  note?: Maybe<Scalars["String"]>;
  notifications?: Maybe<Array<Notification>>;
  portSubscriptionInstanceId?: Maybe<Scalars["UUID"]>;
  product: Product;
  /** Return all products blocks that are part of a subscription */
  productBlocks: Array<ProductBlock>;
  startDate?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  tag?: Maybe<Scalars["String"]>;
  vlanRange?: Maybe<Scalars["String"]>;
};

/** The IP Peer subscription type. */
export type IpPeerSubscriptionDependsOnArgs = {
  dependsOnStatusFilter?: InputMaybe<Array<Scalars["String"]>>;
  productTypeFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The IP Peer subscription type. */
export type IpPeerSubscriptionImsCircuitsArgs = {
  boundary?: SubscriptionBoundary;
};

/** The IP Peer subscription type. */
export type IpPeerSubscriptionInUseByArgs = {
  inUseByFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The IP Peer subscription type. */
export type IpPeerSubscriptionProductBlocksArgs = {
  resourceTypes?: InputMaybe<Array<Scalars["String"]>>;
  tags?: InputMaybe<Array<Scalars["String"]>>;
};

export type IpPrefixBlock = ProductBlockModel & {
  __typename?: "IpPrefixBlock";
  customerAggregate: Scalars["Boolean"];
  extraInformation?: Maybe<Scalars["String"]>;
  ipam?: Maybe<PrefixType>;
  ipamPrefixId?: Maybe<Scalars["Int"]>;
  label?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  /** Returns list of other_subscriptions ids */
  otherSubscriptionIds: Array<Scalars["UUID"]>;
  /** Returns list of other subscriptions */
  otherSubscriptions: Array<SubscriptionInterface>;
  ownerSubscriptionId?: Maybe<Scalars["UUID"]>;
  planned: Scalars["Boolean"];
  subscriptionInstanceId: Scalars["UUID"];
  title?: Maybe<Scalars["String"]>;
  toInternet: Scalars["Boolean"];
};

export type IpPrefixBlockOtherSubscriptionIdsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type IpPrefixBlockOtherSubscriptionsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** Prefix object from Orchestrator */
export type IpPrefixObject = {
  __typename?: "IpPrefixObject";
  customerId: Scalars["UUID"];
  description?: Maybe<Scalars["String"]>;
  family: InetFamily;
  /** tbd */
  ipam?: Maybe<PrefixType>;
  ipamPrefixId: Scalars["Int"];
  parent: Scalars["String"];
  prefix: Scalars["String"];
  /** tbd */
  prefixSubscription: IpPrefixSubscription;
  prefixlen: Scalars["Int"];
  state: Scalars["Int"];
  stateLabel: Scalars["String"];
  subscriptionId: Scalars["UUID"];
};

/** The IP Prefix subscription type. */
export type IpPrefixSubscription = BaseEnrichedTagsSubscription &
  BaseHealthSubscription &
  SubscriptionInterface & {
    __typename?: "IpPrefixSubscription";
    /** Return organisation on this subscription */
    customer: Customer;
    customerDescriptions: Array<CustomerDescription>;
    customerId: Scalars["UUID"];
    /** Get depends_on subscriptions */
    dependsOn: Array<SubscriptionInterface>;
    description: Scalars["String"];
    endDate?: Maybe<Scalars["String"]>;
    enrichedTags: Array<Scalars["String"]>;
    firewallEnabled: Scalars["Boolean"];
    /** Return fixed inputs */
    fixedInputs: Scalars["JSON"];
    healthStatus?: Maybe<HealthStatus>;
    /** Return all ims circuits */
    imsCircuits: Array<ImsCircuit>;
    /** Get in_use_by subscriptions */
    inUseBy: Array<SubscriptionInterface>;
    insync: Scalars["Boolean"];
    ipPrefix: IpPrefixBlock;
    /** Return locations on this subscription */
    locations: Array<FederatedLocation>;
    /** Get metadata for this subscription */
    metadata: Scalars["JSON"];
    /** List of minimal impacted notifications for this subscription */
    minimalImpactNotifications: Array<MinimalImpactNotification>;
    name?: Maybe<Scalars["String"]>;
    note?: Maybe<Scalars["String"]>;
    notifications?: Maybe<Array<Notification>>;
    portSubscriptionInstanceId?: Maybe<Scalars["UUID"]>;
    product: Product;
    /** Return all products blocks that are part of a subscription */
    productBlocks: Array<ProductBlock>;
    startDate?: Maybe<Scalars["String"]>;
    status: Scalars["String"];
    subscriptionId: Scalars["UUID"];
    tag?: Maybe<Scalars["String"]>;
    vlanRange?: Maybe<Scalars["String"]>;
  };

/** The IP Prefix subscription type. */
export type IpPrefixSubscriptionDependsOnArgs = {
  dependsOnStatusFilter?: InputMaybe<Array<Scalars["String"]>>;
  productTypeFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The IP Prefix subscription type. */
export type IpPrefixSubscriptionImsCircuitsArgs = {
  boundary?: SubscriptionBoundary;
};

/** The IP Prefix subscription type. */
export type IpPrefixSubscriptionInUseByArgs = {
  inUseByFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The IP Prefix subscription type. */
export type IpPrefixSubscriptionProductBlocksArgs = {
  resourceTypes?: InputMaybe<Array<Scalars["String"]>>;
  tags?: InputMaybe<Array<Scalars["String"]>>;
};

export type JiraCustomerType = {
  __typename?: "JiraCustomerType";
  code?: Maybe<Scalars["String"]>;
  /** Return all locations for this customer */
  locations: Array<LocationType>;
  name: Scalars["String"];
  /** Return all service tickets for this customer */
  tickets: Array<ServiceTicketType>;
};

export type JiraCustomerTypeTicketsArgs = {
  status: Scalars["String"];
};

export enum LabelEnum {
  EngelsKort = "ENGELS_KORT",
  EngelsUitgebreid = "ENGELS_UITGEBREID",
  NederlandsKort = "NEDERLANDS_KORT",
  NederlandsUitgebreid = "NEDERLANDS_UITGEBREID",
}

export enum Language {
  Eng = "ENG",
  Nl = "NL",
}

export enum LanguageEnum {
  Eng = "ENG",
  Fr = "FR",
  Ger = "GER",
  Nl = "NL",
  Peerings = "PEERINGS",
}

/** Filter locations by attribute */
export type LocationFilter = {
  /** Location type to filter by */
  type?: InputMaybe<LocationTypeEnum>;
};

export type LocationType = {
  __typename?: "LocationType";
  /** Return all access points for this location */
  accessPoints: Array<AccessPointType>;
  address?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  institute?: Maybe<Scalars["String"]>;
  jiraLocationId: Scalars["String"];
  locationName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  /** Wireless statistics */
  stats: AccessPointsStatsType;
  /** Return all wireless tickets for this location */
  tickets: Array<WirelessTicketType>;
};

export type LocationTypeAccessPointsArgs = {
  filter?: InputMaybe<AccessPointFilter>;
};

export type LocationTypeTicketsArgs = {
  status?: InputMaybe<Scalars["String"]>;
};

/** Location types to filter by */
export enum LocationTypeEnum {
  Colocatie = "COLOCATIE",
  Hoofdlocatie = "HOOFDLOCATIE",
  Nevenlocatie = "NEVENLOCATIE",
}

/** Setting for the minimal impact type at which you want notification */
export type MinimalImpactNotification = {
  __typename?: "MinimalImpactNotification";
  createdAt?: Maybe<Scalars["Float"]>;
  customerId: Scalars["UUID"];
  id?: Maybe<Scalars["UUID"]>;
  impact: Scalars["String"];
  lastModified?: Maybe<Scalars["Float"]>;
  /** Get subscription */
  subscription: SubscriptionInterface;
  subscriptionId: Scalars["UUID"];
};

export type MinimalImpactNotificationPythiaError = MinimalImpactNotification | PythiaError;

export type MinimalImpactNotificationPythiaNotFoundPythiaError =
  | MinimalImpactNotification
  | PythiaError
  | PythiaNotFound;

/** Pythia mutations */
export type Mutation = {
  __typename?: "Mutation";
  /** Delete customer description */
  removeCustomerDescription: CustomerSubscriptionDescriptionPythiaNotFoundPythiaError;
  /** Delete customer minimal impact notification */
  removeMinimalImpactNotification: MinimalImpactNotificationPythiaNotFoundPythiaError;
  /** Create a process in the Orchestrator */
  startProcess: ProcessCreatedPythiaError;
  /** Create or update customer description */
  upsertCustomerDescription: CustomerSubscriptionDescriptionPythiaNotFoundPythiaError;
  /** Create or update customer minimal impact notification */
  upsertMinimalImpactNotification: MinimalImpactNotificationPythiaError;
  /** Create or update user preferences */
  upsertUserPreferences: UserPreferencesPythiaError;
};

/** Pythia mutations */
export type MutationRemoveCustomerDescriptionArgs = {
  customerId: Scalars["UUID"];
  subscriptionId: Scalars["UUID"];
};

/** Pythia mutations */
export type MutationRemoveMinimalImpactNotificationArgs = {
  customerId: Scalars["UUID"];
  subscriptionId: Scalars["UUID"];
};

/** Pythia mutations */
export type MutationStartProcessArgs = {
  name: Scalars["String"];
  payload: Payload;
  reporter?: InputMaybe<Scalars["String"]>;
};

/** Pythia mutations */
export type MutationUpsertCustomerDescriptionArgs = {
  customerId: Scalars["UUID"];
  description: Scalars["String"];
  subscriptionId: Scalars["UUID"];
};

/** Pythia mutations */
export type MutationUpsertMinimalImpactNotificationArgs = {
  customerId: Scalars["UUID"];
  impact: Scalars["String"];
  subscriptionId: Scalars["UUID"];
};

/** Pythia mutations */
export type MutationUpsertUserPreferencesArgs = {
  onboarding: Scalars["Boolean"];
  preferenceDomain: Scalars["String"];
  username: Scalars["String"];
};

export type NodeProductBlock = ProductBlockModel & {
  __typename?: "NodeProductBlock";
  bgpFullTable: Scalars["Boolean"];
  imsNodeId: Scalars["Int"];
  label?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  nodeIpv4IpamId: Scalars["Int"];
  nodeIpv6IpamId: Scalars["Int"];
  nodeLocation: Scalars["String"];
  nsoDeviceId: Scalars["String"];
  nsoServiceId: Scalars["UUID"];
  /** Returns list of other_subscriptions ids */
  otherSubscriptionIds: Array<Scalars["UUID"]>;
  /** Returns list of other subscriptions */
  otherSubscriptions: Array<SubscriptionInterface>;
  ownerSubscriptionId?: Maybe<Scalars["UUID"]>;
  srNodeSegmentId: Scalars["Int"];
  subscriptionInstanceId: Scalars["UUID"];
  title?: Maybe<Scalars["String"]>;
};

export type NodeProductBlockOtherSubscriptionIdsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type NodeProductBlockOtherSubscriptionsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The Node port subscription type. */
export type NodeSubscription = SubscriptionInterface & {
  __typename?: "NodeSubscription";
  /** Return organisation on this subscription */
  customer: Customer;
  customerDescriptions: Array<CustomerDescription>;
  customerId: Scalars["UUID"];
  /** Get depends_on subscriptions */
  dependsOn: Array<SubscriptionInterface>;
  description: Scalars["String"];
  endDate?: Maybe<Scalars["String"]>;
  firewallEnabled: Scalars["Boolean"];
  /** Return fixed inputs */
  fixedInputs: Scalars["JSON"];
  /** Return all ims circuits */
  imsCircuits: Array<ImsCircuit>;
  /** Get in_use_by subscriptions */
  inUseBy: Array<SubscriptionInterface>;
  insync: Scalars["Boolean"];
  /** Return locations on this subscription */
  locations: Array<FederatedLocation>;
  /** Get metadata for this subscription */
  metadata: Scalars["JSON"];
  /** List of minimal impacted notifications for this subscription */
  minimalImpactNotifications: Array<MinimalImpactNotification>;
  name?: Maybe<Scalars["String"]>;
  node: NodeProductBlock;
  note?: Maybe<Scalars["String"]>;
  notifications?: Maybe<Array<Notification>>;
  portSubscriptionInstanceId?: Maybe<Scalars["UUID"]>;
  product: Product;
  /** Return all products blocks that are part of a subscription */
  productBlocks: Array<ProductBlock>;
  startDate?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  tag?: Maybe<Scalars["String"]>;
  vlanRange?: Maybe<Scalars["String"]>;
};

/** The Node port subscription type. */
export type NodeSubscriptionDependsOnArgs = {
  dependsOnStatusFilter?: InputMaybe<Array<Scalars["String"]>>;
  productTypeFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The Node port subscription type. */
export type NodeSubscriptionImsCircuitsArgs = {
  boundary?: SubscriptionBoundary;
};

/** The Node port subscription type. */
export type NodeSubscriptionInUseByArgs = {
  inUseByFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The Node port subscription type. */
export type NodeSubscriptionProductBlocksArgs = {
  resourceTypes?: InputMaybe<Array<Scalars["String"]>>;
  tags?: InputMaybe<Array<Scalars["String"]>>;
};

export type NodeType = {
  __typename?: "NodeType";
  domain?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  /** Get all internal ports for this node */
  internalPorts: Array<InternalPortType>;
  ipv4?: Maybe<Scalars["String"]>;
  kind: Scalars["String"];
  /** Get location for this node */
  location?: Maybe<ImsLocationType>;
  name: Scalars["String"];
  networkrole?: Maybe<Scalars["String"]>;
  /** Get all internal ports for this node */
  ports: Array<ImsPort>;
  serialNumber?: Maybe<Scalars["String"]>;
  status?: Maybe<StatusEnum>;
  type: Scalars["String"];
  vendor?: Maybe<Scalars["String"]>;
};

export type Notification = {
  __typename?: "Notification";
  affectedSubscriptions: Array<AffectedSubscription>;
  category: Category;
  endTimestamp?: Maybe<Scalars["DateTime"]>;
  heading: Scalars["String"];
  id: Scalars["String"];
  /** All different impacts types */
  impact: Scalars["String"];
  jiraTicketId: Scalars["String"];
  planTimestamp?: Maybe<Scalars["DateTime"]>;
  startTimestamp: Scalars["DateTime"];
  status?: Maybe<Scalars["String"]>;
  summary?: Maybe<Scalars["String"]>;
  tags: Array<Scalars["String"]>;
  updates: Array<NotificationUpdate>;
  urgency: Urgency;
};

/** Represents a paginated relationship between two entities */
export type NotificationConnection = {
  __typename?: "NotificationConnection";
  page: Array<Notification>;
  pageInfo: PageInfo;
};

/** Filter notifications and impacted objects by various attributes (AND). */
export type NotificationFilter = {
  /** Show notifications on and after this start date */
  beginTimestamp?: InputMaybe<Scalars["DateTime"]>;
  /** Filter by subscription owner. This will filter notifications having at least 1 matching impacted object (subscription). The impacted objects per notification are also filtered. */
  customerId?: InputMaybe<Scalars["UUID"]>;
  /** Show notifications on and before this end date, or without end date */
  endTimestamp?: InputMaybe<Scalars["DateTime"]>;
  /** Filter by subscription impacts. This will filter notifications having at least 1 matching impacted object (subscription). The impacted objects per notification are also filtered. */
  impacts?: InputMaybe<Array<Impact>>;
  /** Filter by subscription product types. This will filter notifications having at least 1 matching impacted object (subscription). The impacted objects per notification are also filtered. */
  productTypes?: InputMaybe<Array<Scalars["String"]>>;
  /** Filter by notification statuses */
  statuses?: InputMaybe<Array<ProcessState>>;
  /** Filter by subscription ids. This will filter notifications having at least 1 matching impacted object (subscription). The impacted objects per notification are also filtered. */
  subscriptionIds?: InputMaybe<Array<Scalars["UUID"]>>;
};

export type NotificationUpdate = {
  __typename?: "NotificationUpdate";
  description: Scalars["String"];
  language: Language;
  timestamp: Scalars["DateTime"];
};

/** The NSI LightPath subscription type. */
export type NsiLightPathSubscription = SubscriptionInterface & {
  __typename?: "NsiLightPathSubscription";
  /** Return organisation on this subscription */
  customer: Customer;
  customerDescriptions: Array<CustomerDescription>;
  customerId: Scalars["UUID"];
  /** Get depends_on subscriptions */
  dependsOn: Array<SubscriptionInterface>;
  description: Scalars["String"];
  domain: Scalars["Domain"];
  endDate?: Maybe<Scalars["String"]>;
  firewallEnabled: Scalars["Boolean"];
  /** Return fixed inputs */
  fixedInputs: Scalars["JSON"];
  /** Return all ims circuits */
  imsCircuits: Array<ImsCircuit>;
  /** Get in_use_by subscriptions */
  inUseBy: Array<SubscriptionInterface>;
  insync: Scalars["Boolean"];
  /** Return locations on this subscription */
  locations: Array<FederatedLocation>;
  /** Get metadata for this subscription */
  metadata: Scalars["JSON"];
  /** List of minimal impacted notifications for this subscription */
  minimalImpactNotifications: Array<MinimalImpactNotification>;
  name?: Maybe<Scalars["String"]>;
  note?: Maybe<Scalars["String"]>;
  notifications?: Maybe<Array<Notification>>;
  portSubscriptionInstanceId?: Maybe<Scalars["UUID"]>;
  product: Product;
  /** Return all products blocks that are part of a subscription */
  productBlocks: Array<ProductBlock>;
  startDate?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  tag?: Maybe<Scalars["String"]>;
  vc: Sn8LightPathVirtualCircuitBlock;
  vlanRange?: Maybe<Scalars["String"]>;
};

/** The NSI LightPath subscription type. */
export type NsiLightPathSubscriptionDependsOnArgs = {
  dependsOnStatusFilter?: InputMaybe<Array<Scalars["String"]>>;
  productTypeFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The NSI LightPath subscription type. */
export type NsiLightPathSubscriptionImsCircuitsArgs = {
  boundary?: SubscriptionBoundary;
};

/** The NSI LightPath subscription type. */
export type NsiLightPathSubscriptionInUseByArgs = {
  inUseByFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The NSI LightPath subscription type. */
export type NsiLightPathSubscriptionProductBlocksArgs = {
  resourceTypes?: InputMaybe<Array<Scalars["String"]>>;
  tags?: InputMaybe<Array<Scalars["String"]>>;
};

export type NsiStpBlock = ProductBlockModel & {
  __typename?: "NsiStpBlock";
  bandwidth: Scalars["Int"];
  exposeInTopology: Scalars["Boolean"];
  isAliasIn: Scalars["String"];
  isAliasOut: Scalars["String"];
  label?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  /** Returns list of other_subscriptions ids */
  otherSubscriptionIds: Array<Scalars["UUID"]>;
  /** Returns list of other subscriptions */
  otherSubscriptions: Array<SubscriptionInterface>;
  ownerSubscriptionId?: Maybe<Scalars["UUID"]>;
  sap: Sn8ServiceAttachPointBlock;
  stpDescription: Scalars["String"];
  stpId: Scalars["String"];
  subscriptionInstanceId: Scalars["UUID"];
  title?: Maybe<Scalars["String"]>;
  topology: Scalars["String"];
};

export type NsiStpBlockOtherSubscriptionIdsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type NsiStpBlockOtherSubscriptionsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The NSI STP subscription type. */
export type NsistpSubscription = SubscriptionInterface & {
  __typename?: "NsistpSubscription";
  /** Return organisation on this subscription */
  customer: Customer;
  customerDescriptions: Array<CustomerDescription>;
  customerId: Scalars["UUID"];
  /** Get depends_on subscriptions */
  dependsOn: Array<SubscriptionInterface>;
  description: Scalars["String"];
  domain: Scalars["Domain"];
  endDate?: Maybe<Scalars["String"]>;
  firewallEnabled: Scalars["Boolean"];
  /** Return fixed inputs */
  fixedInputs: Scalars["JSON"];
  /** Return all ims circuits */
  imsCircuits: Array<ImsCircuit>;
  /** Get in_use_by subscriptions */
  inUseBy: Array<SubscriptionInterface>;
  insync: Scalars["Boolean"];
  /** Return locations on this subscription */
  locations: Array<FederatedLocation>;
  /** Get metadata for this subscription */
  metadata: Scalars["JSON"];
  /** List of minimal impacted notifications for this subscription */
  minimalImpactNotifications: Array<MinimalImpactNotification>;
  name?: Maybe<Scalars["String"]>;
  note?: Maybe<Scalars["String"]>;
  notifications?: Maybe<Array<Notification>>;
  portSubscriptionInstanceId?: Maybe<Scalars["UUID"]>;
  product: Product;
  /** Return all products blocks that are part of a subscription */
  productBlocks: Array<ProductBlock>;
  settings: NsiStpBlock;
  startDate?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  tag?: Maybe<Scalars["String"]>;
  vlanRange?: Maybe<Scalars["String"]>;
};

/** The NSI STP subscription type. */
export type NsistpSubscriptionDependsOnArgs = {
  dependsOnStatusFilter?: InputMaybe<Array<Scalars["String"]>>;
  productTypeFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The NSI STP subscription type. */
export type NsistpSubscriptionImsCircuitsArgs = {
  boundary?: SubscriptionBoundary;
};

/** The NSI STP subscription type. */
export type NsistpSubscriptionInUseByArgs = {
  inUseByFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The NSI STP subscription type. */
export type NsistpSubscriptionProductBlocksArgs = {
  resourceTypes?: InputMaybe<Array<Scalars["String"]>>;
  tags?: InputMaybe<Array<Scalars["String"]>>;
};

export type ObjectRelateType = {
  __typename?: "ObjectRelateType";
  id?: Maybe<Scalars["Int"]>;
  objectName: Scalars["String"];
  relateId: Scalars["Int"];
  relateType: Scalars["String"];
};

export enum OrganisationStatus {
  Active = "ACTIVE",
  Other = "OTHER",
}

/** Filter contacts by attribute */
export type OrganisationsFilter = {
  /** Organisation status to filter by, True is active, False is inactive */
  status?: OrganisationStatus;
  /** Filter the results of the query by organisational type; choose any (comma separated combination) of the following: institutions, service_providers, colo_providers, international_partners, other */
  type?: InputMaybe<Scalars["String"]>;
};

export type OwnerType = {
  __typename?: "OwnerType";
  email?: Maybe<Scalars["String"]>;
  fullName?: Maybe<Scalars["String"]>;
  mobilePhone?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
};

/** Pagination context to navigate objects with cursor-based pagination */
export type PageInfo = {
  __typename?: "PageInfo";
  endCursor?: Maybe<Scalars["Int"]>;
  filterFields: Array<Scalars["String"]>;
  hasNextPage: Scalars["Boolean"];
  hasPreviousPage: Scalars["Boolean"];
  sortFields: Array<Scalars["String"]>;
  startCursor?: Maybe<Scalars["Int"]>;
  totalItems?: Maybe<Scalars["Int"]>;
};

/** Payload for process create */
export type Payload = {
  /** Payload */
  payload: Scalars["JSON"];
};

export type PeerBlock = PeerPortProvider &
  ProductBlockModel & {
    __typename?: "PeerBlock";
    asPrepend?: Maybe<Scalars["AsPrepend"]>;
    authKey?: Maybe<Scalars["String"]>;
    bfd: Scalars["Boolean"];
    bfdMinimumInterval?: Maybe<Scalars["Int"]>;
    bfdMultiplier?: Maybe<Scalars["Int"]>;
    bgpExportRejectAll: Scalars["Boolean"];
    bgpImportRejectAll: Scalars["Boolean"];
    bgpSessionPriority?: Maybe<Scalars["BgpSessionPriority"]>;
    ipv4RemoteAddress?: Maybe<Scalars["String"]>;
    ipv6RemoteAddress?: Maybe<Scalars["String"]>;
    label?: Maybe<Scalars["String"]>;
    metricOut?: Maybe<Scalars["MetricOut"]>;
    name: Scalars["String"];
    /** Returns list of other_subscriptions ids */
    otherSubscriptionIds: Array<Scalars["UUID"]>;
    /** Returns list of other subscriptions */
    otherSubscriptions: Array<SubscriptionInterface>;
    ownerSubscriptionId?: Maybe<Scalars["UUID"]>;
    peerGroup: IpPeerGroupBlock;
    port: IpPeerPortSubscription;
    portSubscriptionId: Scalars["UUID"];
    subscriptionInstanceId: Scalars["UUID"];
    title?: Maybe<Scalars["String"]>;
  };

export type PeerBlockOtherSubscriptionIdsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type PeerBlockOtherSubscriptionsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type PeerPortProvider = {
  port: IpPeerPortSubscription;
  portSubscriptionId: Scalars["UUID"];
};

export type PlannedWorkLogType = {
  __typename?: "PlannedWorkLogType";
  entryDatetime?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["Int"]>;
  language?: Maybe<LanguageEnum>;
  logging?: Maybe<Scalars["String"]>;
};

export type PlannedWorkWithCustomerInfoRelatedCircuitsType = {
  __typename?: "PlannedWorkWithCustomerInfoRelatedCircuitsType";
  downType?: Maybe<Scalars["Int"]>;
  imsCircuitId: Scalars["Int"];
};

export type PlannedWorkWithCustomerInfoType = {
  __typename?: "PlannedWorkWithCustomerInfoType";
  category: Scalars["String"];
  /** Get long or short description in different languages */
  contents: Array<Content>;
  customerInfo?: Maybe<Array<CustomerInfoType>>;
  customersInformed?: Maybe<Scalars["Int"]>;
  description: Scalars["String"];
  endDatetime?: Maybe<Scalars["DateTime"]>;
  expirationDate?: Maybe<Scalars["Date"]>;
  id: Scalars["Int"];
  logs?: Maybe<Array<PlannedWorkLogType>>;
  name: Scalars["String"];
  outageTime?: Maybe<Scalars["String"]>;
  plannedTime?: Maybe<Scalars["DateTime"]>;
  relatedCircuits?: Maybe<Array<PlannedWorkWithCustomerInfoRelatedCircuitsType>>;
  startDatetime?: Maybe<Scalars["DateTime"]>;
  status: Scalars["String"];
};

export type PlannedWorkWithCustomerInfoTypeContentsArgs = {
  language?: InputMaybe<Scalars["String"]>;
};

/** Resolve port subscription information */
export type PortProvider = {
  port: SubscriptionInterface;
  portSubscriptionId?: Maybe<Scalars["UUID"]>;
};

export type PrefixType = {
  __typename?: "PrefixType";
  addresses: Array<AddressType>;
  /** Address Family indicator */
  afi: Scalars["String"];
  /** AS number id */
  asn?: Maybe<Scalars["Int"]>;
  asnLabel?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  /** Resolve prefix for this address */
  parent?: Maybe<PrefixType>;
  /** Parent prefix id */
  parentId?: Maybe<Scalars["Int"]>;
  parentLabel?: Maybe<Scalars["String"]>;
  prefix: Scalars["String"];
  state: Scalars["Int"];
  stateLabel?: Maybe<Scalars["String"]>;
  subPrefixes: Array<PrefixType>;
  tags: Array<Scalars["String"]>;
  vrf?: Maybe<Scalars["Int"]>;
  vrfLabel?: Maybe<Scalars["String"]>;
};

/** Workflow details */
export type Process = {
  __typename?: "Process";
  createdBy?: Maybe<Scalars["String"]>;
  customerId?: Maybe<Scalars["UUID"]>;
  id: Scalars["UUID"];
  isTask: Scalars["Boolean"];
  lastModified?: Maybe<Scalars["DateTime"]>;
  lastStep?: Maybe<Scalars["String"]>;
  productId?: Maybe<Scalars["UUID"]>;
  started?: Maybe<Scalars["DateTime"]>;
  status: Scalars["String"];
  steps: Array<ProcessStep>;
  workflowName: Scalars["String"];
};

/** Process status */
export type ProcessCreated = {
  __typename?: "ProcessCreated";
  id: Scalars["UUID"];
};

export type ProcessCreatedPythiaError = ProcessCreated | PythiaError;

export enum ProcessState {
  Aborted = "ABORTED",
  Closed = "CLOSED",
  ClosedWithoutInform = "CLOSED_WITHOUT_INFORM",
  New = "NEW",
  Open = "OPEN",
  OpenAccepted = "OPEN_ACCEPTED",
  OpenRelated = "OPEN_RELATED",
  Updated = "UPDATED",
}

/** Process step details */
export type ProcessStep = {
  __typename?: "ProcessStep";
  commitHash?: Maybe<Scalars["String"]>;
  createdBy?: Maybe<Scalars["String"]>;
  executed?: Maybe<Scalars["DateTime"]>;
  id?: Maybe<Scalars["UUID"]>;
  name: Scalars["String"];
  status: Scalars["String"];
};

/** Orchestrator Product information */
export type Product = {
  __typename?: "Product";
  createdAt?: Maybe<Scalars["String"]>;
  description: Scalars["String"];
  endDate?: Maybe<Scalars["String"]>;
  /** Unique product identifier */
  id: Scalars["UUID"];
  name: Scalars["String"];
  /**
   * Unique product identifier
   * @deprecated Use id instead
   */
  productId: Scalars["String"];
  status: Scalars["String"];
  tag?: Maybe<Scalars["String"]>;
  type: Scalars["String"];
};

export type ProductBlock = {
  __typename?: "ProductBlock";
  id: Scalars["Int"];
  ownerSubscriptionId: Scalars["UUID"];
  parent?: Maybe<Scalars["Int"]>;
  resourceTypes: Scalars["JSON"];
};

export type ProductBlockModel = {
  label?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  /** Returns list of other_subscriptions ids */
  otherSubscriptionIds: Array<Scalars["UUID"]>;
  /** Returns list of other subscriptions */
  otherSubscriptions: Array<SubscriptionInterface>;
  ownerSubscriptionId?: Maybe<Scalars["UUID"]>;
  subscriptionInstanceId: Scalars["UUID"];
  title?: Maybe<Scalars["String"]>;
};

export type ProductBlockModelOtherSubscriptionIdsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type ProductBlockModelOtherSubscriptionsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** Detailed information about an Orchestrator product */
export type ProductDetails = {
  __typename?: "ProductDetails";
  /** Product creation date */
  createdAt?: Maybe<Scalars["DateTime"]>;
  /** Product description */
  description?: Maybe<Scalars["String"]>;
  /** Product end date. If None, the product is still active */
  endDate?: Maybe<Scalars["DateTime"]>;
  /** Unique product identifier */
  id?: Maybe<Scalars["UUID"]>;
  /** Product name */
  name?: Maybe<Scalars["String"]>;
  /** Product status */
  status?: Maybe<Scalars["String"]>;
  /** List of subscriptions for this product */
  subscriptions: Array<SubscriptionInterface>;
  /** Product tag */
  tag?: Maybe<Scalars["String"]>;
  /** Product type */
  type?: Maybe<Scalars["String"]>;
};

/** Detailed information about an Orchestrator product */
export type ProductDetailsSubscriptionsArgs = {
  customerId: Scalars["UUID"];
  filter?: InputMaybe<SubscriptionFilter>;
};

export enum ProductEnum {
  AcVpn = "AC_VPN",
  Agglink = "AGGLINK",
  Aggsp = "AGGSP",
  Aw = "AW",
  Cable = "CABLE",
  Elan = "ELAN",
  Ip = "IP",
  Ip1 = "IP1",
  Ip2 = "IP2",
  Ippp = "IPPP",
  Ir = "IR",
  Ir1 = "IR1",
  Ir2 = "IR2",
  Ir3 = "IR3",
  Ir4 = "IR4",
  Irbsp = "IRBSP",
  L2Vpn = "L2VPN",
  L3Vpn = "L3VPN",
  Le = "LE",
  Lp = "LP",
  Lpnl = "LPNL",
  Lr = "LR",
  Lr1 = "LR1",
  Lr2 = "LR2",
  Lrnl = "LRNL",
  Msp = "MSP",
  Och = "OCH",
  Patchext = "PATCHEXT",
  Sp = "SP",
  Spnl = "SPNL",
  Ssp = "SSP",
  Surfmultipoint = "SURFMULTIPOINT",
  Svlan = "SVLAN",
  Tft = "TFT",
  Trail = "TRAIL",
  Trunk = "TRUNK",
  Wdmext = "WDMEXT",
}

/** Filter products by attribute */
export type ProductFilter = {
  /** Excluded product statuses */
  statusExcludedFilter?: InputMaybe<Array<Scalars["String"]>>;
  /** Included product statuses */
  statusIncludedFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** Resolve IPAM ipv4 and ipv6 information */
export type PtpIpamProvider = {
  /** Ipam details */
  ptpIpv4Ipam?: Maybe<PrefixType>;
  ptpIpv4IpamId?: Maybe<Scalars["Int"]>;
  /** Ipam details */
  ptpIpv6Ipam?: Maybe<PrefixType>;
  ptpIpv6IpamId?: Maybe<Scalars["Int"]>;
};

/** Generic class to capture errors */
export type PythiaError = {
  __typename?: "PythiaError";
  /** Details of error cause */
  details?: Maybe<Scalars["String"]>;
  /** Error message */
  message: Scalars["String"];
};

/** Error class if a resource couldn't be found (404) */
export type PythiaNotFound = {
  __typename?: "PythiaNotFound";
  /** Details of error cause */
  details?: Maybe<Scalars["String"]>;
  /** Error message */
  message: Scalars["String"];
};

/** Sort order (ASC or DESC) */
export enum PythiaSortOrder {
  Asc = "ASC",
  Desc = "DESC",
}

export type Query = {
  __typename?: "Query";
  /** Returns an address by id */
  address: AddressType;
  /** Returns a list of AIRT Scans */
  airtScans: AirtScanConnection;
  /** Returns all CIM tickets */
  cimTickets: Array<CimTicketType>;
  /** Returns a list of all colocations in CRM */
  colocations: Array<ColocationType>;
  /** Returns a list of all contacts in CRM */
  contacts: Array<ContactType>;
  /** Returns an organisation by id */
  customer: Customer;
  /** Return a list of all organisations in CRM */
  customers: Array<Customer>;
  /** Returns the workflow engine status */
  engineStatus: EngineStatus;
  /** Returns a list of free prefixes */
  freePrefixes: Array<Scalars["String"]>;
  /** Returns health status of apis that Pythia relies upon */
  health: Health;
  /** Returns customers by code */
  jiraCustomer?: Maybe<JiraCustomerType>;
  /** Returns all customers */
  jiraCustomers: Array<JiraCustomerType>;
  /** Returns a wifi location by id */
  location?: Maybe<LocationType>;
  /** Returns a list of all locations in CRM */
  locations: Array<CrmLocationType>;
  /** Notification */
  notification: Notification;
  /** List of Notifications */
  notifications: Array<Notification>;
  /** List of Notifications for WFO UI */
  notificationsWfo: NotificationConnection;
  /** Returns a prefix by id */
  prefix: PrefixType;
  /** Returns detailed information for a prefix */
  prefixSubscription: IpPrefixSubscription;
  /** Returns a list of prefixes */
  prefixSubscriptions: Array<IpPrefixObject>;
  /** Returns a list of prefixes */
  prefixes: Array<PrefixType>;
  /** Return process information from the Orchestrator */
  process: Process;
  /** Returns a list of Orchestrator products */
  products: Array<ProductDetails>;
  /** Returns subscription info */
  subscription: SubscriptionInterface;
  /** Returns a list of subscriptions */
  subscriptions: SubscriptionInterfaceConnection;
  /** Returns a ticket by id */
  ticket: TicketType;
  /** Virtual root for user info */
  user: User;
};

export type QueryAddressArgs = {
  addressId: Scalars["Int"];
};

export type QueryAirtScansArgs = {
  after?: Scalars["Int"];
  explain?: Scalars["Boolean"];
  first?: Scalars["Int"];
  searchFilter?: InputMaybe<SearchFilter>;
  sort?: InputMaybe<AirtSort>;
};

export type QueryColocationsArgs = {
  filter?: InputMaybe<ColocationFilter>;
};

export type QueryContactsArgs = {
  filter?: InputMaybe<ContactsFilter>;
};

export type QueryCustomerArgs = {
  organisationId: Scalars["String"];
};

export type QueryCustomersArgs = {
  orgFilter?: InputMaybe<OrganisationsFilter>;
};

export type QueryJiraCustomerArgs = {
  code: Scalars["String"];
};

export type QueryLocationArgs = {
  locationId: Scalars["String"];
};

export type QueryLocationsArgs = {
  filter?: InputMaybe<LocationFilter>;
};

export type QueryNotificationArgs = {
  id: Scalars["UUID"];
};

export type QueryNotificationsArgs = {
  filter?: InputMaybe<NotificationFilter>;
};

export type QueryNotificationsWfoArgs = {
  after?: Scalars["Int"];
  filterBy?: InputMaybe<Array<GraphqlFilter>>;
  first?: Scalars["Int"];
  query?: InputMaybe<Scalars["String"]>;
  sortBy?: InputMaybe<Array<GraphqlSort>>;
};

export type QueryPrefixArgs = {
  prefixId: Scalars["Int"];
};

export type QueryPrefixSubscriptionArgs = {
  prefixId: Scalars["Int"];
};

export type QueryPrefixesArgs = {
  vrf?: InputMaybe<Scalars["Int"]>;
};

export type QueryProcessArgs = {
  pid: Scalars["UUID"];
};

export type QueryProductsArgs = {
  filter?: InputMaybe<ProductFilter>;
  groupByType?: Scalars["Boolean"];
};

export type QuerySubscriptionArgs = {
  id: Scalars["ID"];
};

export type QuerySubscriptionsArgs = {
  after?: Scalars["Int"];
  filterBy?: InputMaybe<Array<Array<Scalars["String"]>>>;
  first?: Scalars["Int"];
  sortBy?: InputMaybe<Array<SubscriptionsSort>>;
};

export type QueryTicketArgs = {
  ticketId: Scalars["String"];
};

export type QueryUserArgs = {
  username: Scalars["String"];
};

/** Filter airt scans by attribute, all ranges are inclusive */
export type SearchFilter = {
  /** Return results after import date */
  afterImportedDate?: InputMaybe<Scalars["DateTime"]>;
  /** Return results after observation date */
  afterObservationDate?: InputMaybe<Scalars["DateTime"]>;
  /** Return results after source date */
  afterSourceDate?: InputMaybe<Scalars["DateTime"]>;
  /** Search on application. E.g. ftp, ssh */
  application?: InputMaybe<Scalars["String"]>;
  /** Return results before import date */
  beforeImportedDate?: InputMaybe<Scalars["DateTime"]>;
  /** Return results before observation date */
  beforeObservationDate?: InputMaybe<Scalars["DateTime"]>;
  /** Return results before date */
  beforeSourceDate?: InputMaybe<Scalars["DateTime"]>;
  /** Case insensitive search on city */
  city?: InputMaybe<Scalars["String"]>;
  /** Search by customer id */
  customerId?: InputMaybe<Scalars["UUID"]>;
  /** Search on IP address */
  ip?: InputMaybe<Scalars["String"]>;
  /** Return results filtered before destination IP */
  ipDestinationEnd?: InputMaybe<Scalars["String"]>;
  /** Return results filtered before destination IP */
  ipDestinationStart?: InputMaybe<Scalars["String"]>;
  /** Search on IP address */
  ipPrefixesDestination?: InputMaybe<Array<Scalars["String"]>>;
  /** Search on IP address */
  ipPrefixesSource?: InputMaybe<Array<Scalars["String"]>>;
  /** Return results filtered before source IP */
  ipSourceEnd?: InputMaybe<Scalars["String"]>;
  /** Return results filtered after source IP */
  ipSourceStart?: InputMaybe<Scalars["String"]>;
  /** Search on protocol: TCP/UDP/ICMP */
  protocol?: InputMaybe<Scalars["String"]>;
  /** Case insensitive on region */
  region?: InputMaybe<Scalars["String"]>;
  /** The severity of the case. */
  severity?: InputMaybe<Array<Severity>>;
};

/** Health status */
export type ServiceHealth = {
  __typename?: "ServiceHealth";
  status: Scalars["String"];
};

export type ServiceTicketType = {
  __typename?: "ServiceTicketType";
  APName?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  openDate?: Maybe<Scalars["DateTime"]>;
  origin?: Maybe<Scalars["String"]>;
  priority?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

/** The severity of the item */
export enum Severity {
  Critical = "CRITICAL",
  High = "HIGH",
  Info = "INFO",
  Low = "LOW",
  Medium = "MEDIUM",
}

export type Sn8AggregatedServicePortBlock = ImsProvider &
  ProductBlockModel & {
    __typename?: "Sn8AggregatedServicePortBlock";
    /** Get detailed IMS info */
    ims?: Maybe<ImsService>;
    imsCircuitId: Scalars["Int"];
    label?: Maybe<Scalars["String"]>;
    name: Scalars["String"];
    nsoServiceId: Scalars["UUID"];
    /** Returns list of other_subscriptions ids */
    otherSubscriptionIds: Array<Scalars["UUID"]>;
    /** Returns list of other subscriptions */
    otherSubscriptions: Array<SubscriptionInterface>;
    ownerSubscriptionId?: Maybe<Scalars["UUID"]>;
    /** List of ports */
    port: Array<SubscriptionInterface>;
    portMode: Scalars["String"];
    portSubscriptionId: Array<Scalars["UUID"]>;
    subscriptionInstanceId: Scalars["UUID"];
    title?: Maybe<Scalars["String"]>;
  };

export type Sn8AggregatedServicePortBlockOtherSubscriptionIdsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8AggregatedServicePortBlockOtherSubscriptionsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** Aggregated service port subscription */
export type Sn8AggregatedServicePortSubscription = BaseEnrichedTagsSubscription &
  BaseHealthSubscription &
  SubscriptionInterface & {
    __typename?: "Sn8AggregatedServicePortSubscription";
    /** Return organisation on this subscription */
    customer: Customer;
    customerDescriptions: Array<CustomerDescription>;
    customerId: Scalars["UUID"];
    /** Get depends_on subscriptions */
    dependsOn: Array<SubscriptionInterface>;
    description: Scalars["String"];
    domain: Scalars["Domain"];
    endDate?: Maybe<Scalars["String"]>;
    enrichedTags: Array<Scalars["String"]>;
    firewallEnabled: Scalars["Boolean"];
    /** Return fixed inputs */
    fixedInputs: Scalars["JSON"];
    healthStatus?: Maybe<HealthStatus>;
    /** Return all ims circuits */
    imsCircuits: Array<ImsCircuit>;
    /** Get in_use_by subscriptions */
    inUseBy: Array<SubscriptionInterface>;
    insync: Scalars["Boolean"];
    /** Return locations on this subscription */
    locations: Array<FederatedLocation>;
    /** Get metadata for this subscription */
    metadata: Scalars["JSON"];
    /** List of minimal impacted notifications for this subscription */
    minimalImpactNotifications: Array<MinimalImpactNotification>;
    name?: Maybe<Scalars["String"]>;
    note?: Maybe<Scalars["String"]>;
    notifications?: Maybe<Array<Notification>>;
    port: Sn8AggregatedServicePortBlock;
    portSpeed: Scalars["Int"];
    portSubscriptionInstanceId?: Maybe<Scalars["UUID"]>;
    product: Product;
    /** Return all products blocks that are part of a subscription */
    productBlocks: Array<ProductBlock>;
    startDate?: Maybe<Scalars["String"]>;
    status: Scalars["String"];
    subscriptionId: Scalars["UUID"];
    tag?: Maybe<Scalars["String"]>;
    vlanRange?: Maybe<Scalars["String"]>;
  };

/** Aggregated service port subscription */
export type Sn8AggregatedServicePortSubscriptionDependsOnArgs = {
  dependsOnStatusFilter?: InputMaybe<Array<Scalars["String"]>>;
  productTypeFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** Aggregated service port subscription */
export type Sn8AggregatedServicePortSubscriptionImsCircuitsArgs = {
  boundary?: SubscriptionBoundary;
};

/** Aggregated service port subscription */
export type Sn8AggregatedServicePortSubscriptionInUseByArgs = {
  inUseByFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** Aggregated service port subscription */
export type Sn8AggregatedServicePortSubscriptionProductBlocksArgs = {
  resourceTypes?: InputMaybe<Array<Scalars["String"]>>;
  tags?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8CorelinkAggregateBlock = ProductBlockModel & {
  __typename?: "Sn8CorelinkAggregateBlock";
  corelinkIpv4IpamId: Scalars["Int"];
  corelinkIpv6IpamId: Scalars["Int"];
  imsAggregatePortId: Scalars["Int"];
  label?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  node: NodeProductBlock;
  /** Returns list of other_subscriptions ids */
  otherSubscriptionIds: Array<Scalars["UUID"]>;
  /** Returns list of other subscriptions */
  otherSubscriptions: Array<SubscriptionInterface>;
  ownerSubscriptionId?: Maybe<Scalars["UUID"]>;
  subscriptionInstanceId: Scalars["UUID"];
  title?: Maybe<Scalars["String"]>;
};

export type Sn8CorelinkAggregateBlockOtherSubscriptionIdsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8CorelinkAggregateBlockOtherSubscriptionsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8CorelinkBlock = ImsProvider &
  ProductBlockModel & {
    __typename?: "Sn8CorelinkBlock";
    aggregates: Array<Sn8CorelinkAggregateBlock>;
    /** Get detailed IMS info */
    ims?: Maybe<ImsService>;
    imsCircuitId: Scalars["Int"];
    isisMetric: Scalars["Int"];
    label?: Maybe<Scalars["String"]>;
    name: Scalars["String"];
    nsoServiceId: Scalars["UUID"];
    /** Returns list of other_subscriptions ids */
    otherSubscriptionIds: Array<Scalars["UUID"]>;
    /** Returns list of other subscriptions */
    otherSubscriptions: Array<SubscriptionInterface>;
    ownerSubscriptionId?: Maybe<Scalars["UUID"]>;
    portPairs: Array<Sn8CorelinkPortPairBlock>;
    subscriptionInstanceId: Scalars["UUID"];
    title?: Maybe<Scalars["String"]>;
  };

export type Sn8CorelinkBlockOtherSubscriptionIdsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8CorelinkBlockOtherSubscriptionsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8CorelinkPortPairBlock = ProductBlockModel & {
  __typename?: "Sn8CorelinkPortPairBlock";
  imsCorelinkTrunkId: Scalars["Int"];
  imsPortId1: Scalars["Int"];
  imsPortId2: Scalars["Int"];
  label?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  /** Returns list of other_subscriptions ids */
  otherSubscriptionIds: Array<Scalars["UUID"]>;
  /** Returns list of other subscriptions */
  otherSubscriptions: Array<SubscriptionInterface>;
  ownerSubscriptionId?: Maybe<Scalars["UUID"]>;
  subscriptionInstanceId: Scalars["UUID"];
  title?: Maybe<Scalars["String"]>;
};

export type Sn8CorelinkPortPairBlockOtherSubscriptionIdsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8CorelinkPortPairBlockOtherSubscriptionsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** Corelink subscription */
export type Sn8CorelinkSubscription = SubscriptionInterface & {
  __typename?: "Sn8CorelinkSubscription";
  corelink: Sn8CorelinkBlock;
  /** Return organisation on this subscription */
  customer: Customer;
  customerDescriptions: Array<CustomerDescription>;
  customerId: Scalars["UUID"];
  /** Get depends_on subscriptions */
  dependsOn: Array<SubscriptionInterface>;
  description: Scalars["String"];
  endDate?: Maybe<Scalars["String"]>;
  firewallEnabled: Scalars["Boolean"];
  /** Return fixed inputs */
  fixedInputs: Scalars["JSON"];
  /** Return all ims circuits */
  imsCircuits: Array<ImsCircuit>;
  /** Get in_use_by subscriptions */
  inUseBy: Array<SubscriptionInterface>;
  insync: Scalars["Boolean"];
  /** Return locations on this subscription */
  locations: Array<FederatedLocation>;
  /** Get metadata for this subscription */
  metadata: Scalars["JSON"];
  /** List of minimal impacted notifications for this subscription */
  minimalImpactNotifications: Array<MinimalImpactNotification>;
  name?: Maybe<Scalars["String"]>;
  note?: Maybe<Scalars["String"]>;
  notifications?: Maybe<Array<Notification>>;
  portSubscriptionInstanceId?: Maybe<Scalars["UUID"]>;
  product: Product;
  /** Return all products blocks that are part of a subscription */
  productBlocks: Array<ProductBlock>;
  startDate?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  tag?: Maybe<Scalars["String"]>;
  vlanRange?: Maybe<Scalars["String"]>;
};

/** Corelink subscription */
export type Sn8CorelinkSubscriptionDependsOnArgs = {
  dependsOnStatusFilter?: InputMaybe<Array<Scalars["String"]>>;
  productTypeFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** Corelink subscription */
export type Sn8CorelinkSubscriptionImsCircuitsArgs = {
  boundary?: SubscriptionBoundary;
};

/** Corelink subscription */
export type Sn8CorelinkSubscriptionInUseByArgs = {
  inUseByFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** Corelink subscription */
export type Sn8CorelinkSubscriptionProductBlocksArgs = {
  resourceTypes?: InputMaybe<Array<Scalars["String"]>>;
  tags?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8IpBgpServiceAttachPointSettingsBlock = ProductBlockModel &
  PtpIpamProvider & {
    __typename?: "Sn8IpBgpServiceAttachPointSettingsBlock";
    bfd: Scalars["Boolean"];
    bfdMinimumInterval?: Maybe<Scalars["Int"]>;
    bfdMultiplier?: Maybe<Scalars["Int"]>;
    bgpExportPolicy: Scalars["String"];
    bgpHashAlgorithm: Scalars["String"];
    bgpPassword?: Maybe<Scalars["String"]>;
    bgpSessionPriority: Scalars["BgpSessionPriority"];
    customerIpv4Mtu: Scalars["MTU"];
    customerIpv6Mtu?: Maybe<Scalars["MTU"]>;
    enableRouting: Scalars["Boolean"];
    label?: Maybe<Scalars["String"]>;
    name: Scalars["String"];
    /** Returns list of other_subscriptions ids */
    otherSubscriptionIds: Array<Scalars["UUID"]>;
    /** Returns list of other subscriptions */
    otherSubscriptions: Array<SubscriptionInterface>;
    ownerSubscriptionId?: Maybe<Scalars["UUID"]>;
    prefixes: Array<IpPrefixBlock>;
    /** Ipam details */
    ptpIpv4Ipam?: Maybe<PrefixType>;
    ptpIpv4IpamId: Scalars["Int"];
    /** Ipam details */
    ptpIpv6Ipam?: Maybe<PrefixType>;
    ptpIpv6IpamId?: Maybe<Scalars["Int"]>;
    sap: Sn8ServiceAttachPointBlock;
    subscriptionInstanceId: Scalars["UUID"];
    title?: Maybe<Scalars["String"]>;
  };

export type Sn8IpBgpServiceAttachPointSettingsBlockOtherSubscriptionIdsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8IpBgpServiceAttachPointSettingsBlockOtherSubscriptionsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8IpBgpServiceSettingsBlock = ProductBlockModel & {
  __typename?: "Sn8IpBgpServiceSettingsBlock";
  asn: Scalars["Asn"];
  label?: Maybe<Scalars["String"]>;
  multicast: Scalars["Boolean"];
  name: Scalars["String"];
  /** Returns list of other_subscriptions ids */
  otherSubscriptionIds: Array<Scalars["UUID"]>;
  /** Returns list of other subscriptions */
  otherSubscriptions: Array<SubscriptionInterface>;
  ownerSubscriptionId?: Maybe<Scalars["UUID"]>;
  pinPrefix: Array<IpPrefixBlock>;
  subscriptionInstanceId: Scalars["UUID"];
  surfcertFilter: Scalars["String"];
  surfcertFilterEnabled: Scalars["Boolean"];
  title?: Maybe<Scalars["String"]>;
};

export type Sn8IpBgpServiceSettingsBlockOtherSubscriptionIdsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8IpBgpServiceSettingsBlockOtherSubscriptionsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The IP BGP subscription type. */
export type Sn8IpBgpSubscription = BaseEnrichedTagsSubscription &
  BaseHealthSubscription &
  SubscriptionInterface & {
    __typename?: "Sn8IpBgpSubscription";
    /** Return organisation on this subscription */
    customer: Customer;
    customerDescriptions: Array<CustomerDescription>;
    customerId: Scalars["UUID"];
    /** Get depends_on subscriptions */
    dependsOn: Array<SubscriptionInterface>;
    description: Scalars["String"];
    domain: Scalars["Domain"];
    endDate?: Maybe<Scalars["String"]>;
    enrichedTags: Array<Scalars["String"]>;
    firewallEnabled: Scalars["Boolean"];
    /** Return fixed inputs */
    fixedInputs: Scalars["JSON"];
    healthStatus?: Maybe<HealthStatus>;
    /** Return all ims circuits */
    imsCircuits: Array<ImsCircuit>;
    /** Get in_use_by subscriptions */
    inUseBy: Array<SubscriptionInterface>;
    insync: Scalars["Boolean"];
    ipRoutingType: Scalars["String"];
    /** Return locations on this subscription */
    locations: Array<FederatedLocation>;
    /** Get metadata for this subscription */
    metadata: Scalars["JSON"];
    /** List of minimal impacted notifications for this subscription */
    minimalImpactNotifications: Array<MinimalImpactNotification>;
    name?: Maybe<Scalars["String"]>;
    note?: Maybe<Scalars["String"]>;
    notifications?: Maybe<Array<Notification>>;
    portSubscriptionInstanceId?: Maybe<Scalars["UUID"]>;
    product: Product;
    /** Return all products blocks that are part of a subscription */
    productBlocks: Array<ProductBlock>;
    startDate?: Maybe<Scalars["String"]>;
    status: Scalars["String"];
    subscriptionId: Scalars["UUID"];
    tag?: Maybe<Scalars["String"]>;
    vc: Sn8IpBgpVirtualCircuitBlock;
    vlanRange?: Maybe<Scalars["String"]>;
  };

/** The IP BGP subscription type. */
export type Sn8IpBgpSubscriptionDependsOnArgs = {
  dependsOnStatusFilter?: InputMaybe<Array<Scalars["String"]>>;
  productTypeFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The IP BGP subscription type. */
export type Sn8IpBgpSubscriptionImsCircuitsArgs = {
  boundary?: SubscriptionBoundary;
};

/** The IP BGP subscription type. */
export type Sn8IpBgpSubscriptionInUseByArgs = {
  inUseByFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The IP BGP subscription type. */
export type Sn8IpBgpSubscriptionProductBlocksArgs = {
  resourceTypes?: InputMaybe<Array<Scalars["String"]>>;
  tags?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8IpBgpVirtualCircuitBlock = ImsProvider &
  ProductBlockModel & {
    __typename?: "Sn8IpBgpVirtualCircuitBlock";
    /** Get detailed IMS info */
    ims?: Maybe<ImsService>;
    imsCircuitId: Scalars["Int"];
    label?: Maybe<Scalars["String"]>;
    name: Scalars["String"];
    nsoServiceId: Scalars["UUID"];
    /** Returns list of other_subscriptions ids */
    otherSubscriptionIds: Array<Scalars["UUID"]>;
    /** Returns list of other subscriptions */
    otherSubscriptions: Array<SubscriptionInterface>;
    ownerSubscriptionId?: Maybe<Scalars["UUID"]>;
    saps: Array<Sn8IpBgpServiceAttachPointSettingsBlock>;
    serviceSpeed: Scalars["Int"];
    settings: Sn8IpBgpServiceSettingsBlock;
    subscriptionInstanceId: Scalars["UUID"];
    title?: Maybe<Scalars["String"]>;
  };

export type Sn8IpBgpVirtualCircuitBlockOtherSubscriptionIdsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8IpBgpVirtualCircuitBlockOtherSubscriptionsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8IpStaticServiceAttachPointSettingsBlock = ProductBlockModel &
  PtpIpamProvider & {
    __typename?: "Sn8IpStaticServiceAttachPointSettingsBlock";
    customerIpv4Mtu: Scalars["MTU"];
    customerIpv6Mtu?: Maybe<Scalars["MTU"]>;
    label?: Maybe<Scalars["String"]>;
    name: Scalars["String"];
    /** Returns list of other_subscriptions ids */
    otherSubscriptionIds: Array<Scalars["UUID"]>;
    /** Returns list of other subscriptions */
    otherSubscriptions: Array<SubscriptionInterface>;
    ownerSubscriptionId?: Maybe<Scalars["UUID"]>;
    prefixes: Array<IpPrefixBlock>;
    /** Ipam details */
    ptpIpv4Ipam?: Maybe<PrefixType>;
    ptpIpv4IpamId?: Maybe<Scalars["Int"]>;
    /** Ipam details */
    ptpIpv6Ipam?: Maybe<PrefixType>;
    ptpIpv6IpamId?: Maybe<Scalars["Int"]>;
    sap: Sn8ServiceAttachPointBlock;
    subscriptionInstanceId: Scalars["UUID"];
    title?: Maybe<Scalars["String"]>;
  };

export type Sn8IpStaticServiceAttachPointSettingsBlockOtherSubscriptionIdsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8IpStaticServiceAttachPointSettingsBlockOtherSubscriptionsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8IpStaticServiceSettingsBlock = ProductBlockModel & {
  __typename?: "Sn8IpStaticServiceSettingsBlock";
  label?: Maybe<Scalars["String"]>;
  multicast: Scalars["Boolean"];
  name: Scalars["String"];
  /** Returns list of other_subscriptions ids */
  otherSubscriptionIds: Array<Scalars["UUID"]>;
  /** Returns list of other subscriptions */
  otherSubscriptions: Array<SubscriptionInterface>;
  ownerSubscriptionId?: Maybe<Scalars["UUID"]>;
  pinPrefix: Array<IpPrefixBlock>;
  subscriptionInstanceId: Scalars["UUID"];
  surfcertFilter: Scalars["String"];
  surfcertFilterEnabled: Scalars["Boolean"];
  title?: Maybe<Scalars["String"]>;
};

export type Sn8IpStaticServiceSettingsBlockOtherSubscriptionIdsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8IpStaticServiceSettingsBlockOtherSubscriptionsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The IP static subscription type. */
export type Sn8IpStaticSubscription = BaseEnrichedTagsSubscription &
  BaseHealthSubscription &
  SubscriptionInterface & {
    __typename?: "Sn8IpStaticSubscription";
    /** Return organisation on this subscription */
    customer: Customer;
    customerDescriptions: Array<CustomerDescription>;
    customerId: Scalars["UUID"];
    /** Get depends_on subscriptions */
    dependsOn: Array<SubscriptionInterface>;
    description: Scalars["String"];
    domain: Scalars["Domain"];
    endDate?: Maybe<Scalars["String"]>;
    enrichedTags: Array<Scalars["String"]>;
    firewallEnabled: Scalars["Boolean"];
    /** Return fixed inputs */
    fixedInputs: Scalars["JSON"];
    healthStatus?: Maybe<HealthStatus>;
    /** Return all ims circuits */
    imsCircuits: Array<ImsCircuit>;
    /** Get in_use_by subscriptions */
    inUseBy: Array<SubscriptionInterface>;
    insync: Scalars["Boolean"];
    ipRoutingType: Scalars["String"];
    /** Return locations on this subscription */
    locations: Array<FederatedLocation>;
    /** Get metadata for this subscription */
    metadata: Scalars["JSON"];
    /** List of minimal impacted notifications for this subscription */
    minimalImpactNotifications: Array<MinimalImpactNotification>;
    name?: Maybe<Scalars["String"]>;
    note?: Maybe<Scalars["String"]>;
    notifications?: Maybe<Array<Notification>>;
    portSubscriptionInstanceId?: Maybe<Scalars["UUID"]>;
    product: Product;
    /** Return all products blocks that are part of a subscription */
    productBlocks: Array<ProductBlock>;
    startDate?: Maybe<Scalars["String"]>;
    status: Scalars["String"];
    subscriptionId: Scalars["UUID"];
    tag?: Maybe<Scalars["String"]>;
    vc: Sn8IpStaticVirtualCircuitBlock;
    vlanRange?: Maybe<Scalars["String"]>;
  };

/** The IP static subscription type. */
export type Sn8IpStaticSubscriptionDependsOnArgs = {
  dependsOnStatusFilter?: InputMaybe<Array<Scalars["String"]>>;
  productTypeFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The IP static subscription type. */
export type Sn8IpStaticSubscriptionImsCircuitsArgs = {
  boundary?: SubscriptionBoundary;
};

/** The IP static subscription type. */
export type Sn8IpStaticSubscriptionInUseByArgs = {
  inUseByFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The IP static subscription type. */
export type Sn8IpStaticSubscriptionProductBlocksArgs = {
  resourceTypes?: InputMaybe<Array<Scalars["String"]>>;
  tags?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8IpStaticVirtualCircuitBlock = ImsProvider &
  ProductBlockModel & {
    __typename?: "Sn8IpStaticVirtualCircuitBlock";
    /** Get detailed IMS info */
    ims?: Maybe<ImsService>;
    imsCircuitId: Scalars["Int"];
    label?: Maybe<Scalars["String"]>;
    name: Scalars["String"];
    nsoServiceId: Scalars["UUID"];
    /** Returns list of other_subscriptions ids */
    otherSubscriptionIds: Array<Scalars["UUID"]>;
    /** Returns list of other subscriptions */
    otherSubscriptions: Array<SubscriptionInterface>;
    ownerSubscriptionId?: Maybe<Scalars["UUID"]>;
    sap: Sn8IpStaticServiceAttachPointSettingsBlock;
    serviceSpeed: Scalars["Int"];
    settings: Sn8IpStaticServiceSettingsBlock;
    subscriptionInstanceId: Scalars["UUID"];
    title?: Maybe<Scalars["String"]>;
  };

export type Sn8IpStaticVirtualCircuitBlockOtherSubscriptionIdsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8IpStaticVirtualCircuitBlockOtherSubscriptionsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8IrbServicePortBlock = ImsProvider &
  ProductBlockModel & {
    __typename?: "Sn8IrbServicePortBlock";
    /** Get detailed IMS info */
    ims?: Maybe<ImsService>;
    imsCircuitId: Scalars["Int"];
    label?: Maybe<Scalars["String"]>;
    name: Scalars["String"];
    node: NodeProductBlock;
    nsoServiceId: Scalars["UUID"];
    /** Returns list of other_subscriptions ids */
    otherSubscriptionIds: Array<Scalars["UUID"]>;
    /** Returns list of other subscriptions */
    otherSubscriptions: Array<SubscriptionInterface>;
    ownerSubscriptionId?: Maybe<Scalars["UUID"]>;
    subscriptionInstanceId: Scalars["UUID"];
    title?: Maybe<Scalars["String"]>;
  };

export type Sn8IrbServicePortBlockOtherSubscriptionIdsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8IrbServicePortBlockOtherSubscriptionsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The IRB port subscription type. */
export type Sn8IrbServicePortSubscription = BaseEnrichedTagsSubscription &
  BaseHealthSubscription &
  SubscriptionInterface & {
    __typename?: "Sn8IrbServicePortSubscription";
    /** Return organisation on this subscription */
    customer: Customer;
    customerDescriptions: Array<CustomerDescription>;
    customerId: Scalars["UUID"];
    /** Get depends_on subscriptions */
    dependsOn: Array<SubscriptionInterface>;
    description: Scalars["String"];
    domain: Scalars["Domain"];
    endDate?: Maybe<Scalars["String"]>;
    enrichedTags: Array<Scalars["String"]>;
    firewallEnabled: Scalars["Boolean"];
    /** Return fixed inputs */
    fixedInputs: Scalars["JSON"];
    healthStatus?: Maybe<HealthStatus>;
    /** Return all ims circuits */
    imsCircuits: Array<ImsCircuit>;
    /** Get in_use_by subscriptions */
    inUseBy: Array<SubscriptionInterface>;
    insync: Scalars["Boolean"];
    /** Return locations on this subscription */
    locations: Array<FederatedLocation>;
    /** Get metadata for this subscription */
    metadata: Scalars["JSON"];
    /** List of minimal impacted notifications for this subscription */
    minimalImpactNotifications: Array<MinimalImpactNotification>;
    name?: Maybe<Scalars["String"]>;
    note?: Maybe<Scalars["String"]>;
    notifications?: Maybe<Array<Notification>>;
    port: Sn8IrbServicePortBlock;
    portSubscriptionInstanceId?: Maybe<Scalars["UUID"]>;
    product: Product;
    /** Return all products blocks that are part of a subscription */
    productBlocks: Array<ProductBlock>;
    startDate?: Maybe<Scalars["String"]>;
    status: Scalars["String"];
    subscriptionId: Scalars["UUID"];
    tag?: Maybe<Scalars["String"]>;
    vlanRange?: Maybe<Scalars["String"]>;
  };

/** The IRB port subscription type. */
export type Sn8IrbServicePortSubscriptionDependsOnArgs = {
  dependsOnStatusFilter?: InputMaybe<Array<Scalars["String"]>>;
  productTypeFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The IRB port subscription type. */
export type Sn8IrbServicePortSubscriptionImsCircuitsArgs = {
  boundary?: SubscriptionBoundary;
};

/** The IRB port subscription type. */
export type Sn8IrbServicePortSubscriptionInUseByArgs = {
  inUseByFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The IRB port subscription type. */
export type Sn8IrbServicePortSubscriptionProductBlocksArgs = {
  resourceTypes?: InputMaybe<Array<Scalars["String"]>>;
  tags?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8L2VpnEsiBlock = ProductBlockModel & {
  __typename?: "Sn8L2VpnEsiBlock";
  inUseByIds?: Maybe<Array<Scalars["UUID"]>>;
  label?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  /** Returns list of other_subscriptions ids */
  otherSubscriptionIds: Array<Scalars["UUID"]>;
  /** Returns list of other subscriptions */
  otherSubscriptions: Array<SubscriptionInterface>;
  ownerSubscriptionId?: Maybe<Scalars["UUID"]>;
  saps: Array<Sn8ServiceAttachPointBlock>;
  subscriptionInstanceId: Scalars["UUID"];
  title?: Maybe<Scalars["String"]>;
};

export type Sn8L2VpnEsiBlockOtherSubscriptionIdsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8L2VpnEsiBlockOtherSubscriptionsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** L2VPN subscription */
export type Sn8L2VpnSubscription = BaseEnrichedTagsSubscription &
  BaseHealthSubscription &
  SubscriptionInterface & {
    __typename?: "Sn8L2VpnSubscription";
    /** Return organisation on this subscription */
    customer: Customer;
    customerDescriptions: Array<CustomerDescription>;
    customerId: Scalars["UUID"];
    /** Get depends_on subscriptions */
    dependsOn: Array<SubscriptionInterface>;
    description: Scalars["String"];
    domain: Scalars["Domain"];
    endDate?: Maybe<Scalars["String"]>;
    enrichedTags: Array<Scalars["String"]>;
    firewallEnabled: Scalars["Boolean"];
    /** Return fixed inputs */
    fixedInputs: Scalars["JSON"];
    healthStatus?: Maybe<HealthStatus>;
    /** Return all ims circuits */
    imsCircuits: Array<ImsCircuit>;
    /** Get in_use_by subscriptions */
    inUseBy: Array<SubscriptionInterface>;
    insync: Scalars["Boolean"];
    /** Return locations on this subscription */
    locations: Array<FederatedLocation>;
    /** Get metadata for this subscription */
    metadata: Scalars["JSON"];
    /** List of minimal impacted notifications for this subscription */
    minimalImpactNotifications: Array<MinimalImpactNotification>;
    name?: Maybe<Scalars["String"]>;
    note?: Maybe<Scalars["String"]>;
    notifications?: Maybe<Array<Notification>>;
    portSubscriptionInstanceId?: Maybe<Scalars["UUID"]>;
    product: Product;
    /** Return all products blocks that are part of a subscription */
    productBlocks: Array<ProductBlock>;
    startDate?: Maybe<Scalars["String"]>;
    status: Scalars["String"];
    subscriptionId: Scalars["UUID"];
    tag?: Maybe<Scalars["String"]>;
    vc: Sn8L2VpnVirtualCircuitBlock;
    vlanRange?: Maybe<Scalars["String"]>;
  };

/** L2VPN subscription */
export type Sn8L2VpnSubscriptionDependsOnArgs = {
  dependsOnStatusFilter?: InputMaybe<Array<Scalars["String"]>>;
  productTypeFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** L2VPN subscription */
export type Sn8L2VpnSubscriptionImsCircuitsArgs = {
  boundary?: SubscriptionBoundary;
};

/** L2VPN subscription */
export type Sn8L2VpnSubscriptionInUseByArgs = {
  inUseByFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** L2VPN subscription */
export type Sn8L2VpnSubscriptionProductBlocksArgs = {
  resourceTypes?: InputMaybe<Array<Scalars["String"]>>;
  tags?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8L2VpnVirtualCircuitBlock = ImsProvider &
  ProductBlockModel & {
    __typename?: "Sn8L2VpnVirtualCircuitBlock";
    bumFilter: Scalars["Boolean"];
    esis: Array<Sn8L2VpnEsiBlock>;
    /** Get detailed IMS info */
    ims?: Maybe<ImsService>;
    imsCircuitId: Scalars["Int"];
    interfaceMacIpLimit: Scalars["Int"];
    label?: Maybe<Scalars["String"]>;
    name: Scalars["String"];
    nsoServiceId: Scalars["UUID"];
    /** Returns list of other_subscriptions ids */
    otherSubscriptionIds: Array<Scalars["UUID"]>;
    /** Returns list of other subscriptions */
    otherSubscriptions: Array<SubscriptionInterface>;
    ownerSubscriptionId?: Maybe<Scalars["UUID"]>;
    serviceSpeed: Scalars["Int"];
    speedPolicer: Scalars["Boolean"];
    subscriptionInstanceId: Scalars["UUID"];
    title?: Maybe<Scalars["String"]>;
    vlanRetagging: Scalars["Boolean"];
  };

export type Sn8L2VpnVirtualCircuitBlockOtherSubscriptionIdsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8L2VpnVirtualCircuitBlockOtherSubscriptionsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8L3VpnServiceAttachPointSettingsBlock = ProductBlockModel & {
  __typename?: "Sn8L3VpnServiceAttachPointSettingsBlock";
  asn: Scalars["Asn"];
  bfd: Scalars["Boolean"];
  bfdMinimumInterval?: Maybe<Scalars["Int"]>;
  bfdMultiplier?: Maybe<Scalars["Int"]>;
  bgpExportPolicy?: Maybe<Scalars["String"]>;
  bgpHashAlgorithm?: Maybe<Scalars["String"]>;
  bgpPassword?: Maybe<Scalars["String"]>;
  bgpSessionPriority?: Maybe<Scalars["BgpSessionPriority"]>;
  customerIpv4Mtu?: Maybe<Scalars["MTU"]>;
  customerIpv6Mtu?: Maybe<Scalars["MTU"]>;
  enableRouting?: Maybe<Scalars["Boolean"]>;
  endpointRole?: Maybe<Scalars["String"]>;
  ipv4Address?: Maybe<Scalars["String"]>;
  ipv4MaxPrefix?: Maybe<Scalars["MaxPrefix"]>;
  ipv4RemoteAddress?: Maybe<Scalars["String"]>;
  ipv6Address?: Maybe<Scalars["String"]>;
  ipv6MaxPrefix?: Maybe<Scalars["MaxPrefix"]>;
  ipv6RemoteAddress?: Maybe<Scalars["String"]>;
  label?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  /** Returns list of other_subscriptions ids */
  otherSubscriptionIds: Array<Scalars["UUID"]>;
  /** Returns list of other subscriptions */
  otherSubscriptions: Array<SubscriptionInterface>;
  ownerSubscriptionId?: Maybe<Scalars["UUID"]>;
  prefixes: Array<IpPrefixBlock>;
  ptpIpv4IpamId?: Maybe<Scalars["Int"]>;
  ptpIpv6IpamId?: Maybe<Scalars["Int"]>;
  sap: Sn8ServiceAttachPointBlock;
  subscriptionInstanceId: Scalars["UUID"];
  title?: Maybe<Scalars["String"]>;
  urpf: Scalars["String"];
};

export type Sn8L3VpnServiceAttachPointSettingsBlockOtherSubscriptionIdsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8L3VpnServiceAttachPointSettingsBlockOtherSubscriptionsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8L3VpnServiceSettingsBlock = ProductBlockModel & {
  __typename?: "Sn8L3VpnServiceSettingsBlock";
  asn: Scalars["Int"];
  label?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  /** Returns list of other_subscriptions ids */
  otherSubscriptionIds: Array<Scalars["UUID"]>;
  /** Returns list of other subscriptions */
  otherSubscriptions: Array<SubscriptionInterface>;
  ownerSubscriptionId?: Maybe<Scalars["UUID"]>;
  subscriptionInstanceId: Scalars["UUID"];
  title?: Maybe<Scalars["String"]>;
};

export type Sn8L3VpnServiceSettingsBlockOtherSubscriptionIdsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8L3VpnServiceSettingsBlockOtherSubscriptionsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** L3VPN subscription */
export type Sn8L3VpnSubscription = BaseEnrichedTagsSubscription &
  BaseHealthSubscription &
  SubscriptionInterface & {
    __typename?: "Sn8L3VpnSubscription";
    /** Return organisation on this subscription */
    customer: Customer;
    customerDescriptions: Array<CustomerDescription>;
    customerId: Scalars["UUID"];
    /** Get depends_on subscriptions */
    dependsOn: Array<SubscriptionInterface>;
    description: Scalars["String"];
    domain: Scalars["Domain"];
    endDate?: Maybe<Scalars["String"]>;
    enrichedTags: Array<Scalars["String"]>;
    firewallEnabled: Scalars["Boolean"];
    /** Return fixed inputs */
    fixedInputs: Scalars["JSON"];
    healthStatus?: Maybe<HealthStatus>;
    /** Return all ims circuits */
    imsCircuits: Array<ImsCircuit>;
    /** Get in_use_by subscriptions */
    inUseBy: Array<SubscriptionInterface>;
    insync: Scalars["Boolean"];
    /** Return locations on this subscription */
    locations: Array<FederatedLocation>;
    /** Get metadata for this subscription */
    metadata: Scalars["JSON"];
    /** List of minimal impacted notifications for this subscription */
    minimalImpactNotifications: Array<MinimalImpactNotification>;
    name?: Maybe<Scalars["String"]>;
    note?: Maybe<Scalars["String"]>;
    notifications?: Maybe<Array<Notification>>;
    portSubscriptionInstanceId?: Maybe<Scalars["UUID"]>;
    product: Product;
    /** Return all products blocks that are part of a subscription */
    productBlocks: Array<ProductBlock>;
    startDate?: Maybe<Scalars["String"]>;
    status: Scalars["String"];
    subscriptionId: Scalars["UUID"];
    tag?: Maybe<Scalars["String"]>;
    vc: Sn8L3VpnVirtualCircuitBlock;
    vlanRange?: Maybe<Scalars["String"]>;
  };

/** L3VPN subscription */
export type Sn8L3VpnSubscriptionDependsOnArgs = {
  dependsOnStatusFilter?: InputMaybe<Array<Scalars["String"]>>;
  productTypeFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** L3VPN subscription */
export type Sn8L3VpnSubscriptionImsCircuitsArgs = {
  boundary?: SubscriptionBoundary;
};

/** L3VPN subscription */
export type Sn8L3VpnSubscriptionInUseByArgs = {
  inUseByFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** L3VPN subscription */
export type Sn8L3VpnSubscriptionProductBlocksArgs = {
  resourceTypes?: InputMaybe<Array<Scalars["String"]>>;
  tags?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8L3VpnVirtualCircuitBlock = ImsProvider &
  ProductBlockModel & {
    __typename?: "Sn8L3VpnVirtualCircuitBlock";
    /** Get detailed IMS info */
    ims?: Maybe<ImsService>;
    imsCircuitId: Scalars["Int"];
    label?: Maybe<Scalars["String"]>;
    name: Scalars["String"];
    nsoServiceId: Scalars["UUID"];
    /** Returns list of other_subscriptions ids */
    otherSubscriptionIds: Array<Scalars["UUID"]>;
    /** Returns list of other subscriptions */
    otherSubscriptions: Array<SubscriptionInterface>;
    ownerSubscriptionId?: Maybe<Scalars["UUID"]>;
    saps: Array<Sn8L3VpnServiceAttachPointSettingsBlock>;
    serviceSpeed: Scalars["Int"];
    settings: Sn8L3VpnServiceSettingsBlock;
    specificTemplate?: Maybe<Scalars["String"]>;
    speedPolicer: Scalars["Boolean"];
    subscriptionInstanceId: Scalars["UUID"];
    title?: Maybe<Scalars["String"]>;
  };

export type Sn8L3VpnVirtualCircuitBlockOtherSubscriptionIdsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8L3VpnVirtualCircuitBlockOtherSubscriptionsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8LightPathRedundantServiceSettingsBlock = ProductBlockModel & {
  __typename?: "Sn8LightPathRedundantServiceSettingsBlock";
  imsProtectionCircuitId: Scalars["Int"];
  label?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  /** Returns list of other_subscriptions ids */
  otherSubscriptionIds: Array<Scalars["UUID"]>;
  /** Returns list of other subscriptions */
  otherSubscriptions: Array<SubscriptionInterface>;
  ownerSubscriptionId?: Maybe<Scalars["UUID"]>;
  subscriptionInstanceId: Scalars["UUID"];
  title?: Maybe<Scalars["String"]>;
  vcs: Array<Sn8LightPathVirtualCircuitBlock>;
};

export type Sn8LightPathRedundantServiceSettingsBlockOtherSubscriptionIdsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8LightPathRedundantServiceSettingsBlockOtherSubscriptionsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The LightPath Redundant subscription type. */
export type Sn8LightPathRedundantSubscription = BaseEnrichedTagsSubscription &
  BaseHealthSubscription &
  SubscriptionInterface & {
    __typename?: "Sn8LightPathRedundantSubscription";
    /** Return organisation on this subscription */
    customer: Customer;
    customerDescriptions: Array<CustomerDescription>;
    customerId: Scalars["UUID"];
    /** Get depends_on subscriptions */
    dependsOn: Array<SubscriptionInterface>;
    description: Scalars["String"];
    domain: Scalars["Domain"];
    endDate?: Maybe<Scalars["String"]>;
    enrichedTags: Array<Scalars["String"]>;
    firewallEnabled: Scalars["Boolean"];
    /** Return fixed inputs */
    fixedInputs: Scalars["JSON"];
    healthStatus?: Maybe<HealthStatus>;
    /** Return all ims circuits */
    imsCircuits: Array<ImsCircuit>;
    /** Get in_use_by subscriptions */
    inUseBy: Array<SubscriptionInterface>;
    insync: Scalars["Boolean"];
    /** Return locations on this subscription */
    locations: Array<FederatedLocation>;
    lrss: Sn8LightPathRedundantServiceSettingsBlock;
    /** Get metadata for this subscription */
    metadata: Scalars["JSON"];
    /** List of minimal impacted notifications for this subscription */
    minimalImpactNotifications: Array<MinimalImpactNotification>;
    name?: Maybe<Scalars["String"]>;
    note?: Maybe<Scalars["String"]>;
    notifications?: Maybe<Array<Notification>>;
    portSubscriptionInstanceId?: Maybe<Scalars["UUID"]>;
    product: Product;
    /** Return all products blocks that are part of a subscription */
    productBlocks: Array<ProductBlock>;
    protectionType: Scalars["String"];
    startDate?: Maybe<Scalars["String"]>;
    status: Scalars["String"];
    subscriptionId: Scalars["UUID"];
    tag?: Maybe<Scalars["String"]>;
    vlanRange?: Maybe<Scalars["String"]>;
  };

/** The LightPath Redundant subscription type. */
export type Sn8LightPathRedundantSubscriptionDependsOnArgs = {
  dependsOnStatusFilter?: InputMaybe<Array<Scalars["String"]>>;
  productTypeFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The LightPath Redundant subscription type. */
export type Sn8LightPathRedundantSubscriptionImsCircuitsArgs = {
  boundary?: SubscriptionBoundary;
};

/** The LightPath Redundant subscription type. */
export type Sn8LightPathRedundantSubscriptionInUseByArgs = {
  inUseByFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The LightPath Redundant subscription type. */
export type Sn8LightPathRedundantSubscriptionProductBlocksArgs = {
  resourceTypes?: InputMaybe<Array<Scalars["String"]>>;
  tags?: InputMaybe<Array<Scalars["String"]>>;
};

/** The LightPath subscription type. */
export type Sn8LightPathSubscription = BaseEnrichedTagsSubscription &
  BaseHealthSubscription &
  SubscriptionInterface & {
    __typename?: "Sn8LightPathSubscription";
    /** Return organisation on this subscription */
    customer: Customer;
    customerDescriptions: Array<CustomerDescription>;
    customerId: Scalars["UUID"];
    /** Get depends_on subscriptions */
    dependsOn: Array<SubscriptionInterface>;
    description: Scalars["String"];
    domain: Scalars["Domain"];
    endDate?: Maybe<Scalars["String"]>;
    enrichedTags: Array<Scalars["String"]>;
    firewallEnabled: Scalars["Boolean"];
    /** Return fixed inputs */
    fixedInputs: Scalars["JSON"];
    healthStatus?: Maybe<HealthStatus>;
    /** Return all ims circuits */
    imsCircuits: Array<ImsCircuit>;
    /** Get in_use_by subscriptions */
    inUseBy: Array<SubscriptionInterface>;
    insync: Scalars["Boolean"];
    /** Return locations on this subscription */
    locations: Array<FederatedLocation>;
    /** Get metadata for this subscription */
    metadata: Scalars["JSON"];
    /** List of minimal impacted notifications for this subscription */
    minimalImpactNotifications: Array<MinimalImpactNotification>;
    name?: Maybe<Scalars["String"]>;
    note?: Maybe<Scalars["String"]>;
    notifications?: Maybe<Array<Notification>>;
    portSubscriptionInstanceId?: Maybe<Scalars["UUID"]>;
    product: Product;
    /** Return all products blocks that are part of a subscription */
    productBlocks: Array<ProductBlock>;
    protectionType: Scalars["String"];
    startDate?: Maybe<Scalars["String"]>;
    status: Scalars["String"];
    subscriptionId: Scalars["UUID"];
    tag?: Maybe<Scalars["String"]>;
    vc: Sn8LightPathVirtualCircuitBlock;
    vlanRange?: Maybe<Scalars["String"]>;
  };

/** The LightPath subscription type. */
export type Sn8LightPathSubscriptionDependsOnArgs = {
  dependsOnStatusFilter?: InputMaybe<Array<Scalars["String"]>>;
  productTypeFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The LightPath subscription type. */
export type Sn8LightPathSubscriptionImsCircuitsArgs = {
  boundary?: SubscriptionBoundary;
};

/** The LightPath subscription type. */
export type Sn8LightPathSubscriptionInUseByArgs = {
  inUseByFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The LightPath subscription type. */
export type Sn8LightPathSubscriptionProductBlocksArgs = {
  resourceTypes?: InputMaybe<Array<Scalars["String"]>>;
  tags?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8LightPathVirtualCircuitBlock = ImsProvider &
  ProductBlockModel & {
    __typename?: "Sn8LightPathVirtualCircuitBlock";
    /** Get detailed IMS info */
    ims?: Maybe<ImsService>;
    imsCircuitId: Scalars["Int"];
    label?: Maybe<Scalars["String"]>;
    name: Scalars["String"];
    nsoServiceId: Scalars["UUID"];
    /** Returns list of other_subscriptions ids */
    otherSubscriptionIds: Array<Scalars["UUID"]>;
    /** Returns list of other subscriptions */
    otherSubscriptions: Array<SubscriptionInterface>;
    ownerSubscriptionId?: Maybe<Scalars["UUID"]>;
    remotePortShutdown: Scalars["Boolean"];
    saps: Array<Sn8ServiceAttachPointBlock>;
    serviceSpeed: Scalars["Int"];
    speedPolicer: Scalars["Boolean"];
    subscriptionInstanceId: Scalars["UUID"];
    title?: Maybe<Scalars["String"]>;
  };

export type Sn8LightPathVirtualCircuitBlockOtherSubscriptionIdsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8LightPathVirtualCircuitBlockOtherSubscriptionsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8MscBlock = ImsProvider &
  PortProvider &
  ProductBlockModel & {
    __typename?: "Sn8MscBlock";
    /** Get detailed IMS info */
    ims?: Maybe<ImsService>;
    imsCircuitId: Scalars["Int"];
    label?: Maybe<Scalars["String"]>;
    name: Scalars["String"];
    /** Returns list of other_subscriptions ids */
    otherSubscriptionIds: Array<Scalars["UUID"]>;
    /** Returns list of other subscriptions */
    otherSubscriptions: Array<SubscriptionInterface>;
    ownerSubscriptionId?: Maybe<Scalars["UUID"]>;
    port: SubscriptionInterface;
    portMode: Scalars["String"];
    portSubscriptionId: Scalars["UUID"];
    serviceTag: Scalars["Int"];
    subscriptionInstanceId: Scalars["UUID"];
    title?: Maybe<Scalars["String"]>;
  };

export type Sn8MscBlockOtherSubscriptionIdsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8MscBlockOtherSubscriptionsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The MSC port subscription type. */
export type Sn8MscSubscription = BaseEnrichedTagsSubscription &
  BaseHealthSubscription &
  SubscriptionInterface & {
    __typename?: "Sn8MscSubscription";
    /** Return organisation on this subscription */
    customer: Customer;
    customerDescriptions: Array<CustomerDescription>;
    customerId: Scalars["UUID"];
    /** Get depends_on subscriptions */
    dependsOn: Array<SubscriptionInterface>;
    description: Scalars["String"];
    domain: Scalars["Domain"];
    endDate?: Maybe<Scalars["String"]>;
    enrichedTags: Array<Scalars["String"]>;
    firewallEnabled: Scalars["Boolean"];
    /** Return fixed inputs */
    fixedInputs: Scalars["JSON"];
    healthStatus?: Maybe<HealthStatus>;
    /** Return all ims circuits */
    imsCircuits: Array<ImsCircuit>;
    /** Get in_use_by subscriptions */
    inUseBy: Array<SubscriptionInterface>;
    insync: Scalars["Boolean"];
    /** Return locations on this subscription */
    locations: Array<FederatedLocation>;
    /** Get metadata for this subscription */
    metadata: Scalars["JSON"];
    /** List of minimal impacted notifications for this subscription */
    minimalImpactNotifications: Array<MinimalImpactNotification>;
    name?: Maybe<Scalars["String"]>;
    note?: Maybe<Scalars["String"]>;
    notifications?: Maybe<Array<Notification>>;
    port: Sn8MscBlock;
    portSpeed: Scalars["Int"];
    portSubscriptionInstanceId?: Maybe<Scalars["UUID"]>;
    product: Product;
    /** Return all products blocks that are part of a subscription */
    productBlocks: Array<ProductBlock>;
    startDate?: Maybe<Scalars["String"]>;
    status: Scalars["String"];
    subscriptionId: Scalars["UUID"];
    tag?: Maybe<Scalars["String"]>;
    vlanRange?: Maybe<Scalars["String"]>;
  };

/** The MSC port subscription type. */
export type Sn8MscSubscriptionDependsOnArgs = {
  dependsOnStatusFilter?: InputMaybe<Array<Scalars["String"]>>;
  productTypeFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The MSC port subscription type. */
export type Sn8MscSubscriptionImsCircuitsArgs = {
  boundary?: SubscriptionBoundary;
};

/** The MSC port subscription type. */
export type Sn8MscSubscriptionInUseByArgs = {
  inUseByFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The MSC port subscription type. */
export type Sn8MscSubscriptionProductBlocksArgs = {
  resourceTypes?: InputMaybe<Array<Scalars["String"]>>;
  tags?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8ServiceAttachPointBlock = PortProvider &
  ProductBlockModel & {
    __typename?: "Sn8ServiceAttachPointBlock";
    label?: Maybe<Scalars["String"]>;
    name: Scalars["String"];
    /** Returns list of other_subscriptions ids */
    otherSubscriptionIds: Array<Scalars["UUID"]>;
    /** Returns list of other subscriptions */
    otherSubscriptions: Array<SubscriptionInterface>;
    ownerSubscriptionId?: Maybe<Scalars["UUID"]>;
    port: SubscriptionInterface;
    portSubscriptionId?: Maybe<Scalars["UUID"]>;
    subscriptionInstanceId: Scalars["UUID"];
    title?: Maybe<Scalars["String"]>;
    vlanrange: Scalars["String"];
  };

export type Sn8ServiceAttachPointBlockOtherSubscriptionIdsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8ServiceAttachPointBlockOtherSubscriptionsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8ServicePortBlock = ImsProvider &
  ProductBlockModel & {
    __typename?: "Sn8ServicePortBlock";
    autoNegotiation?: Maybe<Scalars["Boolean"]>;
    ignoreL3Incompletes: Scalars["Boolean"];
    /** Get detailed IMS info */
    ims?: Maybe<ImsService>;
    imsCircuitId?: Maybe<Scalars["Int"]>;
    label?: Maybe<Scalars["String"]>;
    lldp: Scalars["Boolean"];
    name: Scalars["String"];
    nsoServiceId: Scalars["UUID"];
    /** Returns list of other_subscriptions ids */
    otherSubscriptionIds: Array<Scalars["UUID"]>;
    /** Returns list of other subscriptions */
    otherSubscriptions: Array<SubscriptionInterface>;
    ownerSubscriptionId?: Maybe<Scalars["UUID"]>;
    portMode: Scalars["String"];
    subscriptionInstanceId: Scalars["UUID"];
    title?: Maybe<Scalars["String"]>;
  };

export type Sn8ServicePortBlockOtherSubscriptionIdsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type Sn8ServicePortBlockOtherSubscriptionsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The Service port subscription type. */
export type Sn8ServicePortSubscription = BaseEnrichedTagsSubscription &
  BaseHealthSubscription &
  SubscriptionInterface & {
    __typename?: "Sn8ServicePortSubscription";
    /** Return organisation on this subscription */
    customer: Customer;
    customerDescriptions: Array<CustomerDescription>;
    customerId: Scalars["UUID"];
    /** Get depends_on subscriptions */
    dependsOn: Array<SubscriptionInterface>;
    description: Scalars["String"];
    domain: Scalars["Domain"];
    endDate?: Maybe<Scalars["String"]>;
    enrichedTags: Array<Scalars["String"]>;
    firewallEnabled: Scalars["Boolean"];
    /** Return fixed inputs */
    fixedInputs: Scalars["JSON"];
    healthStatus?: Maybe<HealthStatus>;
    /** Return all ims circuits */
    imsCircuits: Array<ImsCircuit>;
    /** Get in_use_by subscriptions */
    inUseBy: Array<SubscriptionInterface>;
    insync: Scalars["Boolean"];
    /** Return locations on this subscription */
    locations: Array<FederatedLocation>;
    /** Get metadata for this subscription */
    metadata: Scalars["JSON"];
    /** List of minimal impacted notifications for this subscription */
    minimalImpactNotifications: Array<MinimalImpactNotification>;
    name?: Maybe<Scalars["String"]>;
    note?: Maybe<Scalars["String"]>;
    notifications?: Maybe<Array<Notification>>;
    port: Sn8ServicePortBlock;
    portSpeed: Scalars["Int"];
    portSubscriptionInstanceId?: Maybe<Scalars["UUID"]>;
    product: Product;
    /** Return all products blocks that are part of a subscription */
    productBlocks: Array<ProductBlock>;
    startDate?: Maybe<Scalars["String"]>;
    status: Scalars["String"];
    subscriptionId: Scalars["UUID"];
    tag?: Maybe<Scalars["String"]>;
    vlanRange?: Maybe<Scalars["String"]>;
  };

/** The Service port subscription type. */
export type Sn8ServicePortSubscriptionDependsOnArgs = {
  dependsOnStatusFilter?: InputMaybe<Array<Scalars["String"]>>;
  productTypeFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The Service port subscription type. */
export type Sn8ServicePortSubscriptionImsCircuitsArgs = {
  boundary?: SubscriptionBoundary;
};

/** The Service port subscription type. */
export type Sn8ServicePortSubscriptionInUseByArgs = {
  inUseByFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** The Service port subscription type. */
export type Sn8ServicePortSubscriptionProductBlocksArgs = {
  resourceTypes?: InputMaybe<Array<Scalars["String"]>>;
  tags?: InputMaybe<Array<Scalars["String"]>>;
};

/** Sort direction: ascending or descending */
export enum SortOrder {
  Asc = "ASC",
  Desc = "DESC",
}

export enum SpeedEnum {
  Agg = "AGG",
  Bridge = "BRIDGE",
  Ethernet = "ETHERNET",
  Internetwolk = "INTERNETWOLK",
  Service = "SERVICE",
  Tg = "TG",
  Vcg = "VCG",
  Wdm = "WDM",
  Wdm8 = "WDM8",
  "1G" = "_1G",
  "1Gbe" = "_1GBE",
  "10G" = "_10G",
  "10GbaseCwdm" = "_10GBASE_CWDM",
  "10GbaseEr" = "_10GBASE_ER",
  "10GbaseLr" = "_10GBASE_LR",
  "10GbaseSr" = "_10GBASE_SR",
  "10GbaseZr" = "_10GBASE_ZR",
  "20G" = "_20G",
  "30G" = "_30G",
  "40G" = "_40G",
  "40GbaseCwdm" = "_40GBASE_CWDM",
  "40GbaseLr4" = "_40GBASE_LR4",
  "40GbaseSr4" = "_40GBASE_SR4",
  "50G" = "_50G",
  "100G" = "_100G",
  "100GbaseCwdm4" = "_100GBASE_CWDM4",
  "100GbaseLr4" = "_100GBASE_LR4",
  "100GbaseLr10" = "_100GBASE_LR10",
  "100GbaseSr4" = "_100GBASE_SR4",
  "100GbaseSr10" = "_100GBASE_SR10",
  "400G" = "_400G",
  "400GFr4" = "_400G_FR4",
  "400GLr4_10" = "_400G_LR4_10",
  "400GLr8" = "_400G_LR8",
  "400GZr" = "_400G_ZR",
  "1000BaseCwdm" = "_1000BASE_CWDM",
  "1000BaseEx" = "_1000BASE_EX",
  "1000BaseLx" = "_1000BASE_LX",
  "1000BaseSx" = "_1000BASE_SX",
  "1000BaseT" = "_1000BASE_T",
  "1000BaseZx" = "_1000BASE_ZX",
}

export enum StatusEnum {
  Is = "IS",
  Mi = "MI",
  Oos = "OOS",
  Pl = "PL",
  Rfc = "RFC",
  Rfs = "RFS",
}

export enum SubscriptionBoundary {
  All = "ALL",
  Owner = "OWNER",
}

/** Filter subscriptions by attribute */
export type SubscriptionFilter = {
  /** Excluded subscription statuses */
  statusExcludedFilter?: InputMaybe<Array<Scalars["String"]>>;
  /** Included subscription statuses */
  statusIncludedFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** Virtual base class for detailed subscriptions */
export type SubscriptionInterface = {
  /** Return organisation on this subscription */
  customer: Customer;
  customerDescriptions: Array<CustomerDescription>;
  customerId: Scalars["UUID"];
  /** Get depends_on subscriptions */
  dependsOn: Array<SubscriptionInterface>;
  description: Scalars["String"];
  endDate?: Maybe<Scalars["String"]>;
  firewallEnabled: Scalars["Boolean"];
  /** Return fixed inputs */
  fixedInputs: Scalars["JSON"];
  /** Return all ims circuits */
  imsCircuits: Array<ImsCircuit>;
  /** Get in_use_by subscriptions */
  inUseBy: Array<SubscriptionInterface>;
  insync: Scalars["Boolean"];
  /** Return locations on this subscription */
  locations: Array<FederatedLocation>;
  /** Get metadata for this subscription */
  metadata: Scalars["JSON"];
  /** List of minimal impacted notifications for this subscription */
  minimalImpactNotifications: Array<MinimalImpactNotification>;
  name?: Maybe<Scalars["String"]>;
  note?: Maybe<Scalars["String"]>;
  notifications?: Maybe<Array<Notification>>;
  portSubscriptionInstanceId?: Maybe<Scalars["UUID"]>;
  product: Product;
  /** Return all products blocks that are part of a subscription */
  productBlocks: Array<ProductBlock>;
  startDate?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  tag?: Maybe<Scalars["String"]>;
  vlanRange?: Maybe<Scalars["String"]>;
};

/** Virtual base class for detailed subscriptions */
export type SubscriptionInterfaceDependsOnArgs = {
  dependsOnStatusFilter?: InputMaybe<Array<Scalars["String"]>>;
  productTypeFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** Virtual base class for detailed subscriptions */
export type SubscriptionInterfaceImsCircuitsArgs = {
  boundary?: SubscriptionBoundary;
};

/** Virtual base class for detailed subscriptions */
export type SubscriptionInterfaceInUseByArgs = {
  inUseByFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** Virtual base class for detailed subscriptions */
export type SubscriptionInterfaceProductBlocksArgs = {
  resourceTypes?: InputMaybe<Array<Scalars["String"]>>;
  tags?: InputMaybe<Array<Scalars["String"]>>;
};

/** Represents a paginated relationship between two entities */
export type SubscriptionInterfaceConnection = {
  __typename?: "SubscriptionInterfaceConnection";
  page: Array<SubscriptionInterface>;
  pageInfo: PageInfo;
};

/** Sort subscriptions by attribute */
export type SubscriptionsSort = {
  /** Field to sort on */
  field: Scalars["String"];
  /** Sort order (ASC or DESC */
  order?: PythiaSortOrder;
};

/** Wireless subscription */
export type SurfWirelessSubscription = BaseEnrichedTagsSubscription &
  SubscriptionInterface & {
    __typename?: "SurfWirelessSubscription";
    /** Return organisation on this subscription */
    customer: Customer;
    customerDescriptions: Array<CustomerDescription>;
    customerId: Scalars["UUID"];
    /** Get depends_on subscriptions */
    dependsOn: Array<SubscriptionInterface>;
    description: Scalars["String"];
    domain: Scalars["Domain"];
    endDate?: Maybe<Scalars["String"]>;
    enrichedTags: Array<Scalars["String"]>;
    firewallEnabled: Scalars["Boolean"];
    /** Return fixed inputs */
    fixedInputs: Scalars["JSON"];
    healthStatus?: Maybe<WirelessHealthStatus>;
    healthStatusDetail?: Maybe<WirelessHealthDetail>;
    /** Return all ims circuits */
    imsCircuits: Array<ImsCircuit>;
    /** Get in_use_by subscriptions */
    inUseBy: Array<SubscriptionInterface>;
    insync: Scalars["Boolean"];
    /** Return locations on this subscription */
    locations: Array<FederatedLocation>;
    /** Get metadata for this subscription */
    metadata: Scalars["JSON"];
    /** List of minimal impacted notifications for this subscription */
    minimalImpactNotifications: Array<MinimalImpactNotification>;
    name?: Maybe<Scalars["String"]>;
    note?: Maybe<Scalars["String"]>;
    notifications?: Maybe<Array<Notification>>;
    portSubscriptionInstanceId?: Maybe<Scalars["UUID"]>;
    product: Product;
    /** Return all products blocks that are part of a subscription */
    productBlocks: Array<ProductBlock>;
    startDate?: Maybe<Scalars["String"]>;
    status: Scalars["String"];
    subscriptionId: Scalars["UUID"];
    tag?: Maybe<Scalars["String"]>;
    vlanRange?: Maybe<Scalars["String"]>;
    wifiLocation: WifiLocation;
  };

/** Wireless subscription */
export type SurfWirelessSubscriptionDependsOnArgs = {
  dependsOnStatusFilter?: InputMaybe<Array<Scalars["String"]>>;
  productTypeFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** Wireless subscription */
export type SurfWirelessSubscriptionHealthStatusArgs = {
  countNumAccessPoints?: Scalars["Boolean"];
};

/** Wireless subscription */
export type SurfWirelessSubscriptionImsCircuitsArgs = {
  boundary?: SubscriptionBoundary;
};

/** Wireless subscription */
export type SurfWirelessSubscriptionInUseByArgs = {
  inUseByFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** Wireless subscription */
export type SurfWirelessSubscriptionProductBlocksArgs = {
  resourceTypes?: InputMaybe<Array<Scalars["String"]>>;
  tags?: InputMaybe<Array<Scalars["String"]>>;
};

export type TicketType = {
  __typename?: "TicketType";
  alarms?: Maybe<Array<Scalars["String"]>>;
  assignee?: Maybe<Scalars["String"]>;
  comments?: Maybe<Array<CommentType>>;
  description?: Maybe<Scalars["String"]>;
  endDate?: Maybe<Scalars["DateTime"]>;
  id: Scalars["String"];
  impact?: Maybe<Scalars["String"]>;
  imsTicketCreated?: Maybe<Scalars["String"]>;
  institutions?: Maybe<Array<Scalars["String"]>>;
  objects?: Maybe<Array<Scalars["String"]>>;
  startDate?: Maybe<Scalars["DateTime"]>;
  status?: Maybe<Scalars["String"]>;
  summary?: Maybe<Scalars["String"]>;
  thirdPartyWorkType?: Maybe<Array<Scalars["String"]>>;
  /** Return the available transition statuses */
  transitions: Array<TransitionType>;
};

/** The traffic metrics for this subscription */
export type Traffic = {
  __typename?: "Traffic";
  last?: Maybe<TrafficMetrics>;
  max?: Maybe<TrafficMetrics>;
  mean?: Maybe<TrafficMetrics>;
};

export type TrafficMetrics = {
  __typename?: "TrafficMetrics";
  in: Scalars["Float"];
  out: Scalars["Float"];
};

export type TransitionFromState = {
  __typename?: "TransitionFromState";
  name: Scalars["String"];
};

export type TransitionToState = {
  __typename?: "TransitionToState";
  description: Scalars["String"];
  name: Scalars["String"];
};

export type TransitionType = {
  __typename?: "TransitionType";
  fromState: TransitionFromState;
  toState: TransitionToState;
};

export enum TypeEnum {
  InternalPort = "INTERNAL_PORT",
  Internet = "INTERNET",
  Port = "PORT",
  Service = "SERVICE",
}

/** Used for (new) subscription types that are not implemented in Pythia yet */
export type UnknownSubscription = SubscriptionInterface & {
  __typename?: "UnknownSubscription";
  /** Return organisation on this subscription */
  customer: Customer;
  customerDescriptions: Array<CustomerDescription>;
  customerId: Scalars["UUID"];
  /** Get depends_on subscriptions */
  dependsOn: Array<SubscriptionInterface>;
  description: Scalars["String"];
  endDate?: Maybe<Scalars["String"]>;
  firewallEnabled: Scalars["Boolean"];
  /** Return fixed inputs */
  fixedInputs: Scalars["JSON"];
  /** Return all ims circuits */
  imsCircuits: Array<ImsCircuit>;
  /** Get in_use_by subscriptions */
  inUseBy: Array<SubscriptionInterface>;
  insync: Scalars["Boolean"];
  /** Return locations on this subscription */
  locations: Array<FederatedLocation>;
  /** Get metadata for this subscription */
  metadata: Scalars["JSON"];
  /** List of minimal impacted notifications for this subscription */
  minimalImpactNotifications: Array<MinimalImpactNotification>;
  name?: Maybe<Scalars["String"]>;
  note?: Maybe<Scalars["String"]>;
  notifications?: Maybe<Array<Notification>>;
  portSubscriptionInstanceId?: Maybe<Scalars["UUID"]>;
  product: Product;
  /** Return all products blocks that are part of a subscription */
  productBlocks: Array<ProductBlock>;
  startDate?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
  subscriptionId: Scalars["UUID"];
  tag?: Maybe<Scalars["String"]>;
  vlanRange?: Maybe<Scalars["String"]>;
};

/** Used for (new) subscription types that are not implemented in Pythia yet */
export type UnknownSubscriptionDependsOnArgs = {
  dependsOnStatusFilter?: InputMaybe<Array<Scalars["String"]>>;
  productTypeFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** Used for (new) subscription types that are not implemented in Pythia yet */
export type UnknownSubscriptionImsCircuitsArgs = {
  boundary?: SubscriptionBoundary;
};

/** Used for (new) subscription types that are not implemented in Pythia yet */
export type UnknownSubscriptionInUseByArgs = {
  inUseByFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** Used for (new) subscription types that are not implemented in Pythia yet */
export type UnknownSubscriptionProductBlocksArgs = {
  resourceTypes?: InputMaybe<Array<Scalars["String"]>>;
  tags?: InputMaybe<Array<Scalars["String"]>>;
};

export enum Urgency {
  High = "HIGH",
  Normal = "NORMAL",
}

/** Customer */
export type User = {
  __typename?: "User";
  /** User preferences for this user */
  preferences?: Maybe<UserPreferences>;
  username: Scalars["String"];
};

/** Customer */
export type UserPreferencesArgs = {
  preferenceDomain: Scalars["String"];
};

/** Return configuration needed to initiate OAuth2 implicit flow. */
export type UserPreferences = {
  __typename?: "UserPreferences";
  /** Whether the user is onboarded */
  onboarding: Scalars["Boolean"];
};

export type UserPreferencesPythiaError = PythiaError | UserPreferences;

export type VlanRangeType = {
  __typename?: "VlanRangeType";
  end: Scalars["Int"];
  start: Scalars["Int"];
  subCircuitId?: Maybe<Scalars["Int"]>;
};

/** Information about WiFi location */
export type WifiLocation = ProductBlockModel & {
  __typename?: "WifiLocation";
  apVendorName: Scalars["String"];
  detail?: Maybe<LocationType>;
  jiraLocationId: Scalars["String"];
  label?: Maybe<Scalars["String"]>;
  locationName: Scalars["String"];
  name: Scalars["String"];
  /** Returns list of other_subscriptions ids */
  otherSubscriptionIds: Array<Scalars["UUID"]>;
  /** Returns list of other subscriptions */
  otherSubscriptions: Array<SubscriptionInterface>;
  ownerSubscriptionId?: Maybe<Scalars["UUID"]>;
  sensorPresent: Scalars["Boolean"];
  subscriptionInstanceId: Scalars["UUID"];
  title?: Maybe<Scalars["String"]>;
};

/** Information about WiFi location */
export type WifiLocationOtherSubscriptionIdsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

/** Information about WiFi location */
export type WifiLocationOtherSubscriptionsArgs = {
  statusFilter?: InputMaybe<Array<Scalars["String"]>>;
};

export type WirelessHealthDetail = {
  __typename?: "WirelessHealthDetail";
  last3Days?: Maybe<WirelessHealthDetailModel>;
  lastWeek?: Maybe<WirelessHealthDetailModel>;
  today?: Maybe<WirelessHealthDetailModel>;
};

export type WirelessHealthDetailModel = {
  __typename?: "WirelessHealthDetailModel";
  five?: Maybe<WirelessKpi>;
  maxActiveUsers?: Maybe<Scalars["Int"]>;
  twofour?: Maybe<WirelessKpi>;
};

export type WirelessHealthStatus = {
  __typename?: "WirelessHealthStatus";
  activeAps?: Maybe<Scalars["Int"]>;
  inactiveAps?: Maybe<Scalars["Int"]>;
  kpiAverages?: Maybe<WirelessKpi>;
};

export type WirelessKpi = {
  __typename?: "WirelessKPI";
  DHCP?: Maybe<Scalars["Float"]>;
  MOSVOIPDOWN?: Maybe<Scalars["Float"]>;
  MOSVOIPUP?: Maybe<Scalars["Float"]>;
  RADIUS?: Maybe<Scalars["Float"]>;
  THROUGHPUTDOWN?: Maybe<Scalars["Float"]>;
  THROUGHPUTUP?: Maybe<Scalars["Float"]>;
};

export type WirelessTicketType = {
  __typename?: "WirelessTicketType";
  assignee?: Maybe<Scalars["String"]>;
  comments?: Maybe<Array<CommentType>>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  impact?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["DateTime"]>;
  status?: Maybe<Scalars["String"]>;
  summary?: Maybe<Scalars["String"]>;
};
