import { gql } from "apollo-angular";
import {
  ProductFragment,
  ServiceDetailsFragment,
  MscPortSpeedFragment,
  SubscriptionDetailsFragment,
} from "./fragments";
import { ImsPortLocationFragment, ImsServicePortLocationFragment } from "./imsInfo";

export const ServiceSpeedL2VpnFragment = gql`
  fragment ServiceSpeedL2Vpn on Sn8L2VpnSubscription {
    vc {
      subscriptionInstanceId
      serviceSpeed
    }
  }
`;

export const ServiceSpeedL3VpnFragment = gql`
  fragment ServiceSpeedL3Vpn on Sn8L3VpnSubscription {
    vc {
      subscriptionInstanceId
      serviceSpeed
    }
  }
`;

export const ServiceSpeedLightpathFragment = gql`
  fragment ServiceSpeedLightpath on Sn8LightPathSubscription {
    vc {
      subscriptionInstanceId
      serviceSpeed
    }
  }
`;

export const ServiceSpeedLightpathRedundantFragment = gql`
  fragment ServiceSpeedLightpathRedundant on Sn8LightPathRedundantSubscription {
    lrss {
      vcs {
        subscriptionInstanceId
        serviceSpeed
      }
    }
  }
`;

export const ServiceSpeedIpBgpFragment = gql`
  fragment ServiceSpeedIpBgp on Sn8IpBgpSubscription {
    vc {
      serviceSpeed
    }
  }
`;

export const ServiceSpeedIpStaticFragment = gql`
  fragment ServiceSpeedIpStatic on Sn8IpStaticSubscription {
    vc {
      serviceSpeed
    }
  }
`;

export const FirewallSubscriptionFragment = gql`
  fragment ServiceSpeedFirewall on FwSubscription {
    size
  }
`;

export const AggregatedServicePortSubscriptionDetailsFragment = gql`
  ${SubscriptionDetailsFragment}
  ${ProductFragment}
  ${ServiceDetailsFragment}
  ${ImsServicePortLocationFragment}
  fragment AggregatedServicePortSpeed on Sn8AggregatedServicePortSubscription {
    port {
      port {
        subscriptionId
        subscriptionType: __typename
        ... on Sn8ServicePortSubscription {
          subscriptionId
          portSpeed
        }
      }
    }
  }
  fragment AggregatedServicePortSubscriptionDetails on SubscriptionInterface {
    ... on Sn8AggregatedServicePortSubscription {
      ...SubscriptionDetails
      ...Product
      ...ServiceDetails
      ...AggregatedServicePortSpeed

      domain

      aggspPort: port {
        subscriptionInstanceId
        portMode
        portSubscriptionId

        ims {
          ...ImsServicePortLocation
        }
      }
    }
  }
`;

export const MscSubscriptionDetailsFragment = gql`
  ${SubscriptionDetailsFragment}
  ${ProductFragment}
  ${ServiceDetailsFragment}
  ${MscPortSpeedFragment}
  ${ImsServicePortLocationFragment}
  fragment MscSubscriptionDetails on SubscriptionInterface {
    ... on Sn8MscSubscription {
      ...SubscriptionDetails
      ...Product
      ...ServiceDetails
      ...MscPortSpeed

      domain

      port {
        name
        portSubscriptionId
        subscriptionInstanceId
        portMode
        serviceTag

        ims {
          ...ImsServicePortLocation
        }
      }
    }
  }
`;

export const IrbServicePortSubscriptionDetailsFragment = gql`
  ${SubscriptionDetailsFragment}
  ${ProductFragment}
  ${ServiceDetailsFragment}
  ${ImsPortLocationFragment}
  fragment IrbServicePortSubscriptionDetails on SubscriptionInterface {
    ... on Sn8IrbServicePortSubscription {
      ...SubscriptionDetails
      ...Product
      ...ServiceDetails

      domain

      port {
        subscriptionInstanceId

        ims {
          ...ImsPortLocation
        }
      }
    }
  }
`;

export const ServicesOnPortFragment = gql`
  ${ServiceSpeedL2VpnFragment}
  ${ServiceSpeedL3VpnFragment}
  ${ServiceSpeedLightpathFragment}
  ${ServiceSpeedLightpathRedundantFragment}
  ${ServiceSpeedIpBgpFragment}
  ${ServiceSpeedIpStaticFragment}
  ${FirewallSubscriptionFragment}
  fragment ServicesOnPort on SubscriptionInterface {
    inUseBy(inUseByFilter: ["provisioning", "active", "migrating"]) {
      subscriptionType: __typename
      subscriptionId
      description
      customerDescriptions {
        description
        customerId
      }
      product {
        type
        tag
      }
      ...ServiceSpeedL2Vpn
      ...ServiceSpeedL3Vpn
      ...ServiceSpeedLightpath
      ...ServiceSpeedLightpathRedundant
      ...ServiceSpeedIpBgp
      ...ServiceSpeedIpStatic
      ...ServiceSpeedFirewall
    }
  }
`;
/**
 * This is an SP!
 */
export const PortSubscriptionDetailsFragment = gql`
  ${SubscriptionDetailsFragment}
  ${ProductFragment}
  ${ImsPortLocationFragment}
  ${ServiceDetailsFragment}
  fragment PortSubscriptionDetails on SubscriptionInterface {
    ... on Sn8ServicePortSubscription {
      ...SubscriptionDetails
      ...Product
      ...ServiceDetails
      domain
      portSpeed

      port {
        subscriptionInstanceId
        autoNegotiation
        portMode
        name
        ims {
          speed
          ...ImsPortLocation
        }
      }
    }
  }
`;
