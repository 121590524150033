import { BgpPriority, IPSessionPriorityServicePort } from "./models/types";

export class ModifyBgpCommand {
  private service_ports: IPSessionPriorityServicePort[] = [];

  get payload() {
    return { service_ports: this.service_ports };
  }

  setForInstance(instanceId: string, priority: BgpPriority) {
    this.service_ports.push({
      subscription_instance_id: instanceId,
      priority,
    });
  }
}
