import { gql } from "apollo-angular";
import { ProductFragment, SubscriptionDetailsFragment } from "./fragments";

export const IpPrefixSubscriptionFragment = gql`
  ${SubscriptionDetailsFragment}
  ${ProductFragment}
  fragment IpPrefixSubscriptionDetails on SubscriptionInterface {
    ... on IpPrefixSubscription {
      ...SubscriptionDetails
      ...Product

      inUseBy(inUseByFilter: ["provisioning", "active", "migrating"]) {
        ...SubscriptionDetails
        product {
          tag
        }
      }

      ipPrefix {
        subscriptionInstanceId
        ipamPrefixId
        name
        ipam {
          vrf
          afi
          state
          prefix
          parentLabel
          vrfLabel
          stateLabel
          # parent
          description
          tags
        }
      }
    }
  }
`;

export const IpPrefixSubscriptionOnlyFragment = gql`
  ${SubscriptionDetailsFragment}
  ${ProductFragment}
  fragment IpPrefixSubscriptionOnlyDetails on SubscriptionInterface {
    ... on IpPrefixSubscription {
      ...SubscriptionDetails
      ...Product

      ipPrefix {
        subscriptionInstanceId
        ipamPrefixId
        name
        ipam {
          vrf
          afi
          state
          prefix
          parentLabel
          vrfLabel
          stateLabel
          #          parent
          description
          tags
        }
      }
    }
  }
`;
