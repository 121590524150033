<div>
  <div class="title-flex">
    <h3 class="title-with-icon">
      <svg-icon src="assets/images/icons/ip.svg" class="blue"></svg-icon>
      <span>{{ 'ServiceElement.SURFinternetUplink' | translate }}</span>
    </h3>
  </div>
  <div class="card card--no-padding tabs" *ngIf="subscription && subscription.ipGateway">
    <div class="tabs__content">
      <div class="tabs__grid">
        <div class="tabs__grid-item">
          <service-summary
            [service]="subscription"
            [vlanranges]="[]"
            [portId]=""
            [showTraffic]="false"
            [showTrafficButton]="true"
            [showMoreDetailsButton]="false"
          ></service-summary>
        </div>
        <div class="tabs__grid-item" *ngIf="subscription.ipGateway">
          <div class="tabs__flex-center">
            <h3>{{ 'ServiceElement.IPConfiguration' | translate }}</h3>
          </div>
          <div class="tabs__table">
            <div class="tabs__table-row">
              <div class="tabs__table-col">{{ 'ServiceElement.DDoSFilter' | translate }}</div>
              <div class="tabs__table-col">{{ subscription.ipGateway.surfcertFilter | enabled_or_value }}</div>
            </div>
            <div class="tabs__table-row" *ngIf="subscription.ipGateway.internetpinnen_ip_prefixes?.length > 0">
              <div class="tabs__table-col">{{ 'ServiceElement.InternetpinnenPrefix' | translate }}</div>
              <div class="tabs__table-col">
                <li *ngFor="let pin of subscription.ipGateway.internetpinnen_ip_prefixes">
                  {{ pin.ip_prefix.prefix }}
                </li>
              </div>
            </div>

            <div class="tabs__table-row">
              <div class="tabs__table-col">{{ 'IP.IPv4MTU' | translate }}</div>
              <div class="tabs__table-col">{{ subscription.ipGateway.ipv4Mtu}}</div>
            </div>
            <div class="tabs__table-row">
              <div class="tabs__table-col">{{ 'IP.IPv6MTU' | translate }}</div>
              <div class="tabs__table-col">{{ subscription.ipGateway.ipv6Mtu}}</div>
            </div>

            <div class="tabs__table-row">
              <div class="tabs__table-col">{{ 'IP.IPPrefixes' | translate }}</div>
              <div class="tabs__table-col">
                <div class="tabs__overflow-box">
                  <div *ngFor="let prefix of subscription.ipGateway.ipPrefixes" class="tabs__overflow-box-item">
                    {{ prefix }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card card--no-padding" *ngIf="!subscription || !subscription.ipGateway">
    <loader *ngIf="loading" class="card--loader"></loader>
    <empty-state
      *ngIf="!loading"
      [title]="'ServiceElement.SURFinternetUplink' | translate"
      [icons]="['ip']"
    ></empty-state>
  </div>
</div>
