export const getDetailRouterLink = (subscription: any, productType: string) => {
  const linkParts = [productType];
  // new replication-based routes contain both the product type and product tag
  if (["Wireless", "FW", "L2VPN", "L3VPN"].indexOf(productType) < 0) {
    linkParts.push(subscription?.product?.tag);
  }

  const link = "/subscription/" + linkParts.join("/") + "/" + subscription?.subscriptionId;
  // console.warn(link);
  return link;
};
