import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";

export interface CustomDropdownOption {
  id: number;
  name: string;
  select: boolean;
}

@Component({
  selector: "custom-dropdown",
  templateUrl: "custom-dropdown.html",
  styleUrls: ["custom-dropdown.scss"],
})
export class CustomDropdownComponent implements OnChanges {
  @Input() multiselect = true;
  @Input() options: any[] = [];
  @Input() formatter?: (item: any) => string;
  // when true, the options array is altered by reference, when false, a shadow array is used
  // and the original options remain unchanged.
  @Input() byReference = true;
  @Output() selectionChanged = new EventEmitter<number[]>();

  public active = false;
  public searchValue = "";
  public shadowOptions: CustomDropdownOption[] = [];

  get actionableOptions(): CustomDropdownOption[] {
    return this.byReference ? this.options : this.shadowOptions;
  }

  get filteredOptions() {
    return this.actionableOptions.filter((option) =>
      option.name.toLowerCase().includes(this.searchValue.toLowerCase()),
    );
  }

  get selectedOptions() {
    const selectedOptions = this.actionableOptions.filter((option) => option.select === true);
    const selectedOptionsString = selectedOptions
      .map((item) => item.name)
      .toString()
      .replace(/,/g, ", ");

    if (selectedOptions.length === 0) {
      return "Geen opties geselecteerd";
    }

    if (selectedOptions.length > 0) {
      return selectedOptionsString;
    }
  }

  get selectedOptionsCount() {
    return this.actionableOptions.filter((item) => item.select === true).length;
  }

  get selectedOptionsIds() {
    return this.actionableOptions.filter((item) => item.select).map((item) => item.id);
  }

  ngOnChanges(changes: SimpleChanges) {
    const haveFormatter = this.formatter !== undefined;
    const haveOptions = this.options.length > 0;

    if (haveOptions) {
      this.shadowOptions = this.options.map((item) => ({
        id: item.id,
        name: haveFormatter ? this.formatter(item) : item.name,
        select: false,
      }));
    }
  }

  optionClick(id) {
    if (!this.multiselect) {
      this.actionableOptions.map((opt) => {
        opt.select = false;
      });
    }

    const item = this.actionableOptions.find((opt) => opt.id === id);
    item.select = !item.select;

    if (!this.multiselect) {
      this.clickedOutside();
    }
  }

  clickInput() {
    this.active = !this.active;
  }

  isSelected(id) {
    this.actionableOptions.find((item) => item.id === id).select = true;
  }

  clickedOutside() {
    if (this.active) {
      this.active = false;
      this.selectionChanged.emit(this.selectedOptionsIds);
    }
  }
}
