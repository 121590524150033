import { AffectedSubscription } from "../../helpers/types";

export class SurfNotification {
  public id: number;
  public category: string;
  public contents: string;
  public cursor: string;
  public heading: string;
  public summary: string;
  public impact_type: string;
  public start_timestamp: number;
  public end_timestamp: number;
  public _timestamp: number;
  public urgency: string;
  public tags: string[];
  public affected_subscriptions: AffectedSubscription[];
  public updates: any;
  public plan_timestamp: number;

  get notificationIconType(): string {
    switch (this.category) {
      case "Maintenance":
        return "maintenance";
      case "PlannedWork":
        return "maintenance";
      case "Malfunction":
        return "warning";
      case "Outage":
        return "warning";
      case "General":
        return "update";
    }
  }

  get toasterColor(): string {
    /*
         * colors by urgency
        switch (this.urgency) {
            case 'normal': return 'blue';
            case 'warning': return 'orange';
            case 'high': return 'red';
        }
        */

    switch (this.category) {
      case "Maintenance":
        return "orange";
      case "PlannedWork":
        return "orange";
      case "Malfunction":
        return "red";
      case "Outage":
        return "red";
      case "General":
        return "blue";
    }
  }

  get timestamp(): number {
    return this._timestamp;
  }

  set timestamp(ts: number) {
    this._timestamp = ts * 1000;
  }
}
