<ng-template #editLightpathModal>
  <div class="dialog" style="min-height: 0px !important">
    <div class="dialog__header">
      <h3 class="dialog__title">
        <svg-icon src="assets/images/icons/lightpath.svg" class="dialog__icon"></svg-icon>
        <span
          >{{ 'Global.Action.Edit' | translate }} {{ 'Subscription.LightPath.Name' | translate }} [{{
          __currentEditableObject.presentableName }}]</span
        >
      </h3>
      <svg-icon src="assets/images/icons/x.svg" class="dialog__close" (click)="closeDialog()"></svg-icon>
    </div>

    <div class="dialog__body dialog__body--grow">
      <div class="row">
        <div class="col-12">
          <label for="" class="form-label">{{ 'Global.Name' | translate }}</label>
          <div class="input__wrapper">
            <input type="text" class="input" placeholder="" [(ngModel)]="__currentEditableObject.presentableName" />
          </div>
        </div>
      </div>
    </div>
    <div class="dialog__footer dialog__footer--end">
      <div class="button__flex">
        <span class="button button--secondary" (click)="closeDialog()">{{ 'Global.Action.Cancel' | translate }}</span>
        <span class="button" (click)="confirmNameEdit()">{{ 'Global.Action.Confirm' | translate }}</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #addFulfilmentRequestModal>
  <div class="dialog" style="min-height: 0px !important">
    <div class="dialog__header">
      <h3 class="dialog__title">
        <span *ngIf="__fulfilmentRequest.serviceRequest === FULFILMENT_SERVICE_REQUEST_PROBLEM"
          >{{ 'Services.Fulfilment.ReportProblem' | translate }} [{{ __fulfilmentRequestObject.presentableName }}]</span
        >
        <span *ngIf="__fulfilmentRequest.serviceRequest === FULFILMENT_SERVICE_REQUEST_MODIFY"
          >{{ 'Services.Fulfilment.Change' | translate }} [{{ __fulfilmentRequestObject.presentableName }}]</span
        >
        <span *ngIf="__fulfilmentRequest.serviceRequest === FULFILMENT_SERVICE_REQUEST_DELETE"
          >{{ 'Services.Fulfilment.Terminate' | translate }} [{{ __fulfilmentRequestObject.presentableName }}]</span
        >
        <span *ngIf="__fulfilmentRequest.serviceRequest === FULFILMENT_SERVICE_REQUEST_CREATE"
          >{{ __fulfilmentRequest.product}} {{ 'Services.Fulfilment.New' | translate }}</span
        >
      </h3>
      <svg-icon src="assets/images/icons/x.svg" class="dialog__close" (click)="closeDialog()"></svg-icon>
    </div>

    <div class="dialog__body dialog__body--grow">
      <div class="row" *ngIf="__fulfilmentRequest.complete ===false">
        <div class="col-12" *ngIf="__fulfilmentRequest.errors.length > 0">
          <p>{{ 'Global.Alert.Form.MissingFields' | translate }}</p>
        </div>

        <div
          style="display: flex; flex-wrap: wrap; width: 100%"
          *ngIf="__fulfilmentRequest.serviceRequest ===FULFILMENT_SERVICE_REQUEST_PROBLEM"
        >
          <div class="col-5">
            <label for="" class="form-label">{{ 'Global.Email' | translate }}</label>
            <div class="input__wrapper">
              <input
                type="email"
                class="input"
                [class.input--witherror]="__fulfilmentRequest.hasErrorForField('email')"
                placeholder="{{ 'Global.Email' | translate }}"
                [(ngModel)]="__fulfilmentRequest.contacts[0].email"
              />
            </div>
            <label for="" class="form-label">{{ 'Global.Name' | translate }}</label>
            <div class="input__wrapper">
              <input
                type="text"
                class="input"
                [class.input--witherror]="__fulfilmentRequest.hasErrorForField('fullName')"
                placeholder="{{ 'Global.Name' | translate }}"
                [(ngModel)]="__fulfilmentRequest.contacts[0].fullName"
              />
            </div>
            <label for="" class="form-label">{{ 'Global.Phone' | translate }}</label>
            <div class="input__wrapper">
              <input
                type="text"
                class="input"
                [class.input--witherror]="__fulfilmentRequest.hasErrorForField('phone')"
                placeholder="{{ 'Global.Phone' | translate }}"
                [(ngModel)]="__fulfilmentRequest.contacts[0].phone"
              />
            </div>
          </div>
          <div class="col-7">
            <label for="" class="form-label">{{ 'Global.Message' | translate }}</label>
            <div class="textarea__wrapper">
              <textarea
                [class.input--witherror]="__fulfilmentRequest.hasErrorForField('text')"
                class="textarea"
                placeholder="{{ 'Global.Message' | translate }}"
                [(ngModel)]="__fulfilmentRequest.text"
              ></textarea>
            </div>
          </div>
        </div>
        <!-- BEGIN Lichtpad formulier -->
        <div
          style="display: flex; flex-wrap: wrap; width: 100%"
          *ngIf="__fulfilmentRequest.product ===FULFILMENT_PRODUCT_LIGHTPATH"
        >
          <div class="col-6 col-md-3">
            <label class="form-label">{{ 'Services.Fulfilment.Lightpath.Type' | translate }}</label>
            <div class="radio">
              <input type="radio" id="radioProtected" name="lightpathType" />
              <label for="radioProtected">{{ 'Services.Fulfilment.Lightpath.TypeProtected' | translate }}</label>
            </div>
            <div class="radio">
              <input type="radio" id="radioRedundant" name="lightpathType" />
              <label for="radioRedundant">{{ 'Services.Fulfilment.Lightpath.TypeRedundant' | translate }}</label>
            </div>
            <br />
            <label for="" class="form-label"
              >{{ 'Services.Fulfilment.Lightpath.Bandwidth' | translate }}
              <span
                class="info"
                matTooltip="{{ 'Services.Fulfilment.Lightpath.Tooltip' | translate }}"
                [matTooltipPosition]="'above'"
              >
              </span
            ></label>
            <div class="input__wrapper input__wrapper--flex">
              <input type="number" value="0" class="input" min="0" />
              <switch></switch>
            </div>
          </div>
          <div class="col-6 offset-md-3">
            <label for="" class="form-label">{{ 'Services.Fulfilment.Lightpath.Endpoints' | translate }}</label>
            <div class="form-explain">{{ 'Service.Fulfilment.Lightpath.Endpoints.Explanation' | translate }}</div>
            <div class="row">
              <div class="col-6">
                <div>{{ 'Services.Fulfilment.Lightpath.Location' | translate }} A</div>
                <br />
                <div class="input__wrapper">
                  <input type="text" class="input" placeholder="" />
                </div>
                <div class="input__wrapper">
                  <input type="text" class="input" placeholder="" />
                </div>
              </div>
              <div class="col-6">
                <div>{{ 'Services.Fulfilment.Lightpath.Location' | translate }} B</div>
                <br />
                <div class="input__wrapper">
                  <input type="text" class="input" placeholder="" />
                </div>
                <div class="input__wrapper">
                  <input type="text" class="input" placeholder="" />
                </div>
              </div>
            </div>
            <br />
            <label for="" class="form-label"
              >{{ 'Services.Fulfilment.Lightpath.AdditionalInfo' | translate }}
              <span
                class="info"
                matTooltip="{{ 'Services.Fulfilment.Lightpath.AdditionalInfo.Explanation' | translate }}"
                [matTooltipPosition]="'above'"
              >
              </span
            ></label>
            <div class="form-explain">
              {{ 'Services.Fulfilment.Lightpath.AdditionalInfo.Explanation2' | translate }}
            </div>
            <div class="textarea__wrapper">
              <textarea class="textarea" placeholder=""></textarea>
            </div>
          </div>
        </div>
        <!-- EIND Lichtpad formulier -->
        <!-- BEGIN Multipoint dienst formulier -->
        <div
          style="display: flex; flex-wrap: wrap; width: 100%"
          *ngIf="__fulfilmentRequest.product ===FULFILMENT_PRODUCT_L2VPN"
        >
          <div class="col-6">
            <div class="form-info">
              <svg-icon class="form-info__icon" src="assets/images/icons/info.svg"></svg-icon>
              <div class="form-info__caption">
                <h3>{{ 'Services.Fulfilment.Multipoint.Header' | translate }}</h3>
                <p>{{ 'Services.Fulfilment.Multipoint.AdditionalInfo' | translate }}</p>
              </div>
            </div>
          </div>
          <div class="col-6">
            <label for="" class="form-label">{{ 'Services.Fulfilment.Multipoint.Explanation' | translate }}</label>
            <div class="textarea__wrapper">
              <textarea class="textarea"></textarea>
            </div>
          </div>
        </div>
        <!-- EIND Multipoint dienst formulier -->
        <!-- BEGIN MSP formulier -->
        <div
          style="display: flex; flex-wrap: wrap; width: 100%"
          *ngIf="__fulfilmentRequest.product ===FULFILMENT_PRODUCT_PORT"
        >
          <div class="col-6">
            <label class="form-label">{{ 'Services.Fulfilment.Multipoint.DesiredLocation' | translate }}</label>
            <div class="input__wrapper">
              <input type="text" class="input" placeholder="" />
            </div>
            <br />
            <label class="form-label">{{ 'Services.Fulfilment.Multipoint.DesiredBandwidth' | translate }}</label>
            <div class="radio">
              <input type="radio" id="radioBandwidth1" name="bandwidth" />
              <label for="radioBandwidth1">1 Gbit/s</label>
            </div>
            <div class="radio">
              <input type="radio" id="radioBandwidth2" name="bandwidth" />
              <label for="radioBandwidth2">10 Gbit/s</label>
            </div>
            <div class="radio">
              <input type="radio" id="radioBandwidth3" name="bandwidth" />
              <label for="radioBandwidth3">100 Gbit/s</label>
            </div>
            <br />
            <label class="form-label">{{ 'Services.Fulfilment.Multipoint.InterfaceType' | translate }}</label>
            <div class="radio__flex">
              <div class="radio">
                <input type="radio" id="radio1000Base-SX" name="interfaceType" />
                <label for="radio1000Base-SX">1000Base-SX</label>
              </div>
              <div class="radio">
                <input type="radio" id="radio1000Base-LX" name="interfaceType" />
                <label for="radio1000Base-LX">1000Base-LX</label>
              </div>
              <div class="radio">
                <input type="radio" id="radio10GBase-SR" name="interfaceType" />
                <label for="radio10GBase-SR">10GBase-SR</label>
              </div>
              <div class="radio">
                <input type="radio" id="radio10GBase-LR" name="interfaceType" />
                <label for="radio10GBase-LR">10GBase-LR</label>
              </div>
              <div class="radio">
                <input type="radio" id="radio100GBase-LR4" name="interfaceType" />
                <label for="radio100GBase-LR4">100GBase-LR4</label>
              </div>
              <div class="radio">
                <input type="radio" id="radio100GBase-SR4" name="interfaceType" />
                <label for="radio100GBase-SR4">100GBase-SR4</label>
              </div>
            </div>
          </div>
          <div class="col-6">
            <label for="" class="form-label">{{ 'Services.Fulfilment.Multipoint.HowMany' | translate }}</label>
            <div class="input__wrapper">
              <input type="number" class="input" placeholder="" />
            </div>
            <br />
            <label for="" class="form-label"
              >{{ 'Services.Fulfilment.Multipoint.WhichDestinations' | translate }}</label
            >
            <div class="form-explain">
              {{ 'Services.Fulfilment.Multipoint.WhichDestinationsExplanation' | translate }}
            </div>
            <div class="textarea__wrapper">
              <textarea class="textarea" placeholder=""></textarea>
            </div>
          </div>
        </div>
        <!-- Eind MSP formulier -->
      </div>
      <div class="row" *ngIf="__fulfilmentRequest.complete ===true">
        <div class="col-6 offset-3 text-center">
          <svg-icon src="assets/images/icons/ok.svg" class="icon blue"></svg-icon>
          <div *ngIf="__fulfilmentRequest.serviceRequest ===FULFILMENT_SERVICE_REQUEST_PROBLEM">
            <h2>{{ 'Services.Fulfilment.ReportSuccessful' | translate }}</h2>
            <p
              translate-params="{product: __fulfilmentRequest.product, presentableName: __fulfilmentRequestObject.presentableName}"
              translate
            >
              Services.Fulfilment.MalfunctionReceived
            </p>
          </div>
          <div *ngIf="__fulfilmentRequest.serviceRequest ===FULFILMENT_SERVICE_REQUEST_MODIFY">
            <h2>{{ 'Services.Fulfilment.ReportSuccessful' | translate }}</h2>
            <p
              translate-params="{product: __fulfilmentRequest.product, presentableName: __fulfilmentRequestObject.presentableName}"
              translate
            >
              Services.Fulfilment.ChangeReceived
            </p>
          </div>
          <div *ngIf="__fulfilmentRequest.serviceRequest ===FULFILMENT_SERVICE_REQUEST_DELETE">
            <h2>{{ 'Services.Fulfilment.ReportSuccessful' | translate }}</h2>
            <p
              translate-params="{product: __fulfilmentRequest.product, presentableName: __fulfilmentRequestObject.presentableName}"
              translate
            >
              Services.Fulfilment.CancelReceived
            </p>
          </div>
          <div *ngIf="__fulfilmentRequest.serviceRequest ===FULFILMENT_SERVICE_REQUEST_CREATE">
            <h2>{{ 'Services.Fulfilment.RequestSuccessful' | translate }}</h2>
            <p>{{ 'Services.Fulfilment.requestReceived' | translate }}</p>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="__fulfilmentRequest.error ===true">
        <div class="col-6 offset-3 text-center">
          <svg-icon src="assets/images/icons/status-malfunction.svg" class="icon blue"></svg-icon>
          <div>
            <h2>{{ 'Services.Fulfilment.ReportFailed' | translate }}</h2>
            <p>...</p>
          </div>
        </div>
      </div>
    </div>
    <div class="dialog__footer dialog__footer--end">
      <div class="button__flex" *ngIf="__fulfilmentRequest.complete ===false">
        <span class="button button--secondary" (click)="closeDialog()">{{ 'Global.Action.Cancel' | translate }}</span>
        <span class="button" (click)="confirmFulfilmentRequest()">{{ 'Global.Action.Confirm' | translate }}</span>
      </div>
      <div class="button__flex" *ngIf="__fulfilmentRequest.complete ===true">
        <span class="button" (click)="closeDialog()">{{ 'Services.Action.Close' | translate }}</span>
      </div>
    </div>
  </div>
</ng-template>
