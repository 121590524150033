import { gql } from "apollo-angular";
import { ProductFragment, Sn8SapBlockInfoFragment, SubscriptionDetailsFragment } from "./fragments";

export const LightPathReduntantSubscriptionFragment = gql`
  ${SubscriptionDetailsFragment}
  ${ProductFragment}
  ${Sn8SapBlockInfoFragment}
  fragment LightPathRedundantSubscriptionDetails on SubscriptionInterface {
    ... on Sn8LightPathRedundantSubscription {
      ...SubscriptionDetails
      ...Product

      protectionType
      domain

      lrss {
        vcs {
          subscriptionInstanceId
          serviceSpeed
          name
          speedPolicer
          remotePortShutdown

          saps {
            ...Sn8SapBlockInfo
          }
        }
      }
    }
  }
`;

export const LightPathSubscriptionFragment = gql`
  ${SubscriptionDetailsFragment}
  ${ProductFragment}
  ${Sn8SapBlockInfoFragment}
  fragment LightPathSubscriptionDetails on SubscriptionInterface {
    ... on Sn8LightPathSubscription {
      ...SubscriptionDetails
      ...Product

      protectionType
      domain

      vc {
        subscriptionInstanceId
        serviceSpeed
        name
        speedPolicer
        remotePortShutdown

        saps {
          ...Sn8SapBlockInfo
        }
      }
    }
  }
`;
