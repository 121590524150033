import { TranslateService } from "@ngx-translate/core";

const constructPageTitle = (translate: TranslateService, pageURL: string): Promise<string> => {
  const productType = pageURL.substr(pageURL.lastIndexOf("/") + 1);

  return new Promise((resolve) => {
    translate.get(`Subscription.${productType}.Name`).subscribe((translation: string) => {
      resolve(translation);
    });
  });
};

export default constructPageTitle;
