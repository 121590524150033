import { Component, OnDestroy, OnInit } from "@angular/core";
import { Apollo, ApolloBase } from "apollo-angular";
import dayjs from "dayjs";
import { allServices } from "./services";
import { AuthService } from "../../services/AuthService";

import { ProductType } from "../../helpers/enums/productType";
import { MatDialog } from "@angular/material/dialog";
import { DialogOnboardingComponent } from "../../components/dialogs/dialog-onboarding/dialog-onboarding";
import { ApiHelper } from "../../helpers/apihelper";
import { UserSettings } from "../../components/models/UserSettings";
import { StorageHelper } from "../../helpers/storage/storagehelper";
import { TranslateService } from "@ngx-translate/core";
import { generateEmptyTile } from "./tile-generator";
import { AIRT_SCAN_COUNT_QUERY } from "../../graphql/domain/airtscan";
import { Category, Notification, SearchFilter, Severity } from "../../gql/generated";
import { ENV } from "../../app/app.runtime";
import { COUNT_QUERIES } from "../../graphql/replication/subscription-count";
import { Subscription as RxSubscription } from "rxjs";
import { CIM_QUERY } from "../../graphql/domain/cim";
import { CimQueryImpactTypes, CimQueryStatuses } from "../../graphql/custom-types";

@Component({
  selector: "page-dashboard",
  templateUrl: "dashboard.html",
})
export class DashboardPage implements OnDestroy, OnInit {
  public subscriptions = new Map();
  public activities: Notification[] = [];
  public notifications: Notification[] = [];
  public cimNotifications: Notification[] = [];
  public isLoading = false;
  public wirelessEnabled = ENV.WIRELESS_ENABLED;
  public productTypes: Array<ProductType> = [
    ProductType.IP,
    ProductType.LightPath,
    ProductType.L2VPN,
    ProductType.L3VPN,
    ProductType.Firewall,
    ProductType.Wireless,
    ProductType.Port,
    ProductType.IPPrefixes,
    ProductType.SURFcert,
  ];

  public apiConfig = {
    health: true,
    all: false,
    active_messages: ENV.FILTER_ACTIVE_MESSAGES,
  };

  public topProductTypes = [
    ProductType.IP,
    ProductType.L3VPN,
    ProductType.Port,
    ProductType.L2VPN,
    ProductType.IPPrefixes,
    ProductType.LightPath,
  ];

  public sideProductTypes = [ProductType.Firewall, ProductType.SURFcert];
  public bottomProductTypes = [ProductType.Wireless, ProductType.SURFdomains];

  private replicatedData: ApolloBase;
  private rxSubscriptions: RxSubscription[] = [];

  constructor(
    private auth: AuthService,
    public dialog: MatDialog,
    public api: ApiHelper,
    private apollo: Apollo,
    private translate: TranslateService,
  ) {
    this.replicatedData = this.apollo.use("replicatedData");
  }

  get customerActivities() {
    return this.activities.filter((note) => note.category === Category.Malfunction);
  }

  get customerNotifications() {
    return this.cimNotifications.filter((note) => note.category !== Category.Malfunction);
  }

  get topSubscriptions() {
    if (!this.subscriptions) {
      return new Map();
    }

    return new Map(Array.from(this.subscriptions).filter(([key, _value]) => this.topProductTypes.includes(key)));
  }

  get sideSubscriptions() {
    if (!this.subscriptions) {
      return new Map();
    }

    return new Map(Array.from(this.subscriptions).filter(([key, _value]) => this.sideProductTypes.includes(key)));
  }

  get bottomSubscriptions() {
    if (!this.subscriptions) {
      return new Map();
    }

    return new Map(Array.from(this.subscriptions).filter(([key, _value]) => this.bottomProductTypes.includes(key)));
  }

  ngOnInit() {
    // Populate subscription map with known productTypes
    this.subscriptions.set(ProductType.IP, {});
    this.subscriptions.set(ProductType.LightPath, {});
    this.subscriptions.set(ProductType.L2VPN, {});
    this.subscriptions.set(ProductType.L3VPN, {});
    this.subscriptions.set(ProductType.Firewall, {});
    this.subscriptions.set(ProductType.Wireless, {});
    this.subscriptions.set(ProductType.Port, {});
    this.subscriptions.set(ProductType.IPPrefixes, {});

    this.isLoading = true;

    // only load data when auth says the user has been loaded
    const subscription = this.auth.userLoaded.subscribe((d) => this.initDashboard());
    this.rxSubscriptions.push(subscription);
  }

  ngOnDestroy() {
    while (this.rxSubscriptions.length) {
      this.rxSubscriptions.pop().unsubscribe();
    }
  }

  initDashboard(): void {
    this.maybeShowOnboarding();
    this.shouldFetchSubscriptions()
      .then(() => this.fetchSubscriptions())
      .catch(() => this.emptyTiles());
  }

  /**
   * Show onboarding dialog if necessary.
   */
  maybeShowOnboarding() {
    this.api.settings().then((settings: UserSettings) => {
      if (!settings.onboarding) {
        this.showOnBoarding();
      }
    });
  }

  shouldFetchSubscriptions(): Promise<void> {
    // for readability
    const hasRoles = (this.auth.state.currentUser.roles || []).length > 0;
    const hasTeams = (this.auth.state.currentUser.teams || []).length > 0;
    return new Promise<void>((resolve, reject) => {
      if (hasRoles || hasTeams) {
        resolve();
      } else {
        reject();
      }
    });
  }

  emptyTiles(): void {
    this.initSubscriptionList();
    this.productTypes.forEach((p) => {
      this.subscriptions.set(p, generateEmptyTile(p));
    });
  }

  initSubscriptionList(): void {
    // Populate subscription map with known productTypes
    this.subscriptions.set(ProductType.IP, {});
    this.subscriptions.set(ProductType.LightPath, {});
    this.subscriptions.set(ProductType.L2VPN, {});
    this.subscriptions.set(ProductType.L3VPN, {});
    this.subscriptions.set(ProductType.Firewall, {});
    this.subscriptions.set(ProductType.Wireless, {});
    this.subscriptions.set(ProductType.Port, {});
    this.subscriptions.set(ProductType.IPPrefixes, {});
    this.subscriptions.set(ProductType.SURFcert, {});
    this.subscriptions.set(ProductType.SURFdomains, {});
  }

  maybeEnableSurfCert() {
    const prefixes = this.subscriptions.get(ProductType.IPPrefixes);
    this.loadSURFcertData();

    if (!prefixes.available) {
      this.subscriptions.set(ProductType.SURFcert, {
        available: false,
        loading: false,
        status: {
          "active-corero-filters": 0, // active corero filters
          "inactive-corero-filters": 2, // inactive corero filters
          "open-incidents": 0, // open incidents
        },
        productType: ProductType.SURFcert,
        key: "cert",
        url: "cert",
        subscriptions: 0,
        subscriptionsIds: [],
        messages: [],
        vulnerabilitiesDetectedCount: 0,
      });
    } else {
      this.subscriptions.set(ProductType.SURFcert, {
        available: true,
        productType: ProductType.SURFcert,
        url: allServices[ProductType.SURFcert].url,
        loading: false,
        vulnerabilitiesDetectedCount: 0,
      });
    }
  }

  maybeEnableSurfDomains() {
    if (
      ENV.DOMAINS_ENABLED &&
      (localStorage.getItem("currentuser") === "3902a8cf-776f-e611-80e0-005056956c1a" ||
        localStorage.getItem("currentuser") === "c9b5e717-0b11-e511-80d0-005056956c1a")
    ) {
      this.subscriptions.set(ProductType.SURFdomains, {
        available: false,
        loading: false,
        status: {
          "active-corero-filters": 0, // active corero filters
          "inactive-corero-filters": 2, // inactive corero filters
          "open-incidents": 0, // open incidents
        },
        productType: ProductType.SURFdomains,
        key: "domains",
        url: "domains",
        subscriptions: 0,
        subscriptionsIds: [],
        messages: [],
        vulnerabilitiesDetectedCount: 0,
      });
    }
  }

  loadSURFcertData() {
    const searchFilter: SearchFilter = {};
    searchFilter.severity = [Severity.Critical, Severity.High];

    const beforeSourceDate: Date = new Date();
    const afterSourceDate: Date = new Date();
    afterSourceDate.setDate(afterSourceDate.getDate() - 7);
    searchFilter.beforeSourceDate = beforeSourceDate;
    searchFilter.afterSourceDate = afterSourceDate;
    searchFilter.customerId = StorageHelper.currentUser;

    this.apollo
      .query<any>({
        query: AIRT_SCAN_COUNT_QUERY,
        variables: {
          filter: searchFilter,
          first: 99,
        },
      })
      .subscribe(({ data }) => {
        const vulnerabilitiesDetectedCount = data?.airtScans?.edges?.length;
        this.subscriptions.get(ProductType.SURFcert).vulnerabilitiesDetectedCount = vulnerabilitiesDetectedCount;
      });
  }

  asIsOrder(a: any, b: any) {
    return 0;
  }

  getTotalMessages() {
    return this.activities.length;
  }

  showOnBoarding() {
    const dialogRef = this.dialog.open(DialogOnboardingComponent);
    dialogRef.componentInstance.done.subscribe((done) => {
      this.api.updateSettings({ onboarding: true }).then((result) => {});
    });
  }

  private fetchCimNotifications = async () => {
    const endDate = dayjs().add(2, "week").toDate();
    const subscription = this.apollo
      .query<{ notifications: Array<Notification> }>({
        query: CIM_QUERY,
        fetchPolicy: "network-only",
        variables: {
          filter: {
            customerId: localStorage.getItem("viewingCustomerGUID"),
            beginTimestamp: dayjs().startOf("day").toDate(),
            endTimestamp: endDate,
            statuses: [CimQueryStatuses.OPEN, CimQueryStatuses.UPDATED],
            impacts: [
              CimQueryImpactTypes.DOWN,
              CimQueryImpactTypes.REDUCED_REDUNDANCY,
              CimQueryImpactTypes.RESILIENCE_LOSS,
            ],
          },
        },
      })
      .subscribe((data) => {
        // TODO #206 Address technical debt Notifications/Messages/PlannedWorks
        this.cimNotifications = data.data.notifications.slice().sort((a, b) => a.startTimestamp - b.startTimestamp);
      });
    this.rxSubscriptions.push(subscription);
  };

  private fetchSubscriptionCount = async (productType: ProductType) => {
    const noTrafficStatuses = ["notraffic", "unknown", "provisioning", "pending"];
    const formatCount = (count) => ({
      counts: {
        ok: count.ok,
        notraffic: noTrafficStatuses.reduce((prev, statusKey) => prev + Number(count[statusKey] ?? 0), 0),
        malfunction: count.malfunction,
      },
      subscriptions: count.all,
      firewallsEnabled: count.firewallsEnabled,
    });

    const query = COUNT_QUERIES[productType];
    this.replicatedData
      .query<any>({
        query,
        fetchPolicy: "network-only",
        variables: { customerId: localStorage.getItem("viewingCustomerGUID") },
      })
      .subscribe((data) => {
        // @ts-ignore
        const counts: any = { ...Object.values(data.data.counts).filter((item) => typeof item === "object")[0] };

        if (counts.all) {
          this.subscriptions.set(productType, {
            ...formatCount(counts),
            loading: false,
          });
        } else {
          this.subscriptions.set(productType, {
            productType,
            available: true,
            url: allServices[productType].url,
            loading: false,
          });
        }

        if (productType === ProductType.IPPrefixes) {
          // moved this call up here to ensure it is called after we know if prefixes are present.
          this.maybeEnableSurfCert();
        }
      });
  };

  private fetchSubscriptions = async () => {
    this.fetchCimNotifications();

    const hasSubscriptionCounts = (p: ProductType): boolean =>
      ![ProductType.SURFcert, ProductType.SURFdomains].includes(p);

    const productTypes = this.productTypes.filter((productType) => hasSubscriptionCounts(productType));
    productTypes.forEach((type) => type in COUNT_QUERIES && this.fetchSubscriptionCount(type));

    this.maybeEnableSurfDomains();
  };
}
