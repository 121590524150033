<navigation [activeItem]="" [requestsBubble]="3"></navigation>
<div class="wrapper">
  <div class="container">
    <div class="row">
      <div class="col-xs-12 offset-md-2 col-md-8 offset-lg-3 col-lg-6">
        <div class="card">
          <h2>{{ 'Page.About.Heading1' | translate }}</h2>
          <p><strong>{{ 'Page.About.Description1' | translate }}</strong></p>
          <p>
            <strong>{{ 'Page.About.Heading2' | translate }}</strong><br />
            {{ 'Page.About.Description2' | translate }}
          </p>
          <p>
            <strong>{{ 'Page.About.Heading3' | translate }}</strong><br />
            {{ 'Page.About.Description3' | translate }}
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 offset-md-2 col-md-8 offset-lg-3 col-lg-6">
        <a class="footer__link" href="https://www.surf.nl/over-surf/">{{ 'Global.MoreInfo' | translate }}</a>
      </div>
    </div>
  </div>
  <div class="container"><footer [activeItem]="2"></footer></div>
</div>
