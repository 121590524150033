import { CustomerDescription, SubscriptionInterface } from "../../gql/generated";

export const sortBySubscriptionInstanceId = (a: any, b: any): number =>
  a.subscriptionInstanceId < b.subscriptionInstanceId ? -1 : 1;

export const sortByProductTag = (a: any, b: any): number => 1;

export const transformProduct = (product: any) => ({
  ...product,
  productType: product?.type,
});

export const isTerminated = (subscription: SubscriptionInterface): boolean => subscription.status === "terminated";

export const isProvisioning = (subscription: SubscriptionInterface): boolean => subscription.status === "provisioning";

export const transformCustomerDescriptions = (cd: CustomerDescription[]) => cd;

export const toList = (listOrScalar: any): any[] => (Array.isArray(listOrScalar) ? listOrScalar : [listOrScalar]);

export const stringOrNA = (thing: any) => thing || "N/A";

export const IrbPortSpeed = 100000; // 100G, fixed value.

export const transformReferences = (references: { name: string; external_id: string }[] | null): string[] => {
  const rf = references || [];
  return rf.map((r) => `${r.name}: ${r.external_id}`);
};

export interface BaseSubscriptionTransformed {
  subscriptionId: string;
  name: string;
  customerId: string;
  status: string;
  insync: boolean;
  tags: Array<string>;
  description: string;
  startDate: string;
  endDate: string;
  firewallEnabled: boolean;
  portSubscriptionInstanceId?: string;
  vlanrange?: string;
  metadata: any;
  organisation?: string;
  md_references?: any;
  product?: ProductTransformed;
}

export const gqlToSubscription = (subscription: any, tags: string[]): BaseSubscriptionTransformed => {
  const rv: BaseSubscriptionTransformed = {
    subscriptionId: subscription.subscriptionId,
    name: subscription.product.name,
    customerId: subscription.customerId,
    status: subscription.status,
    insync: subscription.insync,
    tags,
    description: subscription.description,
    startDate: subscription.startDate,
    endDate: subscription.endDate,
    firewallEnabled: false,
    portSubscriptionInstanceId: subscription.portSubscriptionInstanceId || null,
    vlanrange: subscription.vlanRange || null,
    metadata: subscription.metadata,
  };

  if (subscription.metadata) {
    rv["md_references"] = transformReferences(subscription.metadata.references);
  }
  if (subscription.customer?.name) {
    rv["organisation"] = subscription.customer.name;
  }
  if (subscription.product) {
    rv["product"] = transformProduct(subscription.product);
  }
  return rv;
};

const visitAddressTypes = ["visiting", "Bezoekadres"];
export const toVisitingAddress = (organisation: any) => {
  const skipPlaceholder = (address: any, key: string) =>
    address && address[key] !== "PLACEHOLDER" ? address[key] : null;
  const addr = organisation?.addresses?.find((address: any) => visitAddressTypes.includes(address.type)) || {};
  return {
    street: addr.street || "",
    number: addr.number || "",
    zipcode: addr.zipcode || "",
    city: addr.city || "",
    addition: addr.addition || "",
    countryCode: skipPlaceholder(addr, "countryCode"),
    extraAddressLine: skipPlaceholder(addr, "extraAddressLine"),
  };
};

export const findAddress = (obj: any) => {
  // l2vpn
  if (Array.isArray(obj.vc?.esis) && obj.vc.esis.length > 0) {
    const esi = obj.vc.esis[0];
    if (Array.isArray(esi.saps) && esi.saps.length > 0) {
      const sap = esi.saps[0];
      if (Array.isArray(sap.port?.locations) && sap.port.locations.length > 0) {
        return toVisitingAddress(sap.port.locations[0]);
      }
    }
  }

  // l3vpn
  if (Array.isArray(obj.vc?.saps) && obj.vc.saps.length > 0) {
    const sap = obj.vc.saps[0];
    if (Array.isArray(sap.sap?.port?.locations) && sap.sap.port.locations.length > 0) {
      return toVisitingAddress(sap.sap.port.locations[0]);
    }
  }
};

export const protectionType = (productType: string) => {
  switch (productType) {
    case "LP":
    case "L2VPN":
    case "L3VPN":
      return "Protected";
      break;
    case "LR":
      return "Redundant";
      break;
  }
};

export interface ProductTransformed {
  productId: string;
  tag: string;
  productType: string;
  createdAt: string;
  name: string;
  description: string;
}

export const toProduct = (product: any) => ({
  productId: product.productId,
  tag: product.tag,
  productType: product.type || product.productType,
  createdAt: gqlToDate(product.createdAt),
  name: product.name,
  description: product.description,
});

export const gqlToDate = (date: any) => date;
