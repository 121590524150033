import { gql } from "apollo-angular";
import { ProductFragment, SubscriptionDetailsFragment } from "./fragments";

export const SurfWirelessSubscriptionDetailsFragment = gql`
  ${SubscriptionDetailsFragment}
  ${ProductFragment}
  fragment SurfWirelessSubscriptionDetails on SubscriptionInterface {
    ... on SurfWirelessSubscription {
      ...SubscriptionDetails
      ...Product

      wifiLocation {
        apVendorName
        sensorPresent
        detail {
          address
          institute
          #  TODO: commented out since unused (probably just mapping of the old REST calls to graphql)
          #  location
          name
          city
          country
          #  TODO: commented out since unused (probably just mapping of the old REST calls to graphql)
          #  stats {
          #    active
          #    inactive
          #  }
        }
      }
    }
  }
`;
