<div class="webarchive-calendar">
  <div *ngFor="let month of months">
    <input
      type="text"
      mwlFlatpickr
      [convertModelValue]="true"
      [disableMobile]="true"
      [value]="year + '-' + month"
      [monthSelectorType]="'static'"
      [showMonths]="1"
      [inline]="true"
      (flatpickrDayCreate)="onDayCreate($event)"
      (flatpickrChange)="onDayChange($event)"
    />
  </div>
</div>
