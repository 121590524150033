import { Component } from "@angular/core";
import { AuthService } from "../../../../services/AuthService";
import { ApiHelper } from "../../../../helpers/apihelper";
import { FirewallSubscriptionDetailsGQL, FirewallSubscriptionDetailsQuery } from "../../../../gql/generated-ingestor";
import { ActivatedRoute } from "@angular/router";
import { GenericSubscriptionComponent } from "../generic-subscription";
import { FirewallSubscriptionDetails } from "../../types";
import { ApolloQueryResult } from "@apollo/client";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "firewall-subscription",
  templateUrl: "firewall-subscription.html",
})
export class FirewallSubscriptionComponent extends GenericSubscriptionComponent<
  FirewallSubscriptionDetailsGQL,
  FirewallSubscriptionDetailsQuery,
  FirewallSubscriptionDetails
> {
  public impactSetting = "Never";
  public serviceSpeed = 2; // TODO Replace with subscription.size? subscription.serviceSpeed?

  constructor(
    protected api: ApiHelper,
    protected auth: AuthService,
    protected dialog: MatDialog,
    protected route: ActivatedRoute,
    protected query: FirewallSubscriptionDetailsGQL,
    protected translate: TranslateService,
  ) {
    super(api, auth, dialog, route, translate);
  }

  protected onQuerySuccess(result: ApolloQueryResult<FirewallSubscriptionDetailsQuery>): FirewallSubscriptionDetails {
    return result.data.firewallDetails as FirewallSubscriptionDetails;
  }
}
