<div class="dialog dialog-send-config">
  <div class="dialog__header">
    <h3 class="dialog__title">{{ 'ShortcutMenu.SendConfig' | translate }}</h3>
    <svg-icon src="assets/images/icons/x.svg" class="dialog__close" (click)="closeDialog($event)"></svg-icon>
  </div>

  <div class="dialog__grow-wrapper">
    <div
      *ngIf="currentStep === 1"
      class="dialog__body dialog__body--scroll dialog__body--flex-justify dialog__body--no-padding dialog__body--grow dialog__body--grey"
    >
      <div class="dialog__inner">
        <h3 class="dialog__sub-heading">{{ 'SendConfig.Title' | translate }}</h3>
        <p class="no-margin" style="margin: 0">{{ 'SendConfig.Text' | translate }}</p>
      </div>

      <div class="dialog-send-config__rows">
        <div class="dialog-send-config__row">
          <div *ngFor="let person of persons; index as i" class="dialog-send-config__row-item">
            <div class="row">
              <div class="col-6">
                <label for="" class="form-label">{{ 'Global.FullName' | translate }}</label>
                <div class="input__wrapper">
                  <input type="text" class="input" placeholder="" [(ngModel)]="person.name" required />
                </div>
              </div>
              <div class="col-6">
                <label for="" class="form-label">{{ 'Global.Email' | translate }}</label>
                <div class="input__wrapper">
                  <input
                    type="email"
                    class="input"
                    placeholder=""
                    [(ngModel)]="person.email"
                    email
                    required
                    (input)="verify(i, $event)"
                    (paste)="paste(i, $event)"
                  />
                </div>
              </div>
            </div>
            <svg-icon
              src="assets/images/icons/x.svg"
              class="dialog-send-config__remove"
              (click)="removeRow(i)"
            ></svg-icon>
          </div>
        </div>
        <div class="dialog-send-config__add" (click)="addRow()">
          <svg-icon src="assets/images/icons/+.svg"></svg-icon>
        </div>
      </div>
    </div>
    <div *ngIf="currentStep === 2" class="dialog__body dialog__body--grow dialog__body--grey dialog__body--center">
      <div class="selfservice-loader">
        <img src="assets/images/success-illustration.svg" alt="" />
        <h3 class="selfservice-loader__title">{{ 'SendConfig.SuccessTitle' | translate }}</h3>
      </div>
    </div>
  </div>

  <div class="dialog__footer dialog__footer--end">
    <div *ngIf="currentStep === 1" class="button__flex">
      <span class="button button--secondary" (click)="closeDialog($event)"
        >{{ 'Global.Action.Cancel' | translate }}</span
      >
      <span class="button" [ngClass]="{'button--disabled': !allValid, 'loading': buttonLoading }" (click)="submit()">
        <svg-icon src="assets/images/icons/loader.svg" class="button__loader"></svg-icon>
        {{ 'Global.Action.Send' | translate }}</span
      >
    </div>
    <div *ngIf="currentStep === 2" class="button__flex">
      <span class="button button--secondary" (click)="closeDialog($event)"
        >{{ 'Services.Action.Close' | translate }}</span
      >
    </div>
  </div>
</div>
