import { gql } from "apollo-angular";

export const ImsLocationFragment = gql`
  fragment ImsLocation on ImsPort {
    location {
      uuid
      name
      code
      addresses {
        city
        zipcode
        type
        street
        addition
        countryCode
        extraAddressLine
        number
      }
    }
  }
`;

export const ImsPortLocationFragment = gql`
  ${ImsLocationFragment}
  fragment ImsPortLocation on ImsService {
    speed
    aliases
    location
    port {
      patchposition
      fiberType
      connectorType
      ifaceType
      locationCode
      ...ImsLocation
    }
  }
`;

export const ImsServicePortLocationFragment = gql`
  ${ImsLocationFragment}
  fragment ImsServicePortLocation on ImsService {
    endpoints(type: SERVICE, limit: 1) {
      ... on ImsService {
        speed
        aliases
        location
        endpoints(type: PORT, limit: 1) {
          ... on ImsPort {
            patchposition
            fiberType
            connectorType
            ifaceType
            locationCode
            ...ImsLocation
          }
        }
      }
    }
  }
`;
