/**
 * Created by gert on 26/06/2018.
 */

import { Injectable } from "@angular/core";

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { SurfExceptionHandler } from "../surfexceptionhandler";
import { AuthService } from "../../services/AuthService";
import { tap } from "rxjs/operators";

@Injectable()
export class NWDHttpInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Only add Authorization header here if not already present
    if (!request.headers.has("Authorization")) {
      const token = localStorage.getItem("access_token");
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // do stuff with response if you want
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (request.headers.get("x-ignore-interceptor") === "1") {
              return;
            }

            const errorState = err.status === 0 || err.status >= 500;
            if (errorState) {
              SurfExceptionHandler.redirectToErrorPage(err);
            } else if (err.status === 401) {
              localStorage.removeItem("access_token");
              this.auth.load();
            } else if (err.status === 403) {
              // forbidden
              SurfExceptionHandler.redirectToForbiddenPage(err);
            } else if (
              (err.status === 404 || err.status === 422) &&
              request.url.indexOf("/subscriptions/") &&
              request.url.indexOf("/counts/") === -1 &&
              request.url.indexOf("/stats/") === -1 &&
              request.url.indexOf("/health/") === -1 &&
              request.url.indexOf("/fulfilment/") === -1 &&
              request.url.indexOf("/user/") === -1 &&
              request.url.indexOf("/customer/") === -1
            ) {
              // Disabled for developent
              SurfExceptionHandler.redirectToNotfoundPage(err);
            }
          }
        },
      ),
    );
  }
}
