import { humanizeMbit } from "../bitspipe/bitspipe";
import { getServiceSpeed } from "./ip";
import { toEndpoint } from "./l3vpn";
import { one } from "./misc";
import { gqlToSubscription } from "./shared";
import { translate } from "./tags";

export interface L2VPNEndpoint {
  subscriptionInstanceId: string;
  organisation?: string;
  name: string;
  vlanrange: string;
  tag: string;
  port?: any;
}

export interface L2VPNESI {
  segmentId: number;
  firewallInterconnect?: any;
  endpoints?: L2VPNEndpoint[];
}

const toEndpoints = (saps: any[]) => saps.map((s) => toEndpoint(s));

const transformEndpoints = (esis: any[]) =>
  esis.map((esi) => toEndpoints(esi.saps)).reduce((acc, val) => acc.concat(val), []);

const transformCircuits = (circuit: any) => {
  const toCircuit = ({ subscriptionInstanceId, name, bumFilter, serviceSpeed, vlanRetagging, speedPolicer, esis }) => ({
    subscriptionInstanceId,
    name,
    tag: "VC",
    serviceId: "0",
    bumFilter,
    serviceSpeed,
    vlanRetagging,
    speedPolicer,
    endpoints: transformEndpoints(esis),
  });

  return [toCircuit(circuit)];
};

const toFirewallEsis = (esis: any[], firewalls: any[]) => {
  const toFirewallEsi = ({ saps, subscriptionInstanceId }) => {
    const firewall = one(firewalls);
    const firewallPb = firewall.firewall;

    return {
      segmentId: 99,
      firewallInterconnect: {
        subscriptionInstanceId,
        firewallSubscriptionId: firewall.subscriptionId,
        availabilityZone: firewallPb.availabilityZoneName,
        endpoints: toEndpoints(saps),
      },
      endpoints: null,
    };
  };
  return [toFirewallEsi(esis[0])];
};

const toRegularEsis = (esis: any[]) => {
  const toRegularEsi = ({ segment_id, saps }) => ({
    segmentId: segment_id,
    firewallInterconnect: null,
    endpoints: toEndpoints(saps),
  });

  return esis.map((esi) => toRegularEsi(esi));
};

const transformEsis = (esis: any[], firewalls: any[]) => {
  const isFirewallEsi = (esi: any) => esi.inUseByIds.length > 0;
  const regularEsis = esis.filter((esi) => !isFirewallEsi(esi));
  const firewallEsis = esis.filter((esi) => isFirewallEsi(esi));

  const rv = [];
  if (firewalls && firewallEsis.length) {
    rv.push(...toFirewallEsis(firewallEsis, firewalls));
  }
  if (regularEsis.length) {
    rv.push(...toRegularEsis(regularEsis));
  }
  return rv;
};

export const transform = (subscription: any) => {
  if (!subscription) {
    return {};
  }
  const circuits = transformCircuits(subscription.vc);
  const serviceSpeed = getServiceSpeed(circuits);
  const firewallEnabled = subscription.dependsOn?.length > 0;
  const esis: Array<L2VPNESI> = transformEsis(subscription.vc.esis, subscription.dependsOn);

  function* getTags() {
    yield subscription.product?.tag;
    yield "Protected";
    yield humanizeMbit(Number(serviceSpeed) * 1000 * 1000);
    yield subscription.domain;
    if (firewallEnabled) {
      yield "Firewall";
    }
  }
  const tags = translate("L2VPN", [...getTags()]);
  const transformedSubscription = gqlToSubscription(subscription, tags);
  return {
    ...transformedSubscription,
    domain: subscription.domain,
    serviceSpeed,
    protection: "Protected",
    firewallEnabled,
    tags,
    _circuits: circuits,
    circuits,
    esis,
  };
};
