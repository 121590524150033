import { gql } from "apollo-angular";
import { OtherSubscriptionsFragment, ProductFragment, SubscriptionDetailsFragment } from "./fragments";
import { ImsPortLocationFragment } from "./imsInfo";

export const FirewallSubscriptionDetailsFragment = gql`
  ${SubscriptionDetailsFragment}
  ${ProductFragment}
  fragment FirewallSubscriptionDetails on SubscriptionInterface {
    ... on FwSubscription {
      ...SubscriptionDetails
      ...Product

      domain
      size
    }
  }
`;

export const FirewallIpGwFragment = gql`
  ${ImsPortLocationFragment}
  fragment FirewallIpGwDetails on FwBlock {
    ipGwEndpoint {
      subscriptionInstanceId

      ip {
        settings {
          surfcertFilter
          surfcertFilterEnabled
          multicast
          pinPrefix {
            ownerSubscriptionId
          }
        }

        saps {
          prefixes {
            ownerSubscriptionId
          }
          customerIpv4Mtu
          customerIpv6Mtu

          sap {
            port {
              ... on Sn8IrbServicePortSubscription {
                subscriptionId
                port {
                  ims {
                    ...ImsPortLocation
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const FirewallFragment = gql`
  ${OtherSubscriptionsFragment}
  ${FirewallIpGwFragment}
  fragment FirewallDetails on FwSubscription {
    size
    firewall {
      asn
      availabilityZoneName
      customerAsn
      nfvServiceId

      ...FirewallIpGwDetails

      l2Endpoints {
        subscriptionInstanceId

        customerPtpIpv4PrimaryIpam {
          prefix
        }
        customerPtpIpv4SecondaryIpam {
          prefix
        }
        customerPtpIpv6PrimaryIpam {
          prefix
        }
        customerPtpIpv6SecondaryIpam {
          prefix
        }

        esis {
          ...OtherSubscriptions
        }
      }

      l3Endpoints {
        subscriptionInstanceId

        saps {
          ...OtherSubscriptions
        }
      }
    }
  }
`;
