import { IpPrefixBlock, IpPrefixSubscription, SubscriptionInterface } from "../../gql/generated";
import { isTerminated, toList, transformCustomerDescriptions, transformProduct } from "./shared";
import { translate } from "./tags";

const transformIpPrefix = (prefix: IpPrefixBlock) => {
  const tags = prefix.ipam ? prefix.ipam.tags : [];
  return !prefix.ipam ?
      {}
    : {
        ...prefix.ipam,
        ipamPrefixId: prefix.ipamPrefixId,
        subscriptionInstanceId: prefix.subscriptionInstanceId,
        name: prefix.name,
        ipamPrefixTags: toList(tags),
        tag: "IP_PREFIX",
      };
};

const transformIpSubscriptions = (parents: SubscriptionInterface[]) => {
  const toIpSubscription = (sub: SubscriptionInterface) => ({
    subscriptionId: sub.subscriptionId,
    description: sub.description,
    customerId: sub.customerId,
    customerName: sub.customer?.name,
    customerDescriptions: transformCustomerDescriptions(sub.customerDescriptions),
  });

  return parents.map((parent) => toIpSubscription(parent));
};

export const transform = (subscription: IpPrefixSubscription) => {
  const product = subscription.product;
  const organisation = subscription.customer;
  const productTag = product.tag;
  const tags = translate(product.type, [productTag]);
  let ipPrefix = null;
  let ipSubscriptions = [];
  if (!isTerminated(subscription)) {
    ipPrefix = transformIpPrefix(subscription.ipPrefix);
    ipSubscriptions = transformIpSubscriptions(subscription.inUseBy);
  }

  return {
    ...subscription,
    ipSubscriptions,
    ip_prefix: ipPrefix,
    organisation: organisation.name,
    customerDescriptions: transformCustomerDescriptions(subscription.customerDescriptions),
    product: transformProduct(product),
    tags,
    autoMitigation: false,
  };
};
