import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AuthService } from "../../../services/AuthService";
import { TranslateService } from "@ngx-translate/core";
import { ApiHelper } from "../../../helpers/apihelper";

@Component({
  selector: "dialog-roles",
  templateUrl: "dialog-roles.html",
  styleUrls: ["dialog-roles.scss"],
})
export class DialogRolesComponent {
  public roles = [
    {
      key: "Infrabeheerder",
      title: this.translate.instant("Role.Infrabeheerder.Title"),
      description: this.translate.instant("Role.Infrabeheerder.Description"),
    },
    {
      key: "Infraverantwoordelijke",
      title: this.translate.instant("Role.Infraverantwoordelijke.Title"),
      description: this.translate.instant("Role.Infraverantwoordelijke.Description"),
    },
    {
      key: "Beveiligingsverantwoordelijke",
      title: this.translate.instant("Role.Beveiligingsverantwoordelijke.Title"),
      description: this.translate.instant("Role.Beveiligingsverantwoordelijke.Description"),
    },
    {
      key: "SURFwireless-beheerder",
      title: this.translate.instant("Role.SURFwireless-beheerder.Title"),
      description: this.translate.instant("Role.SURFwireless-beheerder.Description"),
    },
    {
      key: "Domeinnaamverantwoordelijke",
      title: this.translate.instant("Role.Domeinnamenverantwoordelijke.Title"),
      description: this.translate.instant("Role.Domeinnamenverantwoordelijke.Description"),
    },
    {
      key: "DNS-beheerder",
      title: this.translate.instant("Role.DNSbeheerder.Title"),
      description: this.translate.instant("Role.DNSbeheerder.Description"),
    },
  ];
  public currentStep = 1;
  public requestedRoles = [];
  public generatedMail = "";
  private collectedContactPersons: any[] = [];
  private roleFilter: string[] = [];

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogRolesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public auth: AuthService,
    private api: ApiHelper,
    private translate: TranslateService,
  ) {
    if (this.data) {
      this.requestedRoles = structuredClone(this.data.roles);

      if (this.data.need?.length > 0) {
        this.roleFilter = this.data.need;
      }

      this.api
        .getContactPersons(localStorage.getItem("viewingCustomerGUID"))
        .then((response) => {
          this.collectedContactPersons = response;
          this.generateMail();
        })
        .catch((err) => {
          console.error(err);
          this.currentStep = 0;
        });
    }
  }

  get requestedRolesString(): string {
    return this.requestedRoles
      .map((role) => {
        if (!this.auth.hasRole([role])) {
          return `*${role}`;
        }
        return role;
      })
      .sort((a, b) => (a.startsWith("*") ? -1 : 1)) // new roles first.
      .join("%0D");
  }

  get filteredRoles() {
    return this.roleFilter.length > 0 ?
        this.roles.filter((role) => this.roleFilter.includes(role.key) || this.data.roles.includes(role.key))
      : this.roles;
  }

  hasRole(role: string): boolean {
    return (this.data?.roles ?? []).includes(role);
  }

  isRequested(role: string): boolean {
    return (this.requestedRoles ?? []).includes(role);
  }

  toggleRole(role: string): void {
    if (this.requestedRoles?.includes(role)) {
      this.requestedRoles = this.requestedRoles.filter((item) => item !== role);
      this.generateMail();
    } else {
      this.requestedRoles.push(role);
      this.generateMail();
    }
  }

  generateMail() {
    const collectedEmails = this.collectedContactPersons.map((contact) => contact.email);
    const collectedNames = this.collectedContactPersons.map((contact) => `${contact.first_name} ${contact.last_name}`);
    this.generatedMail = `mailto:${collectedEmails.join(
      ",",
    )}?&subject=Verzoek voor wijziging SAB rollen voor SURF Netwerk Dashboard&body=Beste ${collectedNames.join(
      ", ",
    )}, ${this.translate.instant("Roles.Request.ContentEmail1")} ${this.auth.state.currentUser.displayName}, ${
      this.auth.state.currentUser.email
    } ${this.translate.instant("Roles.Request.ContentEmail2")} ${this.requestedRolesString} ${this.translate.instant(
      "Roles.Request.ContentEmail3",
    )} ${this.auth.state.currentUser.displayName}, ${this.auth.state.currentUser.email} ${this.translate.instant(
      "Roles.Request.ContentEmail4",
    )} ${this.requestedRolesString} ${this.translate.instant("Roles.Request.ContentEmail5")}`;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
