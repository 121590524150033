import {
  CustomerDescription,
  Sn8IpBgpSubscription as IpBgpSubscription,
  Sn8IpStaticSubscription as IpStaticSubscription,
  Sn8IpBgpVirtualCircuitBlock,
  Sn8IpStaticVirtualCircuitBlock,
} from "../../gql/generated";
import { getServiceSpeed, getTags, transformIPSS, transformSaps } from "../../helpers/subscription/ip";
import { Instance } from "./instance";

export class InternetInstance extends Instance {
  customerDescriptions: CustomerDescription[];
  customerId: string;
  description: string;
  domain: string;
  name: string;
  status: string;
  subscriptionId: string;
  organisation: string;
  _saps: any[];
  _ownPoints: any[];
  _circuits: any[];
  _pairs: any[];
  _otherPortsCount: 0;

  constructor(data?: IpBgpSubscription | IpStaticSubscription) {
    super();

    if (data) {
      // let the transformations begin!
      this.status = data.status;
      this.domain = data.domain;
      this.description = data.description;
      this.name = data.name;
      this.subscriptionId = data.subscriptionId;
      this.insync = data.insync;
      this.customerDescriptions = data.customerDescriptions;
      this.customerId = data.customerId;
      this.circuits = Array.isArray(data.vc) ? data.vc : [data.vc];
      this.organisation = data.customer.name;
      this.product = data.product;
      this.startDate = data.startDate;
      this.endDate = data.endDate;
      this.ipRoutingType = data.ipRoutingType;
      this.serviceSpeed = data.vc.serviceSpeed;
      if (data.vc?.settings) {
        this.ipss = transformIPSS(data.vc.settings);
      }
      this.tags = getTags(data);
    }
  }
  get sapPairs(): any[] {
    return this._pairs;
  }

  get ownPoints(): any[] {
    return this._ownPoints;
  }
  get circuits() {
    return this._circuits;
  }

  set circuits(c: any[]) {
    this._circuits = c;
  }

  get saps(): any {
    return this._saps;
  }
  set saps(ports: any) {
    this._saps = [];
    this._pairs = [];
    this._otherPortsCount = 0;

    for (const port of ports) {
      if (port.port.customerId === this.customerId) {
        this._saps.unshift(port);
      } else {
        this._saps.push(port);
        this._otherPortsCount++;
      }
    }

    for (const sap of this._saps) {
      const isOwnPort = sap.port.customerId === this.customerId;

      if (isOwnPort && this._otherPortsCount === 0) {
        // if the lightpath only has ports of the current customer,
        // alternate them left and right.
        // the default preference is to show own ports on the left side.
        let isAdded = false;
        for (const p in this._pairs) {
          if (this._pairs[p].length === 1) {
            this._pairs[p][1] = sap;
            isAdded = true;
            break;
          }
        }
        if (!isAdded) {
          this._pairs.push([sap]);
        }
      } else {
        if (isOwnPort) {
          this._pairs.push([sap]);
        } else {
          let added = false;
          for (const p in this._pairs) {
            if (this._pairs[p].length === 1) {
              this._pairs[p][1] = sap;
              added = true;
              break;
            }
          }
          if (!added) {
            this._pairs.push([, sap]);
          }
        }
      }
    }
  }

  set gqlSaps(vc: Sn8IpBgpVirtualCircuitBlock | Sn8IpStaticVirtualCircuitBlock) {
    const isStatic = (v: any): v is Sn8IpStaticVirtualCircuitBlock => v.sap !== undefined;
    let _saps = [];
    if (isStatic(vc)) {
      _saps = [vc.sap];
    } else {
      _saps = vc.saps;
    }
    this.saps = transformSaps(_saps);
  }
}
