import { gql } from "apollo-angular";
import { AirtDetailsFragment } from "../fragments/airt";
import { PaginationFragment } from "../fragments/pagination";

export const AIRT_SCAN_QUERY = gql`
  ${AirtDetailsFragment}
  ${PaginationFragment}
  query GetAirtScans($filter: SearchFilter = {}, $first: Int = 20, $after: Int = 0, $sort: AirtSort) {
    airtScans(searchFilter: $filter, first: $first, after: $after, sort: $sort) {
      edges {
        node {
          ...AirtScanDetails
        }
      }
      pageInfo {
        ...PaginationInfo
      }
    }
  }
`;

export const AIRT_SCAN_CLASSIFICATION_PROTOCOL_QUERY = gql`
  query GetAirtScansClassificationProtocol($filter: SearchFilter = {}) {
    airtScans(searchFilter: $filter, first: 999999) {
      edges {
        node {
          classification
          protocol
          severity
        }
      }
    }
  }
`;

export const AIRT_SCAN_COUNT_QUERY = gql`
  ${PaginationFragment}
  query GetAirtScansCount($filter: SearchFilter = {}, $first: Int = 20, $after: Int = 0) {
    airtScans(searchFilter: $filter, first: $first, after: $after) {
      edges {
        node {
          id
        }
      }
      pageInfo {
        ...PaginationInfo
      }
    }
  }
`;
