import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { SelfServiceCommand } from "../../../helpers/self-service/self-service-command";
import { ApiHelper } from "../../../helpers/apihelper";
import { SelfServiceCommandKeys } from "../../../helpers/enums/selfServiceCommands";
import { ModifyErrorDetail, ModifyResponse } from "../../../helpers/self-service/models/types";
import { ModifyLightPath, ModifyRedundantLightPath } from "../../../helpers/self-service/modify_lightpath";
import { transformError } from "../../../helpers/self-service/transform-error";

@Component({
  selector: "selfservice-rps",
  templateUrl: "selfservice-rps.html",
})
export class SelfserviceRpsComponent implements OnChanges {
  @Output() dialogCloseEmitter = new EventEmitter<string>();
  @Input() subscription;
  @Input() bus: EventEmitter<any>;
  public filterStatus: boolean;
  public processId: string;
  public hasErrors = false;
  public errors: ModifyErrorDetail[] = [];
  public buttonLoading = false;

  constructor(public api: ApiHelper) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["subscription"] && this.subscription) {
      this.filterStatus = this.subscription._circuits[0].remotePortShutdown === true;
    }
  }

  emitCloseDialog() {
    this.dialogCloseEmitter.emit("close");
  }

  submit() {
    this.buttonLoading = true;
    const modify = this.subscription.product.tag === "LP" ? new ModifyLightPath() : new ModifyRedundantLightPath();
    const action =
      this.subscription.product.tag === "LP" ?
        SelfServiceCommandKeys.ModifyLightpath
      : SelfServiceCommandKeys.ModifyRedundantLightpath;
    const command = new SelfServiceCommand(this.subscription.subscriptionId, action);

    modify.subscription = this.subscription;
    modify.remotePortShutdown = !this.filterStatus;
    command.payload = modify;
    console.dir(command);

    this.api
      .selfServiceCommand(command)
      .then((response) => {
        this.processId = (response as ModifyResponse).pid;
        this.bus.emit({ processId: this.processId, action });
      })
      .catch((err) => {
        this.buttonLoading = false;
        console.log(err);
        this.errors = transformError(err.error.detail);
      });
  }
}
