/**
 * Created by gert on 02/03/2018.
 */
import { Directive, ViewContainerRef } from "@angular/core";

@Directive({
  selector: "[editLightpathOptions]",
})
export class EditLightpathOptionsDirective {
  constructor(public viewContainerRef: ViewContainerRef) {}
}
