import { PipeTransform, Pipe } from "@angular/core";

@Pipe({ name: "customer_minimal_impact", pure: true })
export class MinimalImpactNotificationPipe implements PipeTransform {
  transform(subscription: MinimalImpactNotificationInput, fallback: string = "-"): string {
    const customerId = localStorage.getItem("viewingCustomerGUID");
    const comparator = (cd: MinimalImpactNotification) => cd.customerId === customerId;
    const impact: MinimalImpactNotification | undefined = subscription?.minimalImpactNotifications.find(comparator);
    return impact?.impact ?? fallback;
  }
}

interface MinimalImpactNotificationInput {
  minimalImpactNotifications: MinimalImpactNotification[];
}

export interface MinimalImpactNotification {
  customerId: string;
  impact: string;
}
