import { gql } from "apollo-angular";
import { ProductType } from "../../helpers/enums/productType";

export const fragmentSubscriptionCount = `
  all
  inactive
  malfunction
  notfound
  notraffic
  ok
  pending
  unknown
`;

export const SUBSCRIPTION_COUNT_PORT = gql`
query PortSubscriptionCounts($customerId: UUID!) {
  counts {
    port(customerId: $customerId) {
      ${fragmentSubscriptionCount}
    }
  }
}
`;

export const SUBSCRIPTION_COUNT_L3VPN = gql`
query L3vpnSubscriptionCounts($customerId: UUID!) {
  counts {
    l3vpn(customerId: $customerId) {
      ${fragmentSubscriptionCount}
      firewallsEnabled
    }
  }
}
`;

export const SUBSCRIPTION_COUNT_L2VPN = gql`
query L2vpnSubscriptionCounts($customerId: UUID!) {
  counts {
    l2vpn(customerId: $customerId) {
      ${fragmentSubscriptionCount}
      firewallsEnabled
    }
  }
}
`;

export const SUBSCRIPTION_COUNT_LP = gql`
query LightPathSubscriptionCounts($customerId: UUID!) {
  counts {
    lp(customerId: $customerId) {
      ${fragmentSubscriptionCount}
    }
  }
}
`;

export const SUBSCRIPTION_COUNT_IP = gql`
query IpSubscriptionCounts($customerId: UUID!) {
  counts {
    ip(customerId: $customerId) {
      ${fragmentSubscriptionCount}
    }
  }
}
`;

export const SUBSCRIPTION_COUNT_FW = gql`
query FirewallSubscriptionCounts($customerId: UUID!) {
  counts {
    fw(customerId: $customerId) {
      ${fragmentSubscriptionCount}
    }
  }
}
`;

export const SUBSCRIPTION_COUNT_IPP = gql`
query IpPrefixSubscriptionCounts($customerId: UUID!) {
  counts {
    ipPrefix(customerId: $customerId) {
      ${fragmentSubscriptionCount}
    }
  }
}
`;

export const SUBSCRIPTION_COUNT_WIRELESS = gql`
query WirelessSubscriptionCounts($customerId: UUID!) {
  counts {
    wireless(customerId: $customerId) {
      ${fragmentSubscriptionCount}
    }
  }
}
`;

export const COUNT_QUERIES = {
  [ProductType.Port]: SUBSCRIPTION_COUNT_PORT,
  [ProductType.L3VPN]: SUBSCRIPTION_COUNT_L3VPN,
  [ProductType.L2VPN]: SUBSCRIPTION_COUNT_L2VPN,
  [ProductType.LightPath]: SUBSCRIPTION_COUNT_LP,
  [ProductType.IP]: SUBSCRIPTION_COUNT_IP,
  [ProductType.Firewall]: SUBSCRIPTION_COUNT_FW,
  [ProductType.IPPrefixes]: SUBSCRIPTION_COUNT_IPP,
  [ProductType.Wireless]: SUBSCRIPTION_COUNT_WIRELESS,
};
