import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class LoadingState {
  private _total = 0;
  private _remaining = 0;
  private _asText = "";

  constructor(private translate: TranslateService) {}

  get remaining(): number {
    return this._remaining;
  }

  get asText() {
    return this._asText;
  }

  get total(): number {
    return this._total;
  }

  /**
   * This also resets the _remaining var!
   */
  set total(n: number) {
    this._total = n;
    this._remaining = n;
    this.updateText();
  }

  done(): boolean {
    this._remaining = this.remaining - 1;
    this.updateText();
    return this._remaining > 0;
  }

  private updateText() {
    this._asText = `${this._total - this._remaining} / ${this._total}`;
  }
}
