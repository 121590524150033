import { gql } from "apollo-angular";

export const fragmentWifiTickets = gql`
  fragment wifiTickets on SurfWirelessSubscription {
    subscriptionId
    wifiLocation {
      jiraLocationId
      detail {
        tickets {
          id
          status
          startDate
          impact
          summary
          description
          comments {
            createdAt
            text
          }
        }
      }
    }
  }
`;

export const SUBSCRIPTION_MESSAGES_QUERY = gql`
  ${fragmentWifiTickets}
  query SubscriptionMessages($id: ID!, $active: Boolean, $beginTimestamp: DateTime, $endTimestamp: DateTime) {
    subscription(id: $id) {
      ... on SurfWirelessSubscription {
        ...wifiTickets
      }
    }
  }
`;
