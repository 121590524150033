import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { PageInfo } from "../../../gql/generated";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DialogCertComponent } from "../../dialogs/dialog-cert/dialog-cert";
import { Sort } from "@angular/material/sort";

@Component({
  selector: "cert-table",
  templateUrl: "cert-table.html",
  styleUrls: ["cert-table.scss"],
})
export class CertTableComponent implements OnChanges {
  //airtScans.edges
  @Input() airtScans: any;
  @Output() paginationChanged = new EventEmitter<number>();
  @Output() sortingChanged = new EventEmitter<Sort>();
  @Input() pageInfo: PageInfo;
  public items = [];
  public dialogRef: MatDialogRef<MatDialog>;
  constructor(public dialog: MatDialog) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["airtScans"]) {
      if (this.airtScans.edges) {
        this.items.splice(0);
        this.items = [...this.airtScans.edges];
      }
    }
  }

  onPaginationChanged($event) {
    this.paginationChanged.emit($event);
  }

  openDialog(item) {
    const dialogRef = this.dialog.open(DialogCertComponent, {
      data: item.node,
    });
  }

  sortData($event: Sort) {
    this.sortingChanged.emit($event);
  }
}
