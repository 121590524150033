export enum ProductType { // This is the order in which services will be displayed
  IP = "IP",
  LightPath = "LightPath",
  L2VPN = "L2VPN",
  L3VPN = "L3VPN",
  Firewall = "FW",
  Wireless = "Wireless",
  Port = "Port",
  IPPrefixes = "IP_PREFIX",
  SURFcert = "cert",
  SURFdomains = "domains",
}
