import { ServicePort } from "../../components/selfservice/selfservice-add-port/ServicePort";

/**
 * Convert the vlanranges for services on this port
 * to a list of numbers.
 * Ranges will be enumerated (1-5 will be added as [1,2,3,4,5])
 *
 * @param port ServicePort
 * @returns Array<number>
 */
export const enumeratedVlans = (port: ServicePort): number[] => {
  const enumerated: number[] = [];
  port.usedVlans.map((vlanString) => {
    enumerated.push(...enumerateVlanString(vlanString));
  });
  return enumerated;
};

export const enumerateVlanString = (vlanString: string): number[] => {
  const vlanParts = vlanString.split(",");
  const enumerated = [];
  vlanParts.map((s) => {
    if (!isNaN(Number(s))) {
      enumerated.push(Number(s));
    } else {
      // x-y
      const range = s.split("-").map((n) => Number(n));
      for (let i = range[0]; i <= range[1]; i++) {
        enumerated.push(i);
      }
    }
  });
  return enumerated;
};

export const isValidVlan = (port: ServicePort, input: string): boolean => {
  const existing = enumeratedVlans(port);
  const candidate = enumerateVlanString(input);
  const decision = existing.some((v) => candidate.indexOf(v) >= 0);
  return !decision;
};
