interface TagMappingEnricher {
  [x: string]: any;
}
interface TagMappingRenamer {
  old: string;
  new: string;
}
interface TagMappingDef {
  [x: string]: TagMapping;
}
class TagMapping {
  remove?: string[];
  enrich?: TagMappingEnricher;
  rename?: TagMappingRenamer[];
}

const tagMapping: TagMappingDef = {
  LightPath: {
    remove: ["SURFNET8"],
    enrich: {
      vlanrange: (v: number) => (v !== 0 ? ["Tagged"] : []),
    },
    rename: [
      { old: "LP", new: "Protected" },
      { old: "LR", new: "Redundant" },
    ],
  },
  Port: {
    remove: ["SURFNET8"],
    enrich: {
      port_mode: (v: string) => v.toUpperCase(),
    },
  },
  L2VPN: {
    remove: ["SURFNET8", "L2VPN"],
  },
  L3VPN: {
    remove: ["SURFNET8", "L3VPN"],
  },
  IP: {
    remove: ["SURFNET8"],
    rename: [
      { old: "IPBGP", new: "IP BGP" },
      { old: "IPS", new: "IP Static" },
    ],
  },
  FW: {
    remove: ["NFV"],
    rename: [{ old: "FW", new: "SURFfirewall" }],
  },
  Wireless: {
    remove: ["Wireless"],
  },
  IP_PREFIX: {
    remove: ["SURFNET8"],
  },
  NOTIFICATION: {
    rename: [
      { old: "Reduced_redundancy", new: "Reduced Redundancy" },
      { old: "Resilience_loss", new: "Resilience loss" },
      { old: "Down", new: "Down time" },
      { old: "No_impact", new: "No impact" },
    ],
  },
};

export const translate = (product: string, tags: string[], resourceTypes?: any) => {
  const mapping = tagMapping[product] || null;
  if (!mapping) {
    return tags;
  }

  let tagsSet = new Set(tags);

  // 1. Remove tags
  if (mapping.remove) {
    mapping.remove.forEach((tagName) => tagsSet.delete(tagName));
  }

  // 2. Rename tags
  if (mapping.rename) {
    mapping.rename.forEach((v) => {
      if (tagsSet.has(v.old)) {
        tagsSet.delete(v.old);
        tagsSet.add(v.new);
      }
    });
  }

  // 3. Enrich with tags
  if (mapping.enrich && resourceTypes) {
    Object.entries(resourceTypes).forEach((entry) => {
      const f = mapping.enrich[entry[0]];
      const added = f(entry[1]);
      if (!Array.isArray(added)) {
        tagsSet.add(added);
      } else {
        added.map((tag) => tagsSet.add(tag));
      }
    });
  }

  return [...tagsSet];
};
