import { gql } from "apollo-angular";
import {
  ServiceSpeedL2VpnFragment,
  ServiceSpeedL3VpnFragment,
  ServiceSpeedIpBgpFragment,
  ServiceSpeedIpStaticFragment,
  ServiceSpeedLightpathFragment,
  ServiceSpeedLightpathRedundantFragment,
  FirewallSubscriptionFragment as ServiceSpeedFirewall,
} from "./port-subscription";

const ServiceSpeedAggspFragment = gql`
  fragment ServiceSpeedAggsp on Sn8AggregatedServicePortSubscription {
    subscriptionId
    port {
      linkMembers: port {
        subscriptionId
        ... on Sn8ServicePortSubscription {
          portSpeed
        }
      }
    }
  }
`;

const ServiceSpeedMscFragment = gql`
  ${ServiceSpeedAggspFragment}
  fragment ServiceSpeedMsc on Sn8MscSubscription {
    port {
      subscriptionInstanceId
      port {
        subscriptionType: __typename
        subscriptionId
        ... on Sn8ServicePortSubscription {
          portSpeed
        }
        ...ServiceSpeedAggsp
      }
    }
  }
`;

const ServiceSpeedSpFragment = gql`
  fragment ServiceSpeedSp on Sn8ServicePortSubscription {
    portSpeed
  }
`;

const CustomerInfoSpFragment = gql`
  fragment CustomerInfoSp on Sn8ServicePortSubscription {
    subscriptionId
    customerId
  }
`;

const CustomerInfoAggspFragment = gql`
  ${CustomerInfoSpFragment}
  fragment CustomerInfoAggsp on Sn8AggregatedServicePortSubscription {
    subscriptionId
    port {
      linkMembers: port {
        subscriptionId
        customerId
        ...CustomerInfoSp
      }
    }
  }
`;

const CustomerInfoMscFragment = gql`
  ${CustomerInfoAggspFragment}
  ${CustomerInfoSpFragment}
  fragment CustomerInfoMsc on Sn8MscSubscription {
    subscriptionId
    port {
      subscriptionInstanceId
      port {
        subscriptionId
        customerId
        ...CustomerInfoAggsp
        ...CustomerInfoSp
      }
    }
  }
`;

const CustomerInfoPortsFragment = gql`
  ${CustomerInfoMscFragment}
  ${CustomerInfoAggspFragment}
  ${CustomerInfoSpFragment}
  fragment CustomerInfoPorts on SubscriptionInterface {
    subscriptionId
    ...CustomerInfoMsc
    ...CustomerInfoAggsp
    ...CustomerInfoSp
  }
`;

const BaseSubscriptionDetailsFragment = gql`
  ${ServiceSpeedIpBgpFragment}
  ${ServiceSpeedIpStaticFragment}
  ${ServiceSpeedL2VpnFragment}
  ${ServiceSpeedL3VpnFragment}
  ${ServiceSpeedLightpathFragment}
  ${ServiceSpeedLightpathRedundantFragment}
  ${ServiceSpeedFirewall}
  ${ServiceSpeedSpFragment}
  ${ServiceSpeedMscFragment}
  ${CustomerInfoPortsFragment}
  fragment BaseSubscriptionDetails on SubscriptionInterface {
    ...ServiceSpeedL2Vpn
    ...ServiceSpeedL3Vpn
    ...ServiceSpeedLightpath
    ...ServiceSpeedLightpathRedundant
    ...ServiceSpeedIpBgp
    ...ServiceSpeedIpStatic
    ...ServiceSpeedFirewall
    ...ServiceSpeedMsc
    ...ServiceSpeedAggsp
    ...ServiceSpeedSp
    ... on Sn8L2VpnSubscription {
      vc {
        esis {
          ownerSubscriptionId
          saps {
            port {
              ...CustomerInfoPorts
            }
          }
        }
      }
    }
    ... on Sn8L3VpnSubscription {
      vc {
        saps {
          sap {
            ownerSubscriptionId
            subscriptionInstanceId
            port {
              ...CustomerInfoPorts
            }
          }
        }
      }
    }
    ... on Sn8LightPathSubscription {
      vc {
        saps {
          port {
            ...CustomerInfoPorts
          }
        }
      }
    }
    ... on Sn8LightPathRedundantSubscription {
      lrss {
        vcs {
          saps {
            port {
              ...CustomerInfoPorts
            }
          }
        }
      }
    }
    ... on Sn8IpBgpSubscription {
      vc {
        saps {
          sap {
            port {
              ...CustomerInfoPorts
            }
          }
        }
      }
    }
    ... on Sn8IpStaticSubscription {
      vc {
        sap {
          sap {
            port {
              ...CustomerInfoPorts
            }
          }
        }
      }
    }
    ... on Sn8ServicePortSubscription {
      port {
        subscriptionInstanceId
        portMode
      }
    }
    ... on Sn8AggregatedServicePortSubscription {
      port {
        subscriptionInstanceId
        portMode
      }
    }
    ... on SurfWirelessSubscription {
      wifiLocation {
        apVendorName
        sensorPresent
      }
    }
  }
`;

export { BaseSubscriptionDetailsFragment };
