import { PipeTransform, Pipe } from "@angular/core";

@Pipe({ name: "state_to_camelcase", pure: true })
export class StateToCamelcase implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case "ok":
        return "Ok";
      case "notfound":
        return "NotFound";
      case "unknown":
        return "Unknown";
      case "notraffic":
        return "NoTraffic";
      case "malfunction":
        return "Warning";
      case "initial":
        return "Initial";
      case "pending":
        return "Pending";
      case "stopped":
        return "Stopped";
      case "active-corero-filters":
        return "ActiveCoreroFilters";
      case "inactive-corero-filters":
        return "InactiveCoreroFilters";
      case "open-incidents":
        return "OpenIncidents";
      default:
        return "Unknown";
    }
  }
}
