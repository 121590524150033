<div class="card cert-filter">
  <h3>{{ 'Cert.Chart.Filter' | translate }}</h3>

  <div class="cert-filter__form-row">
    <label for="" class="form-label">IP prefixes</label>
    <custom-dropdown [options]="prefixOptions" (selectionChanged)="onFilterChanged()"></custom-dropdown>
  </div>

  <div class="cert-filter__form-row">
    <label for="" class="form-label">{{ 'Cert.IPAddress' | translate }}</label>
    <input type="text" class="input" [(ngModel)]="ip" placeholder="IP address" />
  </div>

  <div *ngIf="enableSpecificFilters" class="cert-filter__form-row">
    <label for="" class="form-label">Scan type</label>
    <custom-dropdown [options]="scanOptions"></custom-dropdown>
  </div>

  <div class="cert-filter__form-row">
    <label for="" class="form-label">{{ 'Cert.Severity' | translate }}</label>
    <custom-dropdown [options]="classifications" (selectionChanged)="onFilterChanged()"></custom-dropdown>
  </div>

  <div class="cert-filter__form-row">
    <label for="" class="form-label">{{ 'Cert.Period' | translate }}</label>
    <div class="cert-filter__date-split">
      <div class="cert-filter__date-input">
        <input
          type="text"
          id="startDate"
          mwlFlatpickr
          [dateFormat]="'d-m-Y'"
          [convertModelValue]="true"
          [disableMobile]="true"
          [(ngModel)]="initialStartDate"
        />
        <svg-icon class="cert-filter__date-input-icon" src="assets/images/icons/select-calendar.svg"> </svg-icon>
      </div>
      <div class="cert-filter__date-input">
        <input
          type="text"
          id="endDate"
          mwlFlatpickr
          [dateFormat]="'d-m-Y'"
          [convertModelValue]="true"
          [disableMobile]="true"
          [(ngModel)]="initialEndDate"
        />
        <svg-icon class="cert-filter__date-input-icon" src="assets/images/icons/select-calendar.svg"> </svg-icon>
      </div>
    </div>
  </div>

  <div class="cert-filter__form-footer">
    <div class="cert-filter__reset" (click)="resetFilters()">Reset</div>
    <button (click)="submit()" class="button button--primary">Filter</button>
  </div>
</div>
