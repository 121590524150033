import { humanizeMbit } from "../bitspipe/bitspipe";
import { getServiceSpeed } from "./ip";
import { L2VPNEndpoint } from "./l2vpn";
import { first } from "./misc";
import { toPort } from "./port";
import { BaseSubscriptionTransformed, gqlToSubscription, stringOrNA } from "./shared";
import { translate } from "./tags";

// exported because it is reused in l2vpn
export const toEndpoint = (sap: any): L2VPNEndpoint => {
  const organisation = sap.port.organisation;
  return {
    subscriptionInstanceId: sap.subscriptionInstanceId,
    organisation: stringOrNA(organisation?.name),
    name: sap.name,
    vlanrange: sap.vlanrange,
    tag: "SAP",
    port: toPort(sap.port),
  };
};

export interface L3VPNSubscriptionTransformed extends BaseSubscriptionTransformed {
  domain: string;
  serviceSpeed: number;
  protection: "Protected";
  firewallEnabled: boolean;
  tags: Array<string>;
  _circuits: any[];
  l3vpnss: any[];
  l3vpnsapss: any[];
  firewallInterconnects: any[];
}

const transformEndpoints = (saps: any[]): L2VPNEndpoint[] => saps.map((sap) => toEndpoint(sap.sap));

export interface L3VPNCircuit {
  subscriptionInstanceId: string;
  name: string;
  tag: "VC";
  endpoints: any[];
  speedPolicer: boolean;
  serviceSpeed: number;
  serviceId: number;
  specificTemplate?: string;
}

const transformCircuits = (vc: any): Array<L3VPNCircuit> => {
  const toCircuit = (circuit: any): L3VPNCircuit => ({
    subscriptionInstanceId: circuit.subscriptionInstanceId,
    name: circuit.name,
    tag: "VC",
    endpoints: transformEndpoints(circuit.saps),
    speedPolicer: circuit.speedPolicer,
    serviceSpeed: circuit.serviceSpeed,
    serviceId: 0,
    specificTemplate: circuit.specificTemplate || null,
  });

  return [toCircuit(vc)];
};

const transformL3Vpnss = (settings: any) => ({
  subscriptionInstanceId: settings.subscriptionInstanceId,
  name: settings.name,
  tag: "L3VPNSS",
  l3vpnAsn: settings.asn,
});

const transformL3VpnSapss = (saps: any[]) => {
  const isServicePort = (port: any): boolean => {
    const product = port.product;
    return ["SP", "SPNL", "AGGSP", "AGGSPNL", "MSC", "MSCNL"].includes(product?.tag);
  };
  const toSapss = (sapss: any) => ({
    subscriptionInstanceId: sapss.subscriptionInstanceId,
    asn: sapss.asn,
    name: sapss.name,
    bfd: sapss.bfd,
    bfdMinimumInterval: sapss.bfdMinimumInterval,
    bfdMultiplier: sapss.bfdMultiplier,
    tag: "L3VPNSAPSS", // TODO: tag name not provided by orchestrator domain-model endpoint yet
    ipv4Address: sapss.ipv4Address,
    ipv6Address: sapss.ipv6Address,
    ipv4RemoteAddress: sapss.ipv4RemoteAddress,
    ipv6RemoteAddress: sapss.ipv6RemoteAddress,
    ipv4Mtu: sapss.customerIpv4Mtu,
    ipv6Mtu: sapss.customerIpv6Mtu,
    ipv4MaxPrefix: sapss.ipv4MaxPrefix,
    ipv6MaxPrefix: sapss.ipv6MaxPrefix,
    urpf: sapss.urpf,
    endpointRole: sapss.endpointRole,
    sap: toEndpoint(sapss.sap),
  });

  return saps.filter((sap) => isServicePort(sap.sap.port)).map((servicePortSap) => toSapss(servicePortSap));
};

export const transformFwInterconnects = (firewalls: any[], saps: any[]) => {
  function* createInterconnects() {
    const findL3VpnSapss = (id: string) =>
      first(
        saps.filter((s) => s.sap.subscriptionInstanceId === id),
        null,
      );
    const endpointInSaps = (endpoint: any) => {
      const found = endpoint.saps
        .map((eps) => findL3VpnSapss(eps.subscriptionInstanceId))
        .filter((feps) => feps !== null);
      return first(found, null);
    };

    if (!firewalls) {
      return [];
    }

    for (const fw of firewalls) {
      const firewall = fw.firewall;
      for (const fwEndpoint of firewall.l3Endpoints) {
        const found = endpointInSaps(fwEndpoint);
        if (found !== null) {
          console.log(found);
          const ip4Address = found.ipv4Address || false;
          const ip6Address = found.ipv6Address || false;

          yield {
            subscriptionInstanceId: fwEndpoint.subscriptionInstanceId,
            firewallSubscriptionId: fw.subscriptionId,
            availabilityZone: firewall.availabilityZoneName,
            firewallAsn: firewall.asn,
            ipv4Mtu: found.customerIpv4Mtu || null,
            ipv6Mtu: found.customerIpv6Mtu || null,
            ipv4MaxPrefix: found.ipv4MaxPrefix || null,
            ipv6MaxPrefix: found.ipv6MaxPrefix || null,
            ipv4AddressFirewall: found.ipv4RemoteAddress || null,
            ipv6AddressFirewall: found.ipv6RemoteAddress || null,
            ipv4Prefix: ip4Address || null,
            ipv6Prefix: ip6Address || null,
            product: fw.product || null,
          };
        }
      }
    }
  }
  return [...createInterconnects()];
};

export const transform = (subscription: any) => {
  if (!subscription) {
    return {};
  }
  const circuits = transformCircuits(subscription.vpnvc);
  const serviceSpeed = getServiceSpeed(circuits);
  const firewallEnabled = subscription.dependsOn?.length > 0;
  function* getTags() {
    yield subscription.product.tag;
    yield "Protected";
    yield humanizeMbit(Number(serviceSpeed) * 1000 * 1000);
    yield subscription.domain;
    if (firewallEnabled) {
      yield "Firewall";
    }
  }
  const tags = translate("L3VPN", [...getTags()]);
  const transformedSubscription = gqlToSubscription(subscription, tags);
  return {
    ...transformedSubscription,
    domain: subscription.domain,
    serviceSpeed,
    protection: "Protected",
    firewallEnabled,
    tags,
    _circuits: circuits,
    l3vpnss: transformL3Vpnss(subscription.vpnvc.settings),
    l3vpnsapss: transformL3VpnSapss(subscription.vpnvc.saps),
    firewallInterconnects: transformFwInterconnects(subscription.dependsOn, subscription.vpnvc.saps),
  };
};
