import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ChartFilterType } from "../helpers/types";

@Injectable()
export class MessageService {
  public enableFilterSource = new Subject<any>();
  public enableMalfunctionFilterSource = new Subject<any>();
  public disableFilterSource = new Subject<any>();
  public setMalfunctionFilterDateSource = new Subject<any>();
  public malfunctionLoadMoreSource = new Subject<any>();
  public chartFilterSource = new Subject<ChartFilterType>();

  enableFilter$ = this.enableFilterSource.asObservable();
  enableMalfunctionFilter$ = this.enableMalfunctionFilterSource.asObservable();
  disableFilter$ = this.disableFilterSource.asObservable();
  malfunctionFilterDate$ = this.setMalfunctionFilterDateSource.asObservable();
  malfunctionLoadMore$ = this.malfunctionLoadMoreSource.asObservable();
  chartFilter$ = this.chartFilterSource.asObservable();

  constructor() {}

  enableFilter(data) {
    // console.log(data); // I have data! Let's return it so subscribers can use it!
    // we can do stuff with data if we want
    this.enableFilterSource.next(data);
  }
  disableFilter(data) {
    // console.log(data); // I have data! Let's return it so subscribers can use it!
    // we can do stuff with data if we want
    this.disableFilterSource.next(data);
  }
  setChartFilter(data: ChartFilterType) {
    this.chartFilterSource.next(data);
  }

  enableMalfunctionFilter(data) {
    // console.log(data); // I have data! Let's return it so subscribers can use it!
    // we can do stuff with data if we want
    this.enableMalfunctionFilterSource.next(data);
  }

  setMalfunctionFilterDate(data) {
    // console.log(data); // I have data! Let's return it so subscribers can use it!
    // we can do stuff with data if we want
    this.setMalfunctionFilterDateSource.next(data);
  }

  setMalfunctionLoadMore(data) {
    // console.log(data); // I have data! Let's return it so subscribers can use it!
    // we can do stuff with data if we want
    this.malfunctionLoadMoreSource.next(data);
  }
}
