<div class="card cert-chart">
  <div *ngIf="!noDataFound" class="cert-chart__left">
    <h4 class="cert-chart__title">Scan types</h4>
    <highcharts-chart [Highcharts]="highcharts" [options]="chartOptions" (chartInstance)="setChartInstance($event)">
    </highcharts-chart>
  </div>
  <div *ngIf="!noDataFound" class="cert-chart__right">
    <h4 class="cert-chart__title">{{ 'Cert.Severity' | translate }}</h4>
    <ul class="cert-chart__classifications">
      <li *ngFor="let classification of classifications">
        <header class="cert-chart__classifications-header">
          <h4 class="cert-chart__classifications-title">{{ classification.name }}</h4>
          <div class="cert-chart__classifications-percentage">{{ classification.percentage }}%</div>
        </header>
        <div class="cert-chart__classifications-bar">
          <span class="cert-chart__classifications-progress" [style.width.%]="classification.percentage"></span>
        </div>
      </li>
    </ul>
  </div>
  <div *ngIf="noDataFound" class="cert-chart__empty-state">
    <i></i>
    <span>{{ 'Chart.TrafficGraph.NoDataFound' | translate }}</span>
  </div>
</div>
