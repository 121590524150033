import { StorageHelper } from "../../helpers/storage/storagehelper";

export class Instance {
  [x: string]: any;
  public subscriptionId: string;
  public customerGuid: string;
  insync: boolean;

  constructor() {
    this.customerGuid = StorageHelper.currentUser;
  }

  get presentableName() {
    if (this.customerDescriptions) {
      // for now, this makes a distinction between an array of description objects
      // (from GraphQL) and an object with GUID keys (from REST)
      if (Array.isArray(this.customerDescriptions)) {
        // this is an array of objects
        const customerDescription = this.customerDescriptions.find(
          (desc: any) => desc.customerId === this.customerGuid,
        );
        if (customerDescription) {
          return customerDescription.description;
        }
      } else if (this.customerGuid in this.customerDescriptions) {
        // this is an object where each key is a customer GUID
        // and each value is the custom description
        return this.customerDescriptions[this.customerGuid];
      }
    }

    return this.description;
  }

  get hasPresentableName(): boolean {
    const customer_Description = this.customerDescriptions?.find(
      (customerDescription: any) => customerDescription?.customerId === this.customerGuid,
    );
    if (customer_Description) {
      return true;
    }
    return false;
  }

  /**
   * Temporarily set a new description for this subscription
   * until the next GraphQL load.
   * Use after changing the customer description.
   *
   * @param description the new description
   */
  updatePresentableName(description: string): void {
    if (Array.isArray(this.customerDescriptions)) {
      this.customerDescriptions = this.customerDescriptions.map((descriptionObject) => {
        if (descriptionObject.customerId === this.customerGuid) {
          return {
            ...descriptionObject,
            description,
          };
        }
        return descriptionObject;
      });
    } else {
      this.customerDescriptions[this.customerGuid] = description;
    }
  }
}
