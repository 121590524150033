<div class="dialog__body dialog__body--flex-justify dialog__body--no-padding dialog__body--grow dialog__body--grey">
  <div class="dialog__port-rule">
    <div class="dialog__port-number">{{ sapIndex + 1 }}</div>
    <div class="dialog__port-text">{{ instance.port | custom_description }}</div>
  </div>
  <div *ngIf="currentStep === 1" class="dialog__inner">
    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="dialog__label">{{ 'Selfservice.BFD.Label.Activate' | translate }}</div>
        <div class="selfservice-bfd__toggle">
          <input
            id="toggle-bfd"
            type="checkbox"
            [(ngModel)]="bfdActivated"
            [value]="true"
            (change)="handleChange($event)"
          />
          <label for="toggle-bfd">
            <selfservice-toggle [active]="bfdActivated"></selfservice-toggle>
            <div [ngClass]="{'blue': bfdActivated}">{{ bfdActivated }}</div>
          </label>
        </div>
        <!-- <div class="selfservice-bfd__radios">
          <label>
            <input
              type="radio"
              name="bfd"
              [value]="true"
              [(ngModel)]="bfdActivated"
              (change)="handleChange($event)"
            />
            <div>True</div>
          </label>
          <label>
            <input
              type="radio"
              name="bfd"
              value="false"
              [(ngModel)]="bfdActivated"
            />
            <div>False</div>
          </label>
        </div> -->
      </div>
    </div>
    <div [ngClass]="{'disabled': !bfdActivated}" class="selfservice-bfd__extra-fields">
      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="dialog__label">{{ 'Selfservice.BFD.Label.TimeInterval' | translate }}</div>
          <div class="input__wrapper">
            <input
              type="number"
              class="input input--shadow"
              placeholder="450"
              min="300"
              max="10000"
              [(ngModel)]="timeInterval"
              [ngClass]="{'input--witherror': !validTimeInterval && bfdActivated}"
            />
            <div class="input__addon">ms</div>
            <div *ngIf="!validTimeInterval && bfdActivated" class="input__error">
              {{ 'Selfservice.BFD.Error.TimeInterval' | translate }}
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="dialog__label">{{ 'Selfservice.BFD.Label.Multiplier' | translate }}</div>
          <div class="input__wrapper">
            <input
              type="number"
              class="input input--shadow"
              placeholder="3"
              [(ngModel)]="multiplier"
              [ngClass]="{'input--witherror': !validMultiplier && bfdActivated}"
            />
            <div *ngIf="!validMultiplier && bfdActivated" class="input__error">
              {{ 'Selfservice.BFD.Error.Multiplier' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="currentStep === 2">
    <div class="dialog__inner">
      <h3 class="dialog__sub-heading">{{ 'Selfservice.Global.Title' | translate }}</h3>
      <p class="no-margin">{{ 'Selfservice.BFD.Text' | translate }}</p>
    </div>

    <div *ngIf="hasErrors" class="dialog__error dialog__error--margin-x">
      <ul>
        <li *ngFor="let e of errors">{{ e.msg }}</li>
      </ul>
    </div>

    <div class="dialog__rule">
      <h3 class="dialog__sub-heading">{{ 'Selfservice.Global.CurrentSetting' | translate }} BFD</h3>
      <div class="dialog__rule-answer">
        {{ instance.bfd }}
        <span *ngIf="instance.bfd && instance.bfdMinimumInterval">/ {{ instance.bfdMinimumInterval }}</span>
        <span *ngIf="instance.bfd && instance.bfdMultiplier">/ {{ instance.bfdMultiplier }}</span>
      </div>
    </div>
    <div class="dialog__rule">
      <h3 class="dialog__sub-heading">{{ 'Selfservice.Global.NewSetting' | translate }} BFD</h3>
      <div class="dialog__rule-answer blue">
        {{ bfdActivated }}
        <span *ngIf="bfdActivated === true">/ {{ timeInterval }} / {{ multiplier }}</span>
      </div>
    </div>
    <div *ngIf="hasErrors" class="dialog__error dialog__error--margin-x">
      <ul>
        <li *ngFor="let e of errors">{{ e.msg }}</li>
      </ul>
    </div>
  </div>
</div>
<div *ngIf="currentStep === 1" class="dialog__bottom-message">
  <div class="dialog__bottom-message-inner">
    <svg-icon src="assets/images/icons/status-failure.svg" class="dialog__bottom-message-icon"></svg-icon>
    <span> {{ 'Selfservice.BFD.Message' | translate }} </span>
  </div>
</div>
<div class="dialog__footer dialog__footer--center dialog__footer--border">
  <div class="dialog__footer-text">
    <selfservice-authentication-box></selfservice-authentication-box>
  </div>
  <div *ngIf="currentStep === 1" class="button__flex">
    <button class="button button--secondary" (click)="emitCloseDialog()">
      {{ 'Global.Action.Cancel' | translate }}
    </button>
    <button (click)="next()" class="button" [ngClass]="{'button--disabled': !validTimeInterval || !validMultiplier}">
      {{ 'Global.Action.Continue' | translate }}
    </button>
  </div>
  <div *ngIf="currentStep === 2" class="button__flex">
    <button class="button button--secondary" (click)="prev()">{{ 'Global.Action.Back' | translate }}</button>
    <button class="button" (click)="submit()" [ngClass]="{'loading': buttonLoading }">
      <svg-icon src="assets/images/icons/loader.svg" class="button__loader"></svg-icon>
      <span>{{ 'Global.Action.Confirm' | translate }}</span>
    </button>
  </div>
</div>
