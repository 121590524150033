import { toPort } from "./port";
import { sortBySubscriptionInstanceId, stringOrNA } from "./shared";
import { translate } from "./tags";

const toSaps = (saps: any[]) => {
  const _toSap = (sap: any) => {
    const organisation = sap.port.customer;
    const port = toPort(sap.port);

    return {
      subscriptionInstanceId: sap.subscriptionInstanceId,
      name: sap.name,
      tag: "SAP",
      organisation: stringOrNA(organisation?.name),
      address: port.address,
      port,
      vlanrange: sap.vlanrange,
    };
  };

  return saps.map((sap: any) => _toSap(sap));
};

export const transformCircuits = (domain: string, circuits: any[]) => {
  const toCircuit = (vc: any) => {
    const saps = vc.saps || [];
    return {
      tag: "VC",
      serviceId: ["SURFNET7", "NETHERLIGHT7"].includes(domain) ? Number(vc.serviceId || 0) : 0,
      subscriptionInstanceId: vc.subscriptionInstanceId,
      name: vc.name,
      speedPolicer: vc.speedPolicer,
      remotePortShutdown: vc.remotePortShutdown,
      serviceSpeed: vc.serviceSpeed,
      endpoints: [...toSaps(saps)].sort(sortBySubscriptionInstanceId),
    };
  };

  return circuits.map((circuit: any) => toCircuit(circuit));
};

export const getTags = (tag: string, serviceSpeed: number, protection: string, domain: string) =>
  translate(
    "LightPath",
    [tag, protection, serviceSpeed, domain].map((v: any) => v.toString()),
  );
