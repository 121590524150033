import { gql } from "apollo-angular";

export const CUSTOMER_WITH_MINIMAL_IMPACT_NOTIFICATIONS_QUERY = gql`
  query CustomerWithMinimalImpactNotificationQuery($customerId: String!, $products: [String!]) {
    customer(organisationId: $customerId) {
      id: uuid
      subscriptions(productsIncludedFilter: $products, statusFilter: ["active", "provisioning"]) {
        subscriptionId
        minimalImpactNotifications {
          subscriptionId
          id
          customerId
          impact
        }
        description
        customerDescriptions {
          customerId
          description
        }
        product {
          type
        }
      }
    }
  }
`;
