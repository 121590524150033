import { first } from "./misc";
import { toPort } from "./port";
import { translate } from "./tags";

export const getServiceSpeed = (circuits: any[]): number => first(circuits, { serviceSpeed: -1 }).serviceSpeed || 0;

export const transformPtp = (ptp: any): any => {
  const findAddressByTag = (tag: string) =>
    first(
      ptp.addresses.filter((address: any) => address.tags.includes(tag)),
      {},
    );
  const client = findAddressByTag("Customer");
  const surf = findAddressByTag("SURFnet");
  return {
    surfIpAddress: surf.address || "",
    clientIpAddress: client.address || "",
    network: ptp.prefix,
    clientFQDN: client.fqdn || "",
    surfFQDN: surf.fqdn || "",
  };
};

export const transformIPSS = (settings) => {
  const surfCertFilter = settings.surfcertFilterEnabled ? "enabled" : "disabled";
  const pinnenPrefixes = (settings.pinPrefix || []).map((prefix) => prefix.ownerSubscriptionId);

  return {
    subscriptionInstanceId: settings.subscriptionInstanceId,
    name: settings.name,
    surfcertFilter: surfCertFilter,
    multicast: settings.multicast,
    tag: "IPSS",
    asn: settings.asn,
    internetpinnen: pinnenPrefixes.length > 0,
    internetpinnenPrefixSubscriptions: pinnenPrefixes,
  };
};

const toSap = (sap: any) => {
  const port = sap.sap.port;
  const ipv4_point_to_point_prefix = sap.ptpIpv4Ipam ? transformPtp(sap.ptpIpv4Ipam) : null;
  const ipv6_point_to_point_prefix = sap.ptpIpv6Ipam ? transformPtp(sap.ptpIpv6Ipam) : null;

  const portData = toPort(port);

  return {
    subscriptionInstanceId: sap["subscriptionInstanceId"],
    name: sap["name"],
    tag: "SAPSS",
    organisation: port["customer"]["name"],
    port: portData,
    locationDescription: portData.locationDescription,
    vlanrange: sap["sap"]["vlanrange"],
    bfd: sap.bfd || false,
    bfdMinimumInterval: sap.bfdMinimumInterval,
    bfdMultiplier: sap.bfdMultiplier,
    bgpExportPolicy: sap.bgpExportPolicy,
    bgpHashAlgorithm: sap.bgpHashAlgorithm,
    bgpPassword: sap.bgpPassword,
    bgpSessionPriority: sap.bgpSessionPriority,
    ipv4Mtu: sap.customerIpv4Mtu,
    ipv6Mtu: sap.customerIpv6Mtu,
    ipv4PointToPointPrefix: ipv4_point_to_point_prefix,
    ipv6PointToPointPrefix: ipv6_point_to_point_prefix,
    prefixSubscriptions: sap.prefixes.map((p) => p.ownerSubscriptionId),
  };
};

export const transformSaps = (saps: any[]) => saps.map((sap) => toSap(sap));

export const getTags = (subscription: any) => {
  if (subscription) {
    return translate("IP", [subscription.product.tag, subscription.ip_routing_type, subscription.domain]);
  }
  return [];
};
