import { IPBFDServicePort } from "./models/types";

export class ModifyBfdCommand {
  private service_ports: IPBFDServicePort[] = [];

  get payload() {
    return { service_ports: this.service_ports };
  }

  setForInstance(instanceId: string, state: boolean, minimum_interval: number, multiplier: number) {
    this.service_ports.push({
      subscription_instance_id: instanceId,
      bfd: {
        enabled: state,
        minimum_interval,
        multiplier,
      },
    });
  }
}
