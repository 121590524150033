// moved to separate file to avoid circular dependencies.

import { GqlTypes } from "../../graphql/custom-types";
import { transformAggsp } from "./port/transform-aggsp";
import { transformIrb } from "./port/transform-irb";
import { transformMsc } from "./port/transform-msc";
import { transformSp } from "./port/transform-sp";
import { BaseSubscriptionTransformed } from "./shared";

export const transform = (subscription: any): BaseSubscriptionTransformed => {
  switch (subscription?.subscriptionType) {
    case GqlTypes.SP:
      return transformSp(subscription);
    case GqlTypes.AGGSP:
      return transformAggsp(subscription);
    case GqlTypes.MSC:
      return transformMsc(subscription);
    case GqlTypes.IRB:
      return transformIrb(subscription);
    default:
      console.warn(`transform: no suitable method found. type=${subscription?.subscriptionType}`);
      return null;
  }
};
