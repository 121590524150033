import { APOLLO_OPTIONS, APOLLO_NAMED_OPTIONS, NamedOptions } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { ApolloLink, InMemoryCache } from "@apollo/client/core";
import generatedIntrospection from "../gql/possible-types";
import generatedIntrospectionIngestor from "../gql/possible-types-ingestor.ts/graphql";
import { createFetchPolicies } from "../graphql/client";
import { authLink } from "../graphql/links/auth.link";
import { persistedQueryLink } from "../graphql/links/persisted-query.link";
import { apiUrl } from "./app.runtime";

const GQL_URI = apiUrl("graphql");

export const graphqlProvider = {
  provide: APOLLO_OPTIONS,
  useFactory: (httpLink: HttpLink) => ({
    cache: new InMemoryCache({
      possibleTypes: generatedIntrospection.possibleTypes,
      typePolicies: createFetchPolicies(generatedIntrospection),
    }),
    // roundTripLink is a link that logs the request duration and graphql query to console.
    // WARNING: it generates a lot (and long) logs, do NOT commit it to the main branch!
    link: ApolloLink.from([
      persistedQueryLink,
      authLink,
      // roundTripLink,
      httpLink.create({ uri: GQL_URI }),
    ]),
  }),
  deps: [HttpLink],
};

const URI_NWD_INGESTOR_DATA = apiUrl("endpoint/private/graphql");

export const graphqlProviderReplicatedData = {
  provide: APOLLO_NAMED_OPTIONS,
  useFactory: (httpLink: HttpLink): NamedOptions => ({
    replicatedData: {
      cache: new InMemoryCache({
        possibleTypes: generatedIntrospectionIngestor.possibleTypes,
        typePolicies: createFetchPolicies(generatedIntrospection),
      }),
      // roundTripLink is a link that logs the request duration and graphql query to console.
      // WARNING: it generates a lot (and long) logs, do NOT commit it to the main branch!
      link: ApolloLink.from([
        authLink,
        // roundTripLink,
        httpLink.create({ uri: URI_NWD_INGESTOR_DATA }),
      ]),
    },
  }),
  deps: [HttpLink],
};
