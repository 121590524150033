<div class="card card--no-padding">
  <div class="access-points__wrapper">
    <div *ngIf="accessPointSummaries === null" class="access-points__loader">
      <loader></loader>
    </div>
    <div *ngIf="accessPointSummaries.length !== null && accessPointSummaries.length === 0">
      <div>
        <div>
          <div>
            <p>No access points known</p>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="accessPointSummaries.length !== null && accessPointSummaries.length > 0"
      class="access-points"
      #scrollarea
      (scroll)="checkScroll()"
    >
      <div *ngFor="let summary of accessPointSummaries" class="access-points__item">
        <div class="access-points__item-left">
          <div class="access-points__aspect {{summary.brand}}">
            <div class="access-points__model">Model {{ summary.model }}</div>
          </div>
        </div>
        <div class="access-points__item-right">
          <div class="access-points__item-info">
            <span>{{ 'Wireless.Ap.Active' | translate }}</span> {{ summary.active }}
          </div>
          <div class="access-points__item-info">
            <span>{{ 'Wireless.Ap.Inactive' | translate }}</span> {{ summary.inactive }}
          </div>
          <div class="access-points__item-info">
            <span>{{ 'Wireless.Ap.Total' | translate }}</span> {{ summary.total }}
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!leftDisabled" class="access-points__control access-points__control--prev" (click)="scrollLeft()">
      <svg-icon class="access-points__control-icon" src="assets/images/icons/chevron-down.svg"> </svg-icon>
    </div>
    <div *ngIf="!rightDisabled" class="access-points__control access-points__control--next" (click)="scrollRight()">
      <svg-icon class="access-points__control-icon" src="assets/images/icons/chevron-down.svg"> </svg-icon>
    </div>
  </div>
</div>
