/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { StrictHttpResponse } from "../../strict-http-response";
import { RequestBuilder } from "../../request-builder";

import { TemplateReadWithRecords } from "../../models/template-read-with-records";

export interface ReadTemplate$Params {
  organisation_id: string;
  template_id: number;
  token?: string | null;
}

export function readTemplate(
  http: HttpClient,
  rootUrl: string,
  params: ReadTemplate$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<TemplateReadWithRecords>> {
  const rb = new RequestBuilder(rootUrl, readTemplate.PATH, "get");
  if (params) {
    rb.path("organisation_id", params.organisation_id, {});
    rb.path("template_id", params.template_id, {});
    rb.query("token", params.token, {});
  }

  // add this header so we are able to examine the failure response
  // otherwise it's intercepted by the http interceptor
  rb.header("x-ignore-interceptor", "1");

  return http.request(rb.build({ responseType: "json", accept: "application/json", context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<TemplateReadWithRecords>;
    }),
  );
}

readTemplate.PATH = "/organisations/{organisation_id}/templates/{template_id}";
