/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { StrictHttpResponse } from "../../strict-http-response";
import { RequestBuilder } from "../../request-builder";

import { RecordsUpdateStatus } from "../../models/records-update-status";
import { ReverseRecordsUpdate } from "../../models/reverse-records-update";

export interface UpdateReverseRecords$Params {
  organisation_id: string;
  reverse_zone_id: number;
  token?: string | null;
  check?: boolean;
  body: ReverseRecordsUpdate;
}

export function updateReverseRecords(
  http: HttpClient,
  rootUrl: string,
  params: UpdateReverseRecords$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<RecordsUpdateStatus>> {
  const rb = new RequestBuilder(rootUrl, updateReverseRecords.PATH, "patch");
  if (params) {
    rb.path("organisation_id", params.organisation_id, {});
    rb.path("reverse_zone_id", params.reverse_zone_id, {});
    rb.query("token", params.token, {});
    rb.query("check", params.check, {});
    rb.body(params.body, "application/json");
  }

  // add this header so we are able to examine the failure response
  // otherwise it's intercepted by the http interceptor
  rb.header("x-ignore-interceptor", "1");

  return http.request(rb.build({ responseType: "json", accept: "application/json", context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<RecordsUpdateStatus>;
    }),
  );
}

updateReverseRecords.PATH = "/organisations/{organisation_id}/reverse/{reverse_zone_id}/records/";
