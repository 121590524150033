export type PossibleTypesResultData = {
  possibleTypes: {
    BaseEnrichedTagsSubscription: [
      "FwSubscription",
      "IpPrefixSubscription",
      "Sn8AggregatedServicePortSubscription",
      "Sn8IpBgpSubscription",
      "Sn8IpStaticSubscription",
      "Sn8IrbServicePortSubscription",
      "Sn8L2VpnSubscription",
      "Sn8L3VpnSubscription",
      "Sn8LightPathRedundantSubscription",
      "Sn8LightPathSubscription",
      "Sn8MscSubscription",
      "Sn8ServicePortSubscription",
      "SurfWirelessSubscription",
    ];
    BaseHealthSubscription: [
      "FwSubscription",
      "IpPrefixSubscription",
      "Sn8AggregatedServicePortSubscription",
      "Sn8IpBgpSubscription",
      "Sn8IpStaticSubscription",
      "Sn8IrbServicePortSubscription",
      "Sn8L2VpnSubscription",
      "Sn8L3VpnSubscription",
      "Sn8LightPathRedundantSubscription",
      "Sn8LightPathSubscription",
      "Sn8MscSubscription",
      "Sn8ServicePortSubscription",
    ];
    CustomerSubscriptionDescriptionPythiaNotFoundPythiaError: [
      "CustomerSubscriptionDescription",
      "PythiaError",
      "PythiaNotFound",
    ];
    ImsPortInternalPortTypeInternetTypeImsService: ["ImsPort", "ImsService", "InternalPortType", "InternetType"];
    ImsProvider: [
      "IpPeerPortBlock",
      "Sn8AggregatedServicePortBlock",
      "Sn8CorelinkBlock",
      "Sn8IpBgpVirtualCircuitBlock",
      "Sn8IpStaticVirtualCircuitBlock",
      "Sn8IrbServicePortBlock",
      "Sn8L2VpnVirtualCircuitBlock",
      "Sn8L3VpnVirtualCircuitBlock",
      "Sn8LightPathVirtualCircuitBlock",
      "Sn8MscBlock",
      "Sn8ServicePortBlock",
    ];
    MinimalImpactNotificationPythiaError: ["MinimalImpactNotification", "PythiaError"];
    MinimalImpactNotificationPythiaNotFoundPythiaError: ["MinimalImpactNotification", "PythiaError", "PythiaNotFound"];
    PeerPortProvider: ["PeerBlock"];
    PortProvider: ["Sn8MscBlock", "Sn8ServiceAttachPointBlock"];
    ProcessCreatedPythiaError: ["ProcessCreated", "PythiaError"];
    ProductBlockModel: [
      "FwBlock",
      "FwIpGwEndpointBlock",
      "FwL2EndpointBlock",
      "FwL3EndpointBlock",
      "IpPeerBlock",
      "IpPeerGroupBlock",
      "IpPeerPortBlock",
      "IpPrefixBlock",
      "NodeProductBlock",
      "NsiStpBlock",
      "PeerBlock",
      "Sn8AggregatedServicePortBlock",
      "Sn8CorelinkAggregateBlock",
      "Sn8CorelinkBlock",
      "Sn8CorelinkPortPairBlock",
      "Sn8IpBgpServiceAttachPointSettingsBlock",
      "Sn8IpBgpServiceSettingsBlock",
      "Sn8IpBgpVirtualCircuitBlock",
      "Sn8IpStaticServiceAttachPointSettingsBlock",
      "Sn8IpStaticServiceSettingsBlock",
      "Sn8IpStaticVirtualCircuitBlock",
      "Sn8IrbServicePortBlock",
      "Sn8L2VpnEsiBlock",
      "Sn8L2VpnVirtualCircuitBlock",
      "Sn8L3VpnServiceAttachPointSettingsBlock",
      "Sn8L3VpnServiceSettingsBlock",
      "Sn8L3VpnVirtualCircuitBlock",
      "Sn8LightPathRedundantServiceSettingsBlock",
      "Sn8LightPathVirtualCircuitBlock",
      "Sn8MscBlock",
      "Sn8ServiceAttachPointBlock",
      "Sn8ServicePortBlock",
      "WifiLocation",
    ];
    PtpIpamProvider: ["Sn8IpBgpServiceAttachPointSettingsBlock", "Sn8IpStaticServiceAttachPointSettingsBlock"];
    SubscriptionInterface: [
      "FwSubscription",
      "IpPeerGroupSubscription",
      "IpPeerPortSubscription",
      "IpPeerSubscription",
      "IpPrefixSubscription",
      "NodeSubscription",
      "NsiLightPathSubscription",
      "NsistpSubscription",
      "Sn8AggregatedServicePortSubscription",
      "Sn8CorelinkSubscription",
      "Sn8IpBgpSubscription",
      "Sn8IpStaticSubscription",
      "Sn8IrbServicePortSubscription",
      "Sn8L2VpnSubscription",
      "Sn8L3VpnSubscription",
      "Sn8LightPathRedundantSubscription",
      "Sn8LightPathSubscription",
      "Sn8MscSubscription",
      "Sn8ServicePortSubscription",
      "SurfWirelessSubscription",
      "UnknownSubscription",
    ];
    UserPreferencesPythiaError: ["PythiaError", "UserPreferences"];
  };
};
const result: PossibleTypesResultData = {
  possibleTypes: {
    BaseEnrichedTagsSubscription: [
      "FwSubscription",
      "IpPrefixSubscription",
      "Sn8AggregatedServicePortSubscription",
      "Sn8IpBgpSubscription",
      "Sn8IpStaticSubscription",
      "Sn8IrbServicePortSubscription",
      "Sn8L2VpnSubscription",
      "Sn8L3VpnSubscription",
      "Sn8LightPathRedundantSubscription",
      "Sn8LightPathSubscription",
      "Sn8MscSubscription",
      "Sn8ServicePortSubscription",
      "SurfWirelessSubscription",
    ],
    BaseHealthSubscription: [
      "FwSubscription",
      "IpPrefixSubscription",
      "Sn8AggregatedServicePortSubscription",
      "Sn8IpBgpSubscription",
      "Sn8IpStaticSubscription",
      "Sn8IrbServicePortSubscription",
      "Sn8L2VpnSubscription",
      "Sn8L3VpnSubscription",
      "Sn8LightPathRedundantSubscription",
      "Sn8LightPathSubscription",
      "Sn8MscSubscription",
      "Sn8ServicePortSubscription",
    ],
    CustomerSubscriptionDescriptionPythiaNotFoundPythiaError: [
      "CustomerSubscriptionDescription",
      "PythiaError",
      "PythiaNotFound",
    ],
    ImsPortInternalPortTypeInternetTypeImsService: ["ImsPort", "ImsService", "InternalPortType", "InternetType"],
    ImsProvider: [
      "IpPeerPortBlock",
      "Sn8AggregatedServicePortBlock",
      "Sn8CorelinkBlock",
      "Sn8IpBgpVirtualCircuitBlock",
      "Sn8IpStaticVirtualCircuitBlock",
      "Sn8IrbServicePortBlock",
      "Sn8L2VpnVirtualCircuitBlock",
      "Sn8L3VpnVirtualCircuitBlock",
      "Sn8LightPathVirtualCircuitBlock",
      "Sn8MscBlock",
      "Sn8ServicePortBlock",
    ],
    MinimalImpactNotificationPythiaError: ["MinimalImpactNotification", "PythiaError"],
    MinimalImpactNotificationPythiaNotFoundPythiaError: ["MinimalImpactNotification", "PythiaError", "PythiaNotFound"],
    PeerPortProvider: ["PeerBlock"],
    PortProvider: ["Sn8MscBlock", "Sn8ServiceAttachPointBlock"],
    ProcessCreatedPythiaError: ["ProcessCreated", "PythiaError"],
    ProductBlockModel: [
      "FwBlock",
      "FwIpGwEndpointBlock",
      "FwL2EndpointBlock",
      "FwL3EndpointBlock",
      "IpPeerBlock",
      "IpPeerGroupBlock",
      "IpPeerPortBlock",
      "IpPrefixBlock",
      "NodeProductBlock",
      "NsiStpBlock",
      "PeerBlock",
      "Sn8AggregatedServicePortBlock",
      "Sn8CorelinkAggregateBlock",
      "Sn8CorelinkBlock",
      "Sn8CorelinkPortPairBlock",
      "Sn8IpBgpServiceAttachPointSettingsBlock",
      "Sn8IpBgpServiceSettingsBlock",
      "Sn8IpBgpVirtualCircuitBlock",
      "Sn8IpStaticServiceAttachPointSettingsBlock",
      "Sn8IpStaticServiceSettingsBlock",
      "Sn8IpStaticVirtualCircuitBlock",
      "Sn8IrbServicePortBlock",
      "Sn8L2VpnEsiBlock",
      "Sn8L2VpnVirtualCircuitBlock",
      "Sn8L3VpnServiceAttachPointSettingsBlock",
      "Sn8L3VpnServiceSettingsBlock",
      "Sn8L3VpnVirtualCircuitBlock",
      "Sn8LightPathRedundantServiceSettingsBlock",
      "Sn8LightPathVirtualCircuitBlock",
      "Sn8MscBlock",
      "Sn8ServiceAttachPointBlock",
      "Sn8ServicePortBlock",
      "WifiLocation",
    ],
    PtpIpamProvider: ["Sn8IpBgpServiceAttachPointSettingsBlock", "Sn8IpStaticServiceAttachPointSettingsBlock"],
    SubscriptionInterface: [
      "FwSubscription",
      "IpPeerGroupSubscription",
      "IpPeerPortSubscription",
      "IpPeerSubscription",
      "IpPrefixSubscription",
      "NodeSubscription",
      "NsiLightPathSubscription",
      "NsistpSubscription",
      "Sn8AggregatedServicePortSubscription",
      "Sn8CorelinkSubscription",
      "Sn8IpBgpSubscription",
      "Sn8IpStaticSubscription",
      "Sn8IrbServicePortSubscription",
      "Sn8L2VpnSubscription",
      "Sn8L3VpnSubscription",
      "Sn8LightPathRedundantSubscription",
      "Sn8LightPathSubscription",
      "Sn8MscSubscription",
      "Sn8ServicePortSubscription",
      "SurfWirelessSubscription",
      "UnknownSubscription",
    ],
    UserPreferencesPythiaError: ["PythiaError", "UserPreferences"],
  },
};
export default result;
