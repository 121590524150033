import { Component, Input } from "@angular/core";

import { MatDialog } from "@angular/material/dialog";
import { Notification } from "../../../gql/generated";
import { DialogActivityComponent } from "../../dialogs/dialog-activity/dialog-activity";
import { cimToPlannedWork } from "../../../helpers/subscription/planned-work";

@Component({
  selector: "activity-container",
  templateUrl: "activity-container.html",
})
export class ActivityContainer {
  @Input() messages: Notification[] = [];
  constructor(public dialog: MatDialog) {}

  openDialog(message: Notification) {
    // TODO #206 Address technical debt Notifications/Messages/PlannedWorks
    const subscription_message = cimToPlannedWork(message);
    const dialogRef = this.dialog.open(DialogActivityComponent, {
      data: subscription_message,
    });
  }

  trackByFn(index, item) {
    return item.id;
  }
}
