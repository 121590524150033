import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { AccessRules, rulesAreAvailable } from "../../helpers/userroles";
import { AuthService } from "../../services/AuthService";

const NONPRODUCT_ROUTES = [
  ["/cert", "cert"],
  ["/ip-prefixes", "IP_PREFIX"],
  ["/support", "support"],
  ["/storing-en-onderhoud", "maintenance"],
  ["/notification-settings", "notification_settings"],
];

const rulesIdentifierForPath = (path: string): string | null =>
  NONPRODUCT_ROUTES.find(([route, productType]) => path.startsWith(route))?.[1];

@Injectable({
  providedIn: "root",
})
export class AuthGuard {
  constructor(
    private auth: AuthService,
    private router: Router,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<true | UrlTree> {
    let productType = route.paramMap.get("productType");
    let rules: any[] = [];

    if (!productType) {
      // attempt to get the rules identifier another way
      productType = rulesIdentifierForPath(state.url);
    }

    if (productType && rulesAreAvailable(productType)) {
      rules.push(...AccessRules[productType].view, ...AccessRules[productType].edit);
    }

    // wait for the auth service to load the userdata.
    // on a page reload this guard is called too soon,
    // so it always redirects to the dashboard even though
    // the user has access.
    return new Promise<true | UrlTree>(async (resolve) => {
      // no rules present for this route
      if (!rules.length) {
        resolve(true);
        return;
      }

      if (!this.auth.state.currentUser.roles) {
        await this.auth.__waaait();
      }

      const roleCheckResult = this.auth.checkRoleAccess(productType, "view");
      if (roleCheckResult.ok) {
        resolve(true);
      } else {
        this.auth.roleEvent.emit(roleCheckResult);
        resolve(this.router.parseUrl("/forbidden"));
      }
    });
  }
}
