import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as pMap from "p-map";
import { SelfServiceCommand } from "../../../helpers/self-service/self-service-command";
import { ApiHelper } from "../../../helpers/apihelper";
import { SelfServiceCommandKeys } from "../../../helpers/enums/selfServiceCommands";
import { ModifyErrorDetail, ModifyResponse } from "../../../helpers/self-service/models/types";
import { SubscriptionService } from "../../../services/subscriptionservice";
import { isValidVlan } from "../../../helpers/self-service/vlanhelper";
import { ModifyLightPath, ModifyRedundantLightPath } from "../../../helpers/self-service/modify_lightpath";
import { HttpErrorResponse } from "@angular/common/http";
import { ServicePort } from "../selfservice-add-port/ServicePort";
import { AuthService } from "../../../services/AuthService";
import { transformError } from "../../../helpers/self-service/transform-error";
import { LightPathBothSubscriptionDetails } from "../../../pages/subscription-detail/types";

@Component({
  selector: "selfservice-vlan-lightpath",
  templateUrl: "selfservice-vlan-lightpath.html",
  styleUrls: ["../../selfservice/selfservice-add-port/selfservice-add-port.scss"],
})
export class SelfserviceVlanLightpath implements OnInit {
  @Input() currentStep = 1;
  @Output() dialogCloseEmitter = new EventEmitter<string>();
  @Input() subscription: LightPathBothSubscriptionDetails;
  @Input() bus: EventEmitter<any>;

  public hasErrors = false;
  public errors: ModifyErrorDetail[] = [];
  public buttonLoading = false;
  public loadingData = false;
  public endpoints: any[] = [];
  public processId: string;

  constructor(
    public api: ApiHelper,
    public subscriptionService: SubscriptionService,
    public auth: AuthService,
  ) {}

  ngOnInit() {
    this.loadingData = true;
    this.loadPortDetails(this.subscription.circuits[0].endpoints);
  }

  async loadPortDetails(endpoints: any[]) {
    const mapper = async (endpoint) => {
      const usedVlans = endpoint.port.usedVlans.filter((v: string) => v !== null && v !== endpoint.vlanrange);
      return {
        ...endpoint,
        vlan: endpoint.vlanrange,
        port: { ...endpoint.port, usedVlans },
      };
    };
    this.endpoints = await pMap(endpoints, mapper, { concurrency: 2 });
    this.loadingData = false;
  }

  errorsAtIndex(index: number): boolean {
    let hasErrors = false;
    this.errors.map((error: ModifyErrorDetail) => {
      if (error.loc.length > 1 && Number(error.loc[1]) === index) {
        hasErrors = true;
      }
    });
    return hasErrors;
  }

  prev() {
    this.currentStep--;
  }

  next() {
    // reset error messages
    this.errors = [];
    this.hasErrors = false;

    this.currentStep++;
  }

  isMyPort(port: ServicePort): boolean {
    return port.customer.customerId === this.auth.state.viewingCustomer.customerId;
  }

  validVlan(port: ServicePort, vlanString: string, currentVlan: string) {
    return vlanString === currentVlan || isValidVlan(port, vlanString);
  }

  /**
   * Quick check to see if the vlans match.
   * This is only important for values with
   * multiple vlans (1,2,3 or 1-3).
   * In case of single vlans, just return true
   * since only multiple vlans need to be identical.
   *
   * @returns boolean
   */
  vlanMatches() {
    let onlySingles = true;
    const check: Set<string> = new Set(
      this.endpoints.map((circuit) => {
        if (circuit.vlan.match(/[^0-9]/)) {
          onlySingles = false;
        }
        return circuit.vlan;
      }),
    );
    return onlySingles ? true : check.size === 1;
  }

  /**
   * Locate the index of our circuit
   * in the list of circuits in the subscription
   * by checking the port subscriptions in it.
   *
   * @returns number
   */
  findMyCircuit(): number {
    let index = 0;
    const myPorts = this.subscription.circuits[0].endpoints.map((endpoint) => endpoint.port.subscriptionId);
    for (let i = 0; i < this.subscription.circuits.length; i++) {
      const ports = this.subscription.circuits[i].endpoints.map((endpoint) => endpoint.port.subscriptionId);
      if (myPorts.every((p) => ports.includes(p))) {
        index = i;
        break;
      }
    }
    return index;
  }

  submit() {
    this.buttonLoading = true;

    const circuitIndex = this.findMyCircuit();
    const modify = this.subscription.product.tag === "LP" ? new ModifyLightPath() : new ModifyRedundantLightPath();
    const commandKey =
      this.subscription.product.tag === "LP" ?
        SelfServiceCommandKeys.ModifyLightpath
      : SelfServiceCommandKeys.ModifyRedundantLightpath;

    const command = new SelfServiceCommand(this.subscription.subscriptionId, commandKey);
    modify.subscription = this.subscription;
    modify.updateCircuit(
      circuitIndex,
      this.endpoints.map((endpoint) => {
        const vlan = endpoint.vlan === "" ? endpoint.vlanrange : endpoint.vlan;
        return {
          vlan,
          subscription_id: endpoint.port.subscriptionId,
        };
      }),
    );
    command.payload = modify;

    // reset error div trigger
    this.hasErrors = false;

    this.api
      .selfServiceCommand(command)
      .then((response) => {
        this.processId = (response as ModifyResponse).pid;
        this.bus.emit({
          processId: this.processId,
          action: SelfServiceCommandKeys.ModifyLightpath,
        });
      })
      .catch((err) => {
        this.onApiError(err);
        this.buttonLoading = false;
      });
  }

  onApiError(err: HttpErrorResponse) {
    try {
      this.errors = transformError(err.error.detail);
      this.hasErrors = true;
    } catch (e) {
      console.log("unknown error", err);
    }
  }
}
