export class FulfilmentRequest {
  static FULFILMENT_SERVICE_REQUEST_PROBLEM = "problem";
  static FULFILMENT_SERVICE_REQUEST_CREATE = "create";
  static FULFILMENT_SERVICE_REQUEST_MODIFY = "modify";
  static FULFILMENT_SERVICE_REQUEST_DELETE = "delete";

  static FULFILMENT_PRODUCT_LIGHTPATH = "LightPath";
  static FULFILMENT_PRODUCT_L2VPN = "L2VPN";
  static FULFILMENT_PRODUCT_PORT = "Port";
  static FULFILMENT_PRODUCT_IP = "IP";
  static FULFILMENT_PRODUCT_FIREWALL = "Firewall";

  name: string;
  contacts: any[];
  product: string;
  serviceRequest: string;
  subscriptionId: string;
  text: string;
  complete: boolean;
  error: boolean; // indicate failure during api call
  errors: any[];

  validators: any = {
    fullName: {
      empty: false,
      description: "Naam",
      regex: false,
    },
    email: {
      empty: false,
      description: "Email",
      regex:
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
    },
    phone: {
      empty: false,
      description: "Telefoonnummer",
      regex: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
    },
  };

  constructor() {
    this.complete = false;
    this.contacts = [
      {
        email: "",
        fullName: "",
        phone: "",
      },
    ];
    this.errors = [];
  }

  validateFields() {
    this.errors = [];

    for (const f of ["fullName", "email", "phone", "text"]) {
      if (this.contacts[0][f] === undefined) {
        this.contacts[0][f] = "";
      }

      if (f === "text" && this.text === undefined) {
        this.text = "";
        this.errors.push({ key: "text", message: "Bericht is verplicht" });
      }

      if (this.validators[f]) {
        const validator = this.validators[f];
        if (!validator.empty && this.contacts[0][f] === "") {
          this.errors.push({
            key: f,
            message: `${validator.description} is verplicht`,
          });
        }

        if (validator.regex && !validator.regex.test(this.contacts[0][f])) {
          // !(new RegExp(validator.regex).test(this.contacts[0][f])) ) {
          this.errors.push({
            key: f,
            message: `${validator.description} is een ongeldige waarde`,
          });
        }
      }
    }

    console.log(this.errors);

    return this.errors.length === 0;
  }

  hasErrorForField(field: string) {
    return this.errors.some((el) => el.key === field);
  }
}

// "product": "LightPath",
// "contacts": [
//    {
//        "email": "string",
//        "fullName": "string",
//        "phone": "string"
//    }
// ],
