import { CustomerDescription, SubscriptionInterface, Notification } from "../gql/generated";

export interface CustomerSubscriptionWithId {
  ipPrefix: any;
  subscriptionId: string;
  customerId: string;
  description: string;
  customerDescriptions: Pick<CustomerDescription, "customerId" | "description">[];
}

export interface CustomerWithSubscriptionIds {
  customer: {
    subscriptions: CustomerSubscriptionWithId[];
  };
}

export enum CimQueryImpactTypes {
  DOWN = "DOWN",
  REDUCED_REDUNDANCY = "REDUCED_REDUNDANCY",
  RESILIENCE_LOSS = "RESILIENCE_LOSS",
  NO_IMPACT = "NO_IMPACT",
  UNKNOWN = "UNKNOWN",
}

export enum CimQueryStatuses {
  NEW = "NEW",
  OPEN_RELATED = "OPEN_RELATED",
  OPEN_ACCEPTED = "OPEN_ACCEPTED",
  OPEN = "OPEN",
  UPDATED = "UPDATED",
  ABORTED = "ABORTED",
  CLOSED = "CLOSED",
}

export enum GqlTypes {
  AGGSP = "Sn8AggregatedServicePortSubscription",
  SP = "Sn8ServicePortSubscription",
  MSC = "Sn8MscSubscription",
  IRB = "Sn8IrbServicePortSubscription",
  FW = "FwSubscription",
  IPS = "Sn8IpStaticSubscription",
  IPBGP = "Sn8IpBgpSubscription",
  L3VPN = "Sn8L3VpnSubscription",
  L2VPN = "Sn8L2VpnSubscription",
  LP = "Sn8LightPathSubscription",
  LR = "Sn8LightPathRedundantSubscription",
  WIRELESS = "SurfWirelessSubscription",
}

export enum CimStatus {
  ABORTED = "Aborted",
  OPEN = "Open",
  CLOSED = "Closed",
}

export enum CimImpactType {
  NONE = "No_impact",
  DOWN = "Down",
  RESILIENCE_LOSS = "Resilience_loss",
  REDUCED_REDUNDANCY = "Reduced_redundancy",
  UNKNOWN = "Unknown",
}

export interface SubscriptionWithDdosStatus {
  insync: boolean;
  subscriptionId: string;
  __typename: string;
  vc?: {
    settings: {
      surfcertFilterEnabled: boolean;
    };
  };
  firewall?: {
    ipGwEndpoint: {
      ip: {
        settings: {
          surfcertFilterEnabled: boolean;
        };
      };
    };
  };
}

export interface CimResult {
  notifications: Notification[];
}

export interface ListPageResult {
  customer: {
    id: string;
    subscriptions: SubscriptionInterface[];
  };
}

export interface SubscriptionStatusResult {
  subscription: {
    subscriptionId: string;
    status: string;
  };
}

export interface SubscriptionTypeResult {
  subscription: {
    subscriptionId: string;
    product: {
      type: string;
    };
  };
}
