<loader *ngIf="!subscription"></loader>
<div *ngIf="subscription" class="availability-export">
  <div class="container">
    <header class="availability-export__header">
      <div class="availability-export__header-left">
        <div *ngIf="productType.length > 0" class="availability-export__header-icon">
          <svg-icon src="assets/images/icons/{{productType.toLowerCase()}}.svg"></svg-icon>
        </div>
        <div>
          <div class="availability-export__header-subtitle">{{ 'SlsReport.Footer.Title' | translate }}</div>
          <div class="availability-export__header-title">
            {{ subscription && customerDescriptionExists ? subscription.customerDescriptions[subscription.customerId] :
            subscription?.description }}
          </div>
        </div>
      </div>
      <div class="availability-export__header-right">
        <button class="button button--secondary" (click)="print()">
          <span>Print</span>
          <svg-icon src="assets/images/icons/print.svg"></svg-icon>
        </button>
      </div>
    </header>
    <section>
      <table>
        <thead>
          <tr>
            <th colspan="4">Service details</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th width="16%">{{ 'ServiceElement.Owner' | translate }}</th>
            <td width="34%">{{ subscription?.organisation }}</td>
            <th width="16%">{{ 'ServiceElement.Start' | translate }}</th>
            <td width="34%">{{ subscription?.startDate * 1000 | date:'y-MM-dd hh:mm' }}</td>
          </tr>
          <tr>
            <th>{{ 'ServiceElement.OwnAlias' | translate }}</th>
            <td>
              {{ subscription?.customerDescriptions ? subscription.customerDescriptions[subscription.customerId] : '-'
              }}
            </td>
            <th>{{ 'ServiceElement.SURFnetDescription' | translate }}</th>
            <td>{{ subscription?.description }}</td>
          </tr>
          <tr>
            <th>{{ 'ServiceElement.ServiceID' | translate }}</th>
            <td>{{ subscription?.subscriptionId | shorten_guid }}</td>
            <th>{{ 'ServiceElement.ServiceSpeed' | translate }}</th>
            <td>{{ subscription?.serviceSpeed * 1000000 | readable_size }}</td>
          </tr>
          <tr>
            <th>{{ 'ServiceElement.Domain' | translate }}</th>
            <td>{{ subscription?.domain }}</td>
            <th>URL</th>
            <td>{{ url }}</td>
          </tr>
        </tbody>
      </table>

      <h2>{{ 'Page.AvailabilityExport.Heading1' | translate }}</h2>
      <div *ngIf="!slsReport">{{ 'Page.AvailabilityExport.NotFound' | translate }}</div>
      <table *ngIf="slsReport">
        <thead>
          <tr>
            <th width="16%">{{ 'Page.AvailabilityExport.Month' | translate }}</th>
            <th width="16%">{{ 'Page.AvailabilityExport.Availability' | translate }}</th>
            <th width="68%">Service tickets</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Last year</td>
            <td>{{ slsReport.yearAvailability }}%</td>
            <td>&nbsp;</td>
          </tr>
          <tr *ngFor="let row of slsReport.months">
            <td>{{ row.date.getMonth() | monthname:3 }}, {{ row.date.getFullYear() }}</td>
            <td>{{ row.availability }}%</td>
            <td>
              <div *ngFor="let event of row.events">
                <strong [innerHtml]="event.description | sls_event_description | safe:'html'"></strong>
                <span>
                  &nbsp;{{ 'SlsReport.Event.From' | translate }}&nbsp; {{ event.startTime * 1000 | date: 'yyyy-MM-dd
                  HH:mm:ss' }} - {{ event.endTime * 1000 | date: 'yyyy-MM-dd HH:mm:ss' }}
                </span>
                <div><strong>Impact:</strong> {{ event.impact }}</div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
    <div style="margin-top: 48px">
      <strong>{{ 'SlsReport.Footer.Title' | translate }}</strong> - {{ subscription?.description }} -- {{
      'SlsReport.Footer.Generated' | translate }} {{ currentDate | date: 'yyy-MM-dd HH:mm:ss' }}
    </div>
  </div>
</div>
