export const getFilteredLocation = (locations, subscriptions) => {
  const filteredSubscriptions = subscriptions.filter((subscription) => {
    // make search case insensitive
    locations = locations.map((loc) => loc.toLowerCase());
    const locationMatches = subscription.locations.filter((l) =>
      locations.some(
        (loc) => `${l.address?.city?.toLowerCase()} (${l.address?.street?.toLowerCase()} ${l.address?.number})` === loc,
      ),
    );
    return locationMatches.length > 0;
  });
  return filteredSubscriptions;
};
