import { TranslateService } from "@ngx-translate/core";

export const translateString = async (
  translate: TranslateService,
  key: string,
  defaultValue: string,
): Promise<string> =>
  translate
    .get(key)
    .toPromise()
    .then((a: string) => a)
    .catch((e) => defaultValue);
