import { humanizeMbit } from "../../../helpers/bitspipe/bitspipe";
import { getPortId, toPortImsInfo, toPortMode, transformShared } from "../port";

const mscPortSpeed = (subscription: any): number => {
  const usedByPortSpeed = (usedBy: any): number => {
    const subscriptionType = usedBy.subscriptionType;
    switch (subscriptionType) {
      case "Sn8L3VpnSubscription":
        return mscL3vpnPortSpeed(subscription.subscriptionId, usedBy);
      default:
        console.warn(`Unknown portspeed for subscription ${subscriptionType}`);
        return 0;
    }
  };

  if (!subscription.inUseBy) {
    return 0;
  }
  const speeds: number[] = subscription.inUseBy.map((value) => usedByPortSpeed(value));
  return speeds.reduce((acc, value) => acc + value, 0);
};

const mscL3vpnPortSpeed = (subscriptionId: any, usedBy: any): number => {
  const vc = usedBy.vc;
  const saps = vc.saps;
  const count = saps.filter((sap) => sap.sap.portSubscriptionId === subscriptionId).length;
  return Number(vc.serviceSpeed || 0) * count;
};

export const transformMsc = (subscription: any) => {
  const port = subscription.port;
  const domain = subscription.domain;
  const portSpeed = mscPortSpeed(subscription);
  const portId = getPortId(domain, port);

  function* getTags() {
    if (portSpeed) {
      yield humanizeMbit(portSpeed);
    }
    yield toPortMode(port.portMode);
  }

  const transformedSubscription = transformShared(subscription, getTags());

  const { surfLocationCode, address, locationDescription } = toPortImsInfo(subscription);

  return {
    ...transformedSubscription,
    name: port?.name ?? "Missing data",
    ieeeInterfaceType: "Virtual Port",
    portMode: "Tagged",
    surfLocationCode,
    portSpeed,
    portId,
    subscriptionInstanceId: port?.subscriptionInstanceId ?? "Missing data",
    portSubscription: port?.portSubscriptionId ?? "Missing data",
    serviceTag: port?.serviceTag ?? "Missing data",
    address,
    locationDescription,
  };
};
