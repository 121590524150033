<div *ngIf="firstNotification !== null" [routerLink]="[getRouterLink()]">
  <div class="service__status-wrapper service--{{notificationColor}}">
    <span class="service-status__icon">
      <svg-icon src="assets/images/icons/{{notificationIcon}}.svg"></svg-icon>
    </span>
    <div class="service-status__caption">
      <span class="service-status__time" *ngIf="isNowActive(firstNotification) === false">
        {{ firstNotification.start_timestamp | date:'d MMM' }}, {{ firstNotification.start_timestamp | date:'hh:mm' }}
        {{ 'Global.Until' | translate }} {{ firstNotification.end_timestamp | date:'hh:mm' }}
      </span>
      <span class="service-status__title">{{ firstNotification.heading }}</span>
      <span> - </span>
      <span class="service-status__impacttype">{{ impactType | translate }}</span>
    </div>
  </div>
</div>
