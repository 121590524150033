import { gql } from "apollo-angular";
import {
  ProductFragment,
  SubscriptionDetailsFragment,
  ptpIpamInfoFragment,
  Sn8SapBlockInfoFragment,
} from "./fragments";

export const IpBgpSubscriptionFragment = gql`
  ${ProductFragment}
  ${SubscriptionDetailsFragment}
  ${ptpIpamInfoFragment}
  ${Sn8SapBlockInfoFragment}
  fragment IpBgpSubscriptionDetails on SubscriptionInterface {
    ... on Sn8IpBgpSubscription {
      ...SubscriptionDetails
      ...Product
      domain
      ipRoutingType

      vc {
        subscriptionInstanceId
        name

        serviceSpeed

        settings {
          surfcertFilterEnabled
          surfcertFilter
          subscriptionInstanceId
          name
          multicast
          asn
          pinPrefix {
            ownerSubscriptionId
          }
        }

        saps {
          subscriptionInstanceId
          name
          bfd
          bfdMinimumInterval
          bfdMultiplier
          bgpExportPolicy
          bgpHashAlgorithm
          bgpPassword
          bgpSessionPriority
          customerIpv4Mtu
          customerIpv6Mtu
          prefixes {
            ownerSubscriptionId
          }
          ptpIpv4Ipam {
            ...ptpIpamInfo
          }

          ptpIpv6Ipam {
            ...ptpIpamInfo
          }

          sap {
            ...Sn8SapBlockInfo
          }
        }
      }
    }
  }
`;
