import { SelfServiceCommands } from "../enums/selfServiceCommands";
import { ModifyBfdCommand } from "./modify_bfd";
import { ModifyBgpCommand } from "./modify_bgp";
import { ModifyDdosFilterCommand } from "./modify_ddos_filter";
import { ModifyIpPrefixes } from "./modify_ip_prefixes";
import { ModifyL2VPNSpeedPolicer, ModifyL2VPNVlan, ModifyL2VPNAddPort, ModifyL2VPNRemovePort } from "./modify_l2vpn";
import { ModifyLightPath, ModifyRedundantLightPath } from "./modify_lightpath";

export type SelfServicePayloads =
  | ModifyDdosFilterCommand
  | ModifyBfdCommand
  | ModifyBgpCommand
  | ModifyIpPrefixes
  | ModifyLightPath
  | ModifyL2VPNSpeedPolicer
  | ModifyL2VPNVlan
  | ModifyL2VPNAddPort
  | ModifyL2VPNRemovePort
  | ModifyRedundantLightPath;

export class SelfServiceCommand {
  protected subscriptionId: string;
  private action: SelfServiceCommands;
  private details: SelfServicePayloads;

  constructor(subscriptionId: string, action: SelfServiceCommands) {
    this.action = action;
    this.subscriptionId = subscriptionId;
  }

  get json(): string {
    const ret = {
      subscription_id: this.subscriptionId,
      action: this.action,
      details: this.details.payload,
    };

    return JSON.stringify(ret);
  }

  /**
   * This is overridden in e.g. ResendConfirmation,
   * which has a different URL path that includes
   * the subscriptionID.
   */
  get uri() {
    return "";
  }

  set payload(payload: SelfServicePayloads) {
    this.details = payload;
  }
}
