import { gql } from "apollo-angular";

// class AirtScan:
//     id: str = strawberry.field(description="Unique AIRT Scan record identifier")
//     imported_at: str = strawberry.field(description="Datetime of import")
//     model_version: str = strawberry.field(description="Mongo collection version")
//     classification: JSON | None = strawberry.field(
//         default=None, description="Contents of Intel MQ Classification field"
//     )
//     destination: JSON | None = strawberry.field(default=None, description="Contents of Intel MQ Destination field")
//     extra: JSON | None = strawberry.field(default=None, description="Contents of Intel MQ Extra field")
//     feed: JSON | None = strawberry.field(default=None, description="Contents of Intel MQ Feed field")
//     malware: JSON | None = strawberry.field(default=None, description="Contents of Intel MQ Malware field")
//     source: JSON | None = strawberry.field(default=None, description="Contents of Intel MQ Source field")
//     time: JSON | None = strawberry.field(default=None, description="Contents of Intel MQ Time field")
//     protocol: JSON | None = strawberry.field(default=None, description="Contents of Intel MQ Protocol field")

export const getOrder = (key: string) => {
  if (key === "CRITICAL") {
    return 0;
  }
  if (key === "HIGH") {
    return 1;
  }
  if (key === "MEDIUM") {
    return 2;
  }
  if (key === "LOW") {
    return 3;
  }
  if (key === "INFO") {
    return 4;
  }
};

export const chartStats = (edges: any[]) => {
  function* transports() {
    for (const edge of edges) {
      yield edge.node.classification?.identifier || "Unknown";
    }
  }
  function* classifications() {
    for (const edge of edges) {
      yield edge.node.severity || "INFO";
    }
  }

  const t = [...transports()];
  const c = [...classifications()];
  const percentageOf = (value: number) => Number(((value / c.length) * 100).toFixed(1));
  return {
    transports: [...new Set(t)].map((key) => ({
      name: key,
      value: t.filter((item) => item === key).length,
    })),
    classifications: [...new Set(c)].map((key) => ({
      name: key,
      order: getOrder(key),
      value: percentageOf(c.filter((item) => item === key).length),
      percentage: percentageOf(c.filter((item) => item === key).length),
    })),
  };
};

export const AirtDetailsFragment = gql`
  fragment AirtScanDetails on AirtScan {
    id
    importedAt
    modelVersion
    classification
    destination
    extra
    feed
    malware
    source
    time
    protocol
    numberOfHits
    lastSeen
    firstSeen
    severity
  }
`;
