import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { DialogMessageComponent } from "../components/dialogs/dialog-message/dialog-message";

@Injectable()
export class ConfirmationDialogService {
  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
  ) {}
  public confirm(callback: any): void {
    const dialogRef = this.dialog.open(DialogMessageComponent, {
      data: {
        title: this.translate.instant("Global.Confirm.Title"),
        message: this.translate.instant("Global.Confirm.Message"),
        isConfirm: true,
      },
    });
    dialogRef.componentInstance.emitter.subscribe((bool) => {
      if (bool) {
        callback();
      }
    });
  }
}
