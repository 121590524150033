/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { StrictHttpResponse } from "../../strict-http-response";
import { RequestBuilder } from "../../request-builder";

import { NameserverCreate } from "../../models/nameserver-create";
import { NameserverRead } from "../../models/nameserver-read";
import { Status } from "../../models/status";

export interface CreateNameserver$Params {
  organisation_id: string;
  token?: string | null;
  check?: boolean;
  body: NameserverCreate;
}

export function createNameserver(
  http: HttpClient,
  rootUrl: string,
  params: CreateNameserver$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<NameserverRead | Status>> {
  const rb = new RequestBuilder(rootUrl, createNameserver.PATH, "post");
  if (params) {
    rb.path("organisation_id", params.organisation_id, {});
    rb.query("token", params.token, {});
    rb.query("check", params.check, {});
    rb.body(params.body, "application/json");
  }

  // add this header so we are able to examine the failure response
  // otherwise it's intercepted by the http interceptor
  rb.header("x-ignore-interceptor", "1");

  return http.request(rb.build({ responseType: "json", accept: "application/json", context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<NameserverRead | Status>;
    }),
  );
}

createNameserver.PATH = "/organisations/{organisation_id}/nameservers/";
