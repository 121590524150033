import { ApiHelper } from "./apihelper";

export class SurfExceptionHandler {
  constructor(private api: ApiHelper) {}

  static redirectToErrorPage(err: any): void {
    // window.location.pathname = '/error';
  }

  static redirectToForbiddenPage(err: any): void {
    window.location.pathname = "/forbidden";
  }

  static redirectToNotfoundPage(err: any): void {
    window.location.pathname = "/notfound";
  }

  handleError(err: any): void {
    // send exception and data to API
    console.error("%cCaught: %c%s", "color: red; font-weight: bold", "color: purple", err.message);
    if (err.status >= 500 && err.status < 600) {
      this.api.pushError(err.message);
      SurfExceptionHandler.redirectToErrorPage(err);
    }
  }
}
