import { transform } from "../../helpers/subscription/l3vpn";
import { Instance } from "./instance";
import { L2VPNInstance } from "./l2vpninstance";

export class L3VPNInstance extends Instance {
  constructor(data?: any) {
    super();
    const details = transform(data);
    Object.entries(details).forEach((entry) => {
      this[entry[0]] = entry[1];
    });
    this.customerDescriptions = data.customerDescriptions;
  }
}
