import { Component, Input } from "@angular/core";

@Component({
  selector: "loader",
  templateUrl: "loader.html",
  styleUrls: ["loader.scss"],
})
export class LoaderComponent {
  @Input() small = false;
  @Input() detailed = false;
  @Input() text = "";
  @Input() paddingSize = "medium";
  @Input() iconOnly = false;
}
