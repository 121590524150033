/**
 * The base config model.
 */

export class Configuration {
  redirectUri: string;
  oauthEnabled: boolean;
  oauthAuthorizeUrl: string;
  clientId: string;
  loa: string;
}
