<button
  [matTooltip]="impactDescription"
  matTooltipPosition="above"
  class="button button--secondary"
  [ngClass]="{ 'active' : isActive }"
  (click)="openNotificationSettingsDialog()"
>
  <svg-icon *ngIf="isActive" src="assets/images/icons/notification-set.svg"></svg-icon>
  <svg-icon *ngIf="!isActive" src="assets/images/icons/notification-off.svg"></svg-icon>
</button>
