import { ModifyErrorDetail } from "./models/types";

interface GqlError {
  error: {
    detail:
      | string
      | {
          msg: string;
          details: string;
        };
  };
}

const isGqlError = (v): v is GqlError =>
  v.hasOwnProperty("error") &&
  v.error.hasOwnProperty("detail") &&
  ((v.error.detail.hasOwnProperty("msg") && v.error.detail.hasOwnProperty("details")) ||
    typeof v.error.detail === "string");

const getGqlError = (v: GqlError): string => (typeof v.error.detail === "string" ? v.error.detail : v.error.detail.msg);

export const transformError = (v: GqlError | ModifyErrorDetail[] | string): ModifyErrorDetail[] => {
  if (typeof v === "string") {
    return [{ loc: [], type: "", msg: v }];
  } else if (isGqlError(v)) {
    return [{ loc: [], type: "", msg: getGqlError(v) }];
  }
  return v;
};
