import { translate } from "./tags";
import { ProductType } from "../enums/productType";
import { gqlToSubscription, toProduct, transformCustomerDescriptions } from "./shared";

const _to_location = (wifi_location) => ({
  name: wifi_location?.["name"],
  address: wifi_location?.["address"],
  city: wifi_location?.["city"],
  country: wifi_location?.["country"],
  institute: wifi_location?.["institute"],
  // TODO: stats does not exist anymore, see nwd-api for fix, but then again, this is not even used here
  active_access_points: 666, // wifi_location["stats"]["active"],
  inactive_access_points: 666, // wifi_location["stats"]["inactive"],
});

const _to_access_point = (accessPoint) => ({
  name: accessPoint["name"],
  key: accessPoint["key"],
  model: accessPoint["model"],
  brand: accessPoint["brand"],
  firmware_version: accessPoint["firmwareVersion"],
  serial_number: accessPoint["serialNumber"],
  supplier: accessPoint["supplier"],
  ci_status: accessPoint["ciStatus"],
});

export const transform = (detail: any) => {
  if (!detail) {
    return {};
  }

  const product = detail["product"];
  const organisation = detail["customer"];
  const wifi_location = detail["wifiLocation"];
  const product_tag = product["tag"];
  const tags = translate(ProductType.Wireless, [product_tag]);

  let subscription = gqlToSubscription(detail, tags);

  let access_points = [];
  if (wifi_location?.detail?.accessPoints !== undefined) {
    wifi_location["detail"]["accessPoints"].map((accessPoint) => {
      if (["Active", "Stopped"].includes(accessPoint["ciStatus"])) {
        access_points.push(_to_access_point(accessPoint));
      }
    });
  }

  return {
    ...subscription,
    customerDescriptions: transformCustomerDescriptions(detail["customerDescriptions"]),
    product: toProduct(product),
    organisation: organisation["name"],
    supplier: wifi_location["apVendorName"],
    sensorPresent: wifi_location["sensorPresent"],
    location: {
      ..._to_location(wifi_location["detail"]),
      access_points,
    },
  };
};
