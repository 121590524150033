/**
 * Created by gert on 22/03/2018.
 */

export class CircuitEndpoint {
  label = "";
  address: any = {};
  name = "";
  organisation = "";
  port: any = {};
  startDate = 0;
  status = "";
  subscriptionId = "";
  subscriptionInstanceId = "";

  get isPrimary() {
    return this.hasOwnProperty("label") && this.label.indexOf("Primary") === 0;
  }
}
