<div class="service-summary" [ngClass]="{'service-summary--force-column': forceTrafficBottom}">
  <div class="service-summary__main">
    <div class="service-summary__header">
      <div class="service-summary__icon service-summary__icon--{{ icon }}">
        <svg-icon src="assets/images/icons/{{ icon }}.svg"></svg-icon>
        <span *ngIf="service?.firewallEnabled && icon !== 'fw'" class="service-summary__icon-overlap-square">
          <svg-icon src="assets/images/icons/fw.svg" class="service-tile__overlap-icon"></svg-icon>
        </span>
      </div>
      <div class="service-summary__header-right">
        <div class="service-summary__title-wrapper">
          <svg-icon src="assets/images/icons/status-{{ healthData?.status | status_to_icon }}.svg"></svg-icon>
          <h3 class="service-summary__title">{{ service | custom_description }}</h3>
        </div>
        <div class="tags">
          <div *ngFor="let tag of service?.tags" class="tag">{{ tag }}</div>
        </div>
      </div>
    </div>
    <div class="service-summary__table">
      <div class="service-summary__table-row">
        <div class="service-summary__table-col">{{ 'ServiceElement.Owner' | translate }}</div>
        <div class="service-summary__table-col">{{ organisationName }}</div>
      </div>
      <div class="service-summary__table-row">
        <div class="service-summary__table-col">
          <span *ngIf="service?.product?.type === 'Port'">{{ 'ServiceElement.PortID' | translate }}</span>
          <span *ngIf="service?.product?.type !== 'Port'">{{ 'ServiceElement.ServiceID' | translate }}</span>
        </div>
        <div class="service-summary__table-col">{{ service?.subscriptionId | shorten_guid }}</div>
      </div>
      <div
        class="service-summary__table-row"
        *ngIf="vlanranges && vlanranges.length > 0 && !this.service?.product?.tag?.startsWith('MSC')"
      >
        <div class="service-summary__table-col">{{ 'ServiceElement.VLANID' | translate }}</div>
        <div class="service-summary__table-col">
          <div *ngFor="let vlanrange of vlanranges;">
            <span *ngIf="vlanrange && vlanrange !== 0">{{ vlanrange }}</span>
            <span *ngIf="!vlanrange || vlanrange === 0">{{ 'Subscription.UntaggedPort' | translate }}</span>
          </div>
        </div>
      </div>
      <div class="service-summary__table-row" *ngIf="service?.port?.serviceTag">
        <div class="service-summary__table-col">{{ 'ServiceElement.ServiceTag' | translate }}</div>
        <div class="service-summary__table-col">{{ service?.port?.serviceTag }}</div>
      </div>
      <div class="service-summary__table-row" *ngIf="locations.length > 0">
        <div class="service-summary__table-col">
          {{ (locations.length === 1 ? 'ServiceElement.Location' : 'ServiceElement.Locations') | translate }}
        </div>
        <div class="service-summary__table-col service-summary__table-col--flex-newline">
          <span *ngFor="let location of locations">{{ location }}</span>
        </div>
      </div>
      <div class="service-summary__table-row" *ngIf="service?.ipGateway">
        <div class="service-summary__table-col">{{ 'ServiceElement.ServiceSpeed' | translate }}</div>
        <div class="service-summary__table-col">{{ service?.ipGateway.serviceSpeed * 1000000 | readable_size }}</div>
      </div>
    </div>
    <port-visualiser [unavailable]="!isAccessible" [subscription]="service" *ngIf="service?.product?.type === 'Port'" />
    <div class="service-summary__bottom">
      <div
        class="button button--secondary service-summary__scroll-button"
        (click)="navigateToChart(400)"
        *ngIf="showTrafficButton"
      >
        {{ 'Global.ShowTraffic' | translate }}
      </div>

      <div
        *ngIf="showMoreDetailsButton && isAccessible"
        class="button button--secondary"
        [routerLink]="routerLinkForService(service)"
      >
        {{ 'Global.MoreDetails' | translate }}
      </div>
    </div>
  </div>
  <div class="service-summary__speed" *ngIf="traffic && service?.product?.type === 'FW'">
    <h3>{{ 'ServiceElement.Traffic' | translate }}</h3>

    <div class="service-summary__speed--block">
      <div class="service-summary__speed--block-title">
        <svg-icon src="assets/images/icons/arrow-long.svg" class="down"></svg-icon>
        <span>{{ 'ServiceElement.TrafficIn' | translate }}</span>
      </div>
      <div class="service-summary__speed--block-speed">
        <span>{{ traffic.down.speed }}</span>
        <span>{{ traffic.down.unit }}</span>
      </div>
    </div>

    <div class="service-summary__speed--block">
      <div class="service-summary__speed--block-title">
        <svg-icon src="assets/images/icons/arrow-long.svg" class="up"></svg-icon>
        <span>{{ 'ServiceElement.TrafficOut' | translate }}</span>
      </div>
      <div class="service-summary__speed--block-speed">
        <span>{{ traffic.up.speed }}</span>
        <span>{{ traffic.up.unit }}</span>
      </div>
    </div>
  </div>
</div>
