export type SelfServiceCommands =
  | "modify_lightpath"
  | "modify_redundant_lightpath"
  | "modify_ddos_filter"
  | "modify_ipprefix_ipbgp"
  | "modify_ipprefix_ips"
  | "modify_bfd"
  | "modify_bgp_priority"
  | "modify_change_vlan_l2vpn"
  | "modify_add_port_l2vpn"
  | "modify_remove_port_l2vpn"
  | "modify_speed_policer_l2vpn"
  | "resend_confirmation";

export enum SelfServiceCommandKeys {
  ModifyLightpath = "modify_lightpath",
  ModifyRedundantLightpath = "modify_redundant_lightpath",
  ModifyDdosFilter = "modify_ddos_filter",
  ModifyIpPrefixesIpBgp = "modify_ipprefix_ipbgp",
  ModifyIpPrefixesIpStatic = "modify_ipprefix_ips",
  ModifyBfd = "modify_bfd",
  ModifyBgpPriority = "modify_bgp_priority",
  ModifyL2VpnVlan = "modify_change_vlan_l2vpn",
  ModifyL2VpnSpeedPolicer = "modify_speed_policer_l2vpn",
  ModifyL2VpnAddPort = "modify_add_port_l2vpn",
  ModifyL2VpnRemovePort = "modify_remove_port_l2vpn",
  ResendConfirmation = "resend_confirmation",
}
