export class HealthIndicator {
  _description: string;
  status: string;
  delay: number;
  jitter: number;
  name: string;
  traffic: any;

  get description(): string {
    return this._description;
  }

  set description(str: string) {
    this._description = str.toLowerCase().replace(" ", "");
  }
}
