/**
 * Auth service
 *
 * AuthService::load() will be run on app init.
 *
 */

import { Output, EventEmitter, NgModule, isDevMode, inject } from "@angular/core";
import { ApiHelper } from "../helpers/apihelper";
import { SurfLog } from "../helpers/surflog";
import { CurrentUser } from "../components/models/currentuser";
import { LocationHashValues } from "../components/models/locationhashvalues";
import { AccessRules, RoleCheckResult, rulesAreAvailable } from "../helpers/userroles";
import { ENV } from "../app/app.runtime";
import { SelfserviceDialogType } from "../helpers/self-service/models/types";
import { ReplaySubject } from "rxjs";

// @Directive()
@NgModule({
  providers: [ApiHelper],
})
export class AuthService {
  @Output() basicInfoReady: EventEmitter<any> = new EventEmitter();
  @Output() userLoaded: ReplaySubject<boolean> = new ReplaySubject();
  @Output() healthLoaded: EventEmitter<any> = new EventEmitter();
  @Output() statsLoaded: EventEmitter<any> = new EventEmitter();
  @Output() reloadEvent: EventEmitter<any> = new EventEmitter();
  @Output() errorEvent: EventEmitter<any> = new EventEmitter();
  @Output() roleEvent: EventEmitter<any> = new EventEmitter();

  public state: {
    authentication_tries: number;
    loading: boolean;
    currentUser: any;
    configuration: any;
    organisations: any[];
    locationCodes: any[];
    products: any[];
    viewingCustomer: any;
    subscriptions: { Internet: []; LightPath: []; L2VPN: []; Port: [] };
    healthIndicators: any[];
    notifications: any[];
    error: boolean;
    errorDialogOpen: boolean;
    errorQueue: any[];
    redirectState: string;
    returnToPage: string;
    strongPreAuthAction: string;
  };
  public settings: any;
  private devMode = true;
  private MAX_AUTHENTICATION_TRIES = 2;

  constructor(
    private api: ApiHelper,
    // private matomo: MatomoTracker,
  ) {
    this.state = {
      authentication_tries: 0,
      loading: true,
      currentUser: {},
      configuration: {},
      organisations: [],
      locationCodes: [],
      products: [],
      viewingCustomer: {},
      subscriptions: { Internet: [], LightPath: [], L2VPN: [], Port: [] },
      healthIndicators: [],
      notifications: [],
      error: false,
      errorDialogOpen: false,
      errorQueue: [],
      redirectState: "/#",
      returnToPage: "",
      strongPreAuthAction: "",
    };

    this.settings = {};

    api.loadingEvent.subscribe((event) => {
      if (event.id === "finish_aggregated_stats") {
        this.statsLoaded.emit(event.subscriptionId);
      }
    });

    api.errorEvent.subscribe((event) => {
      this.state.errorQueue.push(event);
      this.state.error = true;
    });

    const env = isDevMode() ? "DEV" : "PROD";
    // this requires a custom dimensions plugin for Matomo (serverside),
    // but seems to be preferred over custom variables.
    // see: https://matomo.org/docs/custom-dimensions
    // this.matomo.setCustomDimension(1, env);

    // scope=visit -> save in cookie for this visitor
    // scope=page -> only save for the current pageview
    // https://matomo.org/docs/custom-variables/
    // if (window["_paq"]) {
    //   this.matomo.setCustomVariable(1, "Environment", env, "visit");
    // }
  }

  get isSelfServiceEnabled(): boolean {
    return ENV.SELFSERVICE_ENABLED;
  }

  get selfServiceTokenExpiryTime(): number {
    let expiry = 0;
    if (this.isAuthenticatedForSelfService()) {
      const validUntil = Number.parseInt(localStorage.getItem("strong_access_token_expiry"));
      const expiryDate = new Date(validUntil);
      expiry = expiryDate.getTime();
    }
    return expiry;
  }

  get viewingCustomerId(): string {
    return this.state.viewingCustomer.customerId;
  }

  /**
   * Manipulate the current state
   *
   * @param obj any
   */
  setState(obj: any) {
    for (const key in obj) {
      if (this.state.hasOwnProperty(key)) {
        this.state[key] = obj[key];
      }
    }
  }

  parseHash(): LocationHashValues {
    const values: LocationHashValues = {
      access_token: "",
      state: {},
      token_expires: 0,
    };
    const accessTokenMatch = window.location.hash.match(/access_token=(.*?)&/);
    const stateMatch = window.location.hash.match(/state=(.*?)(&|$)/);
    const expiryMatch = window.location.hash.match(/expires_in=([0-9]*)/);
    if (accessTokenMatch) {
      values.access_token = accessTokenMatch[1];
    }
    if (stateMatch) {
      values.state = JSON.parse(atob(stateMatch[1].replace(/%3d/gi, "=")));
    }
    if (expiryMatch) {
      const isRegularSignin = values.state.authType === "signin";
      // the regular token has an expiry time that's fine.
      // the strong access token should not be valid for 24h,.
      // We initially set it to 60 minutes, forcing the user to reauth after that.
      const expiry = isRegularSignin ? Number.parseInt(expiryMatch[1]) - 300 : 3600;
      values.token_expires = new Date(new Date().getTime() + expiry * 1000).getTime();
    }
    return values;
  }

  /**
   * This is called on app start.
   * So, on a refresh, or first visit to the app.
   * It checks the existence of an access_token.
   * If not found and oauth is enabled, the user
   * will be redirected to the provided oauth url.
   */
  load() {
    const hash = window.location.hash;
    const hashValues = this.parseHash();

    this.trackAuthenticationTries(hash);
    if (hashValues.access_token !== "") {
      // currently in the login process.
      // auth has been handled and we are on
      // the redirect_uri.

      if (Object.keys(hashValues.state).length) {
        // nested a bit too deep I think.
        // we need 'state' now, because of a new
        // authentication type for 'strong'.
        try {
          if (hashValues.state.authType === "signin") {
            localStorage.setItem("access_token", hashValues.access_token);
          } else {
            localStorage.setItem("strong_access_token", hashValues.access_token);
            localStorage.setItem("strong_access_token_expiry", hashValues.token_expires.toString());
            localStorage.setItem("strong_pending", "yes");
            localStorage.setItem("strong_preauth_action", hashValues.state.initialAction);
          }
        } catch (e) {
          console.error("Could not parse returned state", e);
        }
        this.setState({ redirectState: hashValues.state.location });
        window.location.replace(window.location.origin + this.state.redirectState);
      }
    } else if (
      window.location.href.indexOf("error") > -1 ||
      window.location.href.indexOf("forbidden") > -1 ||
      window.location.href.indexOf("notfound") > -1
    ) {
      // do not authenticate again for these pages.
      this.setState({ loading: false });
    } else {
      // no redirect_uri but a regular dashboard page.
      // here we check for an access token and redirect
      // to the authentication server if needed.
      const accessToken = localStorage.getItem("access_token");
      if (!accessToken) {
        this.api
          .config()
          .catch((err) => {
            this.handleBackendDown(err);
            return Promise.reject(err.message);
          })
          .then((conf) => {
            if (conf) {
              if (window.location.href.startsWith("http://localhost")) {
                conf.redirectUri = "http://localhost:4200/oauth2/callback";
                // conf.oauthEnabled = false;
              }

              this.setState({ configuration: conf });

              if (this.state.authentication_tries > this.MAX_AUTHENTICATION_TRIES) {
                SurfLog.err("Too many authentication tries");
                this.handleBackendDown("auth");
              }

              if (conf.oauthEnabled === true) {
                console.log("Redirect to AUTH server");
                this.setState({ returnToPage: window.location.href });
                this.redirectToAuthorizationServer();
              } else {
                this.fetchUser();
              }
            }
          });

        return;
      } else {
        // access token present. just load userdata.
        this.fetchUser();
      }
    }
  }

  /**
   * Retrieve basic user info.
   * If the stored access_token is invalid, it is removed from
   * the localStorage, and load() is called again, redirecting
   * the user to the oauth login URL.
   */
  fetchUser() {
    if (window.location.href.indexOf("error") > -1) {
      this.setState({ loading: false });
      return;
    }

    const redirectLocation = localStorage.getItem("redirect_location");
    if (redirectLocation) {
      // redirect if this is not the same location
      if (window.location.href !== redirectLocation) {
        window.location.replace(redirectLocation);
      }
      localStorage.setItem("redirect_location", "");
    }

    this.api
      .config()
      .catch((err) => {
        this.handleBackendDown(err);
        return Promise.reject(err);
      })
      .then((configuration) => {
        this.setState({ configuration });

        this.api
          .me()
          .then((user) => {
            if (this.devMode && user.displayName === "Anonymous") {
              const props = {
                authenticatingAuthority: "",
                authorities: [],
                displayName: "Gert van der Weyde",
                eduPersonPrincipalName: "gert@gravity.nl",
                email: "gert@gravity.nl",
                customerId: "ccadb9d1-0911-e511-80d0-005056956c1a",
                organizationName: "Gravity",
                teams: ["noc_superuserro_team_for_netwerkdashboard"],
                roles: [
                  "SuperuserRO",
                  "Infraverantwoordelijke",
                  "Beveiligingsverantwoordelijke",
                  "SURFwireless-beheerder",
                ],
                // roles: ["SuperuserRO", "Infraverantwoordelijke", "Infrabeheerder", "Beveiligingsverantwoordelijke", "SURFwireless-beheerder", "Domeinnamenverantwoordelijke", "DNS beheerder"],
              };
              user = Object.assign(new CurrentUser(), props);
            }

            // this.matomo.setUserId(`${user.displayName} (${user.organizationName})`);

            if (user && user.displayName) {
              this.setState({
                currentUser: user,
              });
              const hasRoles = (user.roles ?? []).length > 0;
              const hasTeams = (user.teams ?? []).length > 0;

              if (!this.isSuperUserRO() && !hasRoles) {
                this.roleEvent.emit({
                  event: "no-roles",
                  need: [],
                });
              }

              if (user.viewingOrganizationGUID != null) {
                const guid = user.viewingOrganizationGUID;

                // let others know that the most basic info has been
                // loaded.
                this.basicInfoReady.emit("ready");

                const promises: any[] = [];
                localStorage.setItem("currentuser", guid);

                if (user.canSwitch && (hasRoles || hasTeams)) {
                  promises.push(this.api.customers());
                }

                if (promises.length === 0) {
                  return;
                }

                Promise.all(promises)
                  .then((result) => {
                    const [customers] = result;

                    this.setState({
                      loading: false,
                      organisations: customers,
                    });
                    // re-store the viewing-customer object in the state
                    this.setViewingCustomer(guid);
                    this.userLoaded.next(true);
                  })
                  .catch((err) => {
                    if (err) {
                      switch (err.status) {
                        case 403:
                          // redirect to /login if this call results
                          // in a 403.
                          if (window.location.href.indexOf("forbidden") === -1) {
                            window.location.pathname = "/forbidden";
                          }
                          break;
                        case 500:
                        case 503:
                        case 504:
                          console.error("Backend reported: " + err.message);
                      }
                    }
                  });
              } else {
                // no requests to be done.
                // set loading to false.
                this.setState({ loading: false });
              }
            } else {
              this.handleBackendDown("No userinfo given.");
            }
          })
          .catch((err) => {
            if (!err) {
              return;
            }

            if (err.status === 401) {
              localStorage.removeItem("access_token");
              this.load();
            } else if (err.status === 403) {
              this.state.currentUser.organizationName = "unknown";
              this.state.currentUser.roles = [];
              this.roleEvent.emit({ event: "no-organization" });
            } else {
            }
          });
      });
  }

  __waaait() {
    return new Promise((resolve) => {
      this.basicInfoReady.subscribe(() => {
        resolve(null);
      });
    });
  }

  switchViewingCustomer(guid: string, redirectLocation: string) {
    this.setViewingCustomer(guid);

    if (redirectLocation !== "") {
      window.location.pathname = redirectLocation;
    } else {
      window.location.reload();
    }
  }

  setViewingCustomer(guid: string) {
    const oldValue = localStorage.getItem("viewingCustomerGUID");
    const newOrganisation = this.state.organisations?.find((o) => o.customerId === guid);

    if (newOrganisation) {
      this.setState({
        viewingCustomer: newOrganisation,
      });
      // also save in localstorage, so models
      // that do not have access to the auth service
      // can find it.
      localStorage.setItem("viewingCustomerGUID", newOrganisation.customerId);
    }
  }

  customerNameForId(guid: string) {
    const customer = this.state.organisations?.filter((e) => e.customerId === guid);
    if (customer.length > 0) {
      return customer[0].name;
    }
    return "";
  }

  isCurrentOrganisation(guid: string) {
    return guid === localStorage.getItem("viewingCustomerGUID");
  }

  /**
   * Redirect the user to the oauth login url provided by the user config.
   * The current state (/#/whatever) is sent with the request. It is also returned
   * to /oauth/callback by the login server, so we can redirect
   * the user back to where they were.
   */
  redirectToAuthorizationServer(authType = "signin", initialAction = "") {
    const re = /http[s]?:\/\/?[^/\s]+\/(.*)/;
    const res = re.exec(window.location.href);
    const state = JSON.stringify({
      location: res ? "/" + res[1] : "/",
      authType,
      initialAction,
    });

    this.setState({
      authentication_tries: this.state.authentication_tries + 1,
    });

    this.api.config().then((conf) => {
      if (window.location.href.startsWith("http://localhost")) {
        conf.redirectUri = "http://localhost:4200/oauth2/callback";
      }
      if (window.location.href.startsWith("http://127.0.0.1")) {
        conf.redirectUri = "http://127.0.0.1:4200/oauth2/callback";
      }

      const authorizeUrl = conf.oauthAuthorizeUrl;
      const clientId = conf.clientId;
      const loa = conf.loa;
      const responseType = "token id_token";
      const nonce = this.generateNonce();

      localStorage.setItem("redirect_location", window.location.href);
      const reUrl =
        authType === "signin" ?
          `${authorizeUrl}?response_type=${responseType}&client_id=${clientId}` +
          `&redirect_uri=${conf.redirectUri}&state=${btoa(state)}&scope=openid+profile` +
          `&nonce=${nonce}`
        : `${authorizeUrl}?response_type=${responseType}&client_id=${clientId}` +
          `&redirect_uri=${conf.redirectUri}&state=${btoa(state)}&scope=openid+profile` +
          `&nonce=${nonce}` +
          `&acr_values=${loa}`;
      window.location.replace(reUrl);
    });
  }

  trackAuthenticationTries(hash: any) {
    const retryMatch = hash.match(/tries=([0-9])/);
    const tryCount = retryMatch !== null ? retryMatch[1] : 0;
    this.setState({ authentication_tries: tryCount });
  }

  generateNonce() {
    const typedArray = new Uint32Array(1);
    window.crypto.getRandomValues(typedArray);
    return typedArray[0];
  }

  isAuthenticatedForSelfService(): boolean {
    const token = localStorage.getItem("strong_access_token");
    const validUntil = localStorage.getItem("strong_access_token_expiry");
    const expired = Number.parseInt(validUntil) < new Date().getTime();

    if (validUntil && token) {
      if (expired) {
        localStorage.removeItem("strong_access_token");
        localStorage.removeItem("strong_access_token_expiry");
      } else {
        return true;
      }
    }

    return false;
  }

  hasPendingStrongAction(type: string) {
    return localStorage.getItem("strong_preauth_action") === type;
  }
  getPendingStrongAction(): SelfserviceDialogType {
    return localStorage.getItem("strong_preauth_action") as SelfserviceDialogType;
  }

  clearPendingStrongAction(type: string) {
    if (this.hasPendingStrongAction(type)) {
      localStorage.removeItem("strong_preauth_action");
      localStorage.removeItem("strong_pending");
    }
  }

  getSelfserviceState() {
    let selfserviceState = localStorage.getItem("selfservice_state");
    return JSON.parse(selfserviceState);
  }

  requestSelfserviceDialog(type: string) {
    localStorage.setItem("request_selfservice_dialog", type);
  }

  hasRequestedSelfServiceDialog(): string {
    const dialogType = localStorage.getItem("request_selfservice_dialog");
    localStorage.removeItem("request_selfservice_dialog");
    return dialogType;
  }

  handleBackendDown = (err) => {
    window.location.pathname = "/error";
  };

  dismissErrors() {
    this.state.errorQueue = [];
    this.state.error = false;
  }

  isSuperUserRO() {
    let ro_teams = ["surfcert-kernel", "noc-fls", "noc-engineers"];
    if (
      this.state.currentUser &&
      ((this.state.currentUser?.roles || []).includes("SuperuserRO") ||
        this.state.currentUser?.teams?.filter((x: string) => ro_teams.includes(x)).length > 0)
    ) {
      return true;
    }
    return false;
  }

  /**
   * Test if the current authenticated user has any (or all) of the given roles.
   *
   * @param roles List of roles to check
   * @param all If true, all roles must be present
   * @returns boolean
   */
  hasRole(roles: string[], all = false): boolean {
    if (all) {
      return roles.every(
        (role) => this.state.currentUser.roles?.includes(role) || this.state.currentUser.teams?.includes(role),
      );
    }

    return roles.some(
      (role) => this.state.currentUser.roles?.includes(role) || this.state.currentUser.teams?.includes(role),
    );
  }

  getRoleNames() {
    return this.state.currentUser.roles?.join(", ");
  }

  checkRoleAccess(productType: string, action: "view" | "edit"): RoleCheckResult {
    const result: RoleCheckResult = {
      ok: false,
      event: "requirements-not-met",
      need: [],
      productType,
      requestedAction: action,
    };

    if (!rulesAreAvailable(productType)) {
      // case: this product type is not specified in the roles list
      return result;
    }
    if (AccessRules[productType][action]) {
      // case: rules are defined for this action
      result.need = AccessRules[productType][action];
      result.ok = this.hasRole(result.need);
    } else {
      // case: no rules defined for this action
      result.ok = true;
    }

    if (result.ok) {
      result.event = "requirements-met";
    }

    return result;
  }
}
