import { Injectable } from "@angular/core";
import * as Rx from "rxjs";
import { Observable, Subject } from "rxjs";

@Injectable()
export class WebsocketService {
  private subject: Rx.Subject<MessageEvent>;

  constructor() {}

  public connect(url): Rx.Subject<MessageEvent> {
    if (!this.subject) {
      this.subject = this.create(url);
      console.log("Successfully connected: " + url);
    }
    return this.subject;
  }

  public close() {
    // test
    this.subject.complete();
    this.subject = null;
  }

  private create(url): Rx.Subject<MessageEvent> {
    let ws = new WebSocket(url);

    let observable = new Observable((obs: Rx.Observer<MessageEvent>) => {
      ws.onmessage = obs.next.bind(obs);
      ws.onerror = obs.error.bind(obs);
      ws.onclose = obs.complete.bind(obs);
      return ws.close.bind(ws);
    });

    let observer = {
      next: (data: object) => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify(data));
        }
      },
      error: (err: any) => {
        console.group("ws error");
        console.log(err);
        console.groupEnd();
      },
      complete: () => {
        this.subject.error("Webservice closed");
      },
    };

    const subject = new Subject<MessageEvent>();
    subject.subscribe(observer);
    observable.subscribe(subject);
    return subject;
  }
}
