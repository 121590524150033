<navigation [activeItem]="1" [requestsBubble]="3"></navigation>
<div class="container">
  <div class="row">
    <div class="col-lg-3">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-12">
          <activity-container [messages]="customerActivities"></activity-container>
        </div>
        <div class="col-12 col-md-6 col-lg-12">
          <notification-container [messages]="customerNotifications"></notification-container>
        </div>
      </div>
    </div>
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-8 col--flex">
          <combined-service-tile [subscriptions]="topSubscriptions"></combined-service-tile>
        </div>
        <div class="col-lg-4">
          <div *ngFor="let subscription of sideSubscriptions | keyvalue: asIsOrder">
            <div *ngIf="subscription.value.available && subscription.value.available === true; else validSubscription">
              <service-tile-available
                serviceUrl="{{ subscription.value.url }}"
                serviceTitle="{{  ('Subscription.' + subscription.key + '.Name.Tile') | translate }}"
                serviceBody="{{  ('Subscription.' + subscription.key.toString() + '.Description') | translate }}"
                [serviceIcon]="subscription.key | lowercase"
              >
              </service-tile-available>
            </div>
            <ng-template #validSubscription>
              <service-tile
                [serviceUrl]="subscription.key"
                serviceTitle="{{  ('Subscription.' + subscription.key + '.Name.Tile') | translate }}"
                [serviceIcon]="subscription.key | lowercase"
                [serviceMessage]="subscription.value.messages && subscription.value.messages[0]"
                [healthIndicators]="subscription.value.counts"
                [isLoading]="subscription.value.loading === undefined || subscription.value.loading === true ? true : false"
                [firewallCount]="subscription.value.firewallsEnabled"
                [subscriptionCount]="subscription.value.subscriptions"
                [serviceMessages]="subscription.value.messages"
                [serviceMessageAmount]="customerActivities.length"
                [wirelessEnabled]="wirelessEnabled"
                [vulnerabilitiesDetectedCount]="subscription.value.vulnerabilitiesDetectedCount"
              >
              </service-tile>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="row">
        <div *ngFor="let subscription of bottomSubscriptions | keyvalue: asIsOrder" class="col-md-6 col-lg-4 col--flex">
          <div *ngIf="subscription.value.available && subscription.value.available === true; else validSubscription">
            <service-tile-available
              serviceUrl="{{ subscription.value.url }}"
              serviceTitle="{{  ('Subscription.' + subscription.key + '.Name.Tile') | translate }}"
              serviceBody="{{  ('Subscription.' + subscription.key.toString() + '.Description') | translate }}"
              [serviceIcon]="subscription.key | lowercase"
            >
            </service-tile-available>
          </div>

          <ng-template #validSubscription>
            <service-tile
              [serviceUrl]="subscription.key"
              serviceTitle="{{  ('Subscription.' + subscription.key + '.Name.Tile') | translate }}"
              [serviceIcon]="subscription.key | lowercase"
              [serviceMessage]="subscription.value.messages && subscription.value.messages[0]"
              [healthIndicators]="subscription.value.counts"
              [isLoading]="subscription.value.loading === undefined || subscription.value.loading === true ? true : false"
              [firewallCount]="subscription.value.firewallsEnabled"
              [subscriptionCount]="subscription.value.subscriptions"
              [serviceMessages]="subscription.value.messages"
              [serviceMessageAmount]="customerActivities.length"
              [wirelessEnabled]="wirelessEnabled"
              [vulnerabilitiesDetectedCount]="subscription.value.vulnerabilitiesDetectedCount"
            >
            </service-tile>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <footer></footer>
</div>
