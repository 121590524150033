import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";

import { AuthService } from "../../services/AuthService";
import { ApiHelper } from "../../helpers/apihelper";
import { SurfNotification } from "../../components/models/notification";
import { ProductType } from "../../helpers/enums/productType";
import { SubscriptionService } from "../../services/subscriptionservice";
import { SubscriptionCount } from "../../helpers/types";
import { ENV } from "../../app/app.runtime";
import * as pMap from "p-map";

@Component({
  selector: "page-messagecenter",
  templateUrl: "messagecenter.html",
  styleUrls: ["./messagecenter.scss"],
})
export class MessagecenterPage {
  public notifications: SurfNotification[] = [];
  public activeNotification: SurfNotification;
  public activeMessage: string;
  public requestedMessageId: string;
  public productTypes: Array<ProductType> = [
    ProductType.IP,
    ProductType.LightPath,
    ProductType.L2VPN,
    ProductType.Port,
  ];
  public apiConfig = {
    health: true,
    all: true,
    active_messages: ENV.FILTER_ACTIVE_MESSAGES,
  };

  constructor(
    public auth: AuthService,
    private api: ApiHelper,
    private route: ActivatedRoute,
    private router: Router,
    private subscriptionService: SubscriptionService,
  ) {
    this.requestedMessageId = this.route.snapshot.paramMap.get("id");
    if (!auth.state.loading) {
      this.updateMessages();
    }
  }

  async updateMessages() {
    if (this.auth.state.notifications.length > 0) {
      // assume they've been loaded on the dashboard.
      this.notifications = this.auth.state.notifications;
    }

    await this.fetchMessages();
    if (this.activeNotification === undefined) {
      this.activeNotification = this.notifications[0];
      this.activeMessage =
        this.activeNotification?.contents !== undefined ?
          this.activeNotification.contents
        : "Dit bericht heeft geen tekst.";
    }
  }

  async fetchMessages() {
    const mapper = async (productType) => {
      await this.subscriptionService
        .getSubscriptionCounts(productType, this.apiConfig)
        .then((subscription: SubscriptionCount) => {
          this.notifications = [
            ...this.notifications,
            ...subscription.messages
              .map((elem) => Object.assign(new SurfNotification(), elem))
              .sort((a, b) => a.timestamp - b.timestamp),
          ];
        });
    };
    await pMap(this.productTypes, mapper, { concurrency: 2 });
    console.log(this.notifications);
  }

  setActiveNotification(index: number) {
    if (this.notifications.length >= index - 1) {
      this.activeNotification = this.notifications[index];

      // set the contents in a separate variable, so
      // the markdown component notices the change :(
      this.activeMessage =
        this.activeNotification.contents !== undefined ?
          this.activeNotification.contents
        : "Dit bericht heeft geen tekst.";
    }
  }

  isActive(id: number) {
    return id === this.activeNotification?.id;
  }

  segueToService(affectedService: any) {
    this.router.navigate([`/subscription/${affectedService.subscription_id}`]);
  }
}
