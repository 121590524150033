<div class="dialog dialog-notification-settings">
  <div>
    <div class="dialog__header">
      <h3 class="dialog__title">{{ 'Notification.NotificationsFor' | translate }} {{ name }}</h3>
      <svg-icon src="assets/images/icons/x.svg" class="dialog__close" (click)="closeDialog()"></svg-icon>
    </div>
    <div class="dialog__body">
      <h3>{{ 'Notification.SendNotificationForState' | translate }}</h3>
      <div class="dialog-notification-settings__options">
        <label>
          <input type="radio" value="Downtime" name="notificationSetting" [(ngModel)]="notificationSetting" />
          <div>
            <i></i>
            <span>{{ 'Notification.Status.Downtime' | translate }}</span>
          </div>
        </label>
        <label>
          <input type="radio" value="Loss of Resiliency" name="notificationSetting" [(ngModel)]="notificationSetting" />
          <div>
            <i></i>
            <span>{{ 'Notification.Status.LossofResiliency' | translate }}</span>
          </div>
        </label>
        <label>
          <input type="radio" value="Reduced Redundancy" name="notificationSetting" [(ngModel)]="notificationSetting" />
          <div>
            <i></i>
            <span>{{ 'Notification.Status.ReducedRedundancy' | translate }}</span>
          </div>
        </label>
        <label>
          <input type="radio" value="Never" name="notificationSetting" [(ngModel)]="notificationSetting" />
          <div>
            <i></i>
            <span>{{ 'Notification.Status.Never' | translate }}</span>
          </div>
        </label>
      </div>
      <div class="dialog-notification-settings__bottom">
        <div>
          <span *ngIf="!auth?.state?.currentUser?.roles.includes('Infraverantwoordelijke')">
            {{ 'Notification.MinimalModifyRole' | translate }}
          </span>
        </div>
        <div>
          <span
            *ngIf="!auth?.state?.currentUser?.roles.includes('Infraverantwoordelijke'); then disabled_button else active_button"
          ></span>
          <ng-template #active_button>
            <button class="button button--primary" (click)="saveSettings()">
              {{ 'Notification.SaveSetting' | translate }}
            </button>
          </ng-template>
          <ng-template #disabled_button>
            <button class="button button--disabled" [disabled]>{{ 'Notification.SaveSetting' | translate }}</button>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
