<div class="page-tab">
  <div class="page-tab__dividers">
    <span></span>
    <span></span>
    <span></span>
  </div>
  <div class="page-tab__buttons">
    <button class="page-tab__button" (click)="changeTab('scan')" [ngClass]="{'active': activeTab === 'scan' }">
      {{ 'Tabs.Scan' | translate }}
    </button>
    <button class="page-tab__button" (click)="changeTab('ddos')" [ngClass]="{'active': activeTab === 'ddos' }">
      {{ 'Tabs.DDOS' | translate }}
    </button>
  </div>
</div>
