import { Instance } from "../../components/models/instance";
import { GqlTypes } from "../../graphql/custom-types";
import { SurfLog } from "../surflog";
import { first, repeat, zip } from "./misc";
import { transformAggsp } from "./port/transform-aggsp";
import { transformMsc } from "./port/transform-msc";
import { transformSp } from "./port/transform-sp";
import {
  gqlToDate,
  gqlToSubscription,
  IrbPortSpeed,
  toProduct,
  toVisitingAddress,
  transformCustomerDescriptions,
} from "./shared";
import { translate } from "./tags";

export const getPortId = (domain: string, ptp: any): number => {
  if (["SURFNET7", "NETHERLIGHT7"].includes(domain)) {
    try {
      return Number(ptp.portId);
    } catch (e) {
      return 0;
    }
  }
  return 0;
};

const EMPTY_ADDRESS_DATA = {
  street: "Missing data",
  number: "Missing data",
  zipcode: "Missing data",
  city: "Missing data",
  addition: "Missing data",
  countryCode: "Missing data",
  extraAddressLine: "Missing data",
};

const EMPTY_IMS_DATA = {
  patchPosition: "Missing data",
  fiberType: "Missing data",
  connectorType: "Missing data",
  ieeeInterfaceType: "Missing data",
  speed: "Missing data",
  aliases: "Missing data",
  locationDescription: "Missing data",
  surfLocationCode: "Missing data",
  address: EMPTY_ADDRESS_DATA,
  location: {
    uuid: "Missing data",
    name: "Missing data",
    code: "Missing data",
    addresses: [EMPTY_ADDRESS_DATA],
  },
};

export const getImsInfo = (portSubscription) => {
  switch (portSubscription.__typename) {
    case "Sn8MscSubscription":
    case "Sn8AggregatedServicePortSubscription":
      const innerPort = portSubscription?.aggspPort || portSubscription?.port || portSubscription?.linkmembers;
      return innerPort?.ims?.endpoints?.[0]?.endpoints?.[0] || {};
    default:
      return portSubscription?.port?.ims?.port;
  }
};

const fakeCache = {};

export const toPortImsInfo = (portSubscription: any) => {
  const imsPort = getImsInfo(portSubscription);
  const location = imsPort?.location;

  if (!location?.uuid) {
    // using a "cache" because multiple updates somehow removes the ims data.
    // tried fixing it but can't find the problem, we won't have this weird side effect with ingestor.
    return fakeCache[portSubscription.subscriptionId] || EMPTY_IMS_DATA;
  }
  const port = {
    ...imsPort,
    patchPosition: imsPort?.patchposition || "",
    fiberType: imsPort?.fiberType || "",
    connectorType: imsPort?.connectorType || "",
    ieeeInterfaceType: imsPort?.speed || "",
    address: toVisitingAddress(location),
    locationDescription: location?.name,
    surfLocationCode: location?.code,
  };
  fakeCache[portSubscription.subscriptionId] = port;
  return port;
};

export const toServicePortShared = (port: any) => ({
  ...port,
  ...toPortImsInfo(port),
  portSubscriptionInstanceId: port.subscriptionInstanceId,
});

export const toPortMode = (mode: string): string => {
  switch (mode) {
    case "tagged":
      return "Tagged";
    case "untagged":
      return "Untagged";
    case "link_member":
      return "Link Member";
    default:
      return "N/A";
  }
};

export const toServicePort = (port: any) => {
  const imsPort = port.port.ims.port;
  const { address, locationDescription } = toServicePortShared(port);
  return {
    ...toServicePortShared(port),
    autoNegotiation: port.auto_negotiation || "N/A",
    portMode: toPortMode(port.port.portMode),
    portSpeed: port.portSpeed,
    patchPosition: imsPort.patchPosition,
    connectorType: imsPort.connectorType,
    fiberType: imsPort.fiberType,
    ieeeInterfaceType: imsPort.ifaceType,
    surfLocationCode: imsPort.location,
    address,
    organisation: port.customer?.name,
    locationDescription,
  };
};

const toAggSpServicePort = (port: any) => {
  // changed this (from `port.port = aggspPort`), because the supplied
  // parameter is read-only.

  const newPort = { ...port };
  const aggspPort = "linkmembers" in port ? port.linkmembers : port.port;
  const linkMembers = aggspPort.port;
  newPort.port = aggspPort;
  const portSpeed = linkMembers?.reduce((prev: number, cur: any) => prev + Number(cur.portSpeed || 0), 0);

  return {
    ...toServicePortShared(newPort),
    portSpeed,
    portMode: toPortMode(aggspPort.portMode),
    organisation: port.customer?.name,
  };
};

const toIrbServicePort = (port: any) => ({
  ...toServicePortShared(port),
  portSpeed: IrbPortSpeed,
  portMode: "Tagged",
});

const toMscServicePort = (port: any) => {
  let portSpeed = port?.port?.portSpeed || -1;
  switch (port?.port?.subscriptionType) {
    case GqlTypes.AGGSP:
      portSpeed = port?.port?.port?.port?.reduce((acc, member: any) => acc + member.portSpeed, 0);
      break;
    case GqlTypes.IRB:
      portSpeed = IrbPortSpeed;
      break;
  }
  return {
    ...toServicePortShared(port),
    portSpeed,
    portMode: "Tagged",
  };
};

export const toPort = (port: any) => {
  switch (port.product?.tag) {
    case "AGGSP":
    case "AGGSPNL":
      return toAggSpServicePort(port);
    case "SP":
    case "SPNL":
      return toServicePort(port);
    case "IRBSP":
      return toIrbServicePort(port);
    case "MSC":
    case "MSCNL":
      return toMscServicePort(port);
    default:
      SurfLog.err(`Unknown port tag ${port.product?.tag}`);
  }
};

function* transformServices(subscriptionId: string, services: any[]) {
  for (const service of services) {
    const product = toProduct(service.product);
    for (const sap of [...findSapsByPortSubscriptionId(service, subscriptionId)]) {
      yield {
        subscriptionId: service.subscriptionId,
        organisation: service.customer.name,
        product,
        status: service.status,
        insync: service.insync,
        description: service.description,
        customerId: service.customerId,
        startDate: gqlToDate(service.startDate),
        serviceSpeed: sap.service_speed,
        vlanrange: sap.vlanrange,
        portSubscriptionInstanceId: sap.subscriptionInstanceId,
      };
    }
  }
}

function* findSapsByPortSubscriptionId(service: any, subscriptionId: string) {
  const findSaps = (product: any) => {
    switch (product.tag) {
      case "FW":
        return _fwFindSaps;
      case "IPBGP":
        return _ipBgpFindSaps;
      case "IPS":
        return _ipStaticFindSaps;
      case "L2VPN":
        return _L2VpnFindSaps;
      case "L3VPN":
        return _L3VpnFindSaps;
      case "LP":
        return _LpFindSaps;
      case "LR":
        return _LrFindSaps;
      case "MSC":
      case "MSCNL":
        return _MSCFindSaps;
      case "NSISTP":
      case "NSISTPNL":
        return _NSISTPFindSaps;
      default:
        console.warn(`findSapsByPortSubscriptionId: no suitable method found. tag=${product.tag}`);
        return null;
    }
  };

  const sapGetter = findSaps(service.product);
  if (typeof sapGetter === "function") {
    const saps = [...sapGetter(service)];
    for (const [sap, serviceSpeed] of saps) {
      if (sap.portSubscriptionId === subscriptionId) {
        yield {
          subscriptionInstanceId: sap.subscriptionInstanceId,
          vlanrange: sap.vlanrange,
          service_speed: serviceSpeed,
        };
      }
    }
  }
}

function* _ipBgpFindSaps(service: any): Generator<any> {
  const vc = service.vc;
  for (const rv of [
    ...zip(
      vc.saps.map((sap) => sap.sap),
      repeat(vc.serviceSpeed),
    ),
  ]) {
    yield rv; // should be: [sap, serviceSpeed]
  }
}
function* _ipStaticFindSaps(service: any) {
  const vc = service.vc;
  yield [vc.sap.sap, vc.serviceSpeed];
}
function* _L2VpnFindSaps(service: any) {
  const vc = service.vc;
  for (const esi of vc.esis) {
    for (const rv of [...zip(esi.saps, repeat(vc.serviceSpeed))]) {
      yield rv;
    }
  }
}
function* _L3VpnFindSaps(service: any) {
  const vc = service.vpnvc;
  for (const rv of [
    ...zip(
      vc.saps.map((sap) => sap.sap),
      repeat(vc.serviceSpeed),
    ),
  ]) {
    yield rv;
  }
}
function* _LpFindSaps(service: any) {
  const vc = service.vc;
  for (const rv of [
    ...zip(
      vc.saps, // .map((sap) => sap.sap),
      repeat(vc.serviceSpeed),
    ),
  ]) {
    yield rv;
  }
}
function* _LrFindSaps(service: any) {
  for (const vc of service.lrss.vcs) {
    for (const rv of [...zip(vc.saps, repeat(vc.serviceSpeed))]) {
      yield rv;
    }
  }
}
function* _MSCFindSaps(service: any) {
  yield [service.port, 0];
}
function* _NSISTPFindSaps(service: any) {
  yield [service.settings.sap, 0];
}

function* _fwFindSaps(service: any) {
  const firewall = service.firewall;

  function* l2vpnInternalGenerator(l2vpn: any) {
    const serviceSpeed = l2vpn.serviceSpeed;
    for (const esi of l2vpn.esis) {
      for (const rv of [...zip(esi.saps, repeat(serviceSpeed))]) {
        yield rv;
      }
    }
  }

  const ipGw = firewall.ipGwEndpoint;
  if (ipGw) {
    const ip = ipGw.ip;
    for (const l2internal of [...l2vpnInternalGenerator(ipGw.l2vpnInternal)]) {
      yield l2internal;
    }
    for (const rv of [
      ...zip(
        ip.saps.map((sap) => sap.sap),
        repeat(ip.serviceSpeed),
      ),
    ]) {
      yield rv;
    }
  }

  for (const l2Endpoint of firewall.l2Endpoints) {
    for (const l2Esi of l2Endpoint.esis) {
      for (const rv of [...zip(l2Esi.saps, repeat(777))]) {
        yield rv;
      }
    }
  }

  for (const l3Endpoint of firewall.l3Endpoints) {
    for (const l2internal of [...l2vpnInternalGenerator(l3Endpoint.l2vpnInternal)]) {
      yield l2internal;
    }
    for (const rv of [...zip(l3Endpoint.saps, repeat(666))]) {
      yield rv;
    }
  }
}

/**
 * Transform the common parts of a PortSubscription.
 *
 * @param subscription Subscription object
 * @param _getTags Generator for tags (list of strings)
 * @returns Object
 */
export const transformShared = (subscription: any, _getTags: Generator<any, void, unknown>) => {
  const product = subscription.product;
  const organisation = subscription.customer.name;
  const domain = subscription.domain;

  function* getTags() {
    yield product.tag;
    if (typeof _getTags === "function") {
      for (const tag of [..._getTags]) {
        yield tag;
      }
    }
  }

  const tags = translate("Port", [...getTags()]);
  const services = [...transformServices(subscription.subscriptionId, subscription.inUseBy || [])];
  const transformedSubscription = gqlToSubscription(subscription, tags);
  return {
    ...transformedSubscription,
    services,
    organisation,
    customerDescriptions: transformCustomerDescriptions(subscription.customerDescriptions),
    product: toProduct(product),
    domain,
  };
};
