/**
 * Mimic behaviour of more_itertools (py): first_true.
 * Return the first value for which predicate `pred` returns true.
 * If nothing matches, return the default value.
 *
 * @param input array
 * @param def default value to return
 * @param pred predicate for Array.find()
 * @returns any
 */
export const first_true = (input: any[], def: any, pred: any) => {
  if (typeof pred !== "function") {
    return def;
  }
  return input.find(pred) || def;
};

/**
 * Mimic behaviour of more_itertools (py): first.
 * Return the first value of input. If input is empty,
 * return the default value.
 *
 * @param input array
 * @param def default value to return
 * @returns any
 */
export const first = (input: any[], def?: any): any => {
  if (input.length > 0) {
    return input[0];
  }
  if (!def) {
    throw new Error("No default value provided");
  }
  return def;
};

export const one = (v: any[]) => {
  if (!Array.isArray(v)) {
    throw new Error(`Expected iterable parameter, but got ${typeof v}`);
  }
  if (v.length !== 1) {
    throw new Error(`Expected exactly one item in iterable, but got ${v.length}`);
  }

  return v[0];
};

/**
 * Recreate the zip() functionality from Python.
 */
type Iterableify<T> = { [K in keyof T]: Iterable<T[K]> };

export function* zip<T extends Array<any>>(...toZip: Iterableify<T>): Generator<T> {
  // Get iterators for all of the iterables.
  const iterators = toZip.map((i) => i[Symbol.iterator]());

  while (true) {
    // Advance all of the iterators.
    const results = iterators.map((i) => i.next());

    // If any of the iterators are done, we should stop.
    if (results.some(({ done }) => done)) {
      break;
    }

    // We can assert the yield type, since we know none
    // of the iterators are done.
    yield results.map(({ value }) => value) as T;
  }
}

export function* repeat(thing: any): Generator<any> {
  while (true) {
    yield thing;
  }
}

export const DownTypeMap = {
  1: "Down time",
  2: "Resilience loss",
  3: "Reduced redundancy",
};

export enum Urgency {
  NORMAL = "normal",
  HIGH = "high",
}

/**
 * Find the description of a service by a specific customer.
 * If not found, return the default description.
 *
 * @param customer_id
 * @param subscription
 * @returns
 */
export const get_customer_description = (customer_id: string, subscription: any) => {
  const pred = (description: any): boolean => description.customerId === customer_id;

  return first_true(subscription.customerDescriptions, { description: "" }, pred)["description"];
};

export const get_impact_type = (planned_work_id: any, subscription: any): string => {
  const circuits = subscription.imsCircuits;
  const circuitIds = circuits.map((c) => c.imsCircuitId);

  function* allPlannedDowntype() {
    for (const circuit of circuits) {
      for (const plannedWork of circuit.plannedWork) {
        if (plannedWork.id !== planned_work_id) {
          continue;
        }
        for (const relatedCircuit of plannedWork.relatedCircuits) {
          if (circuitIds.includes(relatedCircuit.imsCircuitId)) {
            yield relatedCircuit.downType;
          }
        }
      }
    }
  }

  const downTypes = [...allPlannedDowntype()];
  const downType = first(downTypes, 2);
  return Object.keys(DownTypeMap).includes(downType) ? DownTypeMap[downType] : DownTypeMap[2];
};

export const to_message_subscription = (work: any, customer_id: string, subscription: any) => {
  const customer_description = get_customer_description(customer_id, subscription);
  const impact_type = get_impact_type(work.id, subscription);
  return {
    subscription_id: subscription.subscriptionId,
    name: subscription.product.name,
    description: subscription.description,
    customer_description,
    impact_type,
    product_type: subscription.product.type,
  };
};
