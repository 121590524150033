export const getFilteredTags = (activeTags: string[], subscriptions) => {
  const filteredSubscriptions = [];
  subscriptions.map((subscription) => {
    const hasActiveTag = subscription.tags.some((tag) => activeTags.includes(tag));
    if (hasActiveTag) {
      filteredSubscriptions.push(subscription);
    }
  });

  return filteredSubscriptions;
};
