import { FirewallInstance } from "../firewallinstance";
import { Instance } from "../instance";
import { InternetInstance } from "../internetinstance";
import { IpPrefixInstance } from "../ipprefixinstance";
import { L2VPNInstance } from "../l2vpninstance";
import { L3VPNInstance } from "../l3vpninstance";
import { LightPathInstance } from "../lightpathinstance";
import { PortInstance } from "../portinstance";
import { WirelessInstance } from "../wirelessinstance";

export class InstanceFactory {
  /**
   * Returns an instance associated with the supplied subscription data in the type you specify.
   * Only use this if you know the type of subscription.
   */
  public static createAs<T>(subscriptionData: any): T {
    return InstanceFactory.create(subscriptionData) as T;
  }

  /**
   * Returns an instance of the type associated with the supplied subscription data.
   */
  public static create(subscriptionData: any): Instance {
    if (!subscriptionData?.product?.type) {
      console.error(`Attempting to create instance without the necessary data: ${subscriptionData}`);
      return null;
    }

    switch (subscriptionData.product.type) {
      case "IP":
        return new InternetInstance(subscriptionData);
      case "LightPath":
        return new LightPathInstance(subscriptionData);
      case "IP_PREFIX":
        return new IpPrefixInstance(subscriptionData);
      case "FW":
        return new FirewallInstance(subscriptionData);
      case "Port":
        return new PortInstance(subscriptionData);
      case "L3VPN":
        return new L3VPNInstance(subscriptionData);
      case "L2VPN":
        return new L2VPNInstance(subscriptionData);
      case "Wireless":
        return new WirelessInstance(subscriptionData);
    }
    return null;
  }
}
