import { gql } from "apollo-angular";

export const PaginationFragment = gql`
  fragment PaginationInfo on PageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
    totalItems
  }
`;
