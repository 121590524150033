/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { StrictHttpResponse } from "../strict-http-response";

import { createNameserver } from "../fn/nameservers/create-nameserver";
import { CreateNameserver$Params } from "../fn/nameservers/create-nameserver";
import { deleteNameserver } from "../fn/nameservers/delete-nameserver";
import { DeleteNameserver$Params } from "../fn/nameservers/delete-nameserver";
import { NameserverRead } from "../models/nameserver-read";
import { NameserverReadWithZones } from "../models/nameserver-read-with-zones";
import { readNameserver } from "../fn/nameservers/read-nameserver";
import { ReadNameserver$Params } from "../fn/nameservers/read-nameserver";
import { readNameservers } from "../fn/nameservers/read-nameservers";
import { ReadNameservers$Params } from "../fn/nameservers/read-nameservers";
import { Status } from "../models/status";
import { updateNameserver } from "../fn/nameservers/update-nameserver";
import { UpdateNameserver$Params } from "../fn/nameservers/update-nameserver";

@Injectable({ providedIn: "root" })
export class NameserversService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `readNameservers()` */
  static readonly ReadNameserversPath = "/organisations/{organisation_id}/nameservers/";

  /**
   * Read Nameservers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readNameservers()` instead.
   *
   * This method doesn't expect any request body.
   */
  readNameservers$Response(
    params: ReadNameservers$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<NameserverRead>>> {
    return readNameservers(this.http, this.rootUrl, params, context);
  }

  /**
   * Read Nameservers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readNameservers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readNameservers(params: ReadNameservers$Params, context?: HttpContext): Observable<Array<NameserverRead>> {
    return this.readNameservers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<NameserverRead>>): Array<NameserverRead> => r.body),
    );
  }

  /** Path part for operation `createNameserver()` */
  static readonly CreateNameserverPath = "/organisations/{organisation_id}/nameservers/";

  /**
   * Create Nameserver.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createNameserver()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createNameserver$Response(
    params: CreateNameserver$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<NameserverRead | Status>> {
    return createNameserver(this.http, this.rootUrl, params, context);
  }

  /**
   * Create Nameserver.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createNameserver$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createNameserver(params: CreateNameserver$Params, context?: HttpContext): Observable<NameserverRead | Status> {
    return this.createNameserver$Response(params, context).pipe(
      map((r: StrictHttpResponse<NameserverRead | Status>): NameserverRead | Status => r.body),
    );
  }

  /** Path part for operation `readNameserver()` */
  static readonly ReadNameserverPath = "/organisations/{organisation_id}/nameservers/{nameserver_id}";

  /**
   * Read Nameserver.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readNameserver()` instead.
   *
   * This method doesn't expect any request body.
   */
  readNameserver$Response(
    params: ReadNameserver$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<NameserverReadWithZones>> {
    return readNameserver(this.http, this.rootUrl, params, context);
  }

  /**
   * Read Nameserver.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readNameserver$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readNameserver(params: ReadNameserver$Params, context?: HttpContext): Observable<NameserverReadWithZones> {
    return this.readNameserver$Response(params, context).pipe(
      map((r: StrictHttpResponse<NameserverReadWithZones>): NameserverReadWithZones => r.body),
    );
  }

  /** Path part for operation `deleteNameserver()` */
  static readonly DeleteNameserverPath = "/organisations/{organisation_id}/nameservers/{nameserver_id}";

  /**
   * Delete Nameserver.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteNameserver()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteNameserver$Response(
    params: DeleteNameserver$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Status>> {
    return deleteNameserver(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete Nameserver.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteNameserver$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteNameserver(params: DeleteNameserver$Params, context?: HttpContext): Observable<Status> {
    return this.deleteNameserver$Response(params, context).pipe(map((r: StrictHttpResponse<Status>): Status => r.body));
  }

  /** Path part for operation `updateNameserver()` */
  static readonly UpdateNameserverPath = "/organisations/{organisation_id}/nameservers/{nameserver_id}";

  /**
   * Update Nameserver.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateNameserver()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateNameserver$Response(
    params: UpdateNameserver$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<NameserverRead | Status>> {
    return updateNameserver(this.http, this.rootUrl, params, context);
  }

  /**
   * Update Nameserver.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateNameserver$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateNameserver(params: UpdateNameserver$Params, context?: HttpContext): Observable<NameserverRead | Status> {
    return this.updateNameserver$Response(params, context).pipe(
      map((r: StrictHttpResponse<NameserverRead | Status>): NameserverRead | Status => r.body),
    );
  }
}
