import { gql } from "apollo-angular";
import { ImsPortLocationFragment, ImsServicePortLocationFragment } from "./imsInfo";

const SubscriptionDetailsFragment = gql`
  fragment SubscriptionDetails on SubscriptionInterface {
    subscriptionId
    status
    customerId
    customer {
      name
    }
    status
    insync
    customerDescriptions {
      customerId
      description
    }
    description
    startDate
    endDate
  }
`;

const ProductFragment = gql`
  fragment Product on SubscriptionInterface {
    product {
      description
      productId
      type
      name
      createdAt
      tag
      productType: type
    }
  }
`;

const ServiceDetailsFragment = gql`
  fragment ServiceDetails on SubscriptionInterface {
    inUseBy(inUseByFilter: ["provisioning", "active", "migrating"]) @include(if: $portServices) {
      ...SubscriptionDetails
      ...Product

      customer {
        name
      }

      ... on FwSubscription {
        subscriptionId
        domain
        firewall {
          ipGwEndpoint {
            ip {
              serviceSpeed
              subscriptionInstanceId
              saps {
                sap {
                  vlanrange
                  subscriptionInstanceId
                  portSubscriptionId
                }
              }
            }
            l2vpnInternal {
              serviceSpeed
              subscriptionInstanceId
              esis {
                saps {
                  vlanrange
                  subscriptionInstanceId
                  portSubscriptionId
                }
              }
            }
          }
          l2Endpoints {
            esis {
              saps {
                vlanrange
                subscriptionInstanceId
                portSubscriptionId
              }
            }
          }
          l3Endpoints {
            l2vpnInternal {
              serviceSpeed
              esis {
                saps {
                  vlanrange
                  subscriptionInstanceId
                  portSubscriptionId
                }
              }
            }
            saps {
              vlanrange
              subscriptionInstanceId
              portSubscriptionId
            }
          }
        }
      }

      ... on Sn8IpBgpSubscription {
        domain
        subscriptionId
        vc {
          subscriptionInstanceId
          serviceSpeed
          saps {
            sap {
              vlanrange
              subscriptionInstanceId
              portSubscriptionId
            }
          }
        }
      }

      ... on Sn8IpStaticSubscription {
        domain
        subscriptionId
        vc {
          subscriptionInstanceId
          serviceSpeed
          sap {
            sap {
              vlanrange
              subscriptionInstanceId
              portSubscriptionId
            }
          }
        }
      }

      ... on Sn8L2VpnSubscription {
        domain
        subscriptionId
        vc {
          subscriptionInstanceId
          serviceSpeed
          esis {
            inUseByIds
            saps {
              vlanrange
              subscriptionInstanceId
              portSubscriptionId
              port {
                subscriptionId
                #                locations1 {
                #                  addresses {
                #                    city
                #                    countryCode
                #                    number
                #                    street
                #                    zipcode
                #                    type
                #                  }
                #                  abbreviation
                #                }
              }
            }
          }
        }
      }

      ... on Sn8L3VpnSubscription {
        domain
        subscriptionId
        vpnvc: vc {
          subscriptionInstanceId
          serviceSpeed
          settings {
            subscriptionInstanceId
            name
            asn
          }
          saps {
            subscriptionInstanceId
            sap {
              vlanrange
              subscriptionInstanceId
              portSubscriptionId
              port {
                subscriptionId
                #                locations2 {
                #                  addresses {
                #                    city
                #                    countryCode
                #                    number
                #                    street
                #                    zipcode
                #                    type
                #                  }
                #                  abbreviation
                #                }
              }
            }
          }
        }
      }

      ... on Sn8LightPathSubscription {
        domain
        subscriptionId
        vc {
          subscriptionInstanceId
          serviceSpeed
          saps {
            vlanrange
            subscriptionInstanceId
            portSubscriptionId
          }
        }
      }

      ... on Sn8LightPathRedundantSubscription {
        domain
        subscriptionId
        lrss {
          vcs {
            subscriptionInstanceId
            serviceSpeed
            saps {
              vlanrange
              subscriptionInstanceId
              portSubscriptionId
            }
          }
        }
      }

      ... on Sn8MscSubscription {
        subscriptionId
        port {
          subscriptionInstanceId
          portSubscriptionId
        }
      }

      ... on NsistpSubscription {
        subscriptionId
        settings {
          sap {
            vlanrange
            subscriptionInstanceId
            portSubscriptionId
          }
        }
      }
    }
  }
`;

const MscPortSpeedFragment = gql`
  fragment MscPortSpeed on Sn8MscSubscription {
    inUseBy(inUseByFilter: ["provisioning", "active", "migrating"]) {
      subscriptionType: __typename
      subscriptionId

      ... on Sn8L3VpnSubscription {
        vc {
          serviceSpeed
          subscriptionInstanceId
          saps {
            sap {
              portSubscriptionId
            }
          }
        }
      }
      ... on Sn8LightPathSubscription {
        vc {
          subscriptionInstanceId
          serviceSpeed
          saps {
            portSubscriptionId
          }
        }
      }
      ... on Sn8L2VpnSubscription {
        vc {
          subscriptionInstanceId
          serviceSpeed
          esis {
            saps {
              portSubscriptionId
            }
          }
        }
      }
      ... on Sn8LightPathRedundantSubscription {
        lrss {
          vcs {
            subscriptionInstanceId
            serviceSpeed
            saps {
              portSubscriptionId
            }
          }
        }
      }
    }
  }
`;

const Sn8SapBlockInfoFragment = gql`
  ${ImsServicePortLocationFragment}
  ${ImsPortLocationFragment}
  ${MscPortSpeedFragment}
  ${SubscriptionDetailsFragment}
  ${ProductFragment}
  fragment Sn8SapBlockInfo on Sn8ServiceAttachPointBlock {
    subscriptionInstanceId
    name

    vlanrange

    port {
      ...SubscriptionDetails
      ...Product

      ... on Sn8AggregatedServicePortSubscription {
        subscriptionId
        domain

        port {
          subscriptionInstanceId
          name
          portMode
          portSubscriptionId

          port {
            ... on Sn8ServicePortSubscription {
              subscriptionId
              portSpeed
            }
          }

          ims {
            ...ImsServicePortLocation
            aliases
          }
        }
      }

      ... on Sn8ServicePortSubscription {
        domain
        portSpeed

        port {
          subscriptionInstanceId
          name
          autoNegotiation
          portMode
          ims {
            ...ImsPortLocation
          }
        }
      }

      ... on Sn8MscSubscription {
        ...MscPortSpeed

        domain
        port {
          subscriptionInstanceId
          name
          ims {
            ...ImsServicePortLocation
            aliases
          }
        }
      }
    }
  }
`;

const ptpIpamInfoFragment = gql`
  fragment ptpIpamInfo on PrefixType {
    addresses {
      address
      fqdn
      tags
    }
    prefix
  }
`;

const OtherSubscriptionsFragment = gql`
  fragment OtherSubscriptions on ProductBlockModel {
    otherSubscriptions(statusFilter: ["active"]) {
      subscriptionId
      description
      customerDescriptions {
        customerId
        description
      }
    }
  }
`;

const DependsOnPrefixesFragment = gql`
  fragment DependsOnPrefixes on SubscriptionInterface {
    subscriptionId
    dependsOn(productTypeFilter: "IP_PREFIX") {
      ... on IpPrefixSubscription {
        note
        name
        subscriptionId

        ipPrefix {
          ipam {
            addresses {
              name
              address
              prefixId
            }
            prefix
          }
          subscriptionInstanceId
        }
      }
    }
  }
`;

export {
  SubscriptionDetailsFragment,
  ProductFragment,
  ServiceDetailsFragment,
  MscPortSpeedFragment,
  Sn8SapBlockInfoFragment,
  ptpIpamInfoFragment,
  OtherSubscriptionsFragment,
  DependsOnPrefixesFragment,
};
