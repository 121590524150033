<div class="dialog dialog--small">
  <div class="dialog__header">
    <h3 class="dialog__title">
      <span>{{ 'Global.Action.Edit' | translate }} {{ service.presentableName }}</span>
    </h3>
    <svg-icon src="assets/images/icons/x.svg" class="dialog__close" (click)="closeDialog()"></svg-icon>
  </div>

  <div class="dialog__body dialog__body--grow">
    <div class="row">
      <div class="col-12">
        <label for="" class="form-label">{{ 'Global.Name' | translate }}</label>
        <div class="input__wrapper">
          <input type="text" class="input" placeholder="" [(ngModel)]="service.presentableName" />
        </div>
        <!--
				<label for="" class="form-label">Beschrijving</label>
				<div class="textarea__wrapper">
					<textarea class="textarea" placeholder="Dit is een placeholder"></textarea>
				</div>
				-->
      </div>
    </div>
    <div *ngIf="editType === 2" class="row">
      <div class="col-5">
        <label for="" class="form-label"
          >{{ 'Services.Fulfilment.Lightpath.Bandwidth' | translate }}
          <span
            class="info"
            matTooltip="{{ 'Services.Fulfilment.Lightpath.Tooltip' | translate }}"
            [matTooltipPosition]="'above'"
          >
          </span
        ></label>
        <div class="input__wrapper input__wrapper--flex">
          <input type="number" value="0" class="input" min="0" />
          <switch></switch>
        </div>
        <label for="" class="form-label">{{ 'Dialog.Edit.FreeCapacity' | translate }}</label>
        <div>2.5 Gb/s</div>
      </div>
      <div class="col-7">
        <label for="" class="form-label">{{ 'Dialog.Edit.Explanation' | translate }}</label>
        <div class="textarea__wrapper">
          <textarea class="textarea" placeholder=""></textarea>
        </div>
      </div>
    </div>
  </div>
  <!-- Success
	<div class="dialog__body">
		<div class="row">
			<div class="col-12 col-md-6 offset-md-3 text-center">
				<svg-icon src="assets/images/icons/ok.svg" class="icon blue"></svg-icon>
				<h2>Aanvraag succesvol</h2>
				<p>Morbi pharetra rhoncus dui id iaculis. Duis vestibulum, ligula a gravida convallis, lorem diam accumsan est, sed pharetra neque sem eu ex. Integer quis semper ligula. Sed sit amet elementum arcu, at porta ante.</p>
			</div>
		</div>
	</div>
	-->

  <div class="dialog__footer dialog__footer--end">
    <div class="button__flex">
      <div (click)="closeDialog()" class="button button--secondary">{{ 'Global.Action.Cancel' | translate }}</div>
      <div (click)="confirm()" class="button">{{ 'Global.Action.Confirm' | translate }}</div>
      <!-- <a href="" class="button">Sluit window</a> -->
    </div>
  </div>
</div>
