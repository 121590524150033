export interface KpiItem {
  key: string;
  name: string;
  percentage: boolean;
  warning: (value: number) => boolean;
  danger: (value: number) => boolean;
}

export const getStatusColor = (
  value: number | undefined | null,
  danger: (value: number) => boolean,
  warning: (value: number) => boolean,
): string => {
  if (!value) {
    return "grey";
  } else if (danger(value)) {
    return "red";
  } else if (warning(value)) {
    return "orange";
  } else {
    return "green";
  }
};

export const knownVendors: string[] = ["aerohive", "aruba"];

export const KPI_ITEMS_AEROHIVE: KpiItem[] = [
  {
    key: "DHCP",
    name: "DHCP successful attempts (%)",
    percentage: true,
    warning: (value) => value < 98,
    danger: (value) => value < 90,
  },
  {
    key: "MOSVOIPDOWN",
    name: "VoIP quality down (1-5)",
    percentage: false,
    warning: (value) => value < 4.5,
    danger: (value) => value < 4.2,
  },
  {
    key: "MOSVOIPUP",
    name: "VoIP quality up (1-5)",
    percentage: false,
    warning: (value) => value < 4.5,
    danger: (value) => value < 4.2,
  },
  {
    key: "RADIUS",
    name: "Successful authentication attempts (%)",
    percentage: true,
    warning: (value) => value < 98,
    danger: (value) => value < 90,
  },
  {
    key: "THROUGHPUTDOWN",
    name: "Throughput Down (Mbit/s)",
    percentage: false,
    warning: (value) => value < 49,
    danger: (value) => value < 45,
  },
  {
    key: "THROUGHPUTUP",
    name: "Throughput Up (Mbit/s)",
    percentage: false,
    warning: (value) => value < 49,
    danger: (value) => value < 45,
  },
];

export const KPI_ITEMS_ARUBA: KpiItem[] = [
  {
    key: "WIFI_ASSOC_TIME",
    name: "WiFi assocation time (s)",
    percentage: false,
    warning: (value) => value > 4.0,
    danger: (value) => value > 5.0,
  },
  {
    key: "DHCP_RES_TIME",
    name: "DHCP response time (s)",
    percentage: false,
    warning: (value) => value > 5,
    danger: (value) => value > 10,
  },
  {
    key: "DNS_RES_TIME",
    name: "DNS resolution time (s)",
    percentage: false,
    warning: (value) => value > 0.018,
    danger: (value) => value > 0.028,
  },
  {
    key: "VOICE_QUALITY",
    name: "Voice quality (1-5)",
    percentage: false,
    warning: (value) => value < 4.5,
    danger: (value) => value < 4.2,
  },
  {
    key: "THROUGHPUT_DOWN",
    name: "Throughput Down (Mbit/s)",
    percentage: false,
    warning: (value) => value < 49,
    danger: (value) => value < 45,
  },
  {
    key: "THROUGHPUT_UP",
    name: "Throughput Up (Mbit/s)",
    percentage: false,
    warning: (value) => value < 49,
    danger: (value) => value < 45,
  },
];
