import { Component, Input, OnChanges, ViewChild, ElementRef, EventEmitter, OnDestroy } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../../environments/environment";
import { AuthService } from "../../../services/AuthService";
import { DialogAuthComponent } from "../../dialogs/dialog-auth/dialog-auth";
import { Instance } from "../../models/instance";
import { SubscriptionService } from "../../../services/subscriptionservice";
import { DialogSelfServiceComponent } from "../../dialogs/dialog-selfservice/dialog-selfservice";
import { Subscription as RxSubscription } from "rxjs";
import {
  canHaveFirewall,
  FirewallSubscriptionDetails,
  IpSubscriptionDetails,
  isFirewall,
  isIp,
  isL2vpn,
  isL3vpn,
  L2vpnSubscriptionDetails,
  L3vpnSubscriptionDetails,
  LightPathBothSubscriptionDetails,
} from "../../../pages/subscription-detail/types";
import { openSelfServiceDialog } from "../../dialogs/dialog-selfservice/dialog-selfservice.helpers";

type TabbedDetailContainerSubscription =
  | FirewallSubscriptionDetails
  | IpSubscriptionDetails
  | L2vpnSubscriptionDetails
  | L3vpnSubscriptionDetails
  | LightPathBothSubscriptionDetails;

@Component({
  selector: "tabbed-detail-container",
  templateUrl: "tabbed-detail-container.html",
})
export class TabbedDetailContainer implements OnChanges, OnDestroy {
  @ViewChild("tabsnav", { read: ElementRef }) tabsnav: ElementRef;
  @ViewChild("iptabsnav", { read: ElementRef }) iptabsnav: ElementRef;

  @Input() service: TabbedDetailContainerSubscription;
  @Input() bus: EventEmitter<string>;
  @Input() canModify = true;
  @Input() selfserviceState: any;

  public endpoints: Instance[] = [];
  public tabs: Tab[] = [];
  public activeTabIndex = 0;
  public displayLoadingBanner = false;
  public dialogRef: MatDialogRef<DialogSelfServiceComponent, any>;
  public overflowMenuIPActive: boolean;
  public firewallEnabled = false;

  rxSubscriptions: RxSubscription[] = [];

  // TODO get required data from replication and remove this
  private subscriptionsInUseByThisSubscription: {
    subscriptionId: string;
    ipGateway: boolean;
    subscriptionInstanceId: string;
  }[] = [];

  constructor(
    public subscriptionService: SubscriptionService,
    public dialog: MatDialog,
    private translate: TranslateService,
    public auth: AuthService,
  ) {}

  get isDevelopment(): boolean {
    return !environment.production;
  }

  get activeTab(): Tab | null {
    if (this.activeTabIndex < 0 || this.activeTabIndex >= this.tabs.length) {
      return null;
    }

    return this.tabs[this.activeTabIndex] || null;
  }

  get showModifyOptions() {
    if (!this.canModify) {
      return false;
    }

    if (!this.service || !this.service.product) {
      return false;
    }

    if (canHaveFirewall(this.service) && this.service.firewallEnabled) {
      return false;
    }

    return ["L2VPN", "FW"].includes(this.service.product.type);
  }

  ngOnChanges() {
    this.tabs = [];
    const service = this.service;
    if (service) {
      // subscribing to auth.userLoaded ensures this fires only after we have the user
      // (and teams, auth info, etc.)
      const s = this.auth.userLoaded.subscribe(() => this.setupComponent(service));
      this.rxSubscriptions.push(s);
      this.firewallEnabled = canHaveFirewall(service) && service.firewallEnabled;
    }
  }

  ngOnDestroy() {
    while (this.rxSubscriptions.length) {
      this.rxSubscriptions.pop().unsubscribe();
    }
  }

  setupComponent(service: TabbedDetailContainerSubscription) {
    this.initializeTabs();
    this.addTabsByProduct(service);
    if (hasFirewallInterconnects(service)) {
      this.addTabsForFirewallInterconnects(service);
    }
    this.postInitializeTabs();

    // No idea what this does or why
    ["bfd", "bgp", "add-port", "remove-port", "vlan-l2vpn"].forEach((type) => {
      if (this.auth.hasPendingStrongAction(type)) {
        this.startDialog(type);
      }
    });
    if (this.auth.hasPendingStrongAction("ip-prefixes")) {
      this.displayLoadingBanner = true;
    }
  }

  addTabsForSAPs(saps) {
    // FIXME #241 Add typing to function parameters
    if (!saps || saps.length === 0) {
      return;
    }

    for (const sap of saps) {
      this.addTab(sap);
    }
    this.displayLoadingBanner = false;

    if (this.auth.hasPendingStrongAction("ip-prefixes")) {
      this.startDialog("ip-prefixes");
    }

    if (this.selfserviceState && this.selfserviceState.action === "addPrefix") {
      // only trigger if selfservice authentication is done (on the subscription-detail page for example)
      if (this.auth.isAuthenticatedForSelfService()) {
        this.openSelfserviceDialog("ip-prefixes");
      }
    }
  }

  addTabsByProduct(service: TabbedDetailContainerSubscription) {
    if (isFirewall(service)) {
      this.addTabsForFirewallEndpoints(service.l2Endpoints, "L2VPN Service");
      this.addTabsForFirewallEndpoints(service.l3Endpoints, "L3VPN Service");
    } else if (isL2vpn(service)) {
      const esis = service.esis.slice();
      for (const esi of esis) {
        this.addTab(esi);
      }
    } else if (isL3vpn(service)) {
      const transformL3vpnSap = (l3vpnSap) => ({
        ...l3vpnSap,
        ...l3vpnSap.sap,
        l3vpn_ipv4Address: l3vpnSap.ipv4Address,
        l3vpn_ipv4RemoteAddress: l3vpnSap.ipv4RemoteAddress,
        l3vpn_ipv6Address: l3vpnSap.ipv6Address,
        l3vpn_ipv6RemoteAddress: l3vpnSap.ipv6RemoteAddress,
      });
      const l3vpnsapss = service.l3vpnsapss.slice();
      this.addTabsForSAPs(l3vpnsapss.map(transformL3vpnSap));
    } else if (isIp(service)) {
      this.addTabsForSAPs(service.saps);
    }
  }

  addTabsForFirewallEndpoints(endpoints: L2Endpoint[] | L3Endpoint[], defaultSubtitle: string) {
    for (const endpoint of endpoints) {
      if (!endpoint.otherSubscriptionId || endpoint.otherSubscriptionId.length === 0) {
        continue;
      }
      this.addTab(endpoint, null, defaultSubtitle);
    }
  }

  addTabsForFirewallInterconnects(service: { firewallInterconnects: any[] }) {
    // FIXME #241 Add typing to function parameters
    for (const fw of service.firewallInterconnects || []) {
      this.addTab({ firewallInterconnect: fw });
    }
  }

  descriptionForEntity(entity) {
    return entity?.customerDescription || entity?.description || "-";
  }

  addTab(item, defaultTitle = "-", defaultSubtitle = "-") {
    // FIXME #241 Add typing to function parameters
    let properties: Tab;
    let addAsFirstTab = false;
    if (item.firewallInterconnect) {
      addAsFirstTab = true;
      properties = this.produceFirewallInterconnectTab(item, defaultTitle, defaultSubtitle);
    } else if (item.port) {
      properties = this.producePortTab(item, defaultTitle, defaultSubtitle);
    } else if (item.endpoints && item.endpoints.length > 0) {
      // For L2VPNs & L3VPNs?
      properties = this.produceEndpointsTab(item, defaultTitle, defaultSubtitle);
    } else if (this.service.product.type === "FW") {
      properties = this.produceFirewallTab(item, defaultTitle, defaultSubtitle);
    } else {
      properties = this.produceDefaultTab(defaultTitle, defaultSubtitle, item);
      properties.title = this.descriptionForEntity(item);
      properties.content.serviceSummaries.push(item);
    }

    if (addAsFirstTab) {
      this.tabs.unshift(properties);
    } else {
      this.tabs.push(properties);
    }
  }

  propertyKeysToRows(keys: Key[], item: any): Row[] {
    if (!item) {
      return [];
    }

    const pathValue = (obj: any, path: string | string[]) => {
      // TODO move to helper func and add unit test
      if (typeof path === "string") {
        return pathValue(obj, path.split("."));
      } else if (path.length === 0 || !obj) {
        return obj;
      }

      return pathValue(obj[path[0]], path.slice(1));
    };

    const propertyKeyToRowOrNull = (key: Key): Row | null => {
      let scalarValue = "";
      let objectValue = {};
      let arrayValue = [];
      if (!key.key || key.key.length === 0) {
        // Value of the entire object
        objectValue = item;
      } else {
        // Value of specific object attribute
        let path_value = pathValue(item, key.key);
        if (path_value === null || path_value === undefined) {
          return null;
        }
        if (Array.isArray(path_value)) {
          arrayValue = path_value;
        } else if (typeof path_value === "object") {
          objectValue = path_value;
        } else {
          scalarValue = path_value;
        }
      }

      if (scalarValue.toString().length === 0 && Object.keys(objectValue).length === 0 && arrayValue.length === 0) {
        return null;
      }

      return {
        title: this.translate.instant(key.title) || "-",
        display: key.display || "default",
        scalarValue,
        objectValue,
        arrayValue,
      };
    };

    return keys
      .filter((key) => key.if !== false)
      .map(propertyKeyToRowOrNull)
      .filter((row) => row);
  }

  moveTab(moveAmount = 1) {
    this.activeTabIndex += moveAmount;
    if (this.activeTabIndex >= this.tabs.length) {
      this.activeTabIndex = this.tabs.length - 1;
    } else if (this.activeTabIndex < 0) {
      this.activeTabIndex = 0;
    }

    const scrollAmount = moveAmount * 300;
    this.tabsnav.nativeElement.scrollLeft += scrollAmount;
  }

  startDialog(type) {
    this.auth.clearPendingStrongAction(type);
    this.selfserviceState = this.auth.getSelfserviceState();
    this.openSelfserviceDialog(type);
  }

  openSelfserviceDialog(type) {
    let selectedPrefix = "";
    let currentStep = 1;
    if (this.selfserviceState) {
      selectedPrefix = this.selfserviceState.selectedPrefix;
      currentStep = 1;
    }

    const roleCheckResult = this.auth.checkRoleAccess(this.service.product?.type, "edit");
    if (!roleCheckResult.ok) {
      this.auth.roleEvent.emit(roleCheckResult);
      return;
    }

    if (!this.auth.isAuthenticatedForSelfService()) {
      this.selfserviceState = { activeTabItem: this.activeTab.item, activeTabIndex: this.activeTabIndex };
      this.dialog.open(DialogAuthComponent, {
        data: {
          state: false,
          initialAction: type,
          selfserviceState: this.selfserviceState,
        },
      });
    } else {
      // if dialogRef, reuse old instance
      if (this.dialogRef !== undefined && this.dialogRef.componentInstance !== null) {
        this.dialogRef.componentInstance.type = type;
        this.dialogRef.componentInstance.subscription = this.service;
        this.dialogRef.componentInstance.instance = this.activeTab.item;
        this.dialogRef.componentInstance.sapIndex = this.activeTabIndex;
        this.dialogRef.componentInstance.selectedPrefix = selectedPrefix;
        this.dialogRef.componentInstance.currentStep = currentStep;
        this.dialogRef.componentInstance.loadingData = false;
      } else {
        const activeTabItem = this.selfserviceState?.activeTabItem || this.activeTab.item;
        const activeTabIndex = this.selfserviceState?.activeTabIndex || this.activeTabIndex;
        const data = {
          type,
          subscription: this.service,
          instance: activeTabItem,
          sapIndex: activeTabIndex,
          selectedPrefix,
          currentStep,
        };

        this.dialogRef = openSelfServiceDialog(this.dialog, data);
      }
      this.selfserviceState = null;

      this.dialogRef.componentInstance.close.subscribe((event: string) => {
        this.bus.emit(event);
      });
    }
  }

  openIpPrefixDialog(): void {
    if (this.auth.isAuthenticatedForSelfService()) {
      const data = {
        type: "ip-prefixes",
        loader: true,
        currentStep: 1,
      };

      this.dialogRef = openSelfServiceDialog(this.dialog, data);
      this.dialogRef.componentInstance.loadingData = true;
    }
  }

  private initializeTabs() {
    this.tabs = [];
  }

  private postInitializeTabs() {
    if (this.activeTabIndex < 0 || this.activeTabIndex >= this.tabs.length) {
      this.activeTabIndex = 0;
    }
  }

  private produceDefaultTab(defaultTitle: string, defaultSubtitle: string, item: any): Tab {
    return {
      title: defaultTitle || "-",
      subtitle: defaultSubtitle || "-",
      item,
      content: {
        showTrafic: false,
        serviceSummaries: [],
        configurationDetails: {
          title: "",
          rows: [],
        },
      },
    };
  }

  private produceFirewallInterconnectTab(item, defaultTitle, defaultSubtitle): Tab {
    // Create FW tab for a L2VPN/L3VPN detail page
    const properties: Tab = this.produceDefaultTab(defaultTitle, defaultSubtitle, item);

    properties.title = this.translate.instant("ServiceElement.FirewallInterconnect");

    properties.subtitle = this.descriptionForEntity(item);
    try {
      // get from dependsOn
      // const firewallInfo = await this.subscriptionService.getSubscriptionDetails(
      //   "fw",
      //   item.firewallInterconnect.firewallSubscriptionId,
      // );

      // TODO get required data from replication and remove this
      const firewallInfo = this.subscriptionsInUseByThisSubscription.find(
        (sub) => sub.subscriptionId === item.firewallInterconnect.firewallSubscriptionId,
      ) ?? { ...item.firewallInterconnect };
      if (!firewallInfo) {
        throw new Error("Unable to find firewall interconnect subscription");
      }
      properties.subtitle = this.descriptionForEntity(firewallInfo);

      if (firewallInfo.ipGateway) {
        delete firewallInfo.ipGateway;
      }

      firewallInfo.subscriptionInstanceId = item.firewallInterconnect.subscriptionInstanceId;
      properties.content.serviceSummaries.push({
        ...firewallInfo,
        subscriptionId: firewallInfo.firewallSubscriptionId,
        product: {
          type: "FW",
        },
        l3Endpoints: firewallInfo.saps,
      });

      // only show the trafic data on the L2VPN detail page
      if (this.service.product.type === "L2VPN") {
        properties.content.showTrafic = true;
      }

      // only show IP data on the L3VPN detail page
      if (this.service.product.type === "L3VPN") {
        properties.content.configurationDetails.title = this.translate.instant("ServiceElement.IPConfiguration");

        const keys: Key[] = [
          { key: "availabilityZone", title: "IP.CustomerASN" },
          { key: "firewallAsn", title: "ServiceElement.FirewallASN" },
          { key: "ipv4Prefix", title: "ServiceElement.IPv4Prefix" },
          {
            key: "ipv4AddressFirewall",
            title: "ServiceElement.IPv4AddressFirewall",
          },
          { key: "ipv4Mtu", title: "ServiceElement.IPv4MTU" },
          { key: "ipv4MaxPrefix", title: "ServiceElement.IPv4MaxPrefix" },
          { key: "ipv6Prefix", title: "ServiceElement.IPv6Prefix" },
          {
            key: "ipv6AddressFirewall",
            title: "ServiceElement.IPv6AddressFirewall",
          },
          {
            key: "ipv6Mtu",
            title: "ServiceElement.IPv6MTU",
          },
          {
            key: "ipv6MaxPrefix",
            title: "ServiceElement.IPv6MaxPrefix",
          },
        ];

        properties.content.configurationDetails.rows = this.propertyKeysToRows(keys, item.firewallInterconnect);
      }
    } catch (e) {
      // Ignore error for now, don't add the tab.
      console.error(e);
    }

    return properties;
  }

  private producePortTab(item, defaultTitle: string, defaultSubtitle: string): Tab {
    // FIXME #241 Add typing to function parameters
    const properties: Tab = this.produceDefaultTab(defaultTitle, defaultSubtitle, item);

    properties.title = this.descriptionForEntity(item.port);

    properties.subtitle =
      item.port.locationDescription || item.port.customer.fullname || item.locationDescription || "-";
    if (item.vlanrange && item.vlanrange.length > 0) {
      properties.subtitle = `${properties.subtitle}, ${this.translate.instant("ServiceElement.VLAN")} ${
        item.vlanrange
      }`;
    } else {
      properties.subtitle = `${properties.subtitle}, ${this.translate.instant("Subscription.UntaggedPort")}`;
    }

    properties.content.serviceSummaries.push(item);
    properties.content.configurationDetails.title = this.translate.instant("ServiceElement.IPConfiguration");

    const service = this.service;
    const keys: Key[] = [
      { key: "asn", title: "IP.CustomerASN" },
      { key: "bgpMetric", title: "IP.BGPMetric" },
      { key: "bgpSessionPriority", title: "IP.BGPSessionPriority" },
      { key: "bgpExportPolicy", title: "IP.BGPExportPolicy" },
      // { key: "bgpHashAlgorithm", title: "IP.BGPHashAlgorithm" }, this field does not represent any value
      {
        key: "bgpPassword",
        title: "IP.BGPPassword",
        if: item.bgpPassword,
      },
      {
        key: "",
        title: "IP.BFD",
        if: isIp(service) && service.ipRoutingType === "BGP",
        display: "bgp-component",
      },
      {
        key: "bfd",
        title: "IP.BFD",
        if: service.product.type === "L3VPN",
      },
      {
        key: "bfdMinimumInterval",
        title: "IP.BFDMinimalInterval",
        if:
          (isIp(service) && service.ipRoutingType === "BGP") ||
          (service.product.type === "L3VPN" && item.bfd && item.bfdMinimumInterval),
      },
      {
        key: "bfdMultiplier",
        title: "IP.BFDMultiplier",
        if:
          (isIp(service) && service.ipRoutingType === "BGP") ||
          (service.product.type === "L3VPN" && item.bfd && item.bfdMultiplier),
      },
      { key: "l3vpn_ipv4Address", title: "IP.L3VPNNeighborIPv4" },
      { key: "l3vpn_ipv4RemoteAddress", title: "IP.CustomerNeighborIPv4" },
      {
        key: "ipv4PointToPointPrefix.surfIpAddress",
        title: "IP.SurfnetNeighborIPv4",
      },
      {
        key: "ipv4PointToPointPrefix.clientIpAddress",
        title: "IP.CustomerNeighborIPv4",
      },
      { key: "ipv4Mtu", title: "IP.IPv4MTU" },
      { key: "ipv4MaxPrefix", title: "IP.IPv4MaxPrefix" },
      { key: "l3vpn_ipv6Address", title: "IP.L3VPNNeighborIPv6" },
      { key: "l3vpn_ipv6RemoteAddress", title: "IP.CustomerNeighborIPv6" },
      {
        key: "ipv6PointToPointPrefix.surfIpAddress",
        title: "IP.SurfnetNeighborIPv6",
      },
      {
        key: "ipv6PointToPointPrefix.clientIpAddress",
        title: "IP.CustomerNeighborIPv6",
      },
      {
        key: "ipv6Mtu",
        title: "IP.IPv6MTU",
      },
      {
        key: "ipv6MaxPrefix",
        title: "IP.IPv6MaxPrefix",
      },
      {
        key: "ipPrefixes",
        title: "IP.IPPrefixes",
        display: "textbox",
        if: item.ipPrefixes && item.ipPrefixes.length > 0 ? true : false,
      },
    ];

    properties.content.configurationDetails.rows = this.propertyKeysToRows(keys, item);
    return properties;
  }

  private produceEndpointsTab(item, defaultTitle: string, defaultSubtitle: string): Tab {
    const properties: Tab = this.produceDefaultTab(defaultTitle, defaultSubtitle, item);

    properties.title =
      item.endpoints.length > 1 ?
        this.translate.instant("ServiceElement.MultipleEndpoints")
      : this.descriptionForEntity(item.endpoints[0].port);
    properties.subtitle = `VLAN ${item.endpoints[0].vlanrange}`;

    for (const endpoint of item.endpoints) {
      properties.content.serviceSummaries.push(endpoint);
    }
    return properties;
  }

  private produceFirewallTab(item: any, defaultTitle: string, defaultSubtitle: string): Tab {
    const properties: Tab = this.produceDefaultTab(defaultTitle, defaultSubtitle, item);

    properties.title = this.descriptionForEntity(item);
    properties.content.serviceSummaries.push({ subscriptionId: item.otherSubscriptionId, ...item });
    properties.content.configurationDetails.title = this.translate.instant("ServiceElement.IPConfiguration");

    if (item.product.type === "L2VPN") {
      const keys: Key[] = [
        {
          key: "ipv4PtpPrefixes",
          title: "ServiceElement.IPv4PointToPoint",
          display: "textbox",
          if: item.ipv4PtpPrefixes && item.ipv4PtpPrefixes?.length > 0 ? true : false,
        },
        {
          key: "ipv6PtpPrefixes",
          title: "ServiceElement.IPv6PointToPoint",
          display: "textbox",
          if: item.ipv6PtpPrefixes && item.ipv6PtpPrefixes.length > 0 ? true : false,
        },
      ];
      properties.content.configurationDetails.rows = this.propertyKeysToRows(keys, item);
    }
    return properties;
  }
}

const hasFirewallInterconnects = (s): s is { firewallInterconnects: any[] } => keyInObject("firewallInterconnects", s);

const keyInObject = <T extends object>(key: PropertyKey, obj: T): key is keyof T => key in obj;

interface Key {
  key: string;
  title: string;
  display?: string;
  if?: boolean;
}

interface Row {
  title: string;
  display: string;
  scalarValue: any;
  arrayValue: any[];
  objectValue: {
    bfd?: string | boolean;
  };
}

interface Tab {
  content: {
    configurationDetails: {
      title: string;
      rows: Row[];
    };
    showTrafic: boolean;
    serviceSummaries: ServiceSummary[];
  };
  item: {
    ipPrefixes: Record<string, unknown>[];
  };
  subtitle: string;
  title: string;
}

interface Endpoint {
  subscriptionInstanceId?: string;
  otherSubscriptionId?: string;
  description: string;
  organisation?: string;
  tags: string[];
  product: {
    type: string;
  };
}

interface L2Endpoint extends Endpoint {
  ipv4PtpPrefixes: string[];
  ipv6PtpPrefixes: string[];
}

type L3Endpoint = Endpoint;

interface ServiceSummary {
  ipGateway: boolean;
  l2Endpoints?: L2Endpoint[];
  l3Endpoints?: L3Endpoint[];
  port?: {
    subscriptionId: string;
  };
  product: {
    type: string;
  };
  status?: string;
  tags?: string[];
  subscriptionId: string;
  subscriptionInstanceId: string;
  vlanrange?: number;
}
