/**
 * This is the default segment id value set in the toFirewallEsis() helper, and
 * it seems to be used as a sentinel value to detect when an ESI received
 * from the backend has no segment id set.
 * */
export const SEGMENT_ID_SENTINEL = 99;

export interface PortFilterItem {
  name: string;
  value: {
    port?: {
      subscriptionId: string;
    };
    firewallSubscriptionId?: string;
    otherSubscriptionId?: string;
    subscriptionInstanceId?: string;
  };
}

export interface ChartChoice {
  key: string;
  label: string;
}

export const WIRELESS_CHART_CHOICES: { [key: string]: ChartChoice[] } = {
  AEROHIVE: [
    { key: "dhcp", label: "KPI 1 - DHCP" },
    { key: "voipup", label: "KPI 2 - MOS VoIP Up" },
    { key: "voipdown", label: "KPI 3 - MOS VoIP Down" },
    { key: "throughputup", label: "KPI 4 - Throughput Up" },
    { key: "throughputdown", label: "KPI 5 - Throughput Down" },
    { key: "radius", label: "KPI 6 - Radius" },
  ],
  ARUBA: [
    { key: "wifi_assoc_time", label: "KPI 1 - WiFi Association Time" },
    { key: "dhcp_res_time", label: "KPI 2 - DHCP Response Time" },
    { key: "dns_res_time", label: "KPI 3 - DNS Resolution Time" },
    { key: "voice_quality", label: "KPI 4 - Voice Quality" },
    { key: "throughput_up", label: "KPI 5 - Throughput Up" },
    { key: "throughput_down", label: "KPI 6 - Throughput Down" },
  ],
};
