import { ReadablePacketSizePipe, ReadableSizePipe } from "../../../helpers/bitspipe/bitspipe";
import { DdosFilteredTraffic } from "../../../helpers/types";

export const CreateCertDdosChartOptions = (
  chartEvents: any,
  bitsPipe: ReadableSizePipe,
  packetPipe: ReadablePacketSizePipe,
  title: any,
  tipLabel: string,
) => ({
  chart: {
    type: "line",
    zoomType: "x",
    height: 400,
    showAxes: false,
    backgroundColor: "#FAFBFD",
    spacingLeft: 24,
    spacingRight: 24,
    spacingTop: 48,
    selectionMarkerFill: "rgba(0,0,0,0.05)",
    resetZoomButton: {
      position: {
        x: 0,
        y: 8,
      },
    },
    style: {
      fontFamily: "Proxima",
    },
    events: chartEvents,
  },
  lang: {
    noData: "Retrieving data...",
  },

  exporting: {
    chartOptions: {
      chart: {
        style: {
          fontFamily: "Helvetica",
        },
      },
      yAxis: [
        {
          labels: {
            style: {
              font: "9px Helvetica",
            },
          },
          title: {
            text: title(),
          },
        },
      ],
      xAxis: [
        {
          type: "datetime",
          dateTimeLabelFormats: {
            hour: "%H:%M",
            minute: "%H:%M",
          },
          labels: {
            style: {
              font: "9px Helvetica",
            },
          },
        },
      ],
    },
    enabled: false,

    csv: {
      decimalPoint: ".",
      lineDelimiter: "\n",
    },
  },

  title: {
    text: null,
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    shared: true,
    shadow: false,
    backgroundColor: "white",
    borderColor: "#E6E9EB",
    pointFormatter() {
      const value = title() === "bits" ? bitsPipe.transform(this.y) : packetPipe.transform(this.y);
      const label = this.series.name;
      return `<span style="color:${this.color}">\u25CF</span> ${label}: <b>${value}</b><br/>`;
    },
  },
  yAxis: {
    title: {
      text: null,
    },

    gridLineColor: "#E6E9EB",
    labels: {
      style: {
        color: "#7f8c8d",
        font: "9px Proxima",
      },
    },
    // minRange: 10000
  },
  xAxis: {
    type: "datetime",
    dateTimeLabelFormats: {
      hour: "%H:%M",
      minute: "%H:%M",
    },
    minPadding: 0,
    maxPadding: 0,
    lineColor: "transparent",
    minorTickLength: 0,
    tickLength: 0,
    labels: {
      style: {
        color: "#7f8c8d",
        font: "9px Proxima",
      },
    },
  },
  credits: {
    enabled: false,
  },
  series: [
    {
      name: "Filtered traffic",
      marker: {
        enabled: false,
        symbol: "circle",
        radius: 2,
      },
      data: [],
      color: "#177abf",
      lineWidth: 1,
      fillColor: {
        linearGradient: { x1: 1, y1: 0, x2: 0, y2: 1 },
        stops: [
          [0, "rgba(231, 134, 122, 0.15)"],
          [1, "rgba(255,255,255,.0)"],
        ],
      },
    },
  ],
});

export const generateMockData = (start: number, end: number, step: number): DdosFilteredTraffic[] => {
  const statNames = [
    "tcp-fragments",
    "dns",
    "chargen",
    "cldap",
    "mdns",
    "memcached",
    "ntp",
    "rip",
    "rpc_portmapper",
    "sentinel",
    "snmp",
    "ssdp",
    "udp-fragments",
    "charge",
    "cldap",
    "mdns",
  ];
  function* randomDatapoints() {
    const waveAt = (sampleNumber: number, frequency: number) => Math.sin(sampleNumber / (frequency / (Math.PI * 2)));
    let sampleN = 0;
    const tone = [293, 369, 440, 554].sort((a, b) => Math.random() - 0.5)[0]; // Dmaj7
    const sampleR = [4096, 8192, 9216, 10240].sort((a, b) => Math.random() - 0.5)[0];
    const freq = sampleR / tone;
    for (let i = start; i <= end; i += step) {
      yield [i * 1000, Math.floor(waveAt(++sampleN, freq) * sampleR)];
    }
  }
  function* mocker(): Generator<DdosFilteredTraffic, void, unknown> {
    for (const stat of statNames) {
      yield {
        name: stat,
        ddos_stat: [...randomDatapoints()],
      };
    }
  }
  return [...mocker()];
};
