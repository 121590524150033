export class CurrentUser {
  authenticatingAuthority: string;
  authorities: string[];
  displayName: string;
  eduPersonPrincipalName: string;
  customerId: string;
  organizationName: string;
  roles: string[];
  teams: string[];

  constructor() {
    this.roles = ["read", "write"];
  }

  public get viewingOrganizationGUID() {
    const org = localStorage.getItem("viewingCustomerGUID");
    if (org === null) {
      return this.organizationGUID();
    }
    return org;
  }

  get canEdit() {
    return (this.roles || []).includes("write");
  }

  get canReport() {
    return (this.roles || []).includes("read");
  }

  get canSwitch() {
    return true;
  }

  public organizationGUID() {
    return this.customerId;
  }
}
