import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { KpiItem, KPI_ITEMS_AEROHIVE, KPI_ITEMS_ARUBA, getStatusColor } from "../../../helpers/kpi/kpi-item";

@Component({
  selector: "kpi-row",
  templateUrl: "kpi-row.html",
  styleUrls: ["kpi-row.scss"],
})
export class KpiRowComponent implements OnChanges {
  @Input() healthData: {
    activeAps: number;
    inactiveAps: number;
    kpiAverages: { [key: string]: number }[];
  };
  @Input() vendor: string;

  public kpiValues: { value: number; item: KpiItem }[] = [];

  statusColor = getStatusColor;

  ngOnChanges(changes: SimpleChanges) {
    if (changes["healthData"]) {
      if (this.healthData && this.healthData.kpiAverages) {
        this.updateKpiAverages(this.healthData.kpiAverages);
      }
    }
  }

  updateKpiAverages(kpiAverages: { [key: string]: number }[]) {
    this.kpiValues = this.getKpiItems().map((kpiItem) => {
      let kpiValue = kpiAverages[kpiItem.key];
      if (!kpiItem.percentage) {
        kpiValue = parseFloat(kpiValue).toFixed(2);
      }

      return { value: kpiValue, item: kpiItem };
    });
  }

  getKpiItems(): KpiItem[] {
    const vendor = this.vendor?.toLowerCase()?.trim()?.replace(" ", "-");
    if (vendor === "aerohive") {
      return KPI_ITEMS_AEROHIVE;
    } else if (vendor === "aruba") {
      return KPI_ITEMS_ARUBA;
    } else {
      console.warn(`Unknown wifi vendor: ${vendor}.`);
      return [];
    }
  }
}
