import { Component } from "@angular/core";

@Component({
  selector: "cert-header",
  templateUrl: "cert-header.html",
  styleUrls: ["cert-header.scss"],
})
export class CertHeaderComponent {
  constructor() {}
}
