<div class="networkgraph">
  <div class="networkgraph--container" #chartContainer>
    <highcharts-chart
      [Highcharts]="highcharts"
      [options]="chartOptions"
      (chartInstance)="setChartInstance($event)"
      style="width: 100%; display: block"
    ></highcharts-chart>
  </div>
</div>
