import { gql } from "apollo-angular";

export const IpStaticSubscriptionFragment = gql`
  fragment IpStaticSubscriptionDetails on SubscriptionInterface {
    ... on Sn8IpStaticSubscription {
      ...SubscriptionDetails
      ...Product

      domain
      ipRoutingType

      vc {
        subscriptionInstanceId
        name

        serviceSpeed

        settings {
          surfcertFilterEnabled
          surfcertFilter
          subscriptionInstanceId
          name
          multicast
          pinPrefix {
            ownerSubscriptionId
          }
        }

        sap {
          subscriptionInstanceId
          name

          customerIpv4Mtu
          customerIpv6Mtu
          prefixes {
            ownerSubscriptionId
          }

          ptpIpv4Ipam {
            ...ptpIpamInfo
          }

          ptpIpv6Ipam {
            ...ptpIpamInfo
          }

          sap {
            ...Sn8SapBlockInfo
          }
        }
      }
    }
  }
`;
