import { gql } from "apollo-angular";

export const fragmentCimNotification = gql`
  fragment cimNotification on Notification {
    category
    endTimestamp
    heading
    id
    impact
    planTimestamp
    startTimestamp
    status
    summary
    tags
    updates {
      language
      description
      timestamp
    }
    urgency
  }
`;

export const fragmentCimAffectedSubscriptions = gql`
  fragment cimAffectedSubscriptions on Notification {
    affectedSubscriptions @include(if: $includeAffectedSubscriptions) {
      subscriptionId
      impactType
      subscription {
        subscriptionId
        customerId
        description
        product {
          type
          name
        }
        customerDescriptions {
          customerId
          description
        }
      }
    }
  }
`;

export const CIM_QUERY = gql`
  ${fragmentCimNotification}
  ${fragmentCimAffectedSubscriptions}
  query CimNotifications($filter: NotificationFilter!, $includeAffectedSubscriptions: Boolean = true) {
    notifications(filter: $filter) {
      ...cimNotification
      ...cimAffectedSubscriptions
    }
  }
`;

export const CIM_SUBSCRIPTION = gql`
  ${fragmentCimNotification}
  query CimNotificationsSubscription($filter: NotificationFilter!) {
    notifications(filter: $filter) {
      ...cimNotification
    }
  }
`;
