/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { StrictHttpResponse } from "../../strict-http-response";
import { RequestBuilder } from "../../request-builder";

import { RecordsUpdateStatus } from "../../models/records-update-status";
import { Status } from "../../models/status";

export interface DeleteZone$Params {
  organisation_id: string;
  zone_id: string;
  token?: string | null;
  check?: boolean;
}

export function deleteZone(
  http: HttpClient,
  rootUrl: string,
  params: DeleteZone$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<Status | RecordsUpdateStatus>> {
  const rb = new RequestBuilder(rootUrl, deleteZone.PATH, "delete");
  if (params) {
    rb.path("organisation_id", params.organisation_id, {});
    rb.path("zone_id", params.zone_id, {});
    rb.query("token", params.token, {});
    rb.query("check", params.check, {});
  }

  // add this header so we are able to examine the failure response
  // otherwise it's intercepted by the http interceptor
  rb.header("x-ignore-interceptor", "1");

  return http.request(rb.build({ responseType: "json", accept: "application/json", context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Status | RecordsUpdateStatus>;
    }),
  );
}

deleteZone.PATH = "/organisations/{organisation_id}/zones/{zone_id}";
