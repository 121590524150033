import { gql } from "apollo-angular";
import { ProductFragment, SubscriptionDetailsFragment } from "./fragments";
import { ImsPortLocationFragment, ImsServicePortLocationFragment } from "./imsInfo";

const L3VpnPortSubscriptionFragment = gql`
  ${ImsPortLocationFragment}
  fragment L3VpnPortSubscription on SubscriptionInterface {
    ... on Sn8ServicePortSubscription {
      domain
      startDate
      portSpeed

      port {
        subscriptionInstanceId
        name
        autoNegotiation
        portMode
        ims {
          ...ImsPortLocation
        }
      }
    }
  }
`;

const L3VpnMscSubscriptionFragment = gql`
  ${ImsServicePortLocationFragment}
  ${ImsPortLocationFragment}
  fragment L3VpnMscSubscription on SubscriptionInterface {
    ... on Sn8MscSubscription {
      domain
      startDate
      port {
        subscriptionInstanceId
        name
        ims {
          ...ImsServicePortLocation
        }
        port {
          subscriptionType: __typename
          subscriptionId
          ... on Sn8ServicePortSubscription {
            portSpeed
            port {
              subscriptionInstanceId
              ims {
                ...ImsPortLocation
              }
            }
          }
          ... on Sn8AggregatedServicePortSubscription {
            port {
              port {
                ... on Sn8ServicePortSubscription {
                  portSpeed
                }
              }
            }
          }
        }
      }
    }
  }
`;

const L3VpnIrbServicePortSubscriptionFragment = gql`
  ${ImsPortLocationFragment}
  fragment L3VpnIrbServicePortSubscription on SubscriptionInterface {
    ... on Sn8IrbServicePortSubscription {
      subscriptionId
      domain
      startDate
      port {
        subscriptionInstanceId
        name
        ims {
          ...ImsPortLocation
        }
      }
    }
  }
`;

const L3VpnAggregatedServicePortSubscriptionFragment = gql`
  ${ImsServicePortLocationFragment}
  fragment L3VpnAggregatedServicePortSubscription on SubscriptionInterface {
    ... on Sn8AggregatedServicePortSubscription {
      domain
      startDate
      linkmembers: port {
        subscriptionInstanceId
        name
        portMode
        ims {
          ...ImsServicePortLocation
        }
        port {
          ... on Sn8ServicePortSubscription {
            portSpeed
            subscriptionId
          }
        }
      }
    }
  }
`;

const L3VpnSn8SapBlockInfoFragment = gql`
  ${L3VpnPortSubscriptionFragment}
  ${L3VpnMscSubscriptionFragment}
  ${L3VpnIrbServicePortSubscriptionFragment}
  ${L3VpnAggregatedServicePortSubscriptionFragment}
  fragment L3VpnSn8SapBlockInfo on Sn8ServiceAttachPointBlock {
    subscriptionInstanceId
    portSubscriptionId
    name
    vlanrange

    port {
      subscriptionType: __typename

      ...SubscriptionDetails
      ...Product
      ...L3VpnAggregatedServicePortSubscription
      ...L3VpnIrbServicePortSubscription
      ...L3VpnMscSubscription
      ...L3VpnPortSubscription
    }
  }
`;

export const L3VpnSubscriptionFragment = gql`
  ${SubscriptionDetailsFragment}
  ${ProductFragment}
  ${L3VpnSn8SapBlockInfoFragment}
  fragment L3VpnSubscriptionDetails on SubscriptionInterface {
    ... on Sn8L3VpnSubscription {
      ...SubscriptionDetails
      ...Product

      domain

      dependsOn(productTypeFilter: "FW") {
        ... on FwSubscription {
          subscriptionId

          firewall {
            subscriptionInstanceId
            availabilityZoneName
            asn
            customerAsn

            l3Endpoints {
              subscriptionInstanceId
              saps {
                subscriptionInstanceId
              }
            }
          }
        }
      }

      vpnvc: vc {
        subscriptionInstanceId
        name
        speedPolicer
        serviceSpeed
        specificTemplate

        settings {
          subscriptionInstanceId
          name
          asn
        }

        saps {
          subscriptionInstanceId
          asn
          name
          bfd
          bfdMinimumInterval
          bfdMultiplier
          ipv4Address
          ipv6Address
          ipv4RemoteAddress
          ipv6RemoteAddress
          customerIpv4Mtu
          customerIpv6Mtu
          ipv4MaxPrefix
          ipv6MaxPrefix
          urpf
          endpointRole

          sap {
            ...L3VpnSn8SapBlockInfo
          }
        }
      }
    }
  }
`;

export const L3VpnSubscriptionConnectionsFragment = gql`
  ${SubscriptionDetailsFragment}
  ${ProductFragment}
  ${L3VpnSn8SapBlockInfoFragment}
  fragment L3VpnSubscriptionConnectionDetails on SubscriptionInterface {
    ... on Sn8L3VpnSubscription {
      dependsOn(productTypeFilter: "FW") {
        ... on FwSubscription {
          ...SubscriptionDetails
          ...Product
          subscriptionId

          firewall {
            subscriptionInstanceId
            availabilityZoneName
            asn
            customerAsn

            l3Endpoints {
              subscriptionInstanceId
              saps {
                subscriptionInstanceId
              }
            }
          }
        }
      }

      vpnvc: vc {
        subscriptionInstanceId
        name
        speedPolicer
        serviceSpeed
        specificTemplate

        settings {
          subscriptionInstanceId
          name
          asn
        }

        saps {
          subscriptionInstanceId
          asn
          name
          bfd
          bfdMinimumInterval
          bfdMultiplier
          ipv4Address
          ipv6Address
          ipv4RemoteAddress
          ipv6RemoteAddress
          customerIpv4Mtu
          customerIpv6Mtu
          ipv4MaxPrefix
          ipv6MaxPrefix
          urpf
          endpointRole

          sap {
            ...L3VpnSn8SapBlockInfo
          }
        }
      }
    }
  }
`;
