/**
 * Convenient access to items in localStorage,
 * as managed by AuthService.
 */
export class StorageHelper {
  private static _instance: StorageHelper;
  public storageMethod = "local";

  public constructor() {
    this.storageMethod = "local";

    if (navigator.storage && navigator.storage.persist) {
      // this will prompt the user to allow persistent storage
      // if they haven't already been prompted.
      //
      // If the user denies, we'll fall back to temporary storage.
      // That means that in case of increased storage pressure (i.e. low disk space)
      // the browser may delete our data (or parts of it).
      navigator.storage.persist().then((granted) => {
        if (granted) {
          this.storageMethod = "persistent";
        } else {
          this.storageMethod = "temporary";
        }
      });
    }
  }

  static get instance(): StorageHelper {
    if (!StorageHelper._instance) {
      StorageHelper._instance = new StorageHelper();
    }
    return StorageHelper._instance;
  }

  static get currentUser(): string {
    return localStorage.getItem("viewingCustomerGUID");
  }

  static async getNavigatorStorageStatus(): Promise<StorageEstimate> {
    const estimate = await navigator.storage.estimate();
    return {
      quota: estimate.quota,
      usage: estimate.usage,
    };
  }
}
