export class ModifyDdosFilterCommand {
  private filterstatus: boolean;

  constructor(filterstatus: boolean) {
    this.filterstatus = filterstatus;
  }

  get payload() {
    return { filter: this.filterstatus };
  }
}
