<div>
  <div class="kpi-row__title">Key performance indicators</div>
  <div class="kpi-row">
    <div
      *ngFor="let kpi of kpiValues"
      class="kpi-row__cell"
      [matTooltip]="kpi.item.name"
      [matTooltipPosition]="'above'"
    >
      <div>
        <!--  "| number: '1.1-1'" is added to ensure always 1 decimal is shown -->
        {{kpi.value ? (kpi.value | number: '1.1-1') : '?' }}<span *ngIf="kpi.value && kpi.item.percentage">%</span>
      </div>
      <i class="{{statusColor(kpi.value, kpi.item.danger, kpi.item.warning)}}"></i>
    </div>
  </div>
</div>
