import { IpPrefixSubscription } from "../../gql/generated";
import { transform } from "../../helpers/subscription/ip-prefix";
import { Instance } from "./instance";

export class IpPrefixInstance extends Instance {
  constructor(data: IpPrefixSubscription) {
    super();
    const transformedSubscription = transform(data);
    Object.entries(transformedSubscription).map((entry) => {
      this[entry[0]] = entry[1];
    });
  }
}
