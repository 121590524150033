export const getFilteredQuery = (query, subscriptions) => {
  const filteredSubscriptions = subscriptions.filter((subscription) => {
    // make search case insensitive
    const searchQuery = query.toLowerCase();
    const name = subscription.customerDescription.toLowerCase();

    // Search on name
    if (name.includes(searchQuery)) {
      return true;
    }

    return false;
  });
  return filteredSubscriptions;
};
