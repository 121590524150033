export const collectTags = (subscriptions: any[], filterString?: string) => {
  const tags = {};
  subscriptions.map((sub) => {
    if (!sub.tags) {
      return null;
    }

    return sub.tags.map((tag) => {
      if (!filterString || tag.indexOf(filterString) !== -1) {
        tags[tag] = 1;
      }
    });
  });
  return Object.keys(tags);
};
