import { ProductType } from "../../helpers/enums/productType";

export const allServices = {
  [ProductType.IP]: {
    url: "https://www.surf.nl/diensten/internet",
  },
  [ProductType.LightPath]: {
    url: "https://www.surf.nl/diensten/evpn",
  },
  [ProductType.L2VPN]: {
    url: "https://www.surf.nl/diensten/evpn",
  },
  [ProductType.Port]: {
    url: "https://wiki.surfnet.nl/display/SURFnetnetwerkWiki/SURF+Service+Poorten",
  },
  [ProductType.L3VPN]: {
    url: "https://wiki.surfnet.nl/display/SURFnetnetwerkWiki/L3VPN+-+Multipoint",
  },
  [ProductType.IPPrefixes]: {
    url: "https://www.surf.nl/surfinternet-een-snelle-betrouwbare-internetverbinding",
  },
  [ProductType.Wireless]: {
    url: "https://www.surf.nl/diensten/surfwireless",
  },
  [ProductType.Firewall]: {
    url: "https://www.surf.nl/surffirewall-een-goed-afgeschermd-netwerk-zonder-zorgen",
  },
  [ProductType.SURFcert]: {
    url: "https://www.surf.nl/surfcert-247-ondersteuning-bij-beveiligingsincidenten",
  },
  [ProductType.SURFdomains]: {
    url: "https://www.surf.nl/surfdomeinen-eenvoudig-internetdomeinen-registreren-en-beheren",
  },
};
