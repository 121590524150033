import { humanizeMbit } from "../../../helpers/bitspipe/bitspipe";
import { getPortId, toPortImsInfo, toPortMode, transformShared } from "../port";

const aggspPortSpeed = (subscription: any): number => {
  const ports = subscription.port?.port ?? [];
  const portSpeed = (port: any) => {
    const subscriptionType = port.subscriptionType;
    switch (subscriptionType) {
      case "Sn8ServicePortSubscription":
        return Number(port.portSpeed);
      default:
        console.warn(`Unknown port speed for subscription ${subscriptionType}`);
        return 0;
    }
  };

  return ports.reduce((acc, val) => acc + portSpeed(val), 0);
};

export const transformAggsp = (subscription: any) => {
  const port = subscription.aggspPort;
  const portMode = toPortMode(port?.portMode);
  const portSpeed = aggspPortSpeed(subscription);
  const domain = subscription.domain;
  const portId = getPortId(domain, port);

  function* getTags() {
    if (portSpeed) {
      yield humanizeMbit(portSpeed);
    }
    yield portMode;
  }

  const linkMemberSubscriptions = port?.portSubscriptionIds ?? [];
  const transformedSubscription = transformShared(subscription, getTags());

  const { surfLocationCode, address, locationDescription } = toPortImsInfo(subscription);

  return {
    ...transformedSubscription,
    locationDescription,
    ieeeInterfaceType: "Aggregate",
    portMode,
    surfLocationCode,
    portSpeed,
    portId,
    subscriptionInstanceId: port?.subscriptionInstanceId,
    address,
    linkMemberSubscriptions,
  };
};
