<div>
  <span class="alias" *ngIf="!isEditable">
    {{ customerDescription }}
    <div class="alias__icon" role="button" aria-label="Edit" (click)="startEditingCustomerDescription()">
      <svg-icon src="assets/images/icons/edit.svg"></svg-icon>
    </div>
  </span>
  <div *ngIf="isEditable" class="alias__form">
    <input
      [(ngModel)]="temporarySubscriptionName"
      class="input"
      type="text"
      role="textbox"
      value="{{ customerDescription }}"
      autofocus
    />
    <span
      class="alias__form-button alias__form-button--save"
      role="button"
      aria-label="Save"
      (click)="saveCustomerDescription()"
    >
      <svg-icon src="assets/images/icons/tick.svg"></svg-icon>
    </span>
    <span
      role="button"
      aria-label="Cancel"
      class="alias__form-button alias__form-button--cancel"
      (click)="cancelEditingCustomerDescription()"
    >
      <svg-icon src="assets/images/icons/x.svg"></svg-icon>
    </span>
  </div>
</div>
