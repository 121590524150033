export class SurfLog {
  static colors = {
    error: "#ff0000",
    warning: "#ffa500",
    info: "#888888",
  };

  static output(message: string, color: string): void {
    console.log("%c%s", `color: ${SurfLog.colors[color]}`, message);
  }

  static err(message: string): void {
    SurfLog.output(message, "error");
  }
  static warn(message: string): void {
    SurfLog.output(message, "warning");
  }
  static info(message: string): void {
    SurfLog.output(message, "info");
  }
}
