import { humanizeMbit } from "../../bitspipe/bitspipe";
import { transformShared } from "../port";
import { IrbPortSpeed } from "../shared";

export const transformIrb = (subscription: any) => {
  function* getTags() {
    yield humanizeMbit(IrbPortSpeed);
  }

  const transformedSubscription = transformShared(subscription, getTags());
  return {
    ...transformedSubscription,
    portSpeed: IrbPortSpeed,
  };
};
