import { HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

// a simple cache service for the REST API.
// a select number of API calls can be cached for a short period of time
// (e.g. the subscription counts).

@Injectable()
export class CacheService {
  private cache: Map<string, CacheOptions> = new Map();

  constructor() {}

  public has(key: string): boolean {
    return this.cache.has(key);
  }

  public set(key: string, value: any, ttl: number = 120) {
    this.cache[key] = {
      data: JSON.stringify(value),
      expiration: new Date().getTime() + 1000 * ttl,
    };
  }

  public get(key: string) {
    const item = this.cache[key];
    const now = new Date().getTime();

    if (!item || item.expiration < now) {
      this.remove(key);
      return null;
    }
    return JSON.parse(item.data);
  }

  public remove(key: string) {
    delete this.cache[key];
  }
}

export interface CacheOptions {
  data: HttpResponse<any>;
  expiration: number;
}
