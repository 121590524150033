<div class="dialog dialog-roles">
  <div class="dialog__header">
    <h3 class="dialog__title" [innerHTML]="data.title"></h3>
    <svg-icon src="assets/images/icons/x.svg" class="dialog__close" (click)="closeDialog()"></svg-icon>
  </div>
  <div class="dialog__body dialog__body--grow dialog__body--scroll">
    <section *ngIf="currentStep === 0">
      <p
        [translateParams]="{ organisation: auth.state.currentUser.organizationName }"
        [translate]="'Role.NoContactsFound'"
      ></p>
    </section>
    <section *ngIf="currentStep === 1">
      <p [innerHTML]="data.description"></p>
      <ul class="dialog-roles__role-list">
        <li *ngFor="let role of filteredRoles">
          <div class="dialog-roles__role-title">
            <span>{{ role.title }}</span>
            <span class="dialog-roles__current-role blue" *ngIf="hasRole(role.key)">
              <svg-icon src="assets/images/icons/tick.svg"></svg-icon>
              <span>{{ 'Role.CurrentRole' | translate }}</span>
            </span>
            <span class="dialog-roles__current-role grey" *ngIf="!hasRole(role.key)">
              <span>{{ 'Role.Required' | translate }}</span>
            </span>
          </div>
          <div class="dialog-roles__role-description">{{ role.description }}</div>
        </li>
      </ul>
    </section>
    <section *ngIf="currentStep === 2">
      <p
        [translateParams]="{ organisation: auth.state.currentUser.organizationName }"
        [translate]="'Role.Step2.Title'"
      ></p>

      <ul class="dialog-roles__role-list">
        <li *ngFor="let role of filteredRoles">
          <div class="dialog-roles__role-title">
            <span>{{ role.title }}</span>
            <selfservice-toggle [active]="isRequested(role.key)" (click)="toggleRole(role.key)"></selfservice-toggle>
          </div>
          <div class="dialog-roles__role-description">{{ role.description }}</div>
        </li>
      </ul>
    </section>
  </div>
  <div class="dialog__footer">
    <div></div>
    <div class="button__flex">
      <button class="button button--secondary" mat-dialog-close>{{ 'Global.Action.Cancel' | translate }}</button>
      <button *ngIf="currentStep === 1" class="button button--primary" (click)="currentStep = 2">
        {{ 'Global.Action.RequestAuthorization' | translate }}
      </button>
      <a *ngIf="currentStep === 2" class="button button--primary" mat-dialog-close [href]="generatedMail">
        {{ 'Global.Action.Request' | translate }}
      </a>
    </div>
  </div>
</div>
