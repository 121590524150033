<navigation [activeItem]="" [isSticky]="false"></navigation>
<div class="container">
  <div class="ip-prefixes__header">
    <h3>
      {{ 'Prefixes.header1' | translate }}{{ subscriptions?.length - plannedSubscriptions?.length }}{{
      'Prefixes.header2' | translate }}{{ plannedSubscriptions?.length || 0 }}{{ 'Prefixes.header3' | translate }}
    </h3>
    <div>
      <div *ngIf="userCanAddPrefixes()" class="overflow-menu" (clickOutside)="overflowMenuIPActive = false">
        <button
          class="overflow-menu__button button button--small button--secondary"
          [ngClass]="{ 'active' : overflowMenuIPActive }"
          (click)="overflowMenuIPActive = !overflowMenuIPActive"
        >
          <svg-icon src="assets/images/icons/+.svg" class="button__icon"></svg-icon>
          <span> {{ 'Global.NewAdd' | translate }}</span>
        </button>
        <div class="overflow-menu__items">
          <div class="overflow-menu__item" (click)="openRequestPrefixDialog($event)">
            {{ 'OverflowMenu.RequestPrefix' | translate }}
          </div>
          <div class="overflow-menu__item" (click)="openAddPrefixDialog($event)">
            {{ 'OverflowMenu.AddPrefix' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card card--no-padding">
    <div class="table-wrapper">
      <table matSort (matSortChange)="sortData($event)" matSortActive="description" matSortDirection="asc">
        <thead>
          <tr>
            <th class="asc" mat-sort-header="description">
              <div>
                <span>{{ 'Prefixes.Table.Description' | translate }}</span>
              </div>
            </th>
            <th class="desc" mat-sort-header="prefix">
              <div>
                <span>IP prefix</span>
              </div>
            </th>
            <th mat-sort-header="startdate">
              <div>
                <span>{{ 'Prefixes.Table.StartDate' | translate }}</span>
              </div>
            </th>
            <th mat-sort-header="version">
              <div>
                <span>{{ 'Prefixes.Table.Version' | translate }}</span>
              </div>
            </th>
            <th mat-sort-header="in_use">
              <div>
                <span>{{ 'Prefixes.Table.InUse' | translate }}</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let sub of sortedData" hidden="{{sub.hidden}}">
            <td>{{ sub.description }}</td>
            <td>{{ sub?.ipPrefix?.prefix }}</td>
            <td class="nowrap">{{ sub?.startDate * 1000 | date:'d MMM y, hh:mm' }}</td>
            <td>{{ ipPrefixVersion(sub) }}</td>
            <td>
              <div class="table-properties__flex">
                <div
                  class="table-link"
                  (click)="openDialog(sub, sub?.ipSubscriptions)"
                  *ngIf="sub?.ipSubscriptions?.length > 0"
                >
                  {{ sub?.ipSubscriptions?.length }} {{ sub?.ipSubscriptions?.length === 1 ? ('Ipprefixes.Service' |
                  translate) : ('Ipprefixes.Services' | translate) }}
                </div>

                <div *ngIf="sub?.ipSubscriptions?.length === 0">
                  <b>{{ 'Ipprefixes.NotInuse' | translate }}</b>
                </div>

                <div
                  *ngIf="userCanEditPrefixes(sub) && !sub?.ip_prefix?.prefix?.startsWith('145.98')"
                  class="table-overflow-menu"
                >
                  <div class="table-settings">
                    <svg-icon src="assets/images/icons/cog.svg"></svg-icon>
                  </div>
                  <div class="overflow-menu__items">
                    <div class="overflow-menu__item" (click)="openAddDialog(sub)">
                      {{ 'OverflowMenu.Link' | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
