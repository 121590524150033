<div (clickOutside)="onClickedOutside()">
  <div class="completer__search" (click)="searchIsActive()">
    <input
      class="completer-input"
      type="text"
      (keyup)="search()"
      [(ngModel)]="location"
      matchClass="match"
      minSearchLength="1"
      placeholder="{{ 'Services.Filter.EnterDestination' | translate }}"
    />
  </div>

  <div *ngIf="searchActive" class="completer-dropdown-holder">
    <ul class="completer-dropdown">
      <li *ngFor="let endpoint of selectedLocations">
        <label class="completer__container completed__selected">
          <input
            type="checkbox"
            class="completer-checkbox"
            [value]="endpoint"
            checked="checked"
            (change)="handleCheck($event.target)"
          />
          <span class="checkmark">
            <svg-icon src="assets/images/icons/tick.svg"></svg-icon>
          </span>
          <span class="completer__label">{{ endpoint }}</span>
        </label>
      </li>
      <ng-container *ngFor="let s of searchResults">
        <li *ngIf="!isLocationFiltered(s)">
          <label class="completer__container">
            <input type="checkbox" class="completer-checkbox" [value]="s" (change)="handleCheck($event.target)" />
            <span class="checkmark">
              <svg-icon src="assets/images/icons/tick.svg"></svg-icon>
            </span>
            <span class="completer__label">{{ s }}</span>
          </label>
        </li>
      </ng-container>
    </ul>
  </div>
</div>

<div class="completer-bottom-results">
  <label *ngFor="let endpoint of selectedLocations" class="completer-bottom-result">
    <input type="checkbox" [value]="endpoint" checked="checked" (change)="handleCheck($event.target)" />
    <div class="test">{{ endpoint }}</div>
    <svg-icon src="assets/images/icons/x.svg"></svg-icon>
  </label>
</div>
