<div class="dialog dialog-generic">
  <div class="dialog-generic__flex">
    <i *ngIf="state === 'success'" class="dialog-generic__success">
      <svg-icon src="assets/images/icons/tick.svg"></svg-icon>
    </i>
    <i *ngIf="state === 'error'" class="dialog-generic__error">
      <svg-icon src="assets/images/icons/x.svg"></svg-icon>
    </i>
    <div class="dialog-generic__body">
      <h3 *ngIf="title" class="dialog-generic__title">{{ title }}</h3>
      <div *ngIf="message" class="dialog-generic__text">{{ message }}</div>
      <div>
        <pre *ngIf="code">{{ code }}</pre>
      </div>

      <button class="button button--secondary" mat-dialog-close>{{ 'Global.Action.Close' | translate }}</button>
    </div>
  </div>
</div>
