<div class="dialog dialog--small">
  <div>
    <div class="dialog__header">
      <h3 class="dialog__title">
        <span
          >{{ 'Malfunction.AffectedServices' | translate }} ({{ data.message.affected_subscriptions?.length }})</span
        >
      </h3>
      <svg-icon src="assets/images/icons/x.svg" class="dialog__close" (click)="closeDialog()"></svg-icon>
    </div>
    <div class="affected-service-item__wrapper">
      <div
        class="affected-service-item"
        *ngFor="let subscription of data.message.affected_subscriptions; let i = index"
        (click)="segueToService(subscription)"
      >
        <div
          class="affected-service-item__icon-box affected-service-item__icon-box--lightpath"
          *ngIf="subscription.product_type === 'LightPath'"
        >
          <svg-icon src="assets/images/icons/lightpath.svg" class="affected-service-item__icon"></svg-icon>
        </div>
        <div
          class="affected-service-item__icon-box affected-service-item__icon-box--ip"
          *ngIf="subscription.product_type === 'IP'"
        >
          <svg-icon src="assets/images/icons/ip.svg" class="affected-service-item__icon"></svg-icon>
        </div>
        <div
          class="affected-service-item__icon-box affected-service-item__icon-box--l2vpn"
          *ngIf="subscription.product_type === 'L2VPN'"
        >
          <svg-icon src="assets/images/icons/l2vpn.svg" class="affected-service-item__icon"></svg-icon>
        </div>
        <div
          class="affected-service-item__icon-box affected-service-item__icon-box--l3vpn"
          *ngIf="subscription.product_type === 'L3VPN'"
        >
          <svg-icon src="assets/images/icons/l3vpn.svg" class="affected-service-item__icon"></svg-icon>
        </div>
        <div
          class="affected-service-item__icon-box affected-service-item__icon-box--port"
          *ngIf="subscription.product_type === 'Port'"
        >
          <svg-icon src="assets/images/icons/port.svg" class="affected-service-item__icon"></svg-icon>
        </div>
        <div
          class="affected-service-item__icon-box affected-service-item__icon-box--fw"
          *ngIf="subscription.product_type === 'FW'"
        >
          <svg-icon src="assets/images/icons/fw.svg" class="affected-service-item__icon"></svg-icon>
        </div>
        <div class="affected-service-item__caption">
          <div class="affected-service-item__title">
            <div>{{ subscription.customer_description }}</div>
            <div *ngIf="subscription.customer_description === ''">{{ subscription.description }}</div>
          </div>
          <div class="affected-service-item__subtitle">{{ subscription.impact_type }}</div>
        </div>
        <svg-icon class="affected-service-item__arrow" src="assets/images/icons/arrow-small-right.svg"> </svg-icon>
      </div>
    </div>
  </div>
</div>
