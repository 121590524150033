<div class="dialog dialog--small">
  <div class="onboarding">
    <div #flickityelement class="onboarding__carousel">
      <div *ngFor="let screen of screens" class="onboarding__slide">
        <div class="onboarding__aspect">
          <div
            *ngFor="let img of screen.imageUrl; let first = first"
            class="onboarding__image"
            [ngClass]="{'onboarding__image--from-right': screen.imageUrl.length > 0 && first }"
            [style.background-image]="'url(' + img + ')'"
          ></div>
        </div>
        <div class="onboarding__caption">
          <div class="onboarding__dots">
            <div
              class="onboarding__dot"
              (click)="flickityClick(i)"
              [ngClass]="{'active': info.active}"
              *ngFor="let info of dotInfo; let i = index"
            ></div>
          </div>
          <div class="onboarding__text">
            <h3 class="onboarding__heading">{{ screen.title }}</h3>
            <p>{{ screen.content }}</p>
          </div>
          <div class="onboarding__bottom">
            <div class="onboarding__checkbox-wrapper">
              <label for="skip-onboarding" class="onboarding__checkbox">
                <input [(ngModel)]="neverAgain" type="checkbox" id="skip-onboarding" />
                <div>
                  <div class="onboarding__checkbox-box"></div>
                  <div class="onboarding__checkbox-text">{{ 'Onboarding.Disable' | translate }}</div>
                </div>
              </label>
            </div>
            <span class="onboarding__skip" (click)="closeDialog()">{{ 'Global.Action.Skip' | translate }}</span>
            <button *ngIf="!screen.isLast" class="button" (click)="flickityNext()">
              {{ 'Global.Action.Next' | translate }}
            </button>
            <button *ngIf="screen.isLast" class="button" (click)="markAsCompleted()">
              {{ 'Global.Action.Complete' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
