import { IPPrefixServicePort } from "./models/types";

export enum MessagePart {
  DELETE = "DELETE",
  ADD = "ADD",
}

export class ModifyIpPrefixes {
  private add_prefix: IPPrefixServicePort[] = [];
  private delete_prefix: IPPrefixServicePort[] = [];

  get payload() {
    return {
      add_prefix: this.add_prefix,
      delete_prefix: this.delete_prefix,
    };
  }

  /**
   * Set a list of prefix subscription IDs for a service instance ID.
   *
   * @param instanceId string
   * @param prefixes list of prefix subscription IDs
   */
  public setForInstance(part: MessagePart, instanceId: string, prefixes: string[]) {
    const entry: IPPrefixServicePort = {
      subscription_instance_id: instanceId,
      ip_prefix_subscriptions: prefixes,
    };

    switch (part) {
      case MessagePart.DELETE:
        this.delete_prefix.push(entry);
        break;
      case MessagePart.ADD:
        this.add_prefix.push(entry);
        break;
    }
  }
}
