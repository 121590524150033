import { SelfServiceCommandKeys } from "../enums/selfServiceCommands";
import { Person } from "./models/types";
import { SelfServiceCommand } from "./self-service-command";

export class ResendConfirmation extends SelfServiceCommand {
  private persons: Person[] = [];

  get uri() {
    return `/email/${this.subscriptionId}`;
  }

  get json(): string {
    return JSON.stringify(this.persons);
  }

  addPerson(p: Person) {
    this.persons.push(p);
  }
}
