import {
  FwSubscription as FirewallSubscription,
  FwBlock,
  FwIpGwEndpointBlock,
  FwL2EndpointBlock,
  FwL3EndpointBlock,
} from "../../gql/generated";
import { gqlToSubscription, transformCustomerDescriptions, transformProduct } from "./shared";
import { translate } from "./tags";

const transformL2Endpoints = (endpoints: FwL2EndpointBlock[], customerId: string) => {
  function* getEndpoints() {
    const concat = (primary: any, secondary: any[]) => {
      const concatenated = primary ? [primary, ...secondary] : secondary;
      return concatenated.map((ptp: any) => ptp.prefix);
    };

    for (const endpoint of endpoints) {
      const otherSubscriptions = endpoint.esis[0].otherSubscriptions;
      if (otherSubscriptions && otherSubscriptions.length) {
        const { subscriptionId: otherSubscriptionId, description } = otherSubscriptions[0];
        yield {
          subscriptionInstanceId: endpoint.subscriptionInstanceId,
          otherSubscriptionId,
          description,
          ipv4Prefixes: concat(endpoint.customerPtpIpv4PrimaryIpam, endpoint.customerPtpIpv4SecondaryIpam),
          ipv6Prefixes: concat(endpoint.customerPtpIpv6PrimaryIpam, endpoint.customerPtpIpv6SecondaryIpam),
        };
      }
    }
  }

  return [...getEndpoints()];
};

const transformL3Endpoints = (endpoints: FwL3EndpointBlock[], customerId: string) => {
  function* getEndpoints() {
    for (const endpoint of endpoints) {
      const otherSubscriptions = endpoint.saps[0].otherSubscriptions;
      if (otherSubscriptions && otherSubscriptions.length) {
        const { subscriptionId: otherSubscriptionId, description } = otherSubscriptions[0];
        yield {
          subscriptionInstanceId: endpoint.subscriptionInstanceId,
          otherSubscriptionId,
          description,
        };
      }
    }
  }
  return [...getEndpoints()];
};

const transformIpGateway = (ipgw: FwIpGwEndpointBlock, speed: number) => {
  if (!ipgw) {
    return null;
  }

  const ip = ipgw.ip;
  const settings = ip.settings;
  const sap = ip.saps[0];
  const surfCertFilter = settings.surfcertFilterEnabled ? "enabled" : "disabled";
  const prefixSubscriptions = sap.prefixes.map((prefix) => prefix.ownerSubscriptionId);
  const pinnenPrefixes = settings.pinPrefix.map((prefix) => prefix.ownerSubscriptionId);

  return {
    subscriptionInstanceId: ipgw.subscriptionInstanceId,
    surfcertFilter: surfCertFilter,
    serviceSpeed: speed,
    prefixSubscriptions,
    internetpinnen: pinnenPrefixes.length > 0,
    internetpinnenPrefixSubscriptions: settings.pinPrefix,
    internetpinnen_ip_prefixes: pinnenPrefixes,
    ipv4Mtu: sap.customerIpv4Mtu || null,
    ipv6Mtu: sap.customerIpv6Mtu || null,
    multiCast: settings.multicast,
  };
};

export const transformFirewall = (firewall: FwBlock, size: number, customerId: string) => {
  const l2_endpoints = transformL2Endpoints(firewall.l2Endpoints || [], customerId);
  const l3_endpoints = transformL3Endpoints(firewall.l3Endpoints || [], customerId);
  const ipGateway = transformIpGateway(firewall.ipGwEndpoint, size);

  return {
    l2Endpoints: l2_endpoints,
    l3Endpoints: l3_endpoints,
    ipGateway,
    availabilityZone: firewall.availabilityZoneName,
    customerAsn: firewall.customerAsn,
    fwAsn: firewall.asn,
    nfvServiceId: firewall.nfvServiceId,
  };
};

export const transform = (subscription: FirewallSubscription) => {
  const organisation = subscription.customer;
  const tags = translate(subscription.product.type, ["NFV", "Redundant"]);
  const transformedSubscription = gqlToSubscription(subscription, tags);
  const firewall =
    subscription.firewall ? transformFirewall(subscription.firewall, subscription.size, subscription.customerId) : {};

  return {
    ...transformedSubscription,
    ...firewall,
    organisation: organisation.name,
    domain: subscription.domain,
    ipRoutingType: "BGP",
    product: transformProduct(subscription.product),
    customerDescriptions: transformCustomerDescriptions(subscription.customerDescriptions),
    firewallEnabled: true,
    serviceSpeed: subscription.size,
    tags,
  };
};
