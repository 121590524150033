import { TranslateService } from "@ngx-translate/core";

const constructWelcomeString = (translate: TranslateService, userName: string): Promise<string> =>
  new Promise((resolve) => {
    translate.get("Onboarding.Welkom").subscribe((translation: string) => {
      if (userName) {
        resolve(translation + " " + userName);
      }
      resolve(translation);
    });
  });
export default constructWelcomeString;
