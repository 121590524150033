import { gql } from "apollo-angular";
import { ProductFragment, Sn8SapBlockInfoFragment, SubscriptionDetailsFragment } from "./fragments";

export const L2VpnSubscriptionFragment = gql`
  ${SubscriptionDetailsFragment}
  ${ProductFragment}
  ${Sn8SapBlockInfoFragment}
  fragment L2VpnSubscriptionDetails on SubscriptionInterface {
    ... on Sn8L2VpnSubscription {
      ...SubscriptionDetails
      ...Product

      domain

      dependsOn(productTypeFilter: ["FW"]) {
        ... on FwSubscription {
          subscriptionId
          ...SubscriptionDetails
          ...Product
          firewall {
            availabilityZoneName
          }
        }
      }

      vc {
        subscriptionInstanceId
        name

        bumFilter
        serviceSpeed
        speedPolicer
        vlanRetagging

        esis {
          subscriptionInstanceId
          name

          inUseByIds
          otherSubscriptionIds

          saps {
            ...Sn8SapBlockInfo
          }
        }
      }
    }
  }
`;
