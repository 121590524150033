<div class="filter-bar" [ngClass]="{'active': filterBarActive}" (click)="toggleFilter()">
  <h3 class="filter-bar__title">
    <svg-icon
      *ngIf="filterIcon"
      src="assets/images/icons/{{filterIcon}}.svg"
      class="filter-bar__icon filter-bar__icon--{{filterColor}}"
    ></svg-icon>
    <span>{{filterTitle}}</span>
  </h3>
  <svg-icon src="assets/images/icons/x.svg" class="filter-bar__close"></svg-icon>
</div>
