import { Injectable, Input } from "@angular/core";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";
import { WebsocketService } from "./websocket.service";
import { ENV } from "../app/app.runtime";

const PROCESS_URL = "fulfilment/process/";

export interface Message {
  processName: string;
  percentage: number;
  status: string;
}

@Injectable()
export class SelfserviceStatusService {
  @Input() processId;
  public messages: Subject<Message>;

  constructor(private wsService: WebsocketService) {}

  public setProcessId(processId) {
    this.processId = processId;
    this.loadStatusMessages();
  }

  public close() {
    this.messages.unsubscribe();
    this.wsService.close();
  }

  private loadStatusMessages() {
    this.messages = this.wsService.connect(this.webserviceUrl()).pipe(
      map((response: MessageEvent): Message => {
        let data = JSON.parse(response.data);

        return {
          processName: data.processName,
          percentage: data.percentage,
          status: data.status,
        };
      }),
    ) as Subject<Message>;
  }

  private webserviceUrl() {
    const apiUrl = ENV.NWD_API_BASE_URL.replace(/^https:\/\//i, "wss://");
    return apiUrl + PROCESS_URL + this.processId;
  }
}
