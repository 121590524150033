import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { mockItem } from "./mockdata";

@Component({
  selector: "dialog-cert",
  templateUrl: "dialog-cert.html",
  styleUrls: ["dialog-cert.scss"],
})
export class DialogCertComponent {
  public activeTab = "details";
  public extraData: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogCertComponent>,
  ) {
    // this.data = mockItem;
    this.setExtraData();
  }

  setExtraData(): void {
    this.extraData = [];
    const makeDataEntry = (header: string, value: string) => ({ header, value });
    if (!this.data?.extra) {
      return;
    }

    for (const key of Object.keys(this.data?.extra)) {
      const value = this.data.extra[key];
      if (typeof value === "string") {
        this.extraData.push(makeDataEntry(key, value));
        continue;
      }
      if (Array.isArray(value)) {
        this.extraData.push(makeDataEntry(key, value.join(", ")));
      }
    }
  }

  changeTab(tab: string) {
    this.activeTab = tab;
  }
}
