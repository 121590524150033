<div class="cert-table card card--no-padding">
  <div class="table-wrapper">
    <table matSort matSortActive="ip" matSortDirection="asc" (matSortChange)="sortData($event)">
      <thead>
        <tr>
          <th class="asc" mat-sort-header="ip">
            <div>
              <span>{{ 'Cert.IPAddress' | translate }}</span>
            </div>
          </th>
          <th mat-sort-header="scan">
            <div>
              <span>{{ 'Cert.Scan' | translate }}</span>
            </div>
          </th>
          <th mat-sort-header="firstObservation">
            <div>
              <span>{{ 'Cert.First' | translate }}</span>
            </div>
          </th>
          <th mat-sort-header="lastObservation">
            <div>
              <span>{{ 'Cert.Last' | translate }}</span>
            </div>
          </th>
          <th>
            <div>
              <span>{{ 'Cert.NumberOfHits' | translate }}</span>
            </div>
          </th>
          <th mat-sort-header="severity">
            <div>
              <span>{{ 'Cert.Severity' | translate }}</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of items;">
          <td><div class="cert-table__link" (click)="openDialog(item)">{{ item?.node?.source?.ip || "---" }}</div></td>
          <td>{{ item?.node?.classification?.identifier }} {{item?.node?.extra?.reason }}</td>
          <td>{{ item?.node?.firstSeen }}</td>
          <td>{{ item?.node?.lastSeen }}</td>
          <td>{{ item?.node?.numberOfHits }}</td>
          <td>{{ item?.node?.severity }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="table-bottom">
    <pagination [pageInfo]="pageInfo" (paginationChanged)="onPaginationChanged($event)"></pagination>
  </div>
</div>
