/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { StrictHttpResponse } from "../strict-http-response";

import { domainAvailable } from "../fn/info/domain-available";
import { DomainAvailable$Params } from "../fn/info/domain-available";
import { getDnssecAlgorithms } from "../fn/info/get-dnssec-algorithms";
import { GetDnssecAlgorithms$Params } from "../fn/info/get-dnssec-algorithms";
import { getDnssecFlags } from "../fn/info/get-dnssec-flags";
import { GetDnssecFlags$Params } from "../fn/info/get-dnssec-flags";
import { getRtypes } from "../fn/info/get-rtypes";
import { GetRtypes$Params } from "../fn/info/get-rtypes";
import { getTlds } from "../fn/info/get-tlds";
import { GetTlds$Params } from "../fn/info/get-tlds";
import { NameValueTuple } from "../models/name-value-tuple";
import { TldInfo } from "../models/tld-info";

@Injectable({ providedIn: "root" })
export class InfoService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getRtypes()` */
  static readonly GetRtypesPath = "/info/rtypes";

  /**
   * Get Rtypes.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRtypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRtypes$Response(params?: GetRtypes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return getRtypes(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Rtypes.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRtypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRtypes(params?: GetRtypes$Params, context?: HttpContext): Observable<Array<string>> {
    return this.getRtypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body),
    );
  }

  /** Path part for operation `getTlds()` */
  static readonly GetTldsPath = "/info/tlds";

  /**
   * Get Tlds.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTlds()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTlds$Response(params?: GetTlds$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TldInfo>>> {
    return getTlds(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Tlds.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTlds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTlds(params?: GetTlds$Params, context?: HttpContext): Observable<Array<TldInfo>> {
    return this.getTlds$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TldInfo>>): Array<TldInfo> => r.body),
    );
  }

  /** Path part for operation `getDnssecFlags()` */
  static readonly GetDnssecFlagsPath = "/info/dnssec_flags";

  /**
   * Get Dnssec Flags.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDnssecFlags()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDnssecFlags$Response(
    params?: GetDnssecFlags$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<NameValueTuple>>> {
    return getDnssecFlags(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Dnssec Flags.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDnssecFlags$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDnssecFlags(params?: GetDnssecFlags$Params, context?: HttpContext): Observable<Array<NameValueTuple>> {
    return this.getDnssecFlags$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<NameValueTuple>>): Array<NameValueTuple> => r.body),
    );
  }

  /** Path part for operation `getDnssecAlgorithms()` */
  static readonly GetDnssecAlgorithmsPath = "/info/dnssec_algorithms";

  /**
   * Get Dnssec Algorithms.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDnssecAlgorithms()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDnssecAlgorithms$Response(
    params?: GetDnssecAlgorithms$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<NameValueTuple>>> {
    return getDnssecAlgorithms(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Dnssec Algorithms.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDnssecAlgorithms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDnssecAlgorithms(params?: GetDnssecAlgorithms$Params, context?: HttpContext): Observable<Array<NameValueTuple>> {
    return this.getDnssecAlgorithms$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<NameValueTuple>>): Array<NameValueTuple> => r.body),
    );
  }

  /** Path part for operation `domainAvailable()` */
  static readonly DomainAvailablePath = "/info/domain_available";

  /**
   * Domain Available.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `domainAvailable()` instead.
   *
   * This method doesn't expect any request body.
   */
  domainAvailable$Response(params: DomainAvailable$Params, context?: HttpContext): Observable<StrictHttpResponse<{}>> {
    return domainAvailable(this.http, this.rootUrl, params, context);
  }

  /**
   * Domain Available.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `domainAvailable$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  domainAvailable(params: DomainAvailable$Params, context?: HttpContext): Observable<{}> {
    return this.domainAvailable$Response(params, context).pipe(map((r: StrictHttpResponse<{}>): {} => r.body));
  }
}
