import {
  Component,
  Output,
  EventEmitter,
  ViewChild,
  ViewChildren,
  TemplateRef,
  ViewContainerRef,
  QueryList,
} from "@angular/core";
import { AuthService } from "../../../services/AuthService";
import { ApiHelper } from "../../../helpers/apihelper";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DialogEditComponent } from "../../../components/dialogs/dialog-edit/dialog-edit";
import { FulfilmentRequest } from "../../../components/models/fulfilmentRequest";
import { EditLightpathDirective } from "../../../components/dialogs/dialog/type/editLightpath";
import { EditLightpathOptionsDirective } from "../../../components/dialogs/dialog/type/editLightpathOptions";

@Component({
  selector: "service-element",
  templateUrl: "service-element.html",
})
export class ServiceElement {
  @Output() requestscrollevent = new EventEmitter();
  @ViewChild("addFulfilmentRequestModal")
  public addfulfilmentRequestTemplateRef: TemplateRef<any>;
  @ViewChild("editLightpathModal")
  private editLightpathTemplateRef: TemplateRef<any>;

  @ViewChild("editLightpathOptionsModal")
  private editLightpathOptionsTemplateRef: TemplateRef<any>;

  @ViewChildren(EditLightpathDirective)
  private queryListEditLightpath: QueryList<EditLightpathDirective>;

  @ViewChildren(EditLightpathOptionsDirective)
  private queryListEditLightpathOptions: QueryList<EditLightpathOptionsDirective>;

  public dialogRef: MatDialogRef<MatDialog>;

  public __currentEditableObject: any;
  public __fulfilmentRequest: FulfilmentRequest;
  public __fulfilmentRequestObject: any;

  public hasSubscriber = false;

  public FULFILMENT_SERVICE_REQUEST_PROBLEM: string = FulfilmentRequest.FULFILMENT_SERVICE_REQUEST_PROBLEM;
  public FULFILMENT_SERVICE_REQUEST_MODIFY: string = FulfilmentRequest.FULFILMENT_SERVICE_REQUEST_MODIFY;
  public FULFILMENT_SERVICE_REQUEST_DELETE: string = FulfilmentRequest.FULFILMENT_SERVICE_REQUEST_DELETE;
  public FULFILMENT_SERVICE_REQUEST_CREATE: string = FulfilmentRequest.FULFILMENT_SERVICE_REQUEST_CREATE;

  public FULFILMENT_PRODUCT_LIGHTPATH: string = FulfilmentRequest.FULFILMENT_PRODUCT_LIGHTPATH;
  public FULFILMENT_PRODUCT_L2VPN: string = FulfilmentRequest.FULFILMENT_PRODUCT_L2VPN;
  public FULFILMENT_PRODUCT_PORT: string = FulfilmentRequest.FULFILMENT_PRODUCT_PORT;
  public FULFILMENT_PRODUCT_IP: string = FulfilmentRequest.FULFILMENT_PRODUCT_IP;

  public colors: any = {
    ok: "green",
    notfound: "orange",
    "no-traffic": "orange",
    stopped: "red",
    unknown: "grey",
    warning: "orange",
  };

  constructor(
    public auth: AuthService,
    public dialog: MatDialog,
    public api: ApiHelper,
    public viewContainerRef: ViewContainerRef,
  ) {}

  openDialog(obj: any, editOptions: boolean): void {
    this.__currentEditableObject = obj;
    const edit_type_param = editOptions ? 2 : 1;
    const objectClass = this.__currentEditableObject.serviceClass;
    const updateMethod = objectClass.toLowerCase() + "_details";
    const dialogRef = this.dialog.open(DialogEditComponent, {
      data: { service: obj, editType: edit_type_param },
    });
    dialogRef.componentInstance.oneditready.subscribe((data) => {
      if (edit_type_param === 1) {
        this.__currentEditableObject = data;
        this.confirmNameEdit()
          .then(() => {
            dialogRef.close();
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
    // if (!editOptions)
    //     this.dialogRef = this.dialog.open(this.editLightpathTemplateRef);
    // else
    //     this.dialogRef = this.dialog.open(this.editLightpathOptionsTemplateRef);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  confirmNameEdit() {
    const update = function (value: string) {
      // return a single promise
      const exists = this.__currentEditableObject.customerDescriptionExists;
      return this.api.set_customer_description(
        this.auth.state.viewingCustomer.customerId,
        this.__currentEditableObject.subscriptionId,
        value,
        exists,
      );
    }.bind(this);

    const runUpdates = (updates: any[]) =>
      updates
        .reduce(
          (promise, item) =>
            promise
              .then((result) =>
                update(item.value).then((res) => {
                  console.log("Update ran successfully");
                }),
              )
              .catch((err) => {
                console.log("Update encountered an error");
              }),
          Promise.resolve(),
        )
        .bind(this);

    if (this.__currentEditableObject.hasUpdates()) {
      return runUpdates(this.__currentEditableObject.updatesArray);
    } else {
      return Promise.resolve();
    }
  }

  public confirmFulfilmentRequest() {
    throw new Error("Undefined function");
  }
}
