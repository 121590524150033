import { HttpHeaders } from "@angular/common/http";
import { setContext } from "@apollo/client/link/context";

export const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("access_token");
  if (token === null) {
    return {};
  }

  return {
    headers: new HttpHeaders({
      Authorization: `Bearer ${token}`,
    }),
  };
});
