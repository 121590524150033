import { Instance } from "./instance";
import { transform } from "../../helpers/subscription/port-shared";

export class PortInstance extends Instance {
  subscriptionId: string;
  customerDescriptions: any[];
  services: any[];
  portId: string;

  constructor(data?: any) {
    super();

    // Object.entries(data).map((entry) => {
    //   this[entry[0]] = entry[1];
    // });
    if (data) {
      const transformedSubscription = transform(data);
      if (transformedSubscription !== null) {
        Object.entries(transformedSubscription).forEach((entry) => {
          this[entry[0]] = entry[1];
        });
      }

      this.servicesOnPort = this.services || []; // this.transformServicesOnPort(data?.inUseBy);
    }
  }

  transformServicesOnPort(inUseByData) {
    return inUseByData.map((service) => ({
      ...service,
      serviceSpeed:
        service.subscriptionType === "LightPathRedundantSubscription" ?
          service?.lrss.vcs[0].serviceSpeed
        : service?.vc.serviceSpeed,
    }));
  }
}
